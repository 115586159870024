import { parseISO } from 'date-fns'
import { GqlDocumentationUrlFragment } from 'src/services'
import { FetchDocsLink } from './docs.types'

export function mapApiToDocsLink(
  documentationUrl: GqlDocumentationUrlFragment,
): FetchDocsLink {
  return {
    ...documentationUrl,
    expiry: parseISO(documentationUrl.expiry),
  }
}
