import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import * as api from 'src/services'
import { SITE_USERS } from './site.get'

export function useAddSiteUserMutation(): UseMutationResult<
  api.GqlInviteFactoryUserMutation,
  Error,
  api.GqlInviteFactoryUserInput
> {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: api.inviteUser,
    onSuccess: (_, input) => {
      queryClient.invalidateQueries([SITE_USERS, input.factoryId])
    },
  })
}
