import { TableConfig, Text } from 'src/components/ui'
import { NodeActions } from './components'

type Props = {
  siteId: string
}
export const getOpcSubscriptionTableConfig = ({
  siteId,
}: Props): TableConfig => {
  return {
    allowPaging: true,
    pageSize: 10,
    allowSorting: true,
    columns: [
      {
        field: 'id',
        headerName: 'Tag ID',
        initialFlex: 300,
        cellRenderer: ({ data }: any) => {
          return <Text bold>{data.name || data.id}</Text>
        },
      },
      {
        field: 'displayName',
        headerName: 'Display Name',
        initialFlex: 300,
        cellRenderer: ({ data }: any) => {
          return <Text>{data.displayName}</Text>
        },
      },
      {
        field: 'customIcon',
        colId: 'actions',
        headerName: 'Actions',
        width: 150,
        cellClass: 'selector-cell',
        cellRenderer: ({ data }: any) => {
          return <NodeActions siteId={siteId} tagName={data.tagName} />
        },
      },
    ],
  }
}
