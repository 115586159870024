import React from 'react'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { OnlyModelProps, SiteRole } from 'src/types'
import { Button, ConfirmModal } from 'src/components/ui'
import { useSite } from 'src/contexts/site'
import { useDeleteModelMutation } from 'models/api'
import { isModelEditing } from '../Overview/modelOverview.state'

export function DeleteButton({ model }: OnlyModelProps): JSX.Element {
  const [showConfirm, setShowConfirm] = React.useState(false)

  const deleteModel = useDeleteModelMutation()
  const navigate = useNavigate()
  const { rootLink, viewerRole } = useSite()
  const isReader = viewerRole === SiteRole.READER

  async function onClick(id: string): Promise<void> {
    await deleteModel.mutateAsync(
      { id },
      {
        onSuccess: () => {
          toast.success(`Successfully deleted model ${id}`)
          navigate(`${rootLink}/models`)
        },
        onError: () => {
          setShowConfirm(false)
          // We're adding timeout to make the toast more visible
          // we ensure the modal closes first before triggering the toast
          setTimeout(
            () =>
              toast.error('Failed to delete the model', {
                position: 'top-right',
              }),
            300,
          )
        },
      },
    )
  }

  return (
    <>
      {showConfirm && (
        <ConfirmModal
          text={
            <>
              Are you sure you want to delete <b>{model.name}</b>?
            </>
          }
          subText="The model and all of it's data will be deleted. This action cannot be undone."
          pending={deleteModel.isLoading}
          renderConfirmButton={() => (
            <Button
              title="Delete"
              variant="primary"
              buttonColor="danger"
              onClick={() => onClick(model.id)}
              isPending={deleteModel.isLoading}
            />
          )}
          isOpen={showConfirm}
          setIsOpen={setShowConfirm}
        />
      )}
      <Button
        title="Delete model"
        variant="secondary"
        buttonColor="danger"
        onClick={() => setShowConfirm(true)}
        disabled={isReader || isModelEditing.value}
      />
    </>
  )
}
