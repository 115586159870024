import { MomentInput } from 'moment'
import { useState } from 'react'
import { Text, Tooltip } from 'src/components/ui'
import { humanDateTime, timeAgo } from 'src/utility/time'

export function UpdatedAtRenderer({
  value,
}: {
  value: MomentInput
}): JSX.Element {
  // Force rerender every 1 minute
  const [tick, setTick] = useState(0)

  setTimeout(() => {
    setTick(prev => prev + 1)
  }, 60000)
  return (
    <div key={tick}>
      <Tooltip
        direction="bottom"
        render={() => <Text variant="description">{humanDateTime(value)}</Text>}
      >
        {timeAgo(value)}
      </Tooltip>
    </div>
  )
}
