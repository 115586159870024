import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { add, formatISO } from 'date-fns'
import { datadogRum } from '@datadog/browser-rum'
import * as api from 'src/services'
import { useSite } from 'src/contexts/site'
import { MODELS_QUERY_KEY, MODEL_QUERY_KEY } from 'models/api'
import { addSubscription } from 'src/utility/subscriptionHelper'

export function useArchiveModelMutation(): UseMutationResult<
  api.GqlModelIdStateFragment | null | undefined,
  unknown,
  api.GqlArchiveModelInput
> {
  const queryClient = useQueryClient()
  const { id: currentFactory } = useSite()

  return useMutation({
    mutationFn: async (input: api.GqlArchiveModelInput) =>
      api.archiveModel(input),
    onSuccess: (_, input) => {
      queryClient.invalidateQueries([MODELS_QUERY_KEY, currentFactory])
      queryClient.invalidateQueries([MODEL_QUERY_KEY, input.id])
    },
  })
}

export function useAddInputTagMutation(): UseMutationResult<
  api.GqlModelBaseFragment | null | undefined,
  unknown,
  api.InputTag
> {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (input: api.InputTag) => api.addInputTag(input),
    onSuccess: (_, input) => {
      queryClient.invalidateQueries([MODEL_QUERY_KEY, input.modelId])
    },
  })
}

export function useRemoveInputTagMutation(): UseMutationResult<
  api.GqlModelBaseFragment | null | undefined,
  unknown,
  api.InputTag
> {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (input: api.InputTag) => api.removeInputTag(input),
    onSuccess: (data, input) => {
      queryClient.invalidateQueries([MODEL_QUERY_KEY, input.modelId])
    },
  })
}

export function useActivateModelMutation(): UseMutationResult<
  api.GqlModelIdStateFragment | undefined | null,
  unknown,
  api.GqlStartModelInput
> {
  const { id: currentFactory } = useSite()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (input: api.GqlStartModelInput) => {
      datadogRum.addAction('Model activated', input)
      return api.activateModel(input)
    },
    onSuccess: (_, input) => {
      queryClient.invalidateQueries([MODEL_QUERY_KEY, input.id])
      queryClient.invalidateQueries([MODELS_QUERY_KEY, currentFactory])
    },
  })
}

export function useDeactivateModelMutation(): UseMutationResult<
  api.GqlModelIdStateFragment | undefined | null,
  unknown,
  api.GqlStopModelInput
> {
  const { id: currentFactory } = useSite()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (input: api.GqlStopModelInput) => {
      datadogRum.addAction('Model deactivated', input)
      return api.deactivateModel(input)
    },
    onSuccess: (_, input) => {
      queryClient.invalidateQueries([MODEL_QUERY_KEY, input.id])
      queryClient.invalidateQueries([MODELS_QUERY_KEY, currentFactory])
    },
  })
}

export function useStartModelTrainingMutation(
  model: api.FetchModel,
): UseMutationResult<
  api.GqlModelIdStateFragment | undefined | null,
  unknown,
  api.GqlActivateModelInput
> {
  const { id: currentFactory } = useSite()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (input: api.GqlActivateModelInput) => {
      datadogRum.addAction('Model training started', input)
      return api.startModelTraining(input)
    },
    onSuccess: (_, input) => {
      // add subscription to poll for model state
      const expire = formatISO(add(new Date(), { hours: 24 }))
      const load = {
        topic: 'trainModel',
        entity: input.id,
        event:
          model.__typename === 'ForecastModel'
            ? 'NOT_RUNNING'
            : 'AWAITING_ANOMALY_ANALYSIS',
        expire,
      }
      addSubscription(load)
      queryClient.invalidateQueries([MODEL_QUERY_KEY, input.id])
      queryClient.invalidateQueries([MODELS_QUERY_KEY, currentFactory])
    },
  })
}

export function useRunAnomalyDetectionMutation(): UseMutationResult<
  api.GqlModelIdStateFragment | undefined | null,
  unknown,
  api.GqlRunAnomalyDetectionInput
> {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (input: api.GqlRunAnomalyDetectionInput) =>
      api.runAnomalyDetection(input),
    onSuccess: (_, variables) => {
      const expire = formatISO(add(new Date(), { hours: 24 }))
      const load = {
        topic: 'anomalyDetection',
        entity: variables.id,
        event: 'NOT_RUNNING',
        expire,
      }
      addSubscription(load)
      queryClient.invalidateQueries([MODEL_QUERY_KEY, variables.id])
    },
  })
}

export function useAddStarMutation(): UseMutationResult<
  api.GqlStarrableStateFragment | null | undefined,
  unknown,
  api.GqlAddStarInput,
  unknown
> {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (input: api.GqlAddStarInput) => api.addStar(input),
    onSuccess: (_, input) => {
      queryClient.invalidateQueries([MODEL_QUERY_KEY, input.starrableId])
      queryClient.invalidateQueries([MODELS_QUERY_KEY])
    },
  })
}

export function useRemoveStarMutation(): UseMutationResult<
  api.GqlStarrableStateFragment | null | undefined,
  unknown,
  api.GqlRemoveStarInput,
  unknown
> {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (input: api.GqlRemoveStarInput) => api.removeStar(input),
    onSuccess: (_, input) => {
      queryClient.invalidateQueries([MODEL_QUERY_KEY, input.starrableId])
      queryClient.invalidateQueries([MODELS_QUERY_KEY])
    },
  })
}
