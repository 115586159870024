import { Link } from 'react-router-dom'
import { Card } from 'src/components/ui'
import { useTitle } from 'src/utility'
import {
  AnomalyCommentsCard,
  AnomalyStatusCard,
  AssetStatusCard,
  MetropolisDashboardCard,
} from './cards'

type DashboardProps = {
  kind: 'default' | 'metropolis'
  rootLink?: string
}

export function DashboardPage({
  kind,
  rootLink = '',
}: DashboardProps): JSX.Element {
  useTitle('Dashboard')

  if (kind === 'metropolis') {
    return (
      <div className="mx-4 mb-4">
        <MetropolisDashboardCard />
      </div>
    )
  }

  return (
    <div className="mx-4 mb-4 grid grid-cols-2 gap-4">
      <Card as={Link} to={`${rootLink}/assets`} $hovercolor>
        <AssetStatusCard />
      </Card>
      <div className="row-span-3">
        <AnomalyCommentsCard />
      </div>
      <Card as={Link} to={`${rootLink}/anomalies`} $hovercolor>
        <AnomalyStatusCard />
      </Card>
    </div>
  )
}
