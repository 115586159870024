import {
  and,
  Comparator,
  comparison,
  duration,
  DurationUnit,
  inCheck,
  isDurationUnit,
  Node,
  not,
  or,
} from './ast'
import { kindString, Lexer, Token, TokenKind } from './lexer'

const parseDuration = (duration: string): [number, DurationUnit] => {
  const time = duration.slice(0, -1)
  const unit = duration.slice(-1)
  if (!isDurationUnit(unit)) {
    throw new Error(`Unsupported duration unit ${unit}`)
  }
  return [parseInt(time, 10), unit]
}

export class Parser {
  private lexer: Lexer

  private current: Token

  constructor(lexer: Lexer) {
    this.lexer = lexer
    this.current = lexer.next()
  }

  parse(): Node {
    return this.parseCondition()
  }

  private next(): Token {
    this.current = this.lexer.next()
    // skip whitespace
    while (this.current.kind === TokenKind.WS) {
      this.current = this.lexer.next()
    }
    return this.current
  }

  private expect(kind: TokenKind): Token {
    if (this.current.kind !== kind) {
      throw new Error(
        `Expected '${kindString(kind)}' but received '${kindString(
          this.current.kind,
        )}'`,
      )
    }
    const token = this.current
    this.next()
    return token
  }

  private parseCondition(): Node {
    return this.parseOr()
  }

  private parseOr(): Node {
    const ands = []

    ands.push(this.parseAnd())
    while (this.current.kind === TokenKind.OR) {
      this.next()
      ands.push(this.parseAnd())
    }

    if (ands.length === 1) {
      return ands[0]
    }
    return or(ands)
  }

  private parseAnd(): Node {
    const nots = []

    nots.push(this.parseNot())
    while (this.current.kind === TokenKind.AND) {
      this.next()
      nots.push(this.parseNot())
    }

    if (nots.length === 1) {
      return nots[0]
    }
    return and(nots)
  }

  private parseNot(): Node {
    if (this.current.kind === TokenKind.NOT) {
      this.next()
      const node = this.parseFor()
      return not(node)
    }

    return this.parseFor()
  }

  private parseFor(): Node {
    if (this.current.kind === TokenKind.FOR) {
      this.next()
      this.expect(TokenKind.LPAREN)
      const durationToken = this.expect(TokenKind.Duration)
      this.expect(TokenKind.COMMA)
      const condition = this.parseCondition()
      this.expect(TokenKind.RPAREN)
      if (!durationToken.value) {
        throw new Error('Duration token with no value')
      }
      const [dur, unit] = parseDuration(durationToken.value)
      return duration(condition, dur, unit)
    }

    return this.parsePredicate()
  }

  private parsePredicate(): Node {
    // sub-condition
    if (this.current.kind === TokenKind.LPAREN) {
      this.next()
      const condition = this.parseCondition()
      this.expect(TokenKind.RPAREN)
      return condition
    }

    if (this.current.kind === TokenKind.Id) {
      if (!this.current.value) {
        throw new Error('Id token with no value')
      }
      const id = this.current.value
      const next = this.next()
      switch (next.kind) {
        case TokenKind.EQUAL:
        case TokenKind.NOTEQUAL:
        case TokenKind.GT:
        case TokenKind.GE:
        case TokenKind.LT:
        case TokenKind.LE: {
          this.next()
          const value = this.parseValue()
          return comparison(id, tokenKindToComparator(next.kind), value)
        }
        default: {
          // this should be an inCheck
          let not = false
          if (next.kind === TokenKind.NOT) {
            this.next()
            not = true
          }
          this.expect(TokenKind.IN)
          this.expect(TokenKind.LPAREN)
          const values = this.parseValues()
          this.expect(TokenKind.RPAREN)
          return inCheck(id, not, values)
        }
      }
    }

    throw new Error(
      `Expected predicate to start with '${kindString(
        TokenKind.Id,
      )}' or '${kindString(TokenKind.LPAREN)}', received '${kindString(
        this.current.kind,
      )}'`,
    )
  }

  private parseValues(): number[] {
    const values = []
    values.push(this.parseValue())
    while (this.current.kind === TokenKind.COMMA) {
      this.next()
      values.push(this.parseValue())
    }
    return values
  }

  private parseValue(): number {
    const value = this.expect(TokenKind.Value)
    if (!value.value) {
      throw new Error('Value token with no value')
    }
    return parseFloat(value.value)
  }
}

function tokenKindToComparator(kind: TokenKind): Comparator {
  switch (kind) {
    case TokenKind.EQUAL:
      return '='
    case TokenKind.NOTEQUAL:
      return '!='
    case TokenKind.GT:
      return '>'
    case TokenKind.GE:
      return '>='
    case TokenKind.LT:
      return '<'
    case TokenKind.LE:
      return '<='
    default:
      throw new Error(`TokenKind '${kindString(kind)} is not a Comparator`)
  }
}
