import { useParams } from 'react-router-dom'
import { useTitle } from 'src/utility'
import { Text, Spinner, Card } from 'src/components/ui'
import { useSite } from 'src/contexts/site'
import { ErrorDisplay } from 'pages/app'
import { useAnomaly } from 'anomalies/api'
import { Comments, Details, Navigation, Overview } from './sections'

export function AnomalyPage(): JSX.Element {
  const { anomalyId } = useParams()
  if (!anomalyId) throw new Error('`anomalyId` route param missing')
  useTitle(`Anomaly ${anomalyId}`)
  const { id: factory } = useSite()

  const anomalyQuery = useAnomaly(factory, anomalyId)

  if (anomalyQuery.isError) {
    return (
      <ErrorDisplay error={anomalyQuery.error} message="Anomaly not found" />
    )
  }

  if (anomalyQuery.isLoading) {
    return (
      <Card>
        <Text variant="title" bold className="!text-2xl">
          Anomaly
        </Text>
        <Spinner />
      </Card>
    )
  }

  return (
    <div className="m-[0.5em] mt-0 grid grid-cols-[minmax(0,1fr)_26em] gap-[0.5em]">
      <>
        <Overview anomaly={anomalyQuery.data} />
        <Navigation anomaly={anomalyQuery.data} />
        <Comments anomaly={anomalyQuery.data} />
        <Details anomaly={anomalyQuery.data} />
      </>
    </div>
  )
}
