import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useViewerQuery } from 'account/api'
import {
  SiteInvitation,
  SiteInvitationStatus,
  isDefined,
  siteRoleFromGql,
} from 'src/types'
import * as api from 'src/services'

export const SITE_INVITATIONS_QUERY = 'siteInvitations'
export function useSiteInvitations(
  siteId: string,
): UseQueryResult<api.GqlFactoryInvitation[]> {
  return useQuery([SITE_INVITATIONS_QUERY, siteId], async () => {
    return await api.fetchFactoryInvitations({ factory: siteId })
  })
}

export function useUserInvitations(): UseQueryResult<SiteInvitation[]> {
  return useViewerQuery(user => {
    if (!user.invitations?.items?.length) return []
    return toUserInvitations(user.invitations)
  })
}

function toUserInvitations(
  invitations: api.GqlMeFragment['invitations'],
): SiteInvitation[] {
  return (
    invitations?.items?.filter(isDefined).map(invitation => {
      return {
        id: invitation.id,
        site: {
          siteName: invitation.factory.name,
          orgName: invitation.factory.customer.name,
        },
        role: siteRoleFromGql(invitation.role),
        state: SiteInvitationStatus[invitation.state],
        inviter: invitation.inviter.name,
        validUntil: invitation.validUntil,
      }
    }) ?? []
  )
}
