import React from 'react'
import { Tag } from 'src/types'
import { AssetHierarchyContext } from './AssetHierarchyContext'

export const AssetHierarchyProvider = ({
  children,
}: {
  children?: React.ReactNode
}): JSX.Element => {
  const [targetId, setTargetId] = React.useState<number>()
  const [selectedTags, setSelectedTags] = React.useState<Tag[]>([])

  const setId = (id: number | undefined): void => {
    setTargetId(id)
  }

  return (
    <AssetHierarchyContext.Provider
      value={{ id: targetId, setId, selectedTags, setSelectedTags }}
    >
      {children}
    </AssetHierarchyContext.Provider>
  )
}
