import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Icon } from 'src/components/ui'

export function GatewayInstallGuideLink(): JSX.Element {
  return (
    <a
      href="https://intelecy.notion.site/Intelecy-Gateway-Install-Guide-a1b351d3e9134f9297844e0bf61627b3"
      target="_blank"
      rel="noreferrer"
      className="text-text-success-secondary no-underline"
    >
      Gateway Install Guide <Icon icon={light('external-link-alt')} />
    </a>
  )
}
