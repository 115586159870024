import { GqlAnomalyState } from 'src/services'
import { AnomalyColor } from 'src/types'

export const ANOMALY_STATUS: Array<{
  value: GqlAnomalyState
  label: string
  color: AnomalyColor
}> = [
  { value: 'NEW', label: 'NEW', color: 'red' },
  { value: 'IN_PROGRESS', label: 'IN PROGRESS', color: 'yellow' },
  { value: 'INCONCLUSIVE', label: 'INCONCLUSIVE', color: 'green' },
  { value: 'ANOMALY_KNOWN', label: 'ANOMALY KNOWN', color: 'green' },
  { value: 'ANOMALY_UNKNOWN', label: 'ANOMALY UNKNOWN', color: 'green' },
  { value: 'NORMAL_BEHAVIOUR', label: 'NORMAL BEHAVIOUR', color: 'green' },
]
