import { useState } from 'react'
import toast from 'react-hot-toast'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import { TagAction, getOutputTagWarningsFromModelTag } from 'pages/site/models'
import { useTags, useTag, useTagData } from 'tags/api'
import { NEW } from 'models/model/model.utils'
import {
  DynamicTable,
  Icon,
  Text,
  Tooltip,
  Button,
  Card,
  Spinner,
  TimePickerNavigation,
  TimeSeriesChart,
  Modal,
  Banner,
} from 'src/components/ui'
import { useSite } from 'src/contexts/site'
import { useUpdateModelMutation } from 'models/api'
import { OnlyModelProps } from 'src/types'
import { getTagOutputListConfig } from 'models/model/table-configs'
import { TagLabel } from '../TagLabel'

interface TrendChartProps {
  id: string
}

const TrendChart = (props: TrendChartProps): JSX.Element => {
  const { data: tag, isLoading } = useTag(props.id)
  const tagData = useTagData(props)

  return (
    <TimeSeriesChart
      {...props}
      data={[{ ...tag, data: tagData.data }]}
      isPending={isLoading || tagData.isLoading}
      tooltip
    />
  )
}

export function OutputTag({ model }: OnlyModelProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const { id: factory } = useSite()
  const tagsQuery = useTags()
  const messages = getOutputTagWarningsFromModelTag(model.tag)
  const isEditable = model.state === NEW

  const update = useUpdateModelMutation(model)

  const actions = (close: any): any => ({
    renderAction: ({ data: tag }: any) => {
      return (
        <TagAction
          isModal
          type="Update"
          tag={tag}
          tagAction={(tagName: string) => {
            const tag = tagsQuery.data?.find(item => item.tagName === tagName)
            if (!tag) return
            update.mutateAsync(
              {
                tagId: tag.tagNodeId,
              },
              {
                onSuccess: () => {
                  close()
                },
                onError: () => {
                  close()
                  setTimeout(() => {
                    toast.error('Failed to update the model', {
                      position: 'top-right',
                    })
                  })
                },
              },
            )
          }}
          modelType={model.__typename}
          modelOutputTagName={model.tag.tagName}
          isPending={update.isLoading}
        />
      )
    },
  })

  return (
    <>
      <Card className="col-span-2">
        <Text variant="title" bold>
          Output tag
        </Text>
        {isEditable ? (
          <div
            className={classNames(
              'flex items-center gap-[1em]',
              messages.length === 0 && 'pl-xl',
            )}
          >
            {messages.length > 0 && (
              <Tooltip
                render={() =>
                  messages.map((message, i) => <div key={i}>{message}</div>)
                }
                direction="right"
              >
                <Icon
                  icon={light('triangle-exclamation')}
                  className="!text-[2em] text-icon-warning"
                />
              </Tooltip>
            )}
            <TagLabel tagName={model.tag.tagName} />
            <Button
              variant="secondary"
              title="Change"
              onClick={() => setIsOpen(true)}
            />
            <Modal
              contentClassName="min-w-[min(1024px,90vw)]"
              isOpen={isOpen}
              close={() => setIsOpen(false)}
            >
              <div className="grid max-h-[90vh] gap-[0.5em]">
                <Text variant="title" className="!text-2xl" bold>
                  Select output tag
                </Text>
                <div style={{ marginBottom: '0.5em' }}>
                  {tagsQuery.isLoading ? (
                    <Spinner />
                  ) : tagsQuery.isError ? (
                    <Banner
                      variant="error"
                      rightComponent={
                        <Button
                          variant="primary"
                          title="Try again"
                          onClick={() => tagsQuery.refetch()}
                        />
                      }
                    >
                      Failed to load tags
                    </Banner>
                  ) : (
                    <DynamicTable
                      id={`${factory}-OutputTag`}
                      className="min-h-[min(50vh,500px)]"
                      data={tagsQuery.data.map(t => ({
                        ...t,
                        displayName: t.displayName || t.tagName,
                        label: t.overriddenLabel || t.automaticLabel,
                      }))}
                      config={getTagOutputListConfig(
                        actions(() => setIsOpen(false)),
                      )}
                    />
                  )}
                </div>
                <div className="flex justify-end">
                  <Button
                    title="Cancel"
                    variant="secondary"
                    onClick={() => setIsOpen(false)}
                  />
                </div>
              </div>
            </Modal>
            <div className="mb-xs flex-1">
              <TimePickerNavigation />
            </div>
          </div>
        ) : (
          <div
            className={classNames(
              'flex justify-between',
              messages.length === 0 && 'pl-xl',
            )}
          >
            <TagLabel tagName={model.tag.tagName} />
            <div className="mb-xs">
              <TimePickerNavigation />
            </div>
          </div>
        )}
        <TrendChart id={model.tag.tagName} />
      </Card>
    </>
  )
}
