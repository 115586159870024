import { humanDateTime } from 'src/utility/time'
import { SiteInvitation, SiteInvitationStatus } from 'src/types'
import { TableConfig } from 'src/components/ui'
import { InvitationActions } from './InvitationActions'

interface Actions {
  acceptInvitation: ({ id }: { id: string }) => Promise<void>
  openConfirmModal: ({ id }: { id: string }) => void
}

export const invitationsTableConfig = ({
  acceptInvitation,
  openConfirmModal,
}: Actions): TableConfig<SiteInvitation> => {
  return {
    pageSize: 10,
    allowPaging: true,
    pageSizes: [10, 50, 100],
    allowSorting: true,
    columns: [
      {
        headerName: 'Site',
        initialFlex: 200,
        cellRenderer: ({ data: { site } }: any) => (
          <span>
            {site.orgName}
            <b>{` | ${site.siteName}`}</b>
          </span>
        ),
      },
      {
        field: 'role',
        headerName: 'Role',
        initialFlex: 200,
        cellRenderer: ({ value }: any) => <b>{value}</b>,
      },
      {
        field: 'state',
        headerName: 'State',
        initialFlex: 200,
      },
      {
        field: 'inviter',
        headerName: 'Inviter',
        initialFlex: 200,
        cellRenderer: ({ value }: any) => <b>{value}</b>,
      },
      {
        field: 'validUntil',
        headerName: 'Valid Until',
        initialFlex: 200,
        cellRenderer: ({ value }: any) => <b>{humanDateTime(value)}</b>,
      },
      {
        headerName: 'Actions',
        initialFlex: 80,
        menuTabs: [],
        cellRenderer: ({ data: { id, state } }: any) => {
          if (state !== SiteInvitationStatus.ACTIVE) return null
          return (
            <InvitationActions
              id={id}
              acceptInvitation={acceptInvitation}
              openConfirmModal={openConfirmModal}
            />
          )
        },
        // colAllowFiltering: false,
        sortable: false,
      },
    ],
  }
}
