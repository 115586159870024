import { createContext } from 'react'
import { useModelAnomalies } from 'models/model'
import { ModelAnomalyWithModelName } from 'src/types'

type ModelAnomalyContextValue = {
  anomalies: ModelAnomalyWithModelName[]
  isLoading: boolean
}

// Note: this only appears to be used in `useAnomalyAreas`
// The default value is only returned if the context is accessed outside of the provider
// That should really throw an exception, since this is a code error
export const ModelAnomaliesContext = createContext<
  ModelAnomalyContextValue | undefined
>(undefined)

interface ModelAnomaliesProviderProps {
  modelId: string
  children: React.ReactNode
}

export const ModelAnomaliesProvider = ({
  modelId,
  children,
}: ModelAnomaliesProviderProps): JSX.Element => {
  const { data: anomalies = [], isLoading } = useModelAnomalies(modelId)

  return (
    <ModelAnomaliesContext.Provider value={{ anomalies, isLoading }}>
      {children}
    </ModelAnomaliesContext.Provider>
  )
}
