import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import ReactMarkdown from 'react-markdown'
import { sort } from 'semver'
import classNames from 'classnames'
import { ErrorDisplay } from 'pages/app'
import { Button, Icon, Modal, Spinner, Text } from 'src/components/ui'
import { isDefined } from 'src/types'
import { humanDate } from 'src/utility/time'
import { GatewayRelease } from 'src/services'
import { useGatewayReleases } from 'orgs-sites/site'
import './markdown-override.css'

type Props = {
  onClose: () => void
}

export function GatewayReleasesModal({ onClose }: Props): JSX.Element {
  const gatewayReleasesQuery = useGatewayReleases()
  const [selectedVersion, setSelectedVersion] = useState<string>()
  const sortedVersions = useMemo(
    () =>
      sort(
        gatewayReleasesQuery.data?.filter(r => !r.isBeta).map(r => r.semver) ??
          [],
      ),
    [gatewayReleasesQuery.data],
  )

  useEffect(() => {
    if (sortedVersions.length > 0)
      setSelectedVersion(sortedVersions[sortedVersions.length - 1])
  }, [sortedVersions])

  if (gatewayReleasesQuery.isLoading) {
    return <Spinner />
  }

  if (gatewayReleasesQuery.isError) {
    return (
      <ErrorDisplay
        error={gatewayReleasesQuery.error}
        action={gatewayReleasesQuery.refetch}
      />
    )
  }

  if (!(sortedVersions.length > 0)) return <></>

  const latestVersion = sortedVersions[sortedVersions.length - 1]
  const latestVersionObject = gatewayReleasesQuery.data.find(
    r => r.semver === latestVersion,
  )

  const previousReleases = sortedVersions
    .slice(0, sortedVersions.length - 1)
    .map(v => gatewayReleasesQuery.data.find(r => r.semver === v))
    .filter(isDefined)
    .slice(0, 3)
    .reverse()

  const selectedVersionObject = gatewayReleasesQuery.data.find(
    r => r.semver === selectedVersion,
  )

  function handleCopy(release: GatewayRelease): void {
    if (!release.url) return
    navigator.clipboard.writeText(release.url).then(() => {
      onClose()
      setTimeout(
        () =>
          toast.success(
            <div className="flex flex-col gap-3xs">
              <Text bold>URL was successfully copied!</Text>
              <Text>This URL is only valid for 24 hours.</Text>
            </div>,
          ),
        300,
      )
    })
  }

  function handleDownloadClick(release: GatewayRelease): void {
    if (!release.url) return
    const aTag = document.createElement('a')
    aTag.href = release.url
    aTag.download = release.summary
    aTag.click()
    setTimeout(() => toast.success('Download started'), 300)
    onClose()
  }

  return (
    <Modal isOpen close={onClose}>
      <div className="relative flex gap-m">
        <div
          className="absolute -right-s -top-l -translate-x-1/2 translate-y-1/2 cursor-pointer"
          onClick={onClose}
        >
          <Icon icon={light('xmark')} />
        </div>
        <div className="flex min-h-[620px] flex-1 flex-col justify-between bg-background">
          <div>
            <Text variant="title" className="text-center" bold>
              Select Gateway Release
            </Text>
            <Text bold className="mt-m">
              Latest Version
            </Text>
            <div
              className={classNames(
                'mt-2xs cursor-pointer rounded-2xs border border-solid border-border p-s transition duration-300 hover:border-border-secondary hover:shadow-sm',
                latestVersion === selectedVersion && 'border-border-secondary',
              )}
              onClick={() => setSelectedVersion(latestVersion ?? undefined)}
            >
              <div className="flex flex-wrap items-center justify-between gap-xs">
                <Text variant="title" className="whitespace-nowrap" bold>
                  Version {latestVersion}
                </Text>

                <div className="flex items-center justify-center rounded-2xs bg-background-secondary p-2xs">
                  <Text
                    variant="description"
                    bold
                    className="text-text-success-secondary"
                  >
                    Recomended
                  </Text>
                </div>
              </div>
              <div className="mt-s flex flex-wrap justify-between gap-s">
                <div className="flex flex-col gap-3xs">
                  <Text variant="description" bold>
                    Summary
                  </Text>
                  <Text variant="description" className="max-w-[250px]">
                    {latestVersionObject?.summary}
                  </Text>
                </div>
                <div className="flex flex-col gap-3xs">
                  <Text variant="description" bold>
                    Release Date
                  </Text>
                  <Text variant="description">
                    {humanDate(latestVersionObject?.release)}
                  </Text>
                </div>
              </div>
            </div>
            {previousReleases.length > 0 && (
              <div className="mt-xl">
                <Text variant="content-rubik" bold>
                  Previous Versions
                </Text>
                {previousReleases.map(r => (
                  <div
                    onClick={() => setSelectedVersion(r.semver)}
                    key={r.id}
                    className={classNames(
                      'mt-2xs cursor-pointer rounded-2xs border border-solid border-border px-s py-2xs hover:border-border-secondary transition duration-300 shadow-sm',
                      r.semver === selectedVersion && 'border-border-secondary',
                    )}
                  >
                    <div className="flex flex-col gap-2xs">
                      <Text variant="description" bold>
                        Version {r.semver}
                      </Text>
                      <Text variant="small">{humanDate(r.release)}</Text>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://intelecy.notion.site/Intelecy-Gateway-Install-Guide-a1b351d3e9134f9297844e0bf61627b3"
            className="flex items-center gap-2xs self-start text-inherit text-text-success-secondary no-underline"
          >
            <Icon icon={light('circle-question')} size="xsmall" />
            <Text variant="small">Gateway Install Guide</Text>
          </a>
        </div>
        <AnimatePresence>
          {selectedVersionObject && (
            <motion.div
              initial={{ opacity: 0, x: -40 }}
              animate={{ opacity: 1, x: 0 }}
              className="flex h-full min-h-[620px] min-w-[400px] max-w-[460px] flex-col justify-between rounded-2xs bg-background-hover px-m py-s"
            >
              <div className="flex flex-col gap-m">
                <div className="flex items-center justify-between">
                  <Text variant="title" bold>
                    Version {selectedVersion}
                  </Text>
                  {selectedVersionObject.semver === latestVersion && (
                    <div className="flex items-center justify-center rounded-2xs bg-background-secondary p-2xs">
                      <Text
                        variant="description"
                        bold
                        className="text-text-success-secondary"
                      >
                        Recomended
                      </Text>
                    </div>
                  )}
                </div>
                <div className="gatewayRelease max-h-[310px] overflow-y-auto">
                  <ReactMarkdown>
                    {selectedVersionObject.fullDescription ?? ''}
                  </ReactMarkdown>
                </div>
                <div className="h-px w-full bg-background-disabled" />
                <div className="flex flex-col gap-xs">
                  <div className="flex flex-col gap-3xs">
                    <Text variant="description" bold>
                      SHA256
                    </Text>
                    <Text
                      variant="code"
                      className="!text-description tracking-tighter"
                    >
                      {selectedVersionObject.sha256Hash}
                    </Text>
                  </div>
                  <div className="flex flex-col gap-3xs">
                    <Text variant="description" bold>
                      File Name
                    </Text>
                    <Text
                      variant="code"
                      className="!text-description tracking-tighter"
                    >
                      {selectedVersionObject.fileName}
                    </Text>
                  </div>
                </div>
              </div>
              <div>
                <div className="my-xs h-px w-full bg-background-disabled" />
                <div className="flex flex-col gap-xs">
                  <Text variant="small" className="max-w-[340px]">
                    Generate a download URL that can be used to download the
                    installer on a different host or download directly on
                    current host.
                  </Text>
                  <div className="flex items-center justify-center gap-xs">
                    <Button
                      variant="icon-primary"
                      className="!py-[7px]"
                      title="Copy Link"
                      icon={light('link')}
                      onClick={() => handleCopy(selectedVersionObject)}
                    />
                    <Text variant="small">Or</Text>
                    <Button
                      onClick={() => handleDownloadClick(selectedVersionObject)}
                      variant="icon-primary"
                      className="!py-[7px]"
                      title="Download Installer"
                      icon={light('download')}
                    />
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </Modal>
  )
}
