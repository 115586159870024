import React from 'react'
import { maxDuration, parse } from 'src/conditions'
import { Condition, DataPoint } from 'src/conditions/condition'
import { Node } from 'src/conditions/ast'
import useTimeRange from 'src/contexts/timeRange'
import { useTagsData } from 'tags/api'
import { TimeSeriesData } from 'src/types'
import { FilterContext, FilterPeriod } from './FilterContext'

const pointsToFilterPeriod = (
  data: DataPoint<boolean>[],
  start: number,
): FilterPeriod[] => {
  // fast escape
  if (data.length === 0) {
    return []
  }

  const periods: FilterPeriod[] = []
  let from: number | null = null

  for (const [timestamp, inCondition] of data) {
    // if we are in a filter period, see if we should stop
    // we stop if we go out of the condition or are at the
    // end of the data
    if (from !== null && !inCondition) {
      // only use timestamps >= start
      if (from >= start) {
        periods.push({ from, to: timestamp })
      } else if (timestamp >= start) {
        periods.push({ from: start, to: timestamp })
      }
      from = null
    } else if (from === null && inCondition) {
      from = timestamp
    }
  }

  // check if we ended in a condition
  if (from !== null) {
    periods.push({ from, to: undefined })
  }

  return periods
}

interface FilterProviderProps {
  // the initial condition for the filter
  condition?: string | null
  children?: React.ReactNode
}

// FIXME we lose all information that the condition parsing
// failed by returning undefined here
const parseConditionString = (cond?: string | null): Node | undefined => {
  if (cond) {
    try {
      return parse(cond)
    } catch (error) {
      console.error('Failed to parse condition', error)
    }
  }
  return undefined
}

export const FilterProvider = ({
  condition: conditionString,
  children,
}: FilterProviderProps): JSX.Element => {
  const [conditionAstNode, setConditionAstNode] = React.useState(
    parseConditionString(conditionString),
  )

  const condition = React.useMemo(
    () => (conditionAstNode ? new Condition(conditionAstNode) : undefined),
    [conditionAstNode],
  )
  const ids = React.useMemo(() => (condition ? condition.ids : []), [condition])

  const { timeRange: defaultTimeRange } = useTimeRange()

  // if the condition has a duration then we need data before the
  // the start of the shown period
  const timeRange = React.useMemo(() => {
    if (conditionAstNode) {
      const max = maxDuration(conditionAstNode)
      // round up to the nearest hour to avoid multiple fetches
      const extra = Math.ceil(max / 3600) * 3600
      return {
        from: defaultTimeRange.from - extra * 1000,
        to: defaultTimeRange.to,
      }
    }
    return defaultTimeRange
  }, [defaultTimeRange, conditionAstNode])

  const tagsData = useTagsData({ ids, timeRange })
  const pendingTags = tagsData.some(x => x.isLoading)

  let filter: FilterPeriod[] | undefined
  if (condition && !pendingTags && tagsData.every(item => !!item.data)) {
    const data: { [tagname: string]: TimeSeriesData[] } = {}
    tagsData.forEach((tagData, i) => {
      const tag = ids[i]
      data[tag] = tagData.data ?? []
    })
    const result = condition.evaluateAggregatedSeries(data)
    filter = pointsToFilterPeriod(result, defaultTimeRange.from)
  }

  return (
    <FilterContext.Provider
      value={{
        filter,
        condition: conditionAstNode,
        setCondition: setConditionAstNode,
        ids,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}
