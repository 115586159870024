import { useState } from 'react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Spinner, TextInput } from 'src/components/ui'
import { siteCompare } from 'orgs-sites/orgsSites.utils'
import { useMySites } from 'orgs-sites/site/api'
import { ErrorDisplay } from 'pages/app'
import { SiteCard } from '../../cards'

export function SiteList(): JSX.Element {
  const [filter, setFilter] = useState('')
  const mySitesQuery = useMySites()

  if (mySitesQuery.isLoading) return <Spinner />
  if (mySitesQuery.isError)
    return (
      <ErrorDisplay
        error={mySitesQuery.error}
        message="Failed to load sites"
        action={mySitesQuery.refetch}
      />
    )

  return (
    <>
      <div className="max-w-[200px] pt-s">
        {(mySitesQuery.data.length ?? 0) > 3 && (
          <TextInput
            iconLeft={light('magnifying-glass')}
            variant="underlined"
            value={filter}
            onChange={e => setFilter(e.target.value)}
          />
        )}
      </div>
      <div className="flex w-full flex-wrap items-center justify-start gap-s pt-s">
        {mySitesQuery.data
          .filter(s => {
            if (!filter) return true
            return (
              s.name.toLowerCase().includes(filter.toLowerCase()) ||
              s.orgName.toLowerCase().includes(filter.toLowerCase())
            )
          })
          .sort(siteCompare)
          .map(site => (
            <SiteCard key={site.id} site={site} />
          ))}
      </div>
    </>
  )
}
