import { formatISO } from 'date-fns'
import { TimeRange } from 'src/types'
import apiFetch from '../apiFetch'
import { graphqlApi } from '../graphQL'
import { Forecast, ForecastDto, HistoricalForecast } from './forecast.types'
import {
  mapApiToForecast,
  mapApiToHistoricalForecast,
} from './forecast.mappers'

export interface FetchForecast {
  modelId: string
  factory: string
}

export async function fetchForecast({
  modelId,
  factory,
}: FetchForecast): Promise<Forecast> {
  const response = await apiFetch({
    path: `/forecast/model/${modelId}`,
    query: {
      factory,
    },
  })
  return mapApiToForecast(ForecastDto.parse(await response.json()))
}

export interface FetchHistoricalForecast {
  modelId: string
  offsetInMilliseconds: number
  timeRange: TimeRange
}

export async function fetchHistoricalForecast({
  modelId,
  offsetInMilliseconds,
  timeRange: { from, to },
}: FetchHistoricalForecast): Promise<HistoricalForecast> {
  const api = await graphqlApi()
  const response = await api.FetchHistoricalForecast({
    modelId,
    offset: offsetInMilliseconds,
    from: formatISO(from),
    to: formatISO(to),
  })

  if (!(response.node?.__typename === 'ForecastModel'))
    throw new Error('Invalid modelId')

  return mapApiToHistoricalForecast(response.node.historicalForecast)
}
