import { IntelecySpinner } from 'src/components/ui'

interface SplashScreenProps {
  text: string
}

export function SplashScreen({ text }: SplashScreenProps): JSX.Element {
  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center gap-[1em] bg-[#fafafa]">
      <IntelecySpinner />
      <div className="text-[1.8em]">{text}</div>
    </div>
  )
}
