import { NumberInput, Button } from 'src/components/ui'
import { FormCondition } from '../formConditions'
import { TagInput } from './TagInput'
import { ComparatorInput } from './ComparatorInput'

interface ConditionInputProps {
  value: FormCondition
  tags: Record<string, string>
  updateCondition: (id: string, props: FormCondition) => void
  removeCondition: (id: string) => void
}

export function ConditionInput({
  value,
  tags,
  updateCondition,
  removeCondition,
}: ConditionInputProps): JSX.Element {
  return (
    <div className="flex items-center gap-[0.3em]">
      <TagInput
        value={value.tag}
        onChange={tag => updateCondition(value.id, { ...value, tag })}
        tags={tags}
      />
      <ComparatorInput
        value={value.comparator}
        onChange={comparator =>
          updateCondition(value.id, { ...value, comparator })
        }
      />
      <NumberInput
        value={value.value1}
        placeholder="Value..."
        onChange={value1 => updateCondition(value.id, { ...value, value1 })}
      />
      {value.comparator === 'between' && (
        <>
          <span>and</span>
          <NumberInput
            value={value.value2}
            placeholder="Value..."
            onChange={value2 => updateCondition(value.id, { ...value, value2 })}
          />
        </>
      )}
      <span>for at least</span>
      <NumberInput
        value={value.duration}
        onChange={duration =>
          duration !== undefined &&
          updateCondition(value.id, { ...value, duration })
        }
        min={0}
        max={999}
      />
      <span>minutes</span>
      <Button
        variant="tertiary"
        buttonColor="danger"
        title="Remove"
        onClick={() => removeCondition(value.id)}
      />
    </div>
  )
}
