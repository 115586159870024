import React from 'react'
import { NavigationContext } from './NavigationContext'

export const NavigationProvider = ({
  children,
}: {
  children?: React.ReactNode
}): JSX.Element => {
  const [titleComponent, setTitleComponent] = React.useState<React.ReactNode>()

  return (
    <NavigationContext.Provider value={{ titleComponent, setTitleComponent }}>
      {children}
    </NavigationContext.Provider>
  )
}
