import { useEffect, useRef } from 'react'
import { Calendar } from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import './calendarStyles.css'
import { Text } from 'src/components/ui'

type DatePickerProps = {
  handleDateChange: (date: Date, includeTime?: boolean) => void
  error?: boolean
  value: Date
  maxDate?: Date
}

const DatePicker = ({
  value,
  handleDateChange,
  error,
  maxDate,
}: DatePickerProps): JSX.Element => {
  const calendarRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (calendarRef.current) {
      const elements = calendarRef.current.querySelectorAll(
        '.react-calendar__tile--active',
      )

      for (let i = 0; i < elements.length; i++) {
        if (error) {
          elements[i].classList.add('react-calendar__tile--active--error')
        } else {
          elements[i].classList.remove('react-calendar__tile--active--error')
        }
      }
    }
  }, [error, value])

  return (
    <div ref={calendarRef} className="relative block">
      <div
        className="absolute right-2xs top-3xs"
        onClick={() => handleDateChange(new Date())}
      >
        <Text variant="description" bold className="text-text-brand">
          Today
        </Text>
      </div>
      <Calendar
        minDetail="decade"
        maxDate={maxDate}
        onChange={value => {
          if (value instanceof Date) {
            handleDateChange(value)
          }
        }}
        onClickMonth={date => handleDateChange(date, true)}
        value={value}
      />
    </div>
  )
}

export default DatePicker
