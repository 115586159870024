import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { fetchFactoryDexAccess } from 'src/services'

const SITE_DEX_ACCESS = 'siteDexAccess'

export function useSiteDexAccess({
  siteId,
}: {
  siteId: string
}): UseQueryResult<boolean, Error> {
  return useQuery({
    queryKey: [SITE_DEX_ACCESS, siteId],
    queryFn: async () => {
      const data = await fetchFactoryDexAccess({ factory: siteId })
      return data.factory?.dexAccess ?? false
    },
  })
}
