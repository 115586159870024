import { isDefined } from 'src/types'
import { ChartMarker, ChartOptions, SeriesId } from 'src/types/chartTypes'

// Functions for working with charts immutably

/**
 * Adds a chart to a chart collection
 * @param charts the chart collection update
 * @param chart the chart to add
 * @returns the new chart collection
 */
// TODO should we be required/allowed to specify the id here?
// maybe it should be automatically added?
export function addChart(
  charts: ChartOptions[],
  chart: ChartOptions,
): ChartOptions[] {
  return [...charts, chart]
}

/**
 * Removes a chart from a chart collection
 * @param charts the chart collection to update
 * @param id the id of the chart to remove
 * @returns the new chart collection
 */
export function removeChart(
  charts: ChartOptions[],
  id: number,
): ChartOptions[] {
  return charts.filter(c => c.id !== id)
}

/**
 * Updates a chart in a chart collection
 * @param charts the chart collection to update
 * @param id the id of the chart to update
 * @param update the new chart or a function to update the chart
 * @returns the new chart collection
 */
// TODO do we need the id? Or should we just use the one in the update?
export function updateChart(
  charts: ChartOptions[],
  id: number,
  update: ChartOptions | ((chart: ChartOptions) => ChartOptions),
): ChartOptions[] {
  return charts.map(c => {
    if (c.id === id) {
      if (typeof update === 'function') {
        return update(c)
      }
      return update
    }
    return c
  })
}

/**
 * Reorders a chart collection
 * @param charts the chart collection to update
 * @param newOrder a list of the chart ids in the new order
 * @returns the new chart collection
 */
// TODO should we be able to truncate or edit contents here?
// TODO we should validate several things here:
// - `charts.length` === `newOrder.length`
// - there are no duplicates in `newOrder`
// - all id's in `newOrder` exist in `charts`
export function reorder(
  charts: ChartOptions[],
  newOrder: number[],
): ChartOptions[] {
  // make a map of id => Chart
  const map = new Map<number, ChartOptions>()
  charts.forEach(chart => map.set(chart.id, chart))
  // reorder
  // for now we just remove chart references that don't exist
  return newOrder.map(id => map.get(id)).filter(isDefined)
}

/**
 * Get the next available chart id for a chart collection
 * @param charts the chart collection
 * @returns the next id
 */
export function nextChartId(charts: ChartOptions[]): number {
  const ids = charts.map(c => c.id)
  const set = new Set(ids)
  return nextId(set)
}

export function nextId(set: Set<number>): number {
  for (let i = 0; i < 1000; i++) {
    if (!set.has(i)) {
      return i
    }
  }
  return -1
}

export function seriesEqualById<S extends SeriesId>(a: S, b: S): boolean {
  if (a.type === 'forecast' && b.type === 'forecast') {
    return a.id === b.id && a.offsetMs === b.offsetMs
  }

  return a.type === b.type && a.id === b.id
}

/**
 * Removes a series from a chart
 * @param chart the chart to remove the series from
 * @param series the series to remove
 * @returns the new chart
 */
export function removeSeries<S extends SeriesId>(
  chart: ChartOptions,
  series: S,
): ChartOptions {
  return {
    ...chart,
    data: chart.data.filter(s => !seriesEqualById(s, series)),
  }
}

/**
 * Does the chart contain the given series data
 * @param chart the chart to check
 * @param series the series to look for
 * @returns whether the chart contains the series
 */
export function hasSeries<S extends SeriesId>(
  chart: ChartOptions,
  series: S,
): boolean {
  return chart.data.some(d => seriesEqualById(d, series))
}

/**
 * Adds a new marker to a chart
 * @param chart the chart to add the marker to
 * @param marker the marker to add
 * @returns the new chart
 */
export function addMarker(
  chart: ChartOptions,
  marker: ChartMarker,
): ChartOptions {
  const markers = chart.markers ?? []
  return {
    ...chart,
    markers: [...markers, marker],
  }
}

/**
 * Updates a chart marker
 * @param chart the chart to update
 * @param id the id of the marker to update
 * @param update the update to apply
 * @returns the new chart
 */
export function updateMarker(
  chart: ChartOptions,
  id: string,
  update: ChartMarker | ((marker: ChartMarker) => ChartMarker),
): ChartOptions {
  const markers = chart.markers?.map(m => {
    if (m.id === id) {
      if (typeof update === 'function') {
        return update(m)
      }
      return update
    }
    return m
  })
  return {
    ...chart,
    markers,
  }
}

/**
 * Removes a marker from a chart
 * @param chart the chart to remove the marker from
 * @param id the id of the marker to remove
 * @returns the new chart
 */
export function removeMarker(chart: ChartOptions, id: string): ChartOptions {
  return {
    ...chart,
    markers: chart.markers?.filter(m => m.id !== id),
  }
}

export function seriesIdToString(seriesId: SeriesId): string {
  if (seriesId.type === 'forecast') {
    return `${seriesId.type}-${seriesId.id}-${seriesId.offsetMs}`
  }
  return `${seriesId.type}-${seriesId.id}`
}
