import React from 'react'
import { Link } from 'react-router-dom'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@components/dropdown-menu'
import { Text } from 'src/components/ui'
import { MenuContent, MenuItem, MenuLink } from './Dropdown.types'

function isMenuLink(item: MenuLink | MenuItem): item is MenuLink {
  return (item as MenuLink).url !== undefined
}

type Props = {
  children: React.ReactNode
  menuContent: MenuContent
  align?: 'start' | 'center' | 'end'
}

export function Dropdown({
  children,
  menuContent,
  align = 'start',
}: Props): JSX.Element {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="cursor-pointer border-none bg-transparent !p-0 outline-none">
        {children}
      </DropdownMenuTrigger>
      <DropdownMenuContent align={align} className="bg-white">
        {menuContent.label && (
          <DropdownMenuLabel>
            <Text variant="description" bold>
              {menuContent.label}
            </Text>
          </DropdownMenuLabel>
        )}
        {menuContent.items.map(item => (
          <DropdownMenuItem
            data-testId={`dropdownMenuItem`}
            className="cursor-pointer hover:bg-background-hover"
            key={item.label}
            onClick={!isMenuLink(item) ? item.onClick : undefined}
          >
            {isMenuLink(item) ? (
              <Link to={item.url} className="text-inherit no-underline">
                <Text variant="description">{item.label}</Text>
              </Link>
            ) : (
              <Text variant="description">{item.label}</Text>
            )}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
