// A port of Go's path.Join
// https://cs.opensource.google/go/go/+/refs/tags/go1.20.1:src/path/path.go
export function join(...elem: string[]): string {
  if (!elem.some(e => e.length > 0)) {
    return ''
  }

  let buf = ''
  elem.forEach(e => {
    if (buf.length > 0 || e !== '') {
      if (buf.length > 0) {
        buf += '/'
      }
      buf += e
    }
  })
  return clean(buf)
}

export function clean(path: string): string {
  if (path === '') {
    return '.'
  }

  const rooted = path[0] === '/'
  const n = path.length

  const out = Array.from(path)
  // position in the input path
  let r = 0
  // position in the output path
  let w = 0
  let dotdot = 0

  if (rooted) {
    out[w] = '/'
    w = 1
    r = 1
    dotdot = 1
  }

  while (r < n) {
    if (path[r] === '/') {
      // empty path element
      r++
    } else if (path[r] === '.' && (r + 1 === n || path[r + 1] === '/')) {
      // . element
      r++
    } else if (
      path[r] === '.' &&
      path[r + 1] === '.' &&
      (r + 2 === n || path[r + 2] === '/')
    ) {
      // .. element: remove to last /
      r += 2
      if (w > dotdot) {
        // can backtrack
        w--
        while (w > dotdot && out[w] !== '/') {
          w--
        }
      } else if (!rooted) {
        // cannot backtrack, but not rooted, so append .. element.
        if (w > 0) {
          out[w] = '/'
          w++
        }
        out[w] = '.'
        w++
        out[w] = '.'
        w++
        dotdot = w
      }
    } else {
      // real path element.
      // add slash if needed
      if ((rooted && w !== 1) || (!rooted && w !== 0)) {
        out[w] = '/'
        w++
      }
      // copy element
      for (; r < n && path[r] !== '/'; r++) {
        out[w] = path[r]
        w++
      }
    }
  }

  if (w === 0) {
    return '.'
  }

  return out.slice(0, w).join('')
}
