import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useState } from 'react'
import { Icon, Tooltip } from 'src/components/ui'

type Props = {
  id: string
  acceptInvitation: ({ id }: { id: string }) => Promise<void>
  openConfirmModal: ({ id }: { id: string }) => void
}

export function InvitationActions({
  id,
  acceptInvitation,
  openConfirmModal,
}: Props): JSX.Element {
  const [acceptDisabled, setAcceptDisabled] = useState(false)
  return (
    <>
      <Tooltip render={() => 'Accept invitation'} direction="bottom">
        <span
          className="fa-layers fa-fw"
          style={{ cursor: 'pointer' }}
          onClick={async () => {
            if (!acceptDisabled) {
              setAcceptDisabled(true)
              await acceptInvitation({ id })
            }
          }}
        >
          <Icon
            icon={regular('circle-check')}
            className="h-[1.3em] w-[1.3em] text-icon-brand"
          />
        </span>
      </Tooltip>
      <Tooltip render={() => 'Decline invitation'} direction="bottom">
        <span
          className="fa-layers fa-fw"
          style={{ cursor: 'pointer', marginLeft: '25px' }}
          onClick={async () => {
            openConfirmModal({ id })
          }}
        >
          <Icon
            icon={regular('circle-xmark')}
            className="h-[1.3em] w-[1.3em] text-text-danger"
          />
        </span>
      </Tooltip>
    </>
  )
}
