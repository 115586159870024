import { Listbox, Transition } from '@headlessui/react'
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import classNames from 'classnames'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useAddOrgUserMutation, useOrganizationSites } from 'orgs-sites/org/api'
import {
  Organization,
  OrganizationRole,
  organizationRoleToGql,
  SiteRole,
  siteRoleToGql,
} from 'src/types'
import { isValidEmail } from 'src/utility/stringValidator'
import { parseApiErrorMessage } from 'src/utility/errors'
import { Button, Icon, Text, Modal } from 'src/components/ui'

type Props = {
  org: Organization
  onClose: () => void
}

type FormValues = {
  email: string
  name: string
}

const FormSchema = Yup.object().shape({
  email: Yup.string()
    .test('valid-email', 'Invalid email', value => isValidEmail(value || ''))
    .required('Required'),
  name: Yup.string().required('Required'),
})

export type SiteToAdd = {
  id: string
  role: SiteRole
  name: string
}
export function AddOrgUserModal({ org, onClose }: Props): JSX.Element {
  const [selectedRole, setSelectedRole] = useState(OrganizationRole.MEMBER)
  const [isRoleOptionsListOpen, setIsRoleOptionsListOpen] = useState(false)
  const [sitesToAdd, setSitesToAdd] = useState<SiteToAdd[]>([])
  const orgSitesQuery = useOrganizationSites({ orgId: org.id })
  const addOrgUserMutation = useAddOrgUserMutation()
  const navigate = useNavigate()

  const roles: OrganizationRole[] = Object.values(OrganizationRole)

  function handleSiteChange(
    site: SiteToAdd,
    index: number,
    role?: SiteRole,
  ): void {
    const newSites = [...sitesToAdd]
    newSites[index].name = site.name
    newSites[index].id = site.id
    if (role) newSites[index].role = role
    setSitesToAdd(newSites)
  }

  const availableSites = orgSitesQuery.data?.filter(
    site => !sitesToAdd.some(s => s.name === site.name),
  )

  useEffect(() => {
    if (orgSitesQuery.data) {
      setSitesToAdd(
        orgSitesQuery.data.slice(0, 1).map(site => ({
          id: site.id,
          name: site.name,
          role: SiteRole.READER,
        })),
      )
    }
  }, [orgSitesQuery.data])

  function addNewSite(): void {
    if (availableSites && availableSites.length > 0) {
      const newSite = availableSites.slice(0, 1).map(site => ({
        id: site.id,
        name: site.name,
        role: SiteRole.READER,
      }))
      setSitesToAdd([...sitesToAdd, ...newSite])
    }
  }

  function removeSite(index: number): void {
    const newSites = [...sitesToAdd]
    newSites.splice(index, 1)
    setSitesToAdd(newSites)
  }

  async function addOrgUser({
    email,
    name,
    orgId,
    organizationRole,
    sitesToAdd,
  }: {
    email: string
    name: string
    orgId: string
    organizationRole: OrganizationRole
    sitesToAdd: SiteToAdd[]
  }): Promise<void> {
    await addOrgUserMutation.mutateAsync(
      {
        customerId: orgId,
        email,
        name,
        role: organizationRoleToGql(organizationRole),
        factoryRoles: sitesToAdd.map(site => {
          return {
            factoryId: site.id,
            role: siteRoleToGql(site.role),
          }
        }),
      },
      {
        onSuccess: () => {
          setTimeout(() => {
            toast.success(
              <Text>
                Invitation for <span className="font-500">{` ${email} `}</span>{' '}
                has been sent.
              </Text>,
              { position: 'top-right' },
            )
          }, 500)

          handleClose()
          navigate(`/settings/orgs/${orgId}`)
        },
      },
    )
  }

  async function handleSubmit({ email, name }: FormValues): Promise<void> {
    await addOrgUser({
      email,
      name,
      organizationRole: selectedRole,
      sitesToAdd,
      orgId: org.id,
    })
  }

  function initializeState(): void {
    setSelectedRole(OrganizationRole.MEMBER)
    setSitesToAdd([])
  }

  function handleClose(): void {
    addOrgUserMutation.reset()
    onClose()
  }

  return (
    <Formik
      initialValues={{
        email: '',
        name: '',
      }}
      validationSchema={FormSchema}
      onReset={(_, { setValues }) => {
        setValues({ email: '', name: '' })
        initializeState()
      }}
      onSubmit={async (values, { resetForm }) => {
        await handleSubmit(values)
        resetForm()
      }}
    >
      {({ errors, touched, isValid, submitForm }) => (
        <Modal
          title="Add User"
          isOpen
          close={onClose}
          footer={
            <Modal.Footer
              onCancel={onClose}
              onConfirm={submitForm}
              confirmLabel="Add User"
              type="success"
              buttonDisabled={
                addOrgUserMutation.isLoading || !isValid || !sitesToAdd.length
              }
            />
          }
        >
          <>
            <Text variant="content-rubik" className="!mt-s">
              Add User to Organization{' '}
              <span className="font-medium">{org.name}</span>
            </Text>
            <Form className="mb-s">
              <div className="relative my-m">
                <label className="mb-xs block" htmlFor="name">
                  <Text variant="small" bold className="my-xs">
                    Full name
                  </Text>
                </label>
                <Field
                  name="name"
                  placeholder="Full name"
                  className="mb-m flex w-full items-center rounded-2xs border-none pl-xs text-xs font-medium leading-[32px] outline-none ring-1 ring-border ring-offset-1 placeholder:font-thin"
                ></Field>
                <Text
                  variant="small"
                  className={`absolute -bottom-s left-0 text-text-danger`}
                >
                  {errors.name && touched.name ? errors.name : null}
                </Text>
              </div>
              <div className="relative my-m">
                <label className="mb-xs block" htmlFor="email">
                  <Text variant="small" bold className="my-xs">
                    Email Address
                  </Text>
                </label>
                <Field
                  name="email"
                  placeholder="Email Address"
                  className="mb-m flex w-full items-center rounded-2xs border-none pl-xs text-xs font-medium leading-[32px] outline-none ring-1 ring-border ring-offset-1 placeholder:font-thin"
                ></Field>
                <Text
                  variant="small"
                  className={`absolute -bottom-s left-0 text-text-danger`}
                >
                  {errors.email && touched.email ? errors.email : null}
                </Text>
              </div>

              <div className="my-m">
                <label className="mb-xs block" htmlFor="site_role">
                  <Text variant="small" bold className="my-xs">
                    Organization Role
                  </Text>
                </label>
                <div className="">
                  <Listbox value={selectedRole} onChange={setSelectedRole}>
                    <div className="relative mt-2xs">
                      <Listbox.Button
                        className={`${
                          isRoleOptionsListOpen && 'ring-black'
                        } relative w-full cursor-default rounded-2xs border-none bg-background p-xs text-left font-500 outline-none ring-1 ring-border ring-offset-1 focus:ring-black focus-visible:ring-black sm:text-xs`}
                      >
                        {/* relative w-full cursor-default rounded-sm bg-background py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-1 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm */}
                        <span className="block truncate">{selectedRole}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <Icon
                            icon={light('chevron-down')}
                            className={classNames(
                              'transition-all',
                              isRoleOptionsListOpen && 'rotate-180',
                            )}
                          />
                        </span>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options
                          style={{ paddingInlineStart: 0 }}
                          className="absolute mt-2xs max-h-60 w-full overflow-auto rounded-md bg-background py-2xs text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-description"
                        >
                          {({ open }) => {
                            setIsRoleOptionsListOpen(open)
                            return (
                              <>
                                {roles.map((role, index) => (
                                  <Listbox.Option
                                    key={index}
                                    className={({ active }) =>
                                      `relative list-none cursor-default select-none py-xs px-s ${
                                        active
                                          ? 'bg-gray-100 text-gray-900'
                                          : 'text-gray-900'
                                      }`
                                    }
                                    value={role}
                                  >
                                    {({ selected }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            'block truncate',
                                            selected
                                              ? 'font-medium'
                                              : 'font-normal',
                                          )}
                                        >
                                          {role}
                                        </span>
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </>
                            )
                          }}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                </div>
              </div>
              <Text variant="description" className="my-xs">
                *The <span className="font-500">Admin role</span> is required to
                be able to manage users for an Organization.
              </Text>
              <hr className="bg-background-disabled-light" />
              <div className="w-full">
                <div className="flex w-full justify-between">
                  <div className="w-3/5 pr-xs">
                    <Text variant="small" bold className="my-xs">
                      Select site
                    </Text>
                  </div>
                  <div className="w-2/5 pl-xs">
                    <Text variant="small" bold className="my-xs">
                      Assign Role
                    </Text>
                  </div>
                </div>
                {sitesToAdd.map((site, index) => (
                  <div
                    className="mb-xs flex w-full justify-between"
                    key={site.name}
                  >
                    <div className="w-3/5 pr-xs">
                      <div className="size-full">
                        <Listbox
                          value={sitesToAdd[index]}
                          onChange={site => handleSiteChange(site, index)}
                        >
                          {({ open }) => (
                            <div className="relative mt-2xs">
                              <Listbox.Button
                                className={`relative w-full cursor-default rounded-2xs border-none bg-background px-s py-xs text-left text-description outline-none ring-1 ring-[#d9d9d9] ring-offset-1 focus:ring-border-tertiary focus-visible:ring-border-tertiary`}
                              >
                                <Text variant="description" bold>
                                  {sitesToAdd[index]?.name}
                                </Text>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <Icon
                                    icon={light('chevron-down')}
                                    className={classNames(
                                      'transition-all',
                                      open && 'rotate-180',
                                    )}
                                  />{' '}
                                </span>
                              </Listbox.Button>
                              <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options
                                  style={{ paddingInlineStart: 0 }}
                                  className="absolute z-20 mt-2xs max-h-60 w-full overflow-auto rounded-2xs bg-background py-2xs text-description shadow-lg ring-1 ring-black/5 focus:outline-none"
                                >
                                  {() => {
                                    return (
                                      <>
                                        {availableSites?.map((site, index) => (
                                          <Listbox.Option
                                            key={index}
                                            className={({ active }) =>
                                              `relative list-none cursor-default select-none py-xs px-s ${
                                                active
                                                  ? 'bg-gray-100 text-gray-900'
                                                  : 'text-gray-900'
                                              }`
                                            }
                                            value={site}
                                          >
                                            {({ selected }) => (
                                              <>
                                                <Text
                                                  variant="description"
                                                  bold={selected}
                                                >
                                                  {site.name}
                                                </Text>
                                              </>
                                            )}
                                          </Listbox.Option>
                                        ))}
                                      </>
                                    )
                                  }}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          )}
                        </Listbox>
                      </div>
                    </div>
                    <div className="w-2/5 pl-xs">
                      <div className="flex size-full items-center justify-between gap-s">
                        <Listbox
                          value={sitesToAdd[index].role}
                          onChange={role =>
                            handleSiteChange(sitesToAdd[index], index, role)
                          }
                        >
                          {({ open }) => (
                            <div className="relative mt-2xs w-full">
                              <Listbox.Button
                                className={`relative w-full cursor-default rounded-2xs border-none bg-background px-s py-xs text-left text-description outline-none ring-1 ring-[#d9d9d9] ring-offset-1 focus:ring-border-tertiary focus-visible:ring-border-tertiary`}
                              >
                                {/* relative w-full cursor-default rounded-sm bg-background py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-1 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm */}
                                <Text variant="description" bold>
                                  {sitesToAdd[index]?.role}
                                </Text>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <Icon
                                    icon={light('chevron-down')}
                                    className={classNames(
                                      'transition-all',
                                      open && 'rotate-180',
                                    )}
                                  />{' '}
                                </span>
                              </Listbox.Button>
                              <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options
                                  style={{ paddingInlineStart: 0 }}
                                  className="absolute z-20 mt-2xs max-h-60 w-full overflow-auto rounded-2xs bg-background py-2xs text-description shadow-lg ring-1 ring-black/5 focus:outline-none"
                                >
                                  {() => {
                                    return (
                                      <>
                                        {Object.values(SiteRole).map(
                                          (role, index) => (
                                            <Listbox.Option
                                              key={index}
                                              className={({ active }) =>
                                                `relative list-none cursor-default select-none py-xs pl-s pr-xs ${
                                                  active
                                                    ? 'bg-gray-100 text-gray-900'
                                                    : 'text-gray-900'
                                                }`
                                              }
                                              value={role}
                                            >
                                              {({ selected }) => (
                                                <>
                                                  <Text
                                                    variant="description"
                                                    bold={selected}
                                                  >
                                                    {role}
                                                  </Text>
                                                </>
                                              )}
                                            </Listbox.Option>
                                          ),
                                        )}
                                      </>
                                    )
                                  }}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          )}
                        </Listbox>
                        <div
                          className="flex h-full items-center"
                          onClick={() => removeSite(index)}
                        >
                          <Icon
                            icon={regular('circle-xmark')}
                            size="big"
                            className="text-icon-danger-secondary"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <Button
                  icon={regular('circle-plus')}
                  variant={'icon-secondary'}
                  title="Add Site"
                  type="button"
                  disabled={!availableSites?.length}
                  onClick={addNewSite}
                ></Button>
              </div>
              {addOrgUserMutation.error && (
                <div className="mt-4 text-xs font-normal text-red-500">
                  {parseApiErrorMessage(
                    addOrgUserMutation.error,
                    'Failed to add user',
                  )}
                </div>
              )}
            </Form>
          </>
        </Modal>
      )}
    </Formik>
  )
}
