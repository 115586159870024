import { GqlCustomerRole, GqlFactoryRole } from 'src/services'

export enum SiteRole {
  ADMIN = 'Admin',
  WRITER = 'Writer',
  READER = 'Reader',
}

export function siteRoleToGql(role: SiteRole): GqlFactoryRole {
  switch (role) {
    case SiteRole.ADMIN:
      return 'ADMIN'
    case SiteRole.WRITER:
      return 'WRITER'
    case SiteRole.READER:
      return 'READER'
    default: {
      const exhaustiveCheck: never = role
      return exhaustiveCheck
    }
  }
}

export function siteRoleFromGql(role: GqlFactoryRole): SiteRole {
  switch (role) {
    case 'ADMIN':
      return SiteRole.ADMIN
    case 'WRITER':
      return SiteRole.WRITER
    case 'READER':
      return SiteRole.READER
    default: {
      const exhaustiveCheck: never = role
      return exhaustiveCheck
    }
  }
}

export enum OrganizationRole {
  ADMIN = 'Admin',
  MEMBER = 'Member',
}

export function organizationRoleToGql(role: OrganizationRole): GqlCustomerRole {
  switch (role) {
    case OrganizationRole.ADMIN:
      return 'ADMIN'
    case OrganizationRole.MEMBER:
      return 'MEMBER'
    default: {
      const exhaustiveCheck: never = role
      return exhaustiveCheck
    }
  }
}

export function organizationRoleFromGql(
  role: GqlCustomerRole,
): OrganizationRole {
  switch (role) {
    case 'ADMIN':
      return OrganizationRole.ADMIN
    case 'MEMBER':
      return OrganizationRole.MEMBER
    default: {
      const exhaustiveCheck: never = role
      return exhaustiveCheck
    }
  }
}

export function isOrgAdmin(role: OrganizationRole | null | undefined): boolean {
  return role === OrganizationRole.ADMIN
}
