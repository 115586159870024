import { useRef, useMemo, useEffect, useState } from 'react'
import { isEqual } from 'lodash'
import HighchartsReact from 'highcharts-react-official'
import useTimeRange from 'src/contexts/timeRange'
import { GqlErrorScoreKind, GqlThresholdKind, Anomaly } from 'src/services'
import {
  useAnomaly,
  useAnomalyAreas,
  useCursor,
  useFilterAreas,
  useNowLine,
  useThreshold,
  useHeightListener,
  useLoading,
  useZoom,
} from './utils'
import { Chart } from './Chart'

interface Data {
  id?: any
  data: any
  name?: string
  color?: string
}

interface TimeSeriesChartProps {
  id?: any
  data: Data[]
  isPending: boolean
  tooltip: any
  threshold?: number
  staticTimeRange?: any
  yAxis?: any
  unit?: any
  breaks?: any
  setYAxisRange?: any
  offset?: any
  desaturate?: boolean
  anomaly?: Anomaly
  yAxisPerData?: boolean
  thresholdType?: GqlThresholdKind
  errorScoreKind?: GqlErrorScoreKind
}

export function TimeSeriesChart({
  id,
  data,
  isPending,
  tooltip,
  threshold,
  thresholdType,
  errorScoreKind,
  staticTimeRange,
  yAxis = {},
  unit = '',
  breaks,
  setYAxisRange,
  offset,
  desaturate = false,
  anomaly,
  yAxisPerData,
}: TimeSeriesChartProps): JSX.Element {
  const ref = useRef<HighchartsReact.RefObject>(null)
  const { height, wrapperRef } = useHeightListener()
  const { timeRange, setTimeRange } = useTimeRange()
  useZoom({ ref, ...timeRange })
  useCursor({ ref, height, withTrackballs: true })
  useLoading({ ref, loading: isPending, data })
  useThreshold({ ref, threshold })
  useFilterAreas({ ref, desaturate })
  useNowLine({ ref })
  useAnomaly({ ref, anomaly })
  useAnomalyAreas({ ref })

  const ids = data.map(({ id }) => id).join(',')

  const [counterHack, setCounterHack] = useState(0)
  const oldData = useRef(data)
  useEffect(() => {
    if (!isEqual(data, oldData.current) && !isPending) {
      oldData.current = data
      setCounterHack(c => c + 1)
    }
  }, [data, isPending, offset])

  const firstData = (() => {
    try {
      return data[0].data[0][1]
    } catch (e) {
      return undefined
    }
  })()

  const chart = useMemo(
    () => (
      <Chart
        theRef={ref}
        wrapperRef={wrapperRef}
        data={data}
        setTimeRange={setTimeRange}
        height={height}
        tooltip={tooltip}
        staticTimeRange={staticTimeRange}
        threshold={threshold}
        thresholdType={thresholdType}
        errorScoreKind={errorScoreKind}
        yAxis={yAxis}
        unit={unit}
        breaks={breaks}
        setYAxisRange={setYAxisRange}
        yAxisPerData={yAxisPerData}
      />
    ),
    // TODO: Not good
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      counterHack,
      ids,
      id,
      ref,
      wrapperRef,
      height,
      staticTimeRange,
      threshold,
      thresholdType,
      yAxis.min,
      yAxis.max,
      firstData,
    ],
  )

  return <div className="overflow-hidden">{chart}</div>
}
