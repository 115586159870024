import { SelectInput } from 'src/components/ui'
import { comparatorString, FormComparator } from '../formConditions'

interface ComparatorInputProps {
  value?: string
  onChange: (value: FormComparator | undefined) => void
}

interface LabelValue {
  label: string
  value: FormComparator
}

interface Options {
  [index: string]: LabelValue
}

export function ComparatorInput({
  value,
  onChange,
}: ComparatorInputProps): JSX.Element {
  const comparators: FormComparator[] = [
    '=',
    '!=',
    '>',
    '>=',
    '<',
    '<=',
    'between',
  ]
  const options: Options = comparators.reduce((acc, comp) => {
    return { ...acc, [comp]: { label: comparatorString(comp), value: comp } }
  }, {})

  return (
    <div className="w-[220px]">
      <SelectInput
        options={Object.values(options)}
        onChange={option => onChange(option as FormComparator)}
        value={(value && options[value]) || undefined}
      />
    </div>
  )
}
