import { useState, useEffect, useRef } from 'react'
import { useTheme } from 'styled-components'

const space = 2
const duration = 3000
const delay = 300
const centerIndexes = [21, 22, 25, 26, 37, 38, 41, 42]
const transforms = [
  'rotate3d(0, 0, 1, 90deg)',
  'rotate3d(0, 1, 0, 90deg)',
  'rotate3d(1, 0, 0, 90deg)',
]

interface IntelecySpinnerProps {
  size?: number
}

interface Position {
  x: number
  y: number
}

export function IntelecySpinner({
  size = 2,
}: IntelecySpinnerProps): JSX.Element {
  const wrapper = useRef<HTMLDivElement>(null)
  const [positions, setPositions] = useState<Position[]>([])
  const theme = useTheme()

  const [transform, setTransform] = useState(0)

  useEffect(() => {
    let interval: NodeJS.Timeout
    const timeout = setTimeout(() => {
      setTransform(t => (t + 1) % transforms.length)
      interval = setInterval(
        () => setTransform(t => (t + 1) % transforms.length),
        duration + delay,
      )
    }, delay)

    return () => {
      if (interval) {
        clearInterval(interval)
      } else {
        clearTimeout(timeout)
      }
    }
  }, [])

  useEffect(() => {
    let unmounted = false
    const step = (): void => {
      if (!unmounted) {
        if (wrapper.current) {
          const { x: a, y: b } = wrapper.current.getBoundingClientRect()
          setPositions(
            [...wrapper.current.children].flatMap(layer =>
              [...layer.children].map(point => {
                const { x, y } = point.getBoundingClientRect()
                return { x: x - a, y: y - b }
              }),
            ),
          )
        }
        requestAnimationFrame(step)
      }
    }
    requestAnimationFrame(step)

    return () => {
      unmounted = true
    }
  }, [])

  return (
    <div
      className="flex items-center justify-center"
      style={{
        height: `${size * 12}em`,
      }}
    >
      <div className="relative" ref={wrapper} style={{ top: `-${size / 2}em` }}>
        {Array(4)
          .fill(undefined)
          .map((_, i) => (
            <div
              key={i}
              className="grid h-0 grid-cols-4 grid-rows-4 content-center justify-center"
              style={{
                gap: `${space * size}em`,
                transform: `rotate3d(0, 0, 1, 45deg) 
														rotate3d(1, -1, 0, 54.7deg) 
														${transforms[transform]}
														translate3d(0, 0, ${(i - 1.5) * space * size}em)`,
                transition: `transform ${duration}ms ease`,
              }}
            >
              {Array(4 * 4)
                .fill(undefined)
                .map((_, i) => (
                  <div key={i} />
                ))}
            </div>
          ))}
        {positions.map(({ x, y }, i) => (
          <div
            key={i}
            style={{
              height: `${size}em`,
              width: `${size}em`,
              borderRadius: '100%',
              backgroundColor: centerIndexes.includes(i)
                ? 'transparent'
                : theme.colors.brand,
              position: 'absolute',
              top: 0,
              left: `-${size / 2}em`,
              transform: `translate(${x}px, ${y}px)`,
            }}
          />
        ))}
      </div>
    </div>
  )
}
