import React from 'react'
import { FilterContext, FilterValue } from './FilterContext'

export const useFilter = (): FilterValue => {
  const context = React.useContext(FilterContext)
  if (context === undefined) {
    // FIXME we really should just throw an error here, but unfortunately,
    // we use useFilter without a FilterProvider. So we log an error and provide
    // an empty default
    // throw new Error('useFilter must be used within a FilterProvider')
    console.warn('useFilter used without a FilterProvider')
    // filter and condition are left as undefined to indicate no filter
    return {
      setCondition: () => {},
      ids: [],
    }
  }
  return context
}
