import { useQuery, UseQueryResult } from '@tanstack/react-query'
import * as api from 'src/services'
import { useSite } from 'src/contexts/site'

export const FETCH_FACTORY_TREND_VIEWS_QUERY = 'fetchFactoryTrendViews'

export function useFactoryTrendViews<T = api.GqlFactoryTrendViewFragment[]>(
  select?: (data: api.GqlFactoryTrendViewFragment[]) => T,
): UseQueryResult<T> {
  const { id: factory } = useSite()
  return useQuery({
    queryKey: [FETCH_FACTORY_TREND_VIEWS_QUERY, factory],
    queryFn: () => api.fetchFactoryTrendViews({ factory }),
    select,
  })
}
