const alphabet = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
]

const getRandomInt = (max: number): number => Math.floor(Math.random() * max)

const getRandomHex = (bytes: number): string => {
  const hex: string[] = []
  for (let i = 0; i < bytes * 2; i++) {
    hex.push(alphabet[getRandomInt(alphabet.length)])
  }
  return hex.join('')
}

const traceparent = (): string => {
  const traceId = getRandomHex(16)
  const parentId = getRandomHex(8)
  return `00-${traceId}-${parentId}-00`
}

export default traceparent
