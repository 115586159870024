import { useNavigate } from 'react-router-dom'
import { SelectInput, Text } from 'src/components/ui'
import { useAuth } from 'src/contexts/auth'

type FactoryPickerProps = {
  currentSiteId: string
}

export const FactoryPicker = ({
  currentSiteId,
}: FactoryPickerProps): JSX.Element => {
  const navigate = useNavigate()
  const {
    viewer: { factories },
  } = useAuth()

  const onSelect = (siteId: string): void => {
    // Clearing table filters and sorting
    const keys = Object.keys(localStorage).filter(
      key => key.includes('filterState') || key.includes('columnState'),
    )
    keys.forEach(key => localStorage.removeItem(key))
    navigate(`/site/${siteId}`)
  }

  const options = [...factories]
    .sort(
      (a, b) =>
        a.customer.name.localeCompare(b.customer.name) ||
        a.name.localeCompare(b.name),
    )
    .map(({ id, name, customer }) => ({
      value: id,
      label: `${customer.name} | ${name}`,
    }))

  return (
    <SelectInput
      value={options.find(o => o.value === currentSiteId)}
      options={options}
      onChange={onSelect}
      textVariant="description"
      bold
      leftComponent={<Text variant="description">Site: </Text>}
      className="!py-[7px]"
    />
  )
}
