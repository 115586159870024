import { useState } from 'react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  ChartAxis,
  ChartOptions,
  ChartType,
  SeriesId,
  SeriesOptions,
} from 'src/types/chartTypes'
import { Icon, Text, ScatterChart } from 'src/components/ui'
import { TimeSeriesChart, Legend, ScatterLegend } from './components'
import useChartData from './useChartData'

interface ChartProps {
  chart: ChartOptions
  setPreviewOptions: (index: number, options: Partial<SeriesOptions>) => void
  removeSeries: (id: SeriesId) => void
  setPreviewChart: (chart: ChartOptions) => void
}

const ChartPreview = ({
  chart,
  setPreviewOptions,
  removeSeries,
  setPreviewChart,
}: ChartProps): JSX.Element => {
  const [chartData, isPending] = useChartData(chart)
  const [hoverIndex, setHoverIndex] = useState()

  return (
    <div className="relative flex h-full flex-col justify-center p-s">
      {chart.type === ChartType.TimeSeries ? (
        <>
          <div className="flex max-h-[calc(100%-140px)] flex-1 flex-col">
            <TimeSeriesChart
              data={chartData}
              chart={chart}
              hoverIndex={hoverIndex}
              isPending={isPending}
            />
          </div>
          <Legend
            chart={chart}
            data={chartData}
            isDataPending={isPending}
            hoverIndex={hoverIndex}
            setHoverIndex={setHoverIndex}
            setOptions={(index, options) => setPreviewOptions(index, options)}
            isModal
            setChart={setPreviewChart}
            remove={index => removeSeries(chart.data[index])}
          />
        </>
      ) : (
        <>
          {chart.data.length == 2 ? (
            <ScatterChart
              chartData_x={
                chartData[chart.data.findIndex(d => d.axis === ChartAxis.X)]
              }
              chartData_y={
                chartData[chart.data.findIndex(d => d.axis === ChartAxis.Y)]
              }
              isPending={isPending}
              colorIndex={chart.colorIndex ?? chart.id}
            />
          ) : (
            <div className="flex flex-1 flex-col items-center justify-center gap-xs">
              <Icon
                icon={light('list-timeline')}
                className="!h-[48px] !w-[48px] text-text-tertiary"
              />
              <Text className="w-[130px] text-center text-text-tertiary">
                Select a metric from the left side panel
              </Text>
            </div>
          )}
          <ScatterLegend
            chart={chart}
            data={chartData}
            isDataPending={isPending}
            remove={index => removeSeries(chart.data[index])}
            switchAxis={() => {
              setPreviewChart({
                ...chart,
                data: chart.data.map(d => {
                  if (d.axis === ChartAxis.X) {
                    return { ...d, axis: ChartAxis.Y }
                  }
                  if (d.axis === ChartAxis.Y) {
                    return { ...d, axis: ChartAxis.X }
                  }
                  return d
                }),
              })
            }}
          />
        </>
      )}
    </div>
  )
}

export default ChartPreview
