import { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

const parentNode = document.querySelector('#root')

export function RootPortal({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  const element = useRef(document.createElement('div'))

  useEffect(() => {
    const currentElement = element.current
    parentNode?.appendChild(currentElement)

    return () => {
      parentNode?.removeChild(currentElement)
    }
  }, [])

  return ReactDOM.createPortal(children, element.current)
}
