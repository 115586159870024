import { ToastBar, Toaster as ToasterComponent } from 'react-hot-toast'
import { Banner } from 'src/components/ui'
import { zIndex } from 'src/utility/constants/StyleConstants'

export function Toaster(): JSX.Element {
  return (
    <ToasterComponent
      position="bottom-right"
      containerStyle={{ zIndex: zIndex.banners }}
    >
      {t => {
        if (t.type === 'success')
          return <Banner variant="success">{t.message}</Banner>
        if (t.type === 'error')
          return <Banner variant="error">{t.message}</Banner>
        return <ToastBar toast={t} />
      }}
    </ToasterComponent>
  )
}
