import React from 'react'
import useTimeRange from 'src/contexts/timeRange'
import { useSite } from 'src/contexts/site'
import { stringify } from 'src/conditions'
import { useFilter } from 'src/contexts/filter'
import { getGraphColor } from 'src/utility/graphUtils/graphColors'
import { Node } from 'src/conditions/ast'
import {
  Text,
  Button,
  Card,
  TimePickerNavigation,
  TimeSeriesChart,
} from 'src/components/ui'
import { useUpdateModelFilterMutation } from 'models/api'
import { FetchStatus, SiteRole, mutationStatusToFetchStatus } from 'src/types'
import {
  ConditionForm,
  DisplayCondition,
  Legend,
} from 'pages/site/models/model'
import { useTagsData, useTags } from 'tags/api'

interface FilterProps {
  tags: string[]
  model: {
    id: string
  }
  instantUpdate: boolean
  onEditing: (editing: boolean, clear?: boolean) => void
}
interface DisplayNames {
  [key: string]: string
}

export function AnomalyFilter({
  tags,
  model,
  instantUpdate,
  onEditing,
}: FilterProps): JSX.Element {
  const { condition, setCondition, ids } = useFilter()
  const { timeRange } = useTimeRange()
  const tagsQuery = useTags()

  // Whether to display the condition form
  const [isOpen, setIsOpen] = React.useState(false)
  // The tags to display in the filter chart
  const [displayTags, setDisplayTags] = React.useState(ids)

  const mutation = useUpdateModelFilterMutation()
  const filterUpdateStatus = mutationStatusToFetchStatus(mutation.status)

  const tagsData = useTagsData({ ids: displayTags, timeRange })

  const { viewerRole: role } = useSite()

  const displayNames: DisplayNames = tags.reduce((m, name) => {
    const tag = tagsQuery.data?.find(t => t.tagName === name)
    return { ...m, [name]: tag?.displayName }
  }, {})

  const updateFilter = (condition?: Node): any => {
    if (instantUpdate) {
      mutation.mutate({
        id: model.id,
        anomalyGenerationFilter: condition ? stringify(condition) : undefined,
      })
    } else {
      onEditing(false, condition === undefined)
    }
  }

  const handleEdit = (): void => {
    onEditing(true)
    setIsOpen(true)
  }

  const isPending = (): boolean => tagsData.some(x => x.isLoading)

  const chartData = (): any =>
    tagsData.map((x, i) => {
      const id = displayTags[i]
      return {
        tagName: id,
        data: x.data,
        color: getGraphColor(id),
      }
    })

  const tagDisplayName = (id: string): string => displayNames[id] || id

  const chartColors = (): Record<string, string> => {
    const colors: Record<string, string> = {
      'Filtered out': '#00000033',
    }
    for (const id of displayTags) {
      colors[tagDisplayName(id)] = getGraphColor(id)
    }
    return colors
  }

  return (
    <Card className="col-span-2">
      <Text variant="title" className="mb-xs" bold>
        Anomaly filter (optional)
      </Text>
      <Text className="text-text-tertiary">
        Anomalies will only be identified during periods where all conditions
        succeed.
      </Text>
      {isOpen ? (
        <>
          <ConditionForm
            condition={condition}
            setCondition={setCondition}
            close={() => setIsOpen(false)}
            tags={displayNames}
            updateModelFilter={updateFilter}
            filterUpdateStatus={filterUpdateStatus}
            setDisplayTags={setDisplayTags}
            instantUpdate={instantUpdate}
            onEditing={onEditing}
          />
          {displayTags && displayTags.length > 0 && (
            <>
              <div className="mb-xs">
                <TimePickerNavigation />
              </div>
              <Legend data={chartColors()} />
              <TimeSeriesChart
                tooltip
                data={chartData()}
                isPending={isPending()}
                yAxisPerData={true}
              />
            </>
          )}
        </>
      ) : (
        <div className="flex items-center gap-[0.5em]">
          {condition ? (
            <>
              <span>
                <DisplayCondition condition={condition} tags={displayNames} />
              </span>
              {role !== SiteRole.READER && (
                <>
                  <Button
                    variant="tertiary"
                    title="Edit"
                    onClick={handleEdit}
                  />

                  <Button
                    title="Clear filter"
                    variant="tertiary"
                    buttonColor="danger"
                    onClick={() => {
                      setCondition(undefined)
                      setDisplayTags([])
                      updateFilter(undefined)
                    }}
                    isPending={filterUpdateStatus === FetchStatus.pending}
                  />
                </>
              )}
            </>
          ) : (
            <>
              <span>Not set</span>
              <Button variant="tertiary" title="Edit" onClick={handleEdit} />
            </>
          )}
        </div>
      )}
    </Card>
  )
}
