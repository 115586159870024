import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import React from 'react'
import classNames from 'classnames'
import { useClickOutside } from 'src/utility'
import { AnomalyColor } from 'src/types'
import { Icon, Text } from '../..'

const colors = {
  green: {
    bg: 'bg-background-success',
    hover: 'hover:bg-background-success-hover',
    text: 'text-text-success',
  },
  red: {
    bg: 'bg-background-danger-tertiary-hover',
    hover: 'hover:bg-background-danger/20',
    text: 'text-text-danger',
  },
  yellow: {
    bg: 'bg-background-warning',
    hover: 'hover:bg-background-warning-hover',
    text: 'text-text-warning',
  },
}

export type Option = {
  label: string
  labelElement?: React.ReactNode
  value: string
  color?: AnomalyColor
}

type Props = {
  value: Option[]
  options: Option[]
  onChange: (value: Option, add: boolean) => void
  placeholder?: string
}

export default function MultipleSelectInput({
  value,
  options,
  onChange,
  placeholder,
}: Props): JSX.Element {
  const [isOpen, setIsOpen] = React.useState(false)
  const dropdownRef = React.useRef<HTMLDivElement>(null)

  useClickOutside(dropdownRef, () => {
    return setIsOpen(false)
  })

  const selectedValues = value.map(el => el.value)
  const remainingOptions = options.filter(
    el => !selectedValues.includes(el.value),
  )
  return (
    <div
      className={`relative flex min-h-l cursor-pointer items-center justify-between gap-s rounded-2xs border border-solid bg-background px-xs py-[5px] transition duration-300 ${
        isOpen ? 'border-border-brand' : 'border-border'
      }`}
      ref={dropdownRef}
      onClick={e => {
        if ((e.target as HTMLElement).id.startsWith('removeIcon')) return
        setIsOpen(!isOpen)
      }}
    >
      <div className="flex max-w-full flex-wrap items-center gap-xs">
        {value.length === 0 && (
          <Text className="text-text-tertiary">{placeholder}</Text>
        )}
        {value.map(opt =>
          opt.labelElement ? (
            opt.labelElement
          ) : (
            <div
              key={opt.value}
              className={classNames(
                'flex items-center gap-2xs whitespace-nowrap rounded-2xs',
                opt.color ? colors[opt.color].bg : colors.green.bg,
              )}
            >
              <Text variant="description" className="py-3xs pl-2xs">
                {opt.label}
              </Text>
              <Icon
                id={`removeIcon-${opt.value}`}
                className={classNames(
                  'rounded-r-2xs px-2xs py-3xs',
                  opt.color ? colors[opt.color].hover : colors.green.hover,
                )}
                icon={light('circle-xmark')}
                onClick={() => {
                  onChange(opt, false)
                }}
              />
            </div>
          ),
        )}
      </div>
      <Icon
        icon={light('chevron-down')}
        className={`transition duration-300 ${isOpen && 'rotate-180'}`}
      />
      {isOpen && (
        <div className="absolute right-0 top-[calc(100%+4px)] z-20 flex min-w-full rounded-2xs border border-solid border-border bg-background drop-shadow-dropdown">
          <div className="w-full py-xs">
            {remainingOptions.length === 0 ? (
              <Text className="px-xs" variant="description">
                No more options
              </Text>
            ) : (
              remainingOptions.map(option => (
                <div
                  key={option.value}
                  className={classNames(
                    'flex w-full cursor-pointer items-center gap-xs whitespace-nowrap py-2xs px-xs transition-all',
                    option.color
                      ? colors[option.color].hover
                      : colors.green.hover,
                  )}
                  onClick={() => {
                    onChange(option, true)
                  }}
                >
                  <Text
                    variant="description"
                    className={classNames(
                      'whitespace-nowrap py-[2px]',
                      option.color
                        ? colors[option.color].text
                        : colors.green.text,
                    )}
                  >
                    {option.label}
                  </Text>
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  )
}
