import { useMemo } from 'react'
import { Serde } from './serde'

type SessionValue<T> = {
  get: () => T | undefined
  set: (value: T) => void
  remove: () => void
}
export function useSessionValue<T>(
  key: string,
  serde: Serde<T>,
): SessionValue<T> {
  return useMemo<SessionValue<T>>(() => {
    return {
      get: () => {
        const value = sessionStorage.getItem(key)
        return value ? serde.deserialize(value) : undefined
      },
      set: (value: T) => sessionStorage.setItem(key, serde.serialize(value)),
      remove: () => sessionStorage.removeItem(key),
    }
  }, [key, serde])
}
