import { RefObject, useEffect, useState } from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'

export function useChartWidth(
  ref: RefObject<HighchartsReact.RefObject>,
): number | undefined {
  const [width, setWidth] = useState<number>()

  useEffect(() => {
    const handleRender: Highcharts.EventCallbackFunction<
      Highcharts.Chart
    > = e => setWidth(e?.target.plotWidth)
    const chart = ref.current?.chart

    if (chart) {
      setWidth(chart.plotWidth)
      Highcharts.addEvent(chart, 'render', handleRender)
    }

    return () => {
      if (chart) {
        Highcharts.removeEvent(chart, 'render', handleRender)
      }
    }
  }, [ref])

  return width
}
