import { Modal, Text } from 'src/components/ui'
import dangerIcon from './images/danger.svg'

type Props = {
  orgName: string
  siteName: string
  isOpen: boolean
  disabled?: boolean
  error?: string
  onClose: () => void
  onConfirm: () => void
}

export function RejectInvitationModal({
  orgName,
  siteName,
  isOpen,
  disabled,
  error,
  onClose,
  onConfirm,
}: Props): JSX.Element {
  return (
    <Modal
      title="Cancel Invitation"
      isOpen={isOpen}
      close={onClose}
      footer={
        <Modal.Footer
          onCancel={onClose}
          onConfirm={onConfirm}
          cancelLabel="Maintain Invite"
          confirmLabel="Decline Invitation"
          type="danger"
          buttonDisabled={!!disabled}
        />
      }
    >
      <div className="flex flex-col items-center">
        <div
          style={{
            background: `url(${dangerIcon}) no-repeat center center / contain`,
          }}
          className="mt-[33px] h-[48px] w-[54.9px] items-center"
        />
        <p className="mt-[33px] text-center font-[Rubik] text-lg font-medium leading-[25px]">
          Are you sure you want to{' '}
          <span className="font-bold text-[#D03C3C]">
            decline the invitation
          </span>{' '}
          to
          <br /> Organization <span className="font-bold">{orgName}</span> |
          site <span className="font-bold">{siteName}</span>?
        </p>
        <p className="w-[398px] pb-[15px] text-xs leading-[20px]">
          By declining the invitation, you will not receive access to the
          organization/site you are invited to.
        </p>
        {error && (
          <Text variant="description" className="my-xs text-text-danger">
            {error}
          </Text>
        )}
      </div>
    </Modal>
  )
}
