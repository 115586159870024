import { ReactNode } from 'react'
import { isWorkshopFactory } from 'src/utility/metropolis'
import { GqlFactoryRole, GqlMeFragment } from 'src/services'
import { SiteRole } from 'src/types'
import { SiteContext } from './SiteContext'

// extract the factory type from GqlMeFragment
type Factory = GqlMeFragment['factories'][number]

const mapFactoryRole = (role: GqlFactoryRole | null | undefined): SiteRole => {
  switch (role) {
    case 'ADMIN':
      return SiteRole.ADMIN
    case 'WRITER':
      return SiteRole.WRITER
    default:
      return SiteRole.READER
  }
}

interface SiteProviderProps {
  factory: Factory
  rootLink: string
  children: ReactNode
}

export const SiteProvider = ({
  children,
  factory,
  rootLink,
}: SiteProviderProps): JSX.Element => {
  const isWorkshop = isWorkshopFactory(factory.id)

  const value = {
    id: factory.id,
    name: factory.name,
    viewerRole: mapFactoryRole(factory.viewerRole),
    isWorkshop,
    rootLink,
  }
  return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>
}
