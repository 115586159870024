import { useEffect, useContext } from 'react'
import Color from 'color'
import { useTheme } from 'styled-components'
import HighchartsReact from 'highcharts-react-official'
import { ModelAnomaliesContext } from 'src/contexts/modelAnomalies'
import useTimeRange from 'src/contexts/timeRange'
import {
  anomalyDisplayTimeRange,
  AnomalyLike,
  TimeRange,
  timeRangeOverlap,
} from 'src/types'
import { useChartWidth } from './useChartWidth'
import { useChartHeight } from './useChartHeight'

const filter = (anomalies: AnomalyLike[], timeRange: TimeRange): TimeRange[] =>
  anomalies.map(anomalyDisplayTimeRange).flatMap(range => {
    const overlap = timeRangeOverlap(range, timeRange)
    return overlap ? [overlap] : []
  })

interface UseAnomalyAreas {
  ref: React.RefObject<HighchartsReact.RefObject>
}

export function useAnomalyAreas({ ref }: UseAnomalyAreas): void {
  const { anomalies = [] } = useContext(ModelAnomaliesContext) || {}
  const { timeRange } = useTimeRange()
  const width = useChartWidth(ref)
  const height = useChartHeight(ref)
  const theme = useTheme()

  useEffect(() => {
    if (ref.current) {
      const { xAxis, plotTop, plotHeight, renderer } = ref.current.chart
      const areas = filter(anomalies, timeRange).map(({ from, to }) => {
        const begin = xAxis[0].toPixels(from, false)
        const stop = xAxis[0].toPixels(to, false)

        return renderer
          .rect(begin, plotTop, stop - begin, plotHeight)
          .attr({
            fill: Color(theme.colors.danger).alpha(0.3).toString(),
          })
          .add()
      })

      return () => {
        areas.forEach(area => area.destroy())
      }
    }

    // empty cleanup
    return () => {}
  }, [ref, width, height, timeRange, theme.colors.danger, anomalies])
}
