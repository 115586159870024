export enum TokenKind {
  EOF = 0,
  LPAREN,
  RPAREN,
  COMMA,
  EQUAL,
  NOTEQUAL,
  GT,
  GE,
  LT,
  LE,

  OR,
  AND,
  NOT,
  IN,
  FOR,

  WS,

  Id,
  Value,
  Duration,
}

export function kindString(kind: TokenKind): string {
  switch (kind) {
    case TokenKind.EOF:
      return 'EOF'
    case TokenKind.LPAREN:
      return '('
    case TokenKind.RPAREN:
      return ')'
    case TokenKind.COMMA:
      return ','
    case TokenKind.EQUAL:
      return '='
    case TokenKind.NOTEQUAL:
      return '!='
    case TokenKind.GT:
      return '>'
    case TokenKind.GE:
      return '>='
    case TokenKind.LT:
      return '<'
    case TokenKind.LE:
      return '<='
    case TokenKind.OR:
      return 'OR'
    case TokenKind.AND:
      return 'AND'
    case TokenKind.NOT:
      return 'NOT'
    case TokenKind.IN:
      return 'IN'
    case TokenKind.FOR:
      return 'FOR'
    case TokenKind.WS:
      return '<whitespace>'
    case TokenKind.Id:
      return '<id>'
    case TokenKind.Value:
      return '<value>'
    case TokenKind.Duration:
      return '<duration>'
    default: {
      const exhaustiveCheck: never = kind
      return exhaustiveCheck
    }
  }
}

export type Token = {
  kind: TokenKind
  value?: string
}

export class Lexer {
  private input: string

  private position: number

  constructor(input: string) {
    this.input = input
    this.position = 0
  }

  // optimize with .charCodeAt
  private current(): string {
    return this.input[this.position]
  }

  next(): Token {
    if (this.position >= this.input.length) return { kind: TokenKind.EOF }

    switch (this.current()) {
      case ' ':
      case '\t':
      case '\r':
      case '\n':
        this.eatWhitespace()
        return { kind: TokenKind.WS }
      case '(':
        this.position++
        return { kind: TokenKind.LPAREN }
      case ')':
        this.position++
        return { kind: TokenKind.RPAREN }
      case ',':
        this.position++
        return { kind: TokenKind.COMMA }
      case '=':
        this.position++
        return { kind: TokenKind.EQUAL }
      case '!':
        this.position++
        if (this.current() === '=') {
          this.position++
          return { kind: TokenKind.NOTEQUAL }
        }
        throw new Error(`Expected '=' after '!', received '${this.current()}'`)
      case '>':
        this.position++
        if (this.current() === '=') {
          this.position++
          return { kind: TokenKind.GE }
        }
        return { kind: TokenKind.GT }
      case '<':
        this.position++
        if (this.current() === '=') {
          this.position++
          return { kind: TokenKind.LE }
        }
        return { kind: TokenKind.LT }
      case "'": {
        this.position++
        const start = this.position
        while (this.current() && this.current() !== "'") {
          this.position++
        }
        if (this.current() === "'") {
          this.position++
          return {
            kind: TokenKind.Id,
            value: this.input.substring(start, this.position - 1),
          }
        }
        throw new Error('Unterminated identifier')
      }
      case '-':
      case '+':
      case '.':
        return this.valueOrDuration()
      default: {
        if (isDigit(this.current())) {
          return this.valueOrDuration()
        }

        // otherwise, it should be a keyword
        const start = this.position
        if (this.eatAlpha() > 0) {
          const keyword = this.input.substring(start, this.position)
          switch (keyword.toLowerCase()) {
            case 'or':
              return { kind: TokenKind.OR }
            case 'and':
              return { kind: TokenKind.AND }
            case 'not':
              return { kind: TokenKind.NOT }
            case 'in':
              return { kind: TokenKind.IN }
            case 'for':
              return { kind: TokenKind.FOR }
            default:
              throw new Error(`Unknown keyword '${keyword}'`)
          }
        }
      }
    }

    throw new Error(`Unexpected '${this.current()}'`)
  }

  // duration:
  // 123m
  // 15s
  // value:
  // 123
  // +123
  // -123
  // [+/-] 15.
  // [+/-] 15.123
  // [+/-] .123
  private valueOrDuration(): Token {
    let canBeDuration = true
    const start = this.position

    const c = this.current()
    if (c === '-' || c === '+') {
      this.position++
      // durations can't have a sign
      canBeDuration = false
    }

    if (this.current() !== '.') {
      // process the integer bit
      if (this.eatDigits() < 1) {
        throw new Error('Expected digits')
      }

      // check for a '.'
      if (this.current() !== '.') {
        // no '.', so either integer or duration
        if (
          canBeDuration &&
          (this.current() === 'm' || this.current() === 's')
        ) {
          this.position++
          return {
            kind: TokenKind.Duration,
            value: this.input.substring(start, this.position),
          }
        }

        return {
          kind: TokenKind.Value,
          value: this.input.substring(start, this.position),
        }
      }
    }

    // if we're here then we've seen a '.'
    this.position++
    this.eatDigits()
    return {
      kind: TokenKind.Value,
      value: this.input.substring(start, this.position),
    }
  }

  private eatWhitespace(): void {
    while (isWhitespace(this.current())) {
      this.position++
    }
  }

  private eatDigits(): number {
    let count = 0
    while (isDigit(this.current())) {
      this.position++
      count++
    }
    return count
  }

  private eatAlpha(): number {
    let count = 0
    while (isAlpha(this.current())) {
      this.position++
      count++
    }
    return count
  }
}

function isAlpha(c: string): boolean {
  // yuck
  return /^[A-Za-z]$/.test(c)
}

function isWhitespace(c: string): boolean {
  switch (c) {
    case ' ':
    case '\t':
    case '\r':
    case '\n':
      return true
    default:
      return false
  }
}

function isDigit(c: string): boolean {
  switch (c) {
    case '0':
    case '1':
    case '2':
    case '3':
    case '4':
    case '5':
    case '6':
    case '7':
    case '8':
    case '9':
      return true
    default:
      return false
  }
}
