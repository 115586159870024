import humanizeDuration from 'humanize-duration'
import {
  add,
  hoursToSeconds,
  minutesToMilliseconds,
  minutesToSeconds,
  secondsToMilliseconds,
  sub,
} from 'date-fns'
import moment from 'moment'
import Color from 'color'
import { DefaultTheme } from 'styled-components'
import { Record } from 'pages/site/models'
import { FetchForecastModel, FetchModel } from 'src/services'
import { ModelTypes } from 'src/types'

type ForecastHorizonOption = {
  label: string
  value: number
}

export const forecastHorizonOptions: ForecastHorizonOption[] = [
  {
    label: '5 minutes',
    value: minutesToSeconds(5),
  },
  {
    label: '15 minutes',
    value: minutesToSeconds(15),
  },
  {
    label: '30 minutes',
    value: minutesToSeconds(30),
  },

  {
    label: '1 hour',
    value: hoursToSeconds(1),
  },
  {
    label: '2 hours',
    value: hoursToSeconds(2),
  },
  {
    label: '4 hours',
    value: hoursToSeconds(4),
  },
  {
    label: '8 hours',
    value: hoursToSeconds(8),
  },
  {
    label: '12 hours',
    value: hoursToSeconds(12),
  },
  {
    label: '24 hours',
    value: hoursToSeconds(24),
  },

  {
    label: '48 hours',
    value: hoursToSeconds(48),
  },
  {
    label: '7 days',
    value: hoursToSeconds(24 * 7),
  },
]

const fallbackForecastHorizonOptions = [
  {
    label: '15 minutes',
    value: minutesToSeconds(15),
  },
  { label: '30 minutes', value: minutesToSeconds(30) },
  { label: '45 minutes', value: minutesToSeconds(45) },
  { label: '60 minutes', value: minutesToSeconds(60) },
]

type ForecastOffsetOption = {
  label: string
  // value in seconds
  value: number
}

export function getOffsets(model: FetchModel): ForecastOffsetOption[] {
  if (!(model.__typename === 'ForecastModel')) return []
  switch (model?.method?.name) {
    case 'Forecast with horizon':
      return model.forecastHorizon
        ? generateOffsets(model.forecastHorizon).map(secondsToOption)
        : fallbackForecastHorizonOptions
    case 'Forecast 1H':
      return generateOffsets(hoursToSeconds(1)).map(secondsToOption)
    case 'Forecast 2H':
      return generateOffsets(hoursToSeconds(2)).map(secondsToOption)
    case 'Forecast 4H':
      return generateOffsets(hoursToSeconds(4)).map(secondsToOption)
    default:
      return []
  }
}

export function secondsToOption(seconds: number): ForecastOffsetOption {
  return {
    label: humanizeDuration(secondsToMilliseconds(seconds), {
      largest: 2,
    }),
    value: seconds,
  }
}

function generateOffsets(horizon: number): number[] {
  const forecastStep = horizon / 12
  return [
    3 * forecastStep,
    6 * forecastStep,
    9 * forecastStep,
    12 * forecastStep,
  ]
}

export function getModelOffset(model: FetchForecastModel): number {
  switch (model.method.name) {
    case 'Forecast 1H':
      return minutesToMilliseconds(60)
    case 'Forecast 2H':
      return minutesToMilliseconds(120)
    case 'Forecast 4H':
      return minutesToMilliseconds(240)
    default: {
      if (model.forecastHorizon)
        return secondsToMilliseconds(model.forecastHorizon)
      return minutesToMilliseconds(60)
    }
  }
}

export enum ForecastDefaultMethod {
  FORECAST_WITH_HORIZON = 'Forecast with horizon',
  FORECAST_1H = 'Forecast 1H',
}

export function getModelSubType(
  name: string,
  forecastHorizon?: number | null,
): string {
  if (forecastHorizon) {
    return (
      forecastHorizonOptions.find(({ value }) => value === forecastHorizon)
        ?.label || `${forecastHorizon} seconds`
    )
  }
  return name?.replace(/Forecast (\d)H/g, (_, n) =>
    n === '1' ? '1 hour' : `${n} hours`,
  )
}

type AvailableTimeRanges = {
  [tagName: string]: {
    displayName?: string | null
    min?: number
    max?: number
  }
}

export function getModelTagAvailableTimeRanges(
  model: FetchModel,
): AvailableTimeRanges {
  const { tag, inputTags } = model
  return (
    inputTags?.includes(tag) ? inputTags : [tag, ...inputTags]
  ).reduce<any>(
    (tags, tag) => ({
      ...tags,
      [tag.tagName]: tag.availableDataTimeRange
        ? {
            displayName: tag.displayName,
            min: tag.availableDataTimeRange.min.valueOf(),
            max: tag.availableDataTimeRange.max.valueOf(),
          }
        : {
            displayName: tag.displayName,
          },
    }),
    {},
  )
}

export const getTrainingStart = (model: FetchModel): number => {
  if (model.trainingStart) {
    return model.trainingStart.valueOf()
  }
  return (
    Date.now() -
    moment.duration(model.trainingPeriod || 'P1Y').as('milliseconds')
  )
}

export const getModelTagsWithNoDataBeforeStart = (
  model: FetchModel,
): string[] => {
  // increment by one day to add a bit of leniency due to time zones
  const trainingStart = add(getTrainingStart(model), { days: 1 }).valueOf()

  const tagAvailableTimeRanges = getModelTagAvailableTimeRanges(model)
  return Object.keys(tagAvailableTimeRanges).filter(tagName => {
    const { min } = tagAvailableTimeRanges[tagName]
    return min === undefined || !(min < trainingStart)
  })
}

export const getModelTagsWithNoDataBeforeSixMonthsAgo = (
  model: FetchModel,
): string[] => {
  const tagAvailableTimeRanges = getModelTagAvailableTimeRanges(model)

  const sixMonthsAgo = sub(new Date(), { months: 6 }).valueOf()

  return Object.keys(tagAvailableTimeRanges).filter(tagName => {
    const { min } = tagAvailableTimeRanges[tagName]
    return min === undefined || !(min < sixMonthsAgo)
  })
}

export const getModelTagsWithNoDataInLastMonth = (
  model: FetchModel,
): string[] => {
  const tagAvailableTimeRanges = getModelTagAvailableTimeRanges(model)

  const oneMonthAgo = sub(new Date(), { months: 1 }).valueOf()

  return Object.keys(tagAvailableTimeRanges).filter(tagName => {
    const { max } = tagAvailableTimeRanges[tagName]
    return max === undefined || !(max > oneMonthAgo)
  })
}

export const NEW = 'NEW'
export const TRAINING = 'TRAINING'
export const INITIAL_INFERENCE = 'INITIAL_INFERENCE'
export const AWAITING_ANOMALY_ANALYSIS = 'AWAITING_ANOMALY_ANALYSIS'
export const INITIAL_ANOMALY_DETECTION = 'INITIAL_ANOMALY_DETECTION'
export const NOT_RUNNING = 'NOT_RUNNING'
export const RUNNING = 'RUNNING'
export const DELETED = 'DELETED'
export const ERROR = 'ERROR'
export const STREAMING = 'STREAMING'
export const ARCHIVED = 'ARCHIVED'

// FIXME this should be a union of all of the valid state strings
// but we're not ready for that yet
type ModelState = string

const order: ModelState[] = [
  NEW,
  TRAINING,
  INITIAL_INFERENCE,
  AWAITING_ANOMALY_ANALYSIS,
  INITIAL_ANOMALY_DETECTION,
  NOT_RUNNING,
  RUNNING,
  STREAMING,
  // Models can only be archived from NOT_RUNNING/RUNNING
  ARCHIVED,
]

export const hasRunInitialInference = (state: ModelState): boolean =>
  order.indexOf(state) > order.indexOf(INITIAL_INFERENCE)
export const hasConfiguredFilterAndThresholds = (state: ModelState): boolean =>
  order.indexOf(state) > order.indexOf(AWAITING_ANOMALY_ANALYSIS)
export const hasRunAnomalyDetection = (state: ModelState): boolean =>
  order.indexOf(state) > order.indexOf(INITIAL_ANOMALY_DETECTION)
export const isRunning = (state: ModelState): boolean =>
  [RUNNING, STREAMING].includes(state)

const labels: Map<ModelState, string> = new Map([
  [NEW, 'Untrained'],
  [TRAINING, 'Training'],
  [INITIAL_INFERENCE, 'Running model on existing data'],
  [AWAITING_ANOMALY_ANALYSIS, 'Awaiting user input'],
  [INITIAL_ANOMALY_DETECTION, 'Identifying anomalies'],
  [NOT_RUNNING, 'Inactive'],
  [RUNNING, 'Active'],
  [DELETED, 'Deleted'],
  [ERROR, 'Error'],
  [STREAMING, 'Active (streaming)'],
  [ARCHIVED, 'Archived'],
])

export const label = (state: ModelState): string | undefined =>
  labels.get(state)

interface ColorRequest {
  state: ModelState
  theme: DefaultTheme
}

export const color = ({ state, theme }: ColorRequest): string => {
  let primary = '#3366cc'
  let danger = '#E85746'
  const inactive = '#F5F5F5'
  const runningColor = '#3CC13B'

  if (theme && theme.colors) {
    primary = theme.colors.primary
    danger = theme.colors.danger
  }
  const colors: Map<ModelState, string> = new Map([
    [NEW, primary],
    [TRAINING, 'yellow'],
    [INITIAL_INFERENCE, 'yellow'],
    [AWAITING_ANOMALY_ANALYSIS, 'pink'],
    [INITIAL_ANOMALY_DETECTION, 'yellow'],
    [NOT_RUNNING, inactive],
    [RUNNING, runningColor],
    [DELETED, danger],
    [ERROR, danger],
    [STREAMING, runningColor],
    [ARCHIVED, 'orange'],
  ])

  const colorFades: Map<ModelState, number> = new Map([[NOT_RUNNING, 0]])

  // get the color for that state
  const clr = colors.get(state)

  return Color(clr)
    .fade(colorFades.get(state) ?? 0.8)
    .toString()
}

export function mapModelTypeToModelTypeRecord(
  model: FetchModel,
): ModelTypes | null {
  if (model.type.name === 'Anomaly Detection') return 'AnomalyModel'
  if (model.type.name === 'Forecast') return 'ForecastModel'
  return null
}

export function mapModelToModelRecord(model: FetchModel): Record {
  return {
    id: model.id,
    name: model.name,
    description: model.description,
    outputTag: model.tag.tagName,
    modelType: mapModelTypeToModelTypeRecord(model),
    inputTags: model.inputTags.map(({ tagName }) => tagName),
  }
}
