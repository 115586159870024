import classNames from 'classnames'

interface CardProps {
  children?: React.ReactNode
  className?: string
  padding?: string
  $hovercolor?: boolean
  placeholder?: string

  as?: any
  to?: string
}

export function Card({
  children,
  className,
  padding,
  $hovercolor,
  placeholder,
  as: Tag = 'div',
  ...props
}: CardProps): JSX.Element {
  return (
    <Tag
      data-testid="card"
      className={classNames(
        'bg-background border border-solid border-border rounded-[8px] no-underline transition-colors duration-100',
        $hovercolor && 'hover:border-border-secondary',
        className,
      )}
      style={{ gridArea: placeholder, padding: padding ?? '1em' }}
      {...props}
    >
      {children}
    </Tag>
  )
}
