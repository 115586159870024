import { graphqlApi } from './graphQL'
import {
  GqlCustomerRole,
  GqlFetchCustomerQuery,
  GqlFetchMyFactoriesQuery,
  GqlRemoveCustomerUserInput,
  GqlRemoveCustomerUserMutation,
  GqlSetCustomerUserRoleMutation,
} from './graphqlTypes'

interface CustomerID {
  id: string
  includeDeactivatedUsers?: boolean
}

export async function fetchOrganization({
  id,
  includeDeactivatedUsers,
}: CustomerID): Promise<GqlFetchCustomerQuery> {
  const api = await graphqlApi()
  return api.FetchCustomer({
    id,
    status: includeDeactivatedUsers ? null : 'ENABLED',
  })
}

interface UserInput {
  userId: string
  customerId: string
  role: GqlCustomerRole
}

export async function setCustomerUserRole({
  userId,
  customerId,
  role,
}: UserInput): Promise<GqlSetCustomerUserRoleMutation> {
  const api = await graphqlApi()
  return api.SetCustomerUserRole({
    input: {
      userId,
      customerId,
      role,
    },
  })
}

export async function fetchCustomerSites(): Promise<GqlFetchMyFactoriesQuery> {
  const api = await graphqlApi()
  return api.FetchMyFactories()
}

export async function removeOrganizationUser(
  input: GqlRemoveCustomerUserInput,
): Promise<GqlRemoveCustomerUserMutation> {
  const api = await graphqlApi()
  return api.RemoveCustomerUser({ input })
}
