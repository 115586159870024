import { useEffect, useRef, useState } from 'react'
import { useClickOutside } from 'src/utility'
import { Text } from 'src/components/ui'
import { ChartMarker, markerColorOptions } from 'src/types/chartTypes'

type Props = {
  marker: ChartMarker
  updateMarker: (marker: ChartMarker) => void
}
export function MarkerColorSelector({
  marker,
  updateMarker,
}: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)
  const dropdownContentRef = useRef<HTMLDivElement>(null)

  useClickOutside(dropdownRef, () => {
    setIsOpen(false)
  })

  useEffect(() => {
    if (isOpen && dropdownContentRef.current) {
      dropdownContentRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [isOpen, dropdownContentRef])

  if (!marker) return <></>

  function handleMarkerColorChange(color: string): void {
    updateMarker({ ...marker, color })
  }

  const markerColor = markerColorOptions.find(m => m.value === marker.color)
  if (!markerColor) return <></>

  return (
    <div
      className="relative flex cursor-pointer items-center gap-xs rounded-2xs border border-solid border-border bg-background p-2xs"
      onClick={() => setIsOpen(!isOpen)}
      ref={dropdownRef}
    >
      <Text variant="description" bold>
        {markerColor.label}
      </Text>
      <div
        className="size-s rounded-full"
        style={{ backgroundColor: marker.color }}
      />
      {isOpen && (
        <div
          ref={dropdownContentRef}
          className="absolute left-0 top-[calc(100%+4px)] z-20 scroll-mt-[110px] rounded-2xs border border-solid border-border bg-background shadow-dropdown"
        >
          {markerColorOptions.map(option => (
            <div
              key={option.value}
              onClick={() => handleMarkerColorChange(option.value)}
              className="flex cursor-pointer items-center gap-xs p-xs transition hover:bg-background-hover"
            >
              <div
                className="flex size-[12px] items-center justify-center rounded-full"
                style={{ backgroundColor: option.value }}
              >
                {marker.color === option.value && (
                  <div className="size-xs rounded-full border-2 border-solid border-white bg-transparent" />
                )}
              </div>
              <Text variant="description" bold className="whitespace-nowrap">
                {option.label}
              </Text>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
