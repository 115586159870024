import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { StreamlitApp } from 'src/streamlitApp'
import { useAuth } from 'src/contexts/auth'
import { SiteProvider, useSite } from 'src/contexts/site'
import { ErrorDisplay, NotFoundPage } from 'pages/app'
import { DashboardPage } from './Dashboard'
import { ForecastPage } from './Forecast'
import { LabsPage } from './Labs'
import { AssetsPage } from './Assets'
import { CreateModelPage, ModelPage, ModelsPage } from './models'
import { AnomaliesPage, AnomalyPage } from './anomalies'
import { TagDetailsPage } from './tags'
import { DataExplorerPage } from './DataExplorer'
import {
  TrendBetaRedirectPage,
  TrendPage,
  TrendViewPage,
  TrendViewsPage,
} from './trend'
import {
  AnalysisDetailsPage,
  NewDataExplorerPage,
  WorkspaceAnalyses,
  WorkspaceSegmentations,
  WorkspaceTagsAndTimeSeries,
  WorkspaceTimeRanges,
  WorkspaceDetailsPage,
} from './DataExplorerNew'

const getSiteRootLink = (pathname: string): string => {
  const parts = pathname.split('/')
  return `/${parts[1]}/${parts[2]}`
}

function SiteApp(): JSX.Element {
  const { isWorkshop, rootLink } = useSite()
  const {
    viewer: { hasAppAccess },
  } = useAuth()
  const { dexUi } = useFlags()

  const dashboardKind = isWorkshop ? 'metropolis' : 'default'

  return (
    <Routes>
      <Route
        index
        element={<DashboardPage kind={dashboardKind} rootLink={rootLink} />}
      />
      <Route path="forecast" element={<ForecastPage />} />
      <Route path="models">
        <Route index element={<ModelsPage />} />
        <Route path="new" element={<CreateModelPage />} />
        <Route path=":modelId" element={<ModelPage />} />
      </Route>
      <Route path="anomalies">
        <Route index element={<AnomaliesPage />} />
        <Route path=":anomalyId" element={<AnomalyPage />} />
      </Route>
      {hasAppAccess && (
        <Route path="labs">
          <Route index element={<LabsPage />} />
          <Route path=":slug" element={<StreamlitApp />} />
          <Route path=":slug/:sessionId" element={<StreamlitApp />} />
        </Route>
      )}
      <Route path="trend">
        <Route index element={<TrendPage />} />
        <Route path="views" element={<TrendViewsPage />} />
        <Route path=":trendViewId" element={<TrendViewPage />} />
      </Route>{' '}
      <Route path="trend-beta" element={<TrendBetaRedirectPage />} />
      {dexUi && (
        <Route path="data-explorer-new">
          <Route index element={<NewDataExplorerPage />} />
          <Route path=":workspaceId" element={<WorkspaceDetailsPage />}>
            <Route index element={<WorkspaceAnalyses />} />
            <Route path="tags" element={<WorkspaceTagsAndTimeSeries />} />
            <Route path="time-ranges" element={<WorkspaceTimeRanges />} />
            <Route path="segmentations" element={<WorkspaceSegmentations />} />
          </Route>
          <Route
            path=":workspaceId/analyses/:analysisId"
            element={<AnalysisDetailsPage />}
          ></Route>
        </Route>
      )}
      <Route path="data-explorer">
        <Route index element={<DataExplorerPage />} />
        <Route path=":sessionId" element={<DataExplorerPage />} />
      </Route>
      <Route path="assets" element={<AssetsPage />} />
      <Route path="tags/:tagId" element={<TagDetailsPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

export function SiteRouter(): JSX.Element {
  const { siteId } = useParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const {
    viewer: { factories },
  } = useAuth()

  const currentFactory = factories.find(item => item.id === siteId)

  // If the factory is not found, get out early
  if (!currentFactory) {
    return (
      <ErrorDisplay
        error={new Error(`Invalid Site Id: ${siteId}`)}
        message={`Invalid Site Id: ${siteId}`}
        action={() => navigate('/')}
      />
    )
  }

  const rootLink = getSiteRootLink(pathname)

  return (
    <SiteProvider factory={currentFactory} rootLink={rootLink}>
      <SiteApp />
    </SiteProvider>
  )
}
