import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { parsePage } from 'src/utility/stringValidator'

type AnomalyFilters = {
  filter: string
  statuses: string[]
  page: number
  pageSize: number
  favoriteModels: boolean
  setFilter: (filter: string) => void
  setStatuses: (value: string[]) => void
  setPage: (page: number) => void
  setPageSize: (pageSize: number) => void
  setFavoriteModels: (favoriteModels: boolean) => void
}

export function useAnomaliesFilter(
  defaultStatuses: string[] = [],
): AnomalyFilters {
  const [search, setSearch] = useSearchParams()

  useEffect(() => {
    if (defaultStatuses && !search.has('status')) {
      search.set('status', defaultStatuses.join(','))
      setSearch(search)
    }
  }, [defaultStatuses, search, setSearch])

  function setFilter(filter: string): void {
    if (filter === '') {
      search.delete('filter')
    } else {
      search.set('filter', filter)
    }
    setSearch(search)
  }

  function setStatuses(value: string[]): void {
    search.set('status', value.join(','))
    setSearch(search)
  }

  function setPage(page: number): void {
    if (page === 0) {
      search.delete('page')
    } else {
      search.set('page', page.toString())
    }
    setSearch(search)
  }

  function setFavoriteModels(favoriteModels: boolean): void {
    search.set('favoriteModels', favoriteModels.toString())
    setSearch(search)
  }

  const page = parsePage(search.get('page'))
  const statusFilter = search.get('status')
  const favouriteModels = search.get('favoriteModels')

  function setPageSize(pageSize: number): void {
    if (pageSize === 10) {
      search.delete('pageSize')
    } else {
      search.set('pageSize', pageSize.toString())
    }
    setSearch(search)
  }

  const pageSize = parsePage(search.get('pageSize'))

  return {
    filter: search.get('filter') || '',
    statuses: statusFilter ? statusFilter.split(',') : [],
    page,
    pageSize,
    favoriteModels: favouriteModels === 'true',
    setPage,
    setPageSize,
    setFilter,
    setStatuses,
    setFavoriteModels,
  }
}
