import { differenceInSeconds, formatISO } from 'date-fns'
import { TimeRange } from 'src/types'
import { graphqlApi } from './graphQL'
import { GqlTsDataFragment } from './graphqlTypes'

export interface FetchModelPrediction {
  id: string
  timeRange: TimeRange
}

export async function fetchModelPrediction({
  id,
  timeRange: { from, to },
}: FetchModelPrediction): Promise<GqlTsDataFragment[]> {
  const seconds = differenceInSeconds(to, from)
  const minNumberOfDataPoints = 400
  const interval = Math.floor(seconds / minNumberOfDataPoints)

  const api = await graphqlApi()
  const data = await api.Predictions({
    id,
    from: formatISO(from),
    to: formatISO(to),
    interval,
  })

  if (data.node?.__typename === 'AnomalyModel') {
    return data.node.prediction.data
  }

  // FIXME this is probably an error case
  return []
}
