import { useEffect, useState } from 'react'
import { getTime, isAfter, subDays } from 'date-fns'
import { Button, Text } from 'src/components/ui'
import { TimeRange } from 'src/types'
import DateTimeInput from '../DateTimeInput/DateTimeInput'

type Props = {
  onChange: (value: TimeRange) => void
  onClose: () => void
  maxDate?: Date
  showTimeOption?: boolean
  timeRange?: TimeRange
}

const isTimeRangeDefined = (value: Partial<TimeRange>): value is TimeRange => {
  return value.from !== undefined && value.to !== undefined
}

export function CustomDateTimeRangeModal({
  onChange,
  onClose,
  maxDate,
  showTimeOption = true,
  timeRange,
}: Props): JSX.Element {
  const [customTimeRange, setCustomTimeRange] = useState<Partial<TimeRange>>(
    timeRange || {
      from: getTime(subDays(new Date(), 1)),
      to: getTime(new Date()),
    },
  )

  const [errors, setErrors] = useState(
    isAfter(
      customTimeRange.from || Date.now(),
      customTimeRange.to || Date.now(),
    )
      ? 'Date range cannot have a negative value.'
      : '',
  )

  useEffect(() => {
    if (
      isAfter(
        customTimeRange.from || Date.now(),
        customTimeRange.to || Date.now(),
      )
    ) {
      if (!errors) setErrors('Date range cannot have a negative value.')
    } else {
      setErrors('')
    }
  }, [customTimeRange, errors])

  return (
    <div className="flex flex-col border-0 border-l border-solid border-border p-xs">
      <div className="flex gap-xs">
        <div className="flex flex-col">
          <Text variant="description" className="ml-2xs">
            From:
          </Text>
          <DateTimeInput
            error={!!errors}
            maxDate={maxDate}
            handleChange={value => {
              setCustomTimeRange({ ...customTimeRange, from: value.valueOf() })
            }}
            value={new Date(customTimeRange.from || Date.now())}
            showTimeOption={showTimeOption}
          />
        </div>
        <div className="flex-1">
          <div className="h-full w-px bg-background-tertiary" />
        </div>
        <div className="flex flex-col">
          <Text variant="description" className="ml-2xs">
            Until:
          </Text>
          <DateTimeInput
            error={!!errors}
            maxDate={maxDate}
            showTimeOption={showTimeOption}
            handleChange={value => {
              setCustomTimeRange({ ...customTimeRange, to: value.valueOf() })
            }}
            value={new Date(customTimeRange.to || Date.now())}
          />
        </div>
      </div>
      <div className="mx-auto mt-xs h-px w-full bg-background-tertiary" />
      <div className="flex items-center justify-between gap-xs">
        <Text bold variant="small" className="text-text-danger">
          {errors && errors}
        </Text>
        <div className="flex gap-s">
          <Button
            variant="tertiary"
            title="Apply"
            disabled={!!errors}
            onClick={() => {
              if (isTimeRangeDefined(customTimeRange)) {
                onChange(customTimeRange)
              }
              onClose()
            }}
            className="!px-2xs"
          />
          <Button
            variant="tertiary"
            title="Cancel"
            className="!px-2xs"
            textStyle="!text-text-secondary hover:!text-text"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  )
}
