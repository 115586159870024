import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { nanoid } from 'nanoid'
import { Button } from 'src/components/ui'
import {
  ChartMarkerType,
  ChartOptions,
  LineChartMarker,
  markerColorOptions,
} from 'src/types/chartTypes'
import { addMarker, removeMarker, updateMarker } from 'src/contexts/charts'
import { Marker } from './Marker'

function generateDefaultMarker(): LineChartMarker {
  return {
    id: nanoid(),
    type: ChartMarkerType.LINE,
    value: 0,
    color: markerColorOptions[0].value,
    label: '',
  }
}

export type MarkersProps = {
  chart: ChartOptions
  setChart: (chart: ChartOptions) => void
}

export function Markers({ chart, setChart }: MarkersProps): JSX.Element {
  return (
    <div className="flex flex-col gap-s">
      {chart.markers?.map(marker => (
        <div key={marker.id} id={marker.id}>
          <Marker
            marker={marker}
            updateMarker={m => setChart(updateMarker(chart, marker.id, m))}
            removeMarker={() => setChart(removeMarker(chart, marker.id))}
          />
        </div>
      ))}
      <div>
        <Button
          variant="icon-secondary"
          icon={light('plus')}
          title="New Marker"
          // TODO: Remove custom classes when the button component is updated
          // with new small sizes
          textStyle="!text-small !leading-small"
          iconSize="xsmall"
          onClick={() => {
            const marker = generateDefaultMarker()
            setChart(addMarker(chart, marker))
            setTimeout(() => {
              const newMarker = document.getElementById(marker.id)
              newMarker?.scrollIntoView({ behavior: 'smooth' })
            }, 100)
          }}
        />
      </div>
    </div>
  )
}
