import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useEffect, useState } from 'react'
import { Text, TextInput } from 'src/components/ui'

type Props = {
  placeholder?: string
  options: string[]
  value?: string
  onChange: (val: string) => void
}

export default function AutocompleteTextInput({
  placeholder,
  value = '',
  options,
  onChange,
}: Props): JSX.Element {
  const [queryValue, setQueryValue] = useState(value)
  const [isFocused, setIsFocused] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const setValue = (val: string): void => {
    setQueryValue(val)
    onChange(val)
  }

  useEffect(() => {
    if (isFocused) {
      if (queryValue.length > 1) setIsDropdownOpen(true)
      else setIsDropdownOpen(false)
    } else {
      setTimeout(() => setIsDropdownOpen(false), 100)
    }
  }, [isFocused, queryValue])

  return (
    <div className="relative w-full min-w-[200px]">
      <TextInput
        value={value}
        placeholder={placeholder}
        onChange={e => setValue(e.target.value)}
        iconRight={value ? light('circle-xmark') : undefined}
        onIconRightClick={() => setValue('')}
        setFocus={setIsFocused}
      />
      {isDropdownOpen && (
        <div className="absolute right-0 top-[calc(100%+4px)] z-20 flex max-h-[200px] w-full overflow-y-auto rounded-2xs border border-solid border-border bg-background drop-shadow-dropdown">
          <div className="w-full py-xs">
            {options.length === 0 ? (
              <div className="whitespace-nowrap px-xs py-2xs">
                <Text variant="description">No models found</Text>
              </div>
            ) : (
              options.map(option => (
                <div
                  key={option}
                  className="flex w-full cursor-pointer items-center gap-xs whitespace-nowrap px-xs py-2xs transition-all hover:bg-background-hover"
                  onClick={() => {
                    setIsDropdownOpen(false)
                    setValue(option)
                  }}
                >
                  <Text
                    variant="description"
                    className="whitespace-nowrap py-3xs"
                  >
                    {option}
                  </Text>
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  )
}
