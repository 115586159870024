import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import toast from 'react-hot-toast'
import { Icon, Text, Modal } from 'src/components/ui'

import { OrganizationUser } from 'src/types'
import { useEnableOrganizationUserMutation } from '../org/api'

type Props = {
  userName: string
  onClose: () => void
  user: OrganizationUser
}

export function EnableUserModal({
  user,
  userName,
  onClose,
}: Props): JSX.Element {
  const enableOrgUserMutation = useEnableOrganizationUserMutation()

  async function enableUser(): Promise<void> {
    await enableOrgUserMutation.mutateAsync(
      { id: user.id },
      {
        onSuccess: () => {
          toast.success(
            <Text>
              <span className="font-500">{`${user.name || user.email} `}</span>
              has been reactivated.
            </Text>,
            { position: 'top-right' },
          )
        },
        onError: () => {
          onClose()
          toast.error('Failed to reactivate the user.', {
            position: 'top-right',
          })
        },
      },
    )
    onClose()
  }

  return (
    <Modal
      title="Reactivate User"
      isOpen
      close={onClose}
      footer={
        <Modal.Footer
          onCancel={onClose}
          onConfirm={enableUser}
          confirmLabel="Reactivate User"
          type="success"
          buttonDisabled={false}
        />
      }
    >
      <div className="flex max-w-[445px] flex-col items-center">
        <Icon icon={regular('rotate-right')} className="my-l h-xl w-xl" />
        <Text
          variant="content-rubik"
          bold
          className="my-xs text-center text-[16px]"
        >
          Are you sure you want to <span className="font-bold">reactivate</span>{' '}
          {userName}?
        </Text>
        <Text variant="content" className="my-s">
          Reactivating {userName} will allow the user to log in to Intelecy.
        </Text>
      </div>
    </Modal>
  )
}
