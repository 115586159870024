import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate } from 'react-router-dom'
import { Icon, Text } from 'src/components/ui'
import { useGateways } from 'gateway/api'
import { useOpcSubscription } from '../opc-ua-connection.api'

type Props = {
  orgId: string
  siteId: string
  gatewayId: string
  opcConnectionId: string
  subscriptionId: string
}
export function OpcUaSubscriptionNavigation({
  orgId,
  siteId,
  gatewayId,
  opcConnectionId,
  subscriptionId,
}: Props): JSX.Element {
  const navigate = useNavigate()
  const gatewayQuery = useGateways(siteId)
  const subscriptionQuery = useOpcSubscription(subscriptionId)

  const baseRoute = `/settings/orgs/${orgId}/sites/${siteId}/gateways/${gatewayId}/opc-ua/${opcConnectionId}/subscriptions`

  if (gatewayQuery.isLoading || !subscriptionQuery.data) return <></>

  if (gatewayQuery.isError || subscriptionQuery.isError) {
    return <> </>
  }

  const selectedGateway = gatewayQuery.data.find(
    gateway => gateway.id === gatewayId,
  )
  const selectedOpcConnection = selectedGateway?.opcConnections?.items?.find(
    c => c?.id === opcConnectionId,
  )

  return (
    <div className="flex items-center gap-xs">
      <Icon
        size="big"
        className="cursor-pointer"
        icon={regular('circle-chevron-left')}
        onClick={() => navigate(baseRoute)}
      />
      <div className="flex items-center">
        <Text variant="content-rubik" bold className="!mt-3xs inline">
          OPC-UA Connections |{' '}
          {selectedOpcConnection?.name || selectedOpcConnection?.id} |
        </Text>
        <Text variant="title" bold className="inline">
          &nbsp;{subscriptionQuery.data.id}
        </Text>
      </div>
    </div>
  )
}
