import { useRef } from 'react'
import { ChartOptions } from 'src/types/chartTypes'

export function useStableChartIds(charts: ChartOptions[]): number[] {
  const chartIds = useRef<number[]>(charts.map(c => c.id))

  const newIds = charts.map(chart => chart.id).sort()

  // Check if set of ids changes
  const isSame =
    chartIds.current.length === newIds.length &&
    chartIds.current.every((id, index) => id === newIds[index])

  // If not the same, update the chartIds
  if (!isSame) {
    chartIds.current = newIds
    return newIds
  }

  return chartIds.current
}
