import { GqlErrorScoreKind, GqlThresholdKind, GqlTransform } from 'src/services'

export type ThresholdOption = {
  label: string
  value: string
  errorScoreKind: GqlErrorScoreKind
  transform: GqlTransform
  thresholdKind: GqlThresholdKind
  defaultValue: number
  min?: number
  description: string
}

export const thresholdOptions: Array<ThresholdOption> = [
  {
    label: 'Spike',
    value: 'spike',
    errorScoreKind: 'ABSOLUTE',
    transform: 'NONE',
    thresholdKind: 'UPPER',
    defaultValue: 0.25,
    min: 0,
    description: 'The difference between the prediction and actual value.',
  },
  {
    label: 'Short',
    value: 'short',
    errorScoreKind: 'ABSOLUTE',
    transform: 'SMOOTHED',
    thresholdKind: 'UPPER',
    defaultValue: 0.1,
    min: 0,
    description:
      'The 10 minute moving average of the difference between the prediction and actual value.',
  },
  {
    label: 'Signed score upper',
    value: 'signed_upper',
    errorScoreKind: 'SIGNED',
    transform: 'NONE',
    thresholdKind: 'UPPER',
    defaultValue: 0.25,
    description:
      'The signed difference between the prediction and actual value. A positive value means the predicted value is higher than the actual and a negative value means the predicted value is lower than the actual. Upper threshold.',
  },
  {
    label: 'Signed score lower',
    value: 'signed_lower',
    errorScoreKind: 'SIGNED',
    transform: 'NONE',
    thresholdKind: 'LOWER',
    defaultValue: -0.25,
    description:
      'The signed difference between the prediction and actual value. A positive value means the predicted value is higher than the actual and a negative value means the predicted value is lower than the actual. Lower threshold.',
  },
  {
    label: 'Smoothed signed score upper',
    value: 'smoothed_signed_upper',
    errorScoreKind: 'SIGNED',
    transform: 'SMOOTHED',
    thresholdKind: 'UPPER',
    defaultValue: 0.1,
    description:
      'The 10 minute moving average of the signed difference between the prediction and actual value. A positive value means the predicted value is higher than the actual and a negative value means the predicted value is lower than the actual. Upper threshold.',
  },
  {
    label: 'Smoothed signed score lower',
    value: 'smoothed_signed_lower',
    errorScoreKind: 'SIGNED',
    transform: 'SMOOTHED',
    thresholdKind: 'LOWER',
    defaultValue: -0.1,
    description:
      'The 10 minute moving average of the signed difference between the prediction and actual value. A positive value means the predicted value is higher than the actual and a negative value means the predicted value is lower than the actual. Lower threshold.',
  },
]
