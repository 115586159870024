import React, { useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { useLocalStorage } from 'src/utility'
import { zIndex } from 'src/utility/constants/StyleConstants'
import { TopNavigation } from 'pages/app'
import { SettingsTopNavigation } from 'pages/settings'
import Sidebar from './Sidebar'

export default function MainLayout(): JSX.Element {
  const [isScrolled, setIsScrolled] = React.useState(false)
  const { siteId } = useParams()
  const [collapsed, setCollapsed] = useLocalStorage('collapseMenu', false)

  const handleScroll = (): void => {
    const offset = window.scrollY
    if (offset > 5) {
      setIsScrolled(true)
    } else {
      setIsScrolled(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  // note: the z-index of the top bar must be less than the streamlit plotly fullscreen mode since it uses
  // absolute positioning (and the controls end up _under_ the top bar). Any changes to this _must_ be tested with DEx.

  // plotly StyledFullScreenFrame -> 1000050
  // StyledSidebarContent -> 999991
  // so we need a z-index somewhere inbetween. therefore 999992

  return (
    <div className="flex min-h-screen bg-[#f8f8f8]">
      <Sidebar
        siteId={siteId}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      />
      <div
        className={`relative flex flex-1 flex-col ${
          collapsed ? 'max-w-[calc(100vw-47px)]' : 'max-w-[calc(100vw-240px)]'
        }`}
      >
        <div
          className={`sticky left-0 top-0 w-full pb-xs transition duration-300 ${
            isScrolled
              ? 'bg-background shadow-[0px_4px_4px_-4px_rgba(0,0,0,0.25)]'
              : 'bg-transparent'
          }`}
          style={{
            zIndex: zIndex.header,
          }}
        >
          {siteId ? (
            <TopNavigation currentSiteId={siteId} />
          ) : (
            <SettingsTopNavigation />
          )}
        </div>
        <div className="w-full flex-1">
          <Outlet />
        </div>
      </div>
    </div>
  )
}
