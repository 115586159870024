import React from 'react'

type TrendState = {
  isDragging: boolean
}
type TrendActions = {
  setDragState: (state: TrendState) => void
}

const TrendContext = React.createContext<
  (TrendState & TrendActions) | undefined
>(undefined)

const initialState = {
  isDragging: false,
}

export const TrendProvider = ({
  children,
}: {
  children?: React.ReactNode
}): JSX.Element => {
  const [state, setState] = React.useState<TrendState>(initialState)

  return (
    <TrendContext.Provider
      value={{
        isDragging: state.isDragging,
        setDragState: setState,
      }}
    >
      {children}
    </TrendContext.Provider>
  )
}

export const useTrendDnd = (): TrendState & TrendActions => {
  const context = React.useContext(TrendContext)
  if (context === undefined) {
    throw new Error('useTrendDnd must be used within a TrendProvider')
  }
  return context
}
