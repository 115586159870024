import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Button, Icon, Modal } from 'src/components/ui'

interface ErrorModalProps {
  test?: boolean // to show network error mode in Storybook
  message: string
  title?: string
  buttonLabel?: string
  // the action to take when clicking the button or closing the window
  action?: () => void
}

export function ErrorModal({
  test,
  message,
  title = 'Error',
  buttonLabel = 'Try again',
  action = () => window.location.reload(),
}: ErrorModalProps): JSX.Element {
  const isOnline = test ? false : window.navigator.onLine

  const defaultMessage = isOnline
    ? message
    : 'No network detected, please connect to a network.'

  const icon = isOnline ? light('exclamation-circle') : light('wifi')

  return (
    <Modal title={title} isOpen close={() => action()}>
      <div className="flex size-full items-center gap-xl p-l text-xl">
        <div className="flex h-full items-center justify-center">
          <Icon icon={icon} className="text-[2em]" />
        </div>
        <div className="flex flex-col items-start gap-2">
          <div className="flex size-full items-center">{defaultMessage}</div>
          <Button
            variant="primary"
            title={buttonLabel}
            onClick={() => action()}
          />
        </div>
      </div>
    </Modal>
  )
}
