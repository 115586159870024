import { useTag } from 'tags/api'
import { Text } from 'src/components/ui'

interface TagLabelProps {
  tagName: string
}

export function TagLabel({ tagName }: TagLabelProps): JSX.Element {
  const { data: tag, isLoading } = useTag(tagName)
  const displayName = tag?.displayName || tagName

  return (
    <div>
      {!isLoading && (
        <>
          <Text>
            <span title={tagName}>{displayName}</span>
          </Text>
          <Text className="text-text-tertiary">
            {tag?.engUnit && tag?.engUnit.toLowerCase() !== 'none'
              ? `${tag?.description} (${tag?.engUnit})`
              : tag?.description}
          </Text>
        </>
      )}
    </div>
  )
}
