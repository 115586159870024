// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.1
//   protoc               v5.26.1
// source: analysis.proto

/* eslint-disable */
import Long from 'long'
import _m0 from 'protobufjs/minimal'
import { Meta } from './common'
import { Duration } from './google/protobuf/duration'
import { Timestamp } from './google/protobuf/timestamp'
import { SegmentProperty } from './segment_series'
import { Segmentation } from './segmentation'
import { TimeRange } from './time_range'
import { TimeSeries } from './time_series'

export const protobufPackage = 'types.v1'

export interface Scatter {
  timeRange: TimeRange | undefined
  trendLine: string
  /** Whether to add a line at f(x) = x to the plot */
  identityHelper: boolean
  axes?:
    | { $case: 'tsAxes'; value: ScatterAxesTs }
    | { $case: 'segAxes'; value: ScatterAxesSeg }
    | undefined
}

export interface ScatterAxesTs {
  x: TimeSeries | undefined
  y: TimeSeries | undefined
  z: TimeSeries | undefined
  /** Should be boolean */
  filter: TimeSeries | undefined
  color?:
    | { $case: 'constantColor'; value: string }
    | { $case: 'variableColor'; value: VariableColorTs }
    | undefined
  opacity?:
    | { $case: 'constantOpacity'; value: number }
    | { $case: 'variableOpacity'; value: TimeSeries }
    | undefined
  symbol?:
    | { $case: 'constantSymbol'; value: string }
    | { $case: 'variableSymbol'; value: TimeSeries }
    | undefined
  size?:
    | { $case: 'constantSize'; value: number }
    | { $case: 'variableSize'; value: TimeSeries }
    | undefined
}

export interface VariableColorTs {
  variable: TimeSeries | undefined
  asCat: boolean
}

export interface ScatterAxesSeg {
  segmentation: Segmentation | undefined
  x: SegmentProperty | undefined
  y: SegmentProperty | undefined
  z: SegmentProperty | undefined
  /** Should be boolean */
  filter: SegmentProperty | undefined
  color?:
    | { $case: 'constantColor'; value: string }
    | { $case: 'variableColor'; value: VariableColorSeg }
    | undefined
  opacity?:
    | { $case: 'constantOpacity'; value: number }
    | { $case: 'variableOpacity'; value: SegmentProperty }
    | undefined
  symbol?:
    | { $case: 'constantSymbol'; value: string }
    | { $case: 'variableSymbol'; value: SegmentProperty }
    | undefined
  size?:
    | { $case: 'constantSize'; value: number }
    | { $case: 'variableSize'; value: SegmentProperty }
    | undefined
}

export interface VariableColorSeg {
  variable: SegmentProperty | undefined
  asCat: boolean
}

export interface Trend {
  timeRange: TimeRange | undefined
  /** Should be boolean */
  filter: TimeSeries | undefined
  timeSeries: TimeSeries[]
}

export interface ParallelCoordinates {
  timeRange: TimeRange | undefined
  dimensions?:
    | { $case: 'tsDims'; value: PCDimensionsTs }
    | { $case: 'segDims'; value: PCDimensionsSeg }
    | undefined
}

export interface QualityAnalysis {
  timeRange: TimeRange | undefined
  filter: SegmentProperty | undefined
  segmentation: Segmentation | undefined
  segmentProperty: SegmentProperty | undefined
  features: SegmentProperty[]
  /** should be boolean */
  positiveCondition: SegmentProperty | undefined
  /** should be boolean */
  negativeCondition: SegmentProperty | undefined
}

export interface PCDimensionsTs {
  filter: TimeSeries | undefined
  /** @deprecated */
  color: TimeSeries | undefined
  dimensions: PCDimensionTs[]
  colorV2: PCDimensionTs | undefined
}

export interface PCDimensionTs {
  data: TimeSeries | undefined
  range: DimensionRange | undefined
}

export interface PCDimensionsSeg {
  segmentation: Segmentation | undefined
  filter: SegmentProperty | undefined
  /** @deprecated */
  color: SegmentProperty | undefined
  dimensions: PCDimensionSeg[]
  colorV2: PCDimensionSeg | undefined
}

export interface PCDimensionSeg {
  data: SegmentProperty | undefined
  range: DimensionRange | undefined
}

export interface DimensionRange {
  range?:
    | { $case: 'int'; value: IntRange }
    | { $case: 'float'; value: FloatRange }
    | { $case: 'time'; value: TimestampRange }
    | { $case: 'duration'; value: DurationRange }
    | undefined
}

export interface IntRange {
  min: number
  max: number
}

export interface FloatRange {
  min: number
  max: number
}

export interface TimestampRange {
  min: Date | undefined
  max: Date | undefined
}

export interface DurationRange {
  min: Duration | undefined
  max: Duration | undefined
}

export interface OverlaidSegments {
  timeRange: TimeRange | undefined
  segmentation: Segmentation | undefined
  percentileRanges: boolean
  /** Should be boolean */
  filter: SegmentProperty | undefined
  x: TimeSeries | undefined
  y: TimeSeries | undefined
  color?:
    | { $case: 'constantColor'; value: string }
    | { $case: 'variableColor'; value: VariableColorSeg }
    | undefined
  opacity?:
    | { $case: 'constantOpacity'; value: number }
    | { $case: 'variableOpacity'; value: SegmentProperty }
    | undefined
}

export interface Analysis {
  id: string
  meta: Meta | undefined
  label: string
  description: string
  kind?:
    | { $case: 'trend'; value: Trend }
    | { $case: 'scatter'; value: Scatter }
    | { $case: 'parallelCoordinates'; value: ParallelCoordinates }
    | { $case: 'overlaidSegments'; value: OverlaidSegments }
    | { $case: 'qualityAnalysis'; value: QualityAnalysis }
    | undefined
}

function createBaseScatter(): Scatter {
  return {
    timeRange: undefined,
    trendLine: '',
    identityHelper: false,
    axes: undefined,
  }
}

export const Scatter = {
  encode(
    message: Scatter,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.timeRange !== undefined) {
      TimeRange.encode(message.timeRange, writer.uint32(10).fork()).ldelim()
    }
    if (message.trendLine !== '') {
      writer.uint32(18).string(message.trendLine)
    }
    if (message.identityHelper !== false) {
      writer.uint32(24).bool(message.identityHelper)
    }
    switch (message.axes?.$case) {
      case 'tsAxes':
        ScatterAxesTs.encode(
          message.axes.value,
          writer.uint32(82).fork(),
        ).ldelim()
        break
      case 'segAxes':
        ScatterAxesSeg.encode(
          message.axes.value,
          writer.uint32(90).fork(),
        ).ldelim()
        break
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Scatter {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseScatter()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.timeRange = TimeRange.decode(reader, reader.uint32())
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.trendLine = reader.string()
          continue
        case 3:
          if (tag !== 24) {
            break
          }

          message.identityHelper = reader.bool()
          continue
        case 10:
          if (tag !== 82) {
            break
          }

          message.axes = {
            $case: 'tsAxes',
            value: ScatterAxesTs.decode(reader, reader.uint32()),
          }
          continue
        case 11:
          if (tag !== 90) {
            break
          }

          message.axes = {
            $case: 'segAxes',
            value: ScatterAxesSeg.decode(reader, reader.uint32()),
          }
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): Scatter {
    return {
      timeRange: isSet(object.time_range)
        ? TimeRange.fromJSON(object.time_range)
        : undefined,
      trendLine: isSet(object.trend_line)
        ? globalThis.String(object.trend_line)
        : '',
      identityHelper: isSet(object.identity_helper)
        ? globalThis.Boolean(object.identity_helper)
        : false,
      axes: isSet(object.ts_axes)
        ? { $case: 'tsAxes', value: ScatterAxesTs.fromJSON(object.ts_axes) }
        : isSet(object.seg_axes)
        ? { $case: 'segAxes', value: ScatterAxesSeg.fromJSON(object.seg_axes) }
        : undefined,
    }
  },

  toJSON(message: Scatter): unknown {
    const obj: any = {}
    if (message.timeRange !== undefined) {
      obj.time_range = TimeRange.toJSON(message.timeRange)
    }
    if (message.trendLine !== '') {
      obj.trend_line = message.trendLine
    }
    if (message.identityHelper !== false) {
      obj.identity_helper = message.identityHelper
    }
    if (message.axes?.$case === 'tsAxes') {
      obj.ts_axes = ScatterAxesTs.toJSON(message.axes.value)
    }
    if (message.axes?.$case === 'segAxes') {
      obj.seg_axes = ScatterAxesSeg.toJSON(message.axes.value)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<Scatter>, I>>(base?: I): Scatter {
    return Scatter.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<Scatter>, I>>(object: I): Scatter {
    const message = createBaseScatter()
    message.timeRange =
      object.timeRange !== undefined && object.timeRange !== null
        ? TimeRange.fromPartial(object.timeRange)
        : undefined
    message.trendLine = object.trendLine ?? ''
    message.identityHelper = object.identityHelper ?? false
    if (
      object.axes?.$case === 'tsAxes' &&
      object.axes?.value !== undefined &&
      object.axes?.value !== null
    ) {
      message.axes = {
        $case: 'tsAxes',
        value: ScatterAxesTs.fromPartial(object.axes.value),
      }
    }
    if (
      object.axes?.$case === 'segAxes' &&
      object.axes?.value !== undefined &&
      object.axes?.value !== null
    ) {
      message.axes = {
        $case: 'segAxes',
        value: ScatterAxesSeg.fromPartial(object.axes.value),
      }
    }
    return message
  },
}

function createBaseScatterAxesTs(): ScatterAxesTs {
  return {
    x: undefined,
    y: undefined,
    z: undefined,
    filter: undefined,
    color: undefined,
    opacity: undefined,
    symbol: undefined,
    size: undefined,
  }
}

export const ScatterAxesTs = {
  encode(
    message: ScatterAxesTs,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.x !== undefined) {
      TimeSeries.encode(message.x, writer.uint32(10).fork()).ldelim()
    }
    if (message.y !== undefined) {
      TimeSeries.encode(message.y, writer.uint32(18).fork()).ldelim()
    }
    if (message.z !== undefined) {
      TimeSeries.encode(message.z, writer.uint32(26).fork()).ldelim()
    }
    if (message.filter !== undefined) {
      TimeSeries.encode(message.filter, writer.uint32(34).fork()).ldelim()
    }
    switch (message.color?.$case) {
      case 'constantColor':
        writer.uint32(82).string(message.color.value)
        break
      case 'variableColor':
        VariableColorTs.encode(
          message.color.value,
          writer.uint32(90).fork(),
        ).ldelim()
        break
    }
    switch (message.opacity?.$case) {
      case 'constantOpacity':
        writer.uint32(165).float(message.opacity.value)
        break
      case 'variableOpacity':
        TimeSeries.encode(
          message.opacity.value,
          writer.uint32(170).fork(),
        ).ldelim()
        break
    }
    switch (message.symbol?.$case) {
      case 'constantSymbol':
        writer.uint32(242).string(message.symbol.value)
        break
      case 'variableSymbol':
        TimeSeries.encode(
          message.symbol.value,
          writer.uint32(250).fork(),
        ).ldelim()
        break
    }
    switch (message.size?.$case) {
      case 'constantSize':
        writer.uint32(325).float(message.size.value)
        break
      case 'variableSize':
        TimeSeries.encode(
          message.size.value,
          writer.uint32(330).fork(),
        ).ldelim()
        break
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScatterAxesTs {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseScatterAxesTs()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.x = TimeSeries.decode(reader, reader.uint32())
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.y = TimeSeries.decode(reader, reader.uint32())
          continue
        case 3:
          if (tag !== 26) {
            break
          }

          message.z = TimeSeries.decode(reader, reader.uint32())
          continue
        case 4:
          if (tag !== 34) {
            break
          }

          message.filter = TimeSeries.decode(reader, reader.uint32())
          continue
        case 10:
          if (tag !== 82) {
            break
          }

          message.color = { $case: 'constantColor', value: reader.string() }
          continue
        case 11:
          if (tag !== 90) {
            break
          }

          message.color = {
            $case: 'variableColor',
            value: VariableColorTs.decode(reader, reader.uint32()),
          }
          continue
        case 20:
          if (tag !== 165) {
            break
          }

          message.opacity = { $case: 'constantOpacity', value: reader.float() }
          continue
        case 21:
          if (tag !== 170) {
            break
          }

          message.opacity = {
            $case: 'variableOpacity',
            value: TimeSeries.decode(reader, reader.uint32()),
          }
          continue
        case 30:
          if (tag !== 242) {
            break
          }

          message.symbol = { $case: 'constantSymbol', value: reader.string() }
          continue
        case 31:
          if (tag !== 250) {
            break
          }

          message.symbol = {
            $case: 'variableSymbol',
            value: TimeSeries.decode(reader, reader.uint32()),
          }
          continue
        case 40:
          if (tag !== 325) {
            break
          }

          message.size = { $case: 'constantSize', value: reader.float() }
          continue
        case 41:
          if (tag !== 330) {
            break
          }

          message.size = {
            $case: 'variableSize',
            value: TimeSeries.decode(reader, reader.uint32()),
          }
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): ScatterAxesTs {
    return {
      x: isSet(object.x) ? TimeSeries.fromJSON(object.x) : undefined,
      y: isSet(object.y) ? TimeSeries.fromJSON(object.y) : undefined,
      z: isSet(object.z) ? TimeSeries.fromJSON(object.z) : undefined,
      filter: isSet(object.filter)
        ? TimeSeries.fromJSON(object.filter)
        : undefined,
      color: isSet(object.constant_color)
        ? {
            $case: 'constantColor',
            value: globalThis.String(object.constant_color),
          }
        : isSet(object.variable_color)
        ? {
            $case: 'variableColor',
            value: VariableColorTs.fromJSON(object.variable_color),
          }
        : undefined,
      opacity: isSet(object.constant_opacity)
        ? {
            $case: 'constantOpacity',
            value: globalThis.Number(object.constant_opacity),
          }
        : isSet(object.variable_opacity)
        ? {
            $case: 'variableOpacity',
            value: TimeSeries.fromJSON(object.variable_opacity),
          }
        : undefined,
      symbol: isSet(object.constant_symbol)
        ? {
            $case: 'constantSymbol',
            value: globalThis.String(object.constant_symbol),
          }
        : isSet(object.variable_symbol)
        ? {
            $case: 'variableSymbol',
            value: TimeSeries.fromJSON(object.variable_symbol),
          }
        : undefined,
      size: isSet(object.constant_size)
        ? {
            $case: 'constantSize',
            value: globalThis.Number(object.constant_size),
          }
        : isSet(object.variable_size)
        ? {
            $case: 'variableSize',
            value: TimeSeries.fromJSON(object.variable_size),
          }
        : undefined,
    }
  },

  toJSON(message: ScatterAxesTs): unknown {
    const obj: any = {}
    if (message.x !== undefined) {
      obj.x = TimeSeries.toJSON(message.x)
    }
    if (message.y !== undefined) {
      obj.y = TimeSeries.toJSON(message.y)
    }
    if (message.z !== undefined) {
      obj.z = TimeSeries.toJSON(message.z)
    }
    if (message.filter !== undefined) {
      obj.filter = TimeSeries.toJSON(message.filter)
    }
    if (message.color?.$case === 'constantColor') {
      obj.constant_color = message.color.value
    }
    if (message.color?.$case === 'variableColor') {
      obj.variable_color = VariableColorTs.toJSON(message.color.value)
    }
    if (message.opacity?.$case === 'constantOpacity') {
      obj.constant_opacity = message.opacity.value
    }
    if (message.opacity?.$case === 'variableOpacity') {
      obj.variable_opacity = TimeSeries.toJSON(message.opacity.value)
    }
    if (message.symbol?.$case === 'constantSymbol') {
      obj.constant_symbol = message.symbol.value
    }
    if (message.symbol?.$case === 'variableSymbol') {
      obj.variable_symbol = TimeSeries.toJSON(message.symbol.value)
    }
    if (message.size?.$case === 'constantSize') {
      obj.constant_size = message.size.value
    }
    if (message.size?.$case === 'variableSize') {
      obj.variable_size = TimeSeries.toJSON(message.size.value)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<ScatterAxesTs>, I>>(
    base?: I,
  ): ScatterAxesTs {
    return ScatterAxesTs.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<ScatterAxesTs>, I>>(
    object: I,
  ): ScatterAxesTs {
    const message = createBaseScatterAxesTs()
    message.x =
      object.x !== undefined && object.x !== null
        ? TimeSeries.fromPartial(object.x)
        : undefined
    message.y =
      object.y !== undefined && object.y !== null
        ? TimeSeries.fromPartial(object.y)
        : undefined
    message.z =
      object.z !== undefined && object.z !== null
        ? TimeSeries.fromPartial(object.z)
        : undefined
    message.filter =
      object.filter !== undefined && object.filter !== null
        ? TimeSeries.fromPartial(object.filter)
        : undefined
    if (
      object.color?.$case === 'constantColor' &&
      object.color?.value !== undefined &&
      object.color?.value !== null
    ) {
      message.color = { $case: 'constantColor', value: object.color.value }
    }
    if (
      object.color?.$case === 'variableColor' &&
      object.color?.value !== undefined &&
      object.color?.value !== null
    ) {
      message.color = {
        $case: 'variableColor',
        value: VariableColorTs.fromPartial(object.color.value),
      }
    }
    if (
      object.opacity?.$case === 'constantOpacity' &&
      object.opacity?.value !== undefined &&
      object.opacity?.value !== null
    ) {
      message.opacity = {
        $case: 'constantOpacity',
        value: object.opacity.value,
      }
    }
    if (
      object.opacity?.$case === 'variableOpacity' &&
      object.opacity?.value !== undefined &&
      object.opacity?.value !== null
    ) {
      message.opacity = {
        $case: 'variableOpacity',
        value: TimeSeries.fromPartial(object.opacity.value),
      }
    }
    if (
      object.symbol?.$case === 'constantSymbol' &&
      object.symbol?.value !== undefined &&
      object.symbol?.value !== null
    ) {
      message.symbol = { $case: 'constantSymbol', value: object.symbol.value }
    }
    if (
      object.symbol?.$case === 'variableSymbol' &&
      object.symbol?.value !== undefined &&
      object.symbol?.value !== null
    ) {
      message.symbol = {
        $case: 'variableSymbol',
        value: TimeSeries.fromPartial(object.symbol.value),
      }
    }
    if (
      object.size?.$case === 'constantSize' &&
      object.size?.value !== undefined &&
      object.size?.value !== null
    ) {
      message.size = { $case: 'constantSize', value: object.size.value }
    }
    if (
      object.size?.$case === 'variableSize' &&
      object.size?.value !== undefined &&
      object.size?.value !== null
    ) {
      message.size = {
        $case: 'variableSize',
        value: TimeSeries.fromPartial(object.size.value),
      }
    }
    return message
  },
}

function createBaseVariableColorTs(): VariableColorTs {
  return { variable: undefined, asCat: false }
}

export const VariableColorTs = {
  encode(
    message: VariableColorTs,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.variable !== undefined) {
      TimeSeries.encode(message.variable, writer.uint32(10).fork()).ldelim()
    }
    if (message.asCat !== false) {
      writer.uint32(16).bool(message.asCat)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VariableColorTs {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseVariableColorTs()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.variable = TimeSeries.decode(reader, reader.uint32())
          continue
        case 2:
          if (tag !== 16) {
            break
          }

          message.asCat = reader.bool()
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): VariableColorTs {
    return {
      variable: isSet(object.variable)
        ? TimeSeries.fromJSON(object.variable)
        : undefined,
      asCat: isSet(object.as_cat) ? globalThis.Boolean(object.as_cat) : false,
    }
  },

  toJSON(message: VariableColorTs): unknown {
    const obj: any = {}
    if (message.variable !== undefined) {
      obj.variable = TimeSeries.toJSON(message.variable)
    }
    if (message.asCat !== false) {
      obj.as_cat = message.asCat
    }
    return obj
  },

  create<I extends Exact<DeepPartial<VariableColorTs>, I>>(
    base?: I,
  ): VariableColorTs {
    return VariableColorTs.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<VariableColorTs>, I>>(
    object: I,
  ): VariableColorTs {
    const message = createBaseVariableColorTs()
    message.variable =
      object.variable !== undefined && object.variable !== null
        ? TimeSeries.fromPartial(object.variable)
        : undefined
    message.asCat = object.asCat ?? false
    return message
  },
}

function createBaseScatterAxesSeg(): ScatterAxesSeg {
  return {
    segmentation: undefined,
    x: undefined,
    y: undefined,
    z: undefined,
    filter: undefined,
    color: undefined,
    opacity: undefined,
    symbol: undefined,
    size: undefined,
  }
}

export const ScatterAxesSeg = {
  encode(
    message: ScatterAxesSeg,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.segmentation !== undefined) {
      Segmentation.encode(
        message.segmentation,
        writer.uint32(10).fork(),
      ).ldelim()
    }
    if (message.x !== undefined) {
      SegmentProperty.encode(message.x, writer.uint32(18).fork()).ldelim()
    }
    if (message.y !== undefined) {
      SegmentProperty.encode(message.y, writer.uint32(26).fork()).ldelim()
    }
    if (message.z !== undefined) {
      SegmentProperty.encode(message.z, writer.uint32(34).fork()).ldelim()
    }
    if (message.filter !== undefined) {
      SegmentProperty.encode(message.filter, writer.uint32(42).fork()).ldelim()
    }
    switch (message.color?.$case) {
      case 'constantColor':
        writer.uint32(82).string(message.color.value)
        break
      case 'variableColor':
        VariableColorSeg.encode(
          message.color.value,
          writer.uint32(90).fork(),
        ).ldelim()
        break
    }
    switch (message.opacity?.$case) {
      case 'constantOpacity':
        writer.uint32(165).float(message.opacity.value)
        break
      case 'variableOpacity':
        SegmentProperty.encode(
          message.opacity.value,
          writer.uint32(170).fork(),
        ).ldelim()
        break
    }
    switch (message.symbol?.$case) {
      case 'constantSymbol':
        writer.uint32(242).string(message.symbol.value)
        break
      case 'variableSymbol':
        SegmentProperty.encode(
          message.symbol.value,
          writer.uint32(250).fork(),
        ).ldelim()
        break
    }
    switch (message.size?.$case) {
      case 'constantSize':
        writer.uint32(325).float(message.size.value)
        break
      case 'variableSize':
        SegmentProperty.encode(
          message.size.value,
          writer.uint32(330).fork(),
        ).ldelim()
        break
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScatterAxesSeg {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseScatterAxesSeg()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.segmentation = Segmentation.decode(reader, reader.uint32())
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.x = SegmentProperty.decode(reader, reader.uint32())
          continue
        case 3:
          if (tag !== 26) {
            break
          }

          message.y = SegmentProperty.decode(reader, reader.uint32())
          continue
        case 4:
          if (tag !== 34) {
            break
          }

          message.z = SegmentProperty.decode(reader, reader.uint32())
          continue
        case 5:
          if (tag !== 42) {
            break
          }

          message.filter = SegmentProperty.decode(reader, reader.uint32())
          continue
        case 10:
          if (tag !== 82) {
            break
          }

          message.color = { $case: 'constantColor', value: reader.string() }
          continue
        case 11:
          if (tag !== 90) {
            break
          }

          message.color = {
            $case: 'variableColor',
            value: VariableColorSeg.decode(reader, reader.uint32()),
          }
          continue
        case 20:
          if (tag !== 165) {
            break
          }

          message.opacity = { $case: 'constantOpacity', value: reader.float() }
          continue
        case 21:
          if (tag !== 170) {
            break
          }

          message.opacity = {
            $case: 'variableOpacity',
            value: SegmentProperty.decode(reader, reader.uint32()),
          }
          continue
        case 30:
          if (tag !== 242) {
            break
          }

          message.symbol = { $case: 'constantSymbol', value: reader.string() }
          continue
        case 31:
          if (tag !== 250) {
            break
          }

          message.symbol = {
            $case: 'variableSymbol',
            value: SegmentProperty.decode(reader, reader.uint32()),
          }
          continue
        case 40:
          if (tag !== 325) {
            break
          }

          message.size = { $case: 'constantSize', value: reader.float() }
          continue
        case 41:
          if (tag !== 330) {
            break
          }

          message.size = {
            $case: 'variableSize',
            value: SegmentProperty.decode(reader, reader.uint32()),
          }
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): ScatterAxesSeg {
    return {
      segmentation: isSet(object.segmentation)
        ? Segmentation.fromJSON(object.segmentation)
        : undefined,
      x: isSet(object.x) ? SegmentProperty.fromJSON(object.x) : undefined,
      y: isSet(object.y) ? SegmentProperty.fromJSON(object.y) : undefined,
      z: isSet(object.z) ? SegmentProperty.fromJSON(object.z) : undefined,
      filter: isSet(object.filter)
        ? SegmentProperty.fromJSON(object.filter)
        : undefined,
      color: isSet(object.constant_color)
        ? {
            $case: 'constantColor',
            value: globalThis.String(object.constant_color),
          }
        : isSet(object.variable_color)
        ? {
            $case: 'variableColor',
            value: VariableColorSeg.fromJSON(object.variable_color),
          }
        : undefined,
      opacity: isSet(object.constant_opacity)
        ? {
            $case: 'constantOpacity',
            value: globalThis.Number(object.constant_opacity),
          }
        : isSet(object.variable_opacity)
        ? {
            $case: 'variableOpacity',
            value: SegmentProperty.fromJSON(object.variable_opacity),
          }
        : undefined,
      symbol: isSet(object.constant_symbol)
        ? {
            $case: 'constantSymbol',
            value: globalThis.String(object.constant_symbol),
          }
        : isSet(object.variable_symbol)
        ? {
            $case: 'variableSymbol',
            value: SegmentProperty.fromJSON(object.variable_symbol),
          }
        : undefined,
      size: isSet(object.constant_size)
        ? {
            $case: 'constantSize',
            value: globalThis.Number(object.constant_size),
          }
        : isSet(object.variable_size)
        ? {
            $case: 'variableSize',
            value: SegmentProperty.fromJSON(object.variable_size),
          }
        : undefined,
    }
  },

  toJSON(message: ScatterAxesSeg): unknown {
    const obj: any = {}
    if (message.segmentation !== undefined) {
      obj.segmentation = Segmentation.toJSON(message.segmentation)
    }
    if (message.x !== undefined) {
      obj.x = SegmentProperty.toJSON(message.x)
    }
    if (message.y !== undefined) {
      obj.y = SegmentProperty.toJSON(message.y)
    }
    if (message.z !== undefined) {
      obj.z = SegmentProperty.toJSON(message.z)
    }
    if (message.filter !== undefined) {
      obj.filter = SegmentProperty.toJSON(message.filter)
    }
    if (message.color?.$case === 'constantColor') {
      obj.constant_color = message.color.value
    }
    if (message.color?.$case === 'variableColor') {
      obj.variable_color = VariableColorSeg.toJSON(message.color.value)
    }
    if (message.opacity?.$case === 'constantOpacity') {
      obj.constant_opacity = message.opacity.value
    }
    if (message.opacity?.$case === 'variableOpacity') {
      obj.variable_opacity = SegmentProperty.toJSON(message.opacity.value)
    }
    if (message.symbol?.$case === 'constantSymbol') {
      obj.constant_symbol = message.symbol.value
    }
    if (message.symbol?.$case === 'variableSymbol') {
      obj.variable_symbol = SegmentProperty.toJSON(message.symbol.value)
    }
    if (message.size?.$case === 'constantSize') {
      obj.constant_size = message.size.value
    }
    if (message.size?.$case === 'variableSize') {
      obj.variable_size = SegmentProperty.toJSON(message.size.value)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<ScatterAxesSeg>, I>>(
    base?: I,
  ): ScatterAxesSeg {
    return ScatterAxesSeg.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<ScatterAxesSeg>, I>>(
    object: I,
  ): ScatterAxesSeg {
    const message = createBaseScatterAxesSeg()
    message.segmentation =
      object.segmentation !== undefined && object.segmentation !== null
        ? Segmentation.fromPartial(object.segmentation)
        : undefined
    message.x =
      object.x !== undefined && object.x !== null
        ? SegmentProperty.fromPartial(object.x)
        : undefined
    message.y =
      object.y !== undefined && object.y !== null
        ? SegmentProperty.fromPartial(object.y)
        : undefined
    message.z =
      object.z !== undefined && object.z !== null
        ? SegmentProperty.fromPartial(object.z)
        : undefined
    message.filter =
      object.filter !== undefined && object.filter !== null
        ? SegmentProperty.fromPartial(object.filter)
        : undefined
    if (
      object.color?.$case === 'constantColor' &&
      object.color?.value !== undefined &&
      object.color?.value !== null
    ) {
      message.color = { $case: 'constantColor', value: object.color.value }
    }
    if (
      object.color?.$case === 'variableColor' &&
      object.color?.value !== undefined &&
      object.color?.value !== null
    ) {
      message.color = {
        $case: 'variableColor',
        value: VariableColorSeg.fromPartial(object.color.value),
      }
    }
    if (
      object.opacity?.$case === 'constantOpacity' &&
      object.opacity?.value !== undefined &&
      object.opacity?.value !== null
    ) {
      message.opacity = {
        $case: 'constantOpacity',
        value: object.opacity.value,
      }
    }
    if (
      object.opacity?.$case === 'variableOpacity' &&
      object.opacity?.value !== undefined &&
      object.opacity?.value !== null
    ) {
      message.opacity = {
        $case: 'variableOpacity',
        value: SegmentProperty.fromPartial(object.opacity.value),
      }
    }
    if (
      object.symbol?.$case === 'constantSymbol' &&
      object.symbol?.value !== undefined &&
      object.symbol?.value !== null
    ) {
      message.symbol = { $case: 'constantSymbol', value: object.symbol.value }
    }
    if (
      object.symbol?.$case === 'variableSymbol' &&
      object.symbol?.value !== undefined &&
      object.symbol?.value !== null
    ) {
      message.symbol = {
        $case: 'variableSymbol',
        value: SegmentProperty.fromPartial(object.symbol.value),
      }
    }
    if (
      object.size?.$case === 'constantSize' &&
      object.size?.value !== undefined &&
      object.size?.value !== null
    ) {
      message.size = { $case: 'constantSize', value: object.size.value }
    }
    if (
      object.size?.$case === 'variableSize' &&
      object.size?.value !== undefined &&
      object.size?.value !== null
    ) {
      message.size = {
        $case: 'variableSize',
        value: SegmentProperty.fromPartial(object.size.value),
      }
    }
    return message
  },
}

function createBaseVariableColorSeg(): VariableColorSeg {
  return { variable: undefined, asCat: false }
}

export const VariableColorSeg = {
  encode(
    message: VariableColorSeg,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.variable !== undefined) {
      SegmentProperty.encode(
        message.variable,
        writer.uint32(10).fork(),
      ).ldelim()
    }
    if (message.asCat !== false) {
      writer.uint32(16).bool(message.asCat)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VariableColorSeg {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseVariableColorSeg()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.variable = SegmentProperty.decode(reader, reader.uint32())
          continue
        case 2:
          if (tag !== 16) {
            break
          }

          message.asCat = reader.bool()
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): VariableColorSeg {
    return {
      variable: isSet(object.variable)
        ? SegmentProperty.fromJSON(object.variable)
        : undefined,
      asCat: isSet(object.as_cat) ? globalThis.Boolean(object.as_cat) : false,
    }
  },

  toJSON(message: VariableColorSeg): unknown {
    const obj: any = {}
    if (message.variable !== undefined) {
      obj.variable = SegmentProperty.toJSON(message.variable)
    }
    if (message.asCat !== false) {
      obj.as_cat = message.asCat
    }
    return obj
  },

  create<I extends Exact<DeepPartial<VariableColorSeg>, I>>(
    base?: I,
  ): VariableColorSeg {
    return VariableColorSeg.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<VariableColorSeg>, I>>(
    object: I,
  ): VariableColorSeg {
    const message = createBaseVariableColorSeg()
    message.variable =
      object.variable !== undefined && object.variable !== null
        ? SegmentProperty.fromPartial(object.variable)
        : undefined
    message.asCat = object.asCat ?? false
    return message
  },
}

function createBaseTrend(): Trend {
  return { timeRange: undefined, filter: undefined, timeSeries: [] }
}

export const Trend = {
  encode(message: Trend, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.timeRange !== undefined) {
      TimeRange.encode(message.timeRange, writer.uint32(10).fork()).ldelim()
    }
    if (message.filter !== undefined) {
      TimeSeries.encode(message.filter, writer.uint32(18).fork()).ldelim()
    }
    for (const v of message.timeSeries) {
      TimeSeries.encode(v!, writer.uint32(26).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Trend {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseTrend()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.timeRange = TimeRange.decode(reader, reader.uint32())
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.filter = TimeSeries.decode(reader, reader.uint32())
          continue
        case 3:
          if (tag !== 26) {
            break
          }

          message.timeSeries.push(TimeSeries.decode(reader, reader.uint32()))
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): Trend {
    return {
      timeRange: isSet(object.time_range)
        ? TimeRange.fromJSON(object.time_range)
        : undefined,
      filter: isSet(object.filter)
        ? TimeSeries.fromJSON(object.filter)
        : undefined,
      timeSeries: globalThis.Array.isArray(object?.time_series)
        ? object.time_series.map((e: any) => TimeSeries.fromJSON(e))
        : [],
    }
  },

  toJSON(message: Trend): unknown {
    const obj: any = {}
    if (message.timeRange !== undefined) {
      obj.time_range = TimeRange.toJSON(message.timeRange)
    }
    if (message.filter !== undefined) {
      obj.filter = TimeSeries.toJSON(message.filter)
    }
    if (message.timeSeries?.length) {
      obj.time_series = message.timeSeries.map(e => TimeSeries.toJSON(e))
    }
    return obj
  },

  create<I extends Exact<DeepPartial<Trend>, I>>(base?: I): Trend {
    return Trend.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<Trend>, I>>(object: I): Trend {
    const message = createBaseTrend()
    message.timeRange =
      object.timeRange !== undefined && object.timeRange !== null
        ? TimeRange.fromPartial(object.timeRange)
        : undefined
    message.filter =
      object.filter !== undefined && object.filter !== null
        ? TimeSeries.fromPartial(object.filter)
        : undefined
    message.timeSeries =
      object.timeSeries?.map(e => TimeSeries.fromPartial(e)) || []
    return message
  },
}

function createBaseParallelCoordinates(): ParallelCoordinates {
  return { timeRange: undefined, dimensions: undefined }
}

export const ParallelCoordinates = {
  encode(
    message: ParallelCoordinates,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.timeRange !== undefined) {
      TimeRange.encode(message.timeRange, writer.uint32(10).fork()).ldelim()
    }
    switch (message.dimensions?.$case) {
      case 'tsDims':
        PCDimensionsTs.encode(
          message.dimensions.value,
          writer.uint32(82).fork(),
        ).ldelim()
        break
      case 'segDims':
        PCDimensionsSeg.encode(
          message.dimensions.value,
          writer.uint32(90).fork(),
        ).ldelim()
        break
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ParallelCoordinates {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseParallelCoordinates()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.timeRange = TimeRange.decode(reader, reader.uint32())
          continue
        case 10:
          if (tag !== 82) {
            break
          }

          message.dimensions = {
            $case: 'tsDims',
            value: PCDimensionsTs.decode(reader, reader.uint32()),
          }
          continue
        case 11:
          if (tag !== 90) {
            break
          }

          message.dimensions = {
            $case: 'segDims',
            value: PCDimensionsSeg.decode(reader, reader.uint32()),
          }
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): ParallelCoordinates {
    return {
      timeRange: isSet(object.time_range)
        ? TimeRange.fromJSON(object.time_range)
        : undefined,
      dimensions: isSet(object.ts_dims)
        ? { $case: 'tsDims', value: PCDimensionsTs.fromJSON(object.ts_dims) }
        : isSet(object.seg_dims)
        ? { $case: 'segDims', value: PCDimensionsSeg.fromJSON(object.seg_dims) }
        : undefined,
    }
  },

  toJSON(message: ParallelCoordinates): unknown {
    const obj: any = {}
    if (message.timeRange !== undefined) {
      obj.time_range = TimeRange.toJSON(message.timeRange)
    }
    if (message.dimensions?.$case === 'tsDims') {
      obj.ts_dims = PCDimensionsTs.toJSON(message.dimensions.value)
    }
    if (message.dimensions?.$case === 'segDims') {
      obj.seg_dims = PCDimensionsSeg.toJSON(message.dimensions.value)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<ParallelCoordinates>, I>>(
    base?: I,
  ): ParallelCoordinates {
    return ParallelCoordinates.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<ParallelCoordinates>, I>>(
    object: I,
  ): ParallelCoordinates {
    const message = createBaseParallelCoordinates()
    message.timeRange =
      object.timeRange !== undefined && object.timeRange !== null
        ? TimeRange.fromPartial(object.timeRange)
        : undefined
    if (
      object.dimensions?.$case === 'tsDims' &&
      object.dimensions?.value !== undefined &&
      object.dimensions?.value !== null
    ) {
      message.dimensions = {
        $case: 'tsDims',
        value: PCDimensionsTs.fromPartial(object.dimensions.value),
      }
    }
    if (
      object.dimensions?.$case === 'segDims' &&
      object.dimensions?.value !== undefined &&
      object.dimensions?.value !== null
    ) {
      message.dimensions = {
        $case: 'segDims',
        value: PCDimensionsSeg.fromPartial(object.dimensions.value),
      }
    }
    return message
  },
}

function createBaseQualityAnalysis(): QualityAnalysis {
  return {
    timeRange: undefined,
    filter: undefined,
    segmentation: undefined,
    segmentProperty: undefined,
    features: [],
    positiveCondition: undefined,
    negativeCondition: undefined,
  }
}

export const QualityAnalysis = {
  encode(
    message: QualityAnalysis,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.timeRange !== undefined) {
      TimeRange.encode(message.timeRange, writer.uint32(10).fork()).ldelim()
    }
    if (message.filter !== undefined) {
      SegmentProperty.encode(message.filter, writer.uint32(18).fork()).ldelim()
    }
    if (message.segmentation !== undefined) {
      Segmentation.encode(
        message.segmentation,
        writer.uint32(26).fork(),
      ).ldelim()
    }
    if (message.segmentProperty !== undefined) {
      SegmentProperty.encode(
        message.segmentProperty,
        writer.uint32(34).fork(),
      ).ldelim()
    }
    for (const v of message.features) {
      SegmentProperty.encode(v!, writer.uint32(42).fork()).ldelim()
    }
    if (message.positiveCondition !== undefined) {
      SegmentProperty.encode(
        message.positiveCondition,
        writer.uint32(50).fork(),
      ).ldelim()
    }
    if (message.negativeCondition !== undefined) {
      SegmentProperty.encode(
        message.negativeCondition,
        writer.uint32(58).fork(),
      ).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QualityAnalysis {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseQualityAnalysis()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.timeRange = TimeRange.decode(reader, reader.uint32())
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.filter = SegmentProperty.decode(reader, reader.uint32())
          continue
        case 3:
          if (tag !== 26) {
            break
          }

          message.segmentation = Segmentation.decode(reader, reader.uint32())
          continue
        case 4:
          if (tag !== 34) {
            break
          }

          message.segmentProperty = SegmentProperty.decode(
            reader,
            reader.uint32(),
          )
          continue
        case 5:
          if (tag !== 42) {
            break
          }

          message.features.push(SegmentProperty.decode(reader, reader.uint32()))
          continue
        case 6:
          if (tag !== 50) {
            break
          }

          message.positiveCondition = SegmentProperty.decode(
            reader,
            reader.uint32(),
          )
          continue
        case 7:
          if (tag !== 58) {
            break
          }

          message.negativeCondition = SegmentProperty.decode(
            reader,
            reader.uint32(),
          )
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): QualityAnalysis {
    return {
      timeRange: isSet(object.time_range)
        ? TimeRange.fromJSON(object.time_range)
        : undefined,
      filter: isSet(object.filter)
        ? SegmentProperty.fromJSON(object.filter)
        : undefined,
      segmentation: isSet(object.segmentation)
        ? Segmentation.fromJSON(object.segmentation)
        : undefined,
      segmentProperty: isSet(object.segment_property)
        ? SegmentProperty.fromJSON(object.segment_property)
        : undefined,
      features: globalThis.Array.isArray(object?.features)
        ? object.features.map((e: any) => SegmentProperty.fromJSON(e))
        : [],
      positiveCondition: isSet(object.positive_condition)
        ? SegmentProperty.fromJSON(object.positive_condition)
        : undefined,
      negativeCondition: isSet(object.negative_condition)
        ? SegmentProperty.fromJSON(object.negative_condition)
        : undefined,
    }
  },

  toJSON(message: QualityAnalysis): unknown {
    const obj: any = {}
    if (message.timeRange !== undefined) {
      obj.time_range = TimeRange.toJSON(message.timeRange)
    }
    if (message.filter !== undefined) {
      obj.filter = SegmentProperty.toJSON(message.filter)
    }
    if (message.segmentation !== undefined) {
      obj.segmentation = Segmentation.toJSON(message.segmentation)
    }
    if (message.segmentProperty !== undefined) {
      obj.segment_property = SegmentProperty.toJSON(message.segmentProperty)
    }
    if (message.features?.length) {
      obj.features = message.features.map(e => SegmentProperty.toJSON(e))
    }
    if (message.positiveCondition !== undefined) {
      obj.positive_condition = SegmentProperty.toJSON(message.positiveCondition)
    }
    if (message.negativeCondition !== undefined) {
      obj.negative_condition = SegmentProperty.toJSON(message.negativeCondition)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<QualityAnalysis>, I>>(
    base?: I,
  ): QualityAnalysis {
    return QualityAnalysis.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<QualityAnalysis>, I>>(
    object: I,
  ): QualityAnalysis {
    const message = createBaseQualityAnalysis()
    message.timeRange =
      object.timeRange !== undefined && object.timeRange !== null
        ? TimeRange.fromPartial(object.timeRange)
        : undefined
    message.filter =
      object.filter !== undefined && object.filter !== null
        ? SegmentProperty.fromPartial(object.filter)
        : undefined
    message.segmentation =
      object.segmentation !== undefined && object.segmentation !== null
        ? Segmentation.fromPartial(object.segmentation)
        : undefined
    message.segmentProperty =
      object.segmentProperty !== undefined && object.segmentProperty !== null
        ? SegmentProperty.fromPartial(object.segmentProperty)
        : undefined
    message.features =
      object.features?.map(e => SegmentProperty.fromPartial(e)) || []
    message.positiveCondition =
      object.positiveCondition !== undefined &&
      object.positiveCondition !== null
        ? SegmentProperty.fromPartial(object.positiveCondition)
        : undefined
    message.negativeCondition =
      object.negativeCondition !== undefined &&
      object.negativeCondition !== null
        ? SegmentProperty.fromPartial(object.negativeCondition)
        : undefined
    return message
  },
}

function createBasePCDimensionsTs(): PCDimensionsTs {
  return {
    filter: undefined,
    color: undefined,
    dimensions: [],
    colorV2: undefined,
  }
}

export const PCDimensionsTs = {
  encode(
    message: PCDimensionsTs,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.filter !== undefined) {
      TimeSeries.encode(message.filter, writer.uint32(10).fork()).ldelim()
    }
    if (message.color !== undefined) {
      TimeSeries.encode(message.color, writer.uint32(18).fork()).ldelim()
    }
    for (const v of message.dimensions) {
      PCDimensionTs.encode(v!, writer.uint32(26).fork()).ldelim()
    }
    if (message.colorV2 !== undefined) {
      PCDimensionTs.encode(message.colorV2, writer.uint32(34).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PCDimensionsTs {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBasePCDimensionsTs()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.filter = TimeSeries.decode(reader, reader.uint32())
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.color = TimeSeries.decode(reader, reader.uint32())
          continue
        case 3:
          if (tag !== 26) {
            break
          }

          message.dimensions.push(PCDimensionTs.decode(reader, reader.uint32()))
          continue
        case 4:
          if (tag !== 34) {
            break
          }

          message.colorV2 = PCDimensionTs.decode(reader, reader.uint32())
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): PCDimensionsTs {
    return {
      filter: isSet(object.filter)
        ? TimeSeries.fromJSON(object.filter)
        : undefined,
      color: isSet(object.color)
        ? TimeSeries.fromJSON(object.color)
        : undefined,
      dimensions: globalThis.Array.isArray(object?.dimensions)
        ? object.dimensions.map((e: any) => PCDimensionTs.fromJSON(e))
        : [],
      colorV2: isSet(object.color_v2)
        ? PCDimensionTs.fromJSON(object.color_v2)
        : undefined,
    }
  },

  toJSON(message: PCDimensionsTs): unknown {
    const obj: any = {}
    if (message.filter !== undefined) {
      obj.filter = TimeSeries.toJSON(message.filter)
    }
    if (message.color !== undefined) {
      obj.color = TimeSeries.toJSON(message.color)
    }
    if (message.dimensions?.length) {
      obj.dimensions = message.dimensions.map(e => PCDimensionTs.toJSON(e))
    }
    if (message.colorV2 !== undefined) {
      obj.color_v2 = PCDimensionTs.toJSON(message.colorV2)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<PCDimensionsTs>, I>>(
    base?: I,
  ): PCDimensionsTs {
    return PCDimensionsTs.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<PCDimensionsTs>, I>>(
    object: I,
  ): PCDimensionsTs {
    const message = createBasePCDimensionsTs()
    message.filter =
      object.filter !== undefined && object.filter !== null
        ? TimeSeries.fromPartial(object.filter)
        : undefined
    message.color =
      object.color !== undefined && object.color !== null
        ? TimeSeries.fromPartial(object.color)
        : undefined
    message.dimensions =
      object.dimensions?.map(e => PCDimensionTs.fromPartial(e)) || []
    message.colorV2 =
      object.colorV2 !== undefined && object.colorV2 !== null
        ? PCDimensionTs.fromPartial(object.colorV2)
        : undefined
    return message
  },
}

function createBasePCDimensionTs(): PCDimensionTs {
  return { data: undefined, range: undefined }
}

export const PCDimensionTs = {
  encode(
    message: PCDimensionTs,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.data !== undefined) {
      TimeSeries.encode(message.data, writer.uint32(10).fork()).ldelim()
    }
    if (message.range !== undefined) {
      DimensionRange.encode(message.range, writer.uint32(18).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PCDimensionTs {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBasePCDimensionTs()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.data = TimeSeries.decode(reader, reader.uint32())
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.range = DimensionRange.decode(reader, reader.uint32())
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): PCDimensionTs {
    return {
      data: isSet(object.data) ? TimeSeries.fromJSON(object.data) : undefined,
      range: isSet(object.range)
        ? DimensionRange.fromJSON(object.range)
        : undefined,
    }
  },

  toJSON(message: PCDimensionTs): unknown {
    const obj: any = {}
    if (message.data !== undefined) {
      obj.data = TimeSeries.toJSON(message.data)
    }
    if (message.range !== undefined) {
      obj.range = DimensionRange.toJSON(message.range)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<PCDimensionTs>, I>>(
    base?: I,
  ): PCDimensionTs {
    return PCDimensionTs.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<PCDimensionTs>, I>>(
    object: I,
  ): PCDimensionTs {
    const message = createBasePCDimensionTs()
    message.data =
      object.data !== undefined && object.data !== null
        ? TimeSeries.fromPartial(object.data)
        : undefined
    message.range =
      object.range !== undefined && object.range !== null
        ? DimensionRange.fromPartial(object.range)
        : undefined
    return message
  },
}

function createBasePCDimensionsSeg(): PCDimensionsSeg {
  return {
    segmentation: undefined,
    filter: undefined,
    color: undefined,
    dimensions: [],
    colorV2: undefined,
  }
}

export const PCDimensionsSeg = {
  encode(
    message: PCDimensionsSeg,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.segmentation !== undefined) {
      Segmentation.encode(
        message.segmentation,
        writer.uint32(10).fork(),
      ).ldelim()
    }
    if (message.filter !== undefined) {
      SegmentProperty.encode(message.filter, writer.uint32(18).fork()).ldelim()
    }
    if (message.color !== undefined) {
      SegmentProperty.encode(message.color, writer.uint32(26).fork()).ldelim()
    }
    for (const v of message.dimensions) {
      PCDimensionSeg.encode(v!, writer.uint32(34).fork()).ldelim()
    }
    if (message.colorV2 !== undefined) {
      PCDimensionSeg.encode(message.colorV2, writer.uint32(42).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PCDimensionsSeg {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBasePCDimensionsSeg()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.segmentation = Segmentation.decode(reader, reader.uint32())
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.filter = SegmentProperty.decode(reader, reader.uint32())
          continue
        case 3:
          if (tag !== 26) {
            break
          }

          message.color = SegmentProperty.decode(reader, reader.uint32())
          continue
        case 4:
          if (tag !== 34) {
            break
          }

          message.dimensions.push(
            PCDimensionSeg.decode(reader, reader.uint32()),
          )
          continue
        case 5:
          if (tag !== 42) {
            break
          }

          message.colorV2 = PCDimensionSeg.decode(reader, reader.uint32())
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): PCDimensionsSeg {
    return {
      segmentation: isSet(object.segmentation)
        ? Segmentation.fromJSON(object.segmentation)
        : undefined,
      filter: isSet(object.filter)
        ? SegmentProperty.fromJSON(object.filter)
        : undefined,
      color: isSet(object.color)
        ? SegmentProperty.fromJSON(object.color)
        : undefined,
      dimensions: globalThis.Array.isArray(object?.dimensions)
        ? object.dimensions.map((e: any) => PCDimensionSeg.fromJSON(e))
        : [],
      colorV2: isSet(object.color_v2)
        ? PCDimensionSeg.fromJSON(object.color_v2)
        : undefined,
    }
  },

  toJSON(message: PCDimensionsSeg): unknown {
    const obj: any = {}
    if (message.segmentation !== undefined) {
      obj.segmentation = Segmentation.toJSON(message.segmentation)
    }
    if (message.filter !== undefined) {
      obj.filter = SegmentProperty.toJSON(message.filter)
    }
    if (message.color !== undefined) {
      obj.color = SegmentProperty.toJSON(message.color)
    }
    if (message.dimensions?.length) {
      obj.dimensions = message.dimensions.map(e => PCDimensionSeg.toJSON(e))
    }
    if (message.colorV2 !== undefined) {
      obj.color_v2 = PCDimensionSeg.toJSON(message.colorV2)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<PCDimensionsSeg>, I>>(
    base?: I,
  ): PCDimensionsSeg {
    return PCDimensionsSeg.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<PCDimensionsSeg>, I>>(
    object: I,
  ): PCDimensionsSeg {
    const message = createBasePCDimensionsSeg()
    message.segmentation =
      object.segmentation !== undefined && object.segmentation !== null
        ? Segmentation.fromPartial(object.segmentation)
        : undefined
    message.filter =
      object.filter !== undefined && object.filter !== null
        ? SegmentProperty.fromPartial(object.filter)
        : undefined
    message.color =
      object.color !== undefined && object.color !== null
        ? SegmentProperty.fromPartial(object.color)
        : undefined
    message.dimensions =
      object.dimensions?.map(e => PCDimensionSeg.fromPartial(e)) || []
    message.colorV2 =
      object.colorV2 !== undefined && object.colorV2 !== null
        ? PCDimensionSeg.fromPartial(object.colorV2)
        : undefined
    return message
  },
}

function createBasePCDimensionSeg(): PCDimensionSeg {
  return { data: undefined, range: undefined }
}

export const PCDimensionSeg = {
  encode(
    message: PCDimensionSeg,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.data !== undefined) {
      SegmentProperty.encode(message.data, writer.uint32(10).fork()).ldelim()
    }
    if (message.range !== undefined) {
      DimensionRange.encode(message.range, writer.uint32(18).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PCDimensionSeg {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBasePCDimensionSeg()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.data = SegmentProperty.decode(reader, reader.uint32())
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.range = DimensionRange.decode(reader, reader.uint32())
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): PCDimensionSeg {
    return {
      data: isSet(object.data)
        ? SegmentProperty.fromJSON(object.data)
        : undefined,
      range: isSet(object.range)
        ? DimensionRange.fromJSON(object.range)
        : undefined,
    }
  },

  toJSON(message: PCDimensionSeg): unknown {
    const obj: any = {}
    if (message.data !== undefined) {
      obj.data = SegmentProperty.toJSON(message.data)
    }
    if (message.range !== undefined) {
      obj.range = DimensionRange.toJSON(message.range)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<PCDimensionSeg>, I>>(
    base?: I,
  ): PCDimensionSeg {
    return PCDimensionSeg.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<PCDimensionSeg>, I>>(
    object: I,
  ): PCDimensionSeg {
    const message = createBasePCDimensionSeg()
    message.data =
      object.data !== undefined && object.data !== null
        ? SegmentProperty.fromPartial(object.data)
        : undefined
    message.range =
      object.range !== undefined && object.range !== null
        ? DimensionRange.fromPartial(object.range)
        : undefined
    return message
  },
}

function createBaseDimensionRange(): DimensionRange {
  return { range: undefined }
}

export const DimensionRange = {
  encode(
    message: DimensionRange,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    switch (message.range?.$case) {
      case 'int':
        IntRange.encode(message.range.value, writer.uint32(10).fork()).ldelim()
        break
      case 'float':
        FloatRange.encode(
          message.range.value,
          writer.uint32(18).fork(),
        ).ldelim()
        break
      case 'time':
        TimestampRange.encode(
          message.range.value,
          writer.uint32(26).fork(),
        ).ldelim()
        break
      case 'duration':
        DurationRange.encode(
          message.range.value,
          writer.uint32(34).fork(),
        ).ldelim()
        break
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DimensionRange {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseDimensionRange()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.range = {
            $case: 'int',
            value: IntRange.decode(reader, reader.uint32()),
          }
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.range = {
            $case: 'float',
            value: FloatRange.decode(reader, reader.uint32()),
          }
          continue
        case 3:
          if (tag !== 26) {
            break
          }

          message.range = {
            $case: 'time',
            value: TimestampRange.decode(reader, reader.uint32()),
          }
          continue
        case 4:
          if (tag !== 34) {
            break
          }

          message.range = {
            $case: 'duration',
            value: DurationRange.decode(reader, reader.uint32()),
          }
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): DimensionRange {
    return {
      range: isSet(object.int)
        ? { $case: 'int', value: IntRange.fromJSON(object.int) }
        : isSet(object.float)
        ? { $case: 'float', value: FloatRange.fromJSON(object.float) }
        : isSet(object.time)
        ? { $case: 'time', value: TimestampRange.fromJSON(object.time) }
        : isSet(object.duration)
        ? { $case: 'duration', value: DurationRange.fromJSON(object.duration) }
        : undefined,
    }
  },

  toJSON(message: DimensionRange): unknown {
    const obj: any = {}
    if (message.range?.$case === 'int') {
      obj.int = IntRange.toJSON(message.range.value)
    }
    if (message.range?.$case === 'float') {
      obj.float = FloatRange.toJSON(message.range.value)
    }
    if (message.range?.$case === 'time') {
      obj.time = TimestampRange.toJSON(message.range.value)
    }
    if (message.range?.$case === 'duration') {
      obj.duration = DurationRange.toJSON(message.range.value)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<DimensionRange>, I>>(
    base?: I,
  ): DimensionRange {
    return DimensionRange.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<DimensionRange>, I>>(
    object: I,
  ): DimensionRange {
    const message = createBaseDimensionRange()
    if (
      object.range?.$case === 'int' &&
      object.range?.value !== undefined &&
      object.range?.value !== null
    ) {
      message.range = {
        $case: 'int',
        value: IntRange.fromPartial(object.range.value),
      }
    }
    if (
      object.range?.$case === 'float' &&
      object.range?.value !== undefined &&
      object.range?.value !== null
    ) {
      message.range = {
        $case: 'float',
        value: FloatRange.fromPartial(object.range.value),
      }
    }
    if (
      object.range?.$case === 'time' &&
      object.range?.value !== undefined &&
      object.range?.value !== null
    ) {
      message.range = {
        $case: 'time',
        value: TimestampRange.fromPartial(object.range.value),
      }
    }
    if (
      object.range?.$case === 'duration' &&
      object.range?.value !== undefined &&
      object.range?.value !== null
    ) {
      message.range = {
        $case: 'duration',
        value: DurationRange.fromPartial(object.range.value),
      }
    }
    return message
  },
}

function createBaseIntRange(): IntRange {
  return { min: 0, max: 0 }
}

export const IntRange = {
  encode(
    message: IntRange,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.min !== 0) {
      writer.uint32(8).sint64(message.min)
    }
    if (message.max !== 0) {
      writer.uint32(16).sint64(message.max)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IntRange {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseIntRange()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break
          }

          message.min = longToNumber(reader.sint64() as Long)
          continue
        case 2:
          if (tag !== 16) {
            break
          }

          message.max = longToNumber(reader.sint64() as Long)
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): IntRange {
    return {
      min: isSet(object.min) ? globalThis.Number(object.min) : 0,
      max: isSet(object.max) ? globalThis.Number(object.max) : 0,
    }
  },

  toJSON(message: IntRange): unknown {
    const obj: any = {}
    if (message.min !== 0) {
      obj.min = Math.round(message.min)
    }
    if (message.max !== 0) {
      obj.max = Math.round(message.max)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<IntRange>, I>>(base?: I): IntRange {
    return IntRange.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<IntRange>, I>>(object: I): IntRange {
    const message = createBaseIntRange()
    message.min = object.min ?? 0
    message.max = object.max ?? 0
    return message
  },
}

function createBaseFloatRange(): FloatRange {
  return { min: 0, max: 0 }
}

export const FloatRange = {
  encode(
    message: FloatRange,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.min !== 0) {
      writer.uint32(13).float(message.min)
    }
    if (message.max !== 0) {
      writer.uint32(21).float(message.max)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FloatRange {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseFloatRange()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break
          }

          message.min = reader.float()
          continue
        case 2:
          if (tag !== 21) {
            break
          }

          message.max = reader.float()
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): FloatRange {
    return {
      min: isSet(object.min) ? globalThis.Number(object.min) : 0,
      max: isSet(object.max) ? globalThis.Number(object.max) : 0,
    }
  },

  toJSON(message: FloatRange): unknown {
    const obj: any = {}
    if (message.min !== 0) {
      obj.min = message.min
    }
    if (message.max !== 0) {
      obj.max = message.max
    }
    return obj
  },

  create<I extends Exact<DeepPartial<FloatRange>, I>>(base?: I): FloatRange {
    return FloatRange.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<FloatRange>, I>>(
    object: I,
  ): FloatRange {
    const message = createBaseFloatRange()
    message.min = object.min ?? 0
    message.max = object.max ?? 0
    return message
  },
}

function createBaseTimestampRange(): TimestampRange {
  return { min: undefined, max: undefined }
}

export const TimestampRange = {
  encode(
    message: TimestampRange,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.min !== undefined) {
      Timestamp.encode(
        toTimestamp(message.min),
        writer.uint32(10).fork(),
      ).ldelim()
    }
    if (message.max !== undefined) {
      Timestamp.encode(
        toTimestamp(message.max),
        writer.uint32(18).fork(),
      ).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TimestampRange {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseTimestampRange()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.min = fromTimestamp(Timestamp.decode(reader, reader.uint32()))
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.max = fromTimestamp(Timestamp.decode(reader, reader.uint32()))
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): TimestampRange {
    return {
      min: isSet(object.min) ? fromJsonTimestamp(object.min) : undefined,
      max: isSet(object.max) ? fromJsonTimestamp(object.max) : undefined,
    }
  },

  toJSON(message: TimestampRange): unknown {
    const obj: any = {}
    if (message.min !== undefined) {
      obj.min = message.min.toISOString()
    }
    if (message.max !== undefined) {
      obj.max = message.max.toISOString()
    }
    return obj
  },

  create<I extends Exact<DeepPartial<TimestampRange>, I>>(
    base?: I,
  ): TimestampRange {
    return TimestampRange.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<TimestampRange>, I>>(
    object: I,
  ): TimestampRange {
    const message = createBaseTimestampRange()
    message.min = object.min ?? undefined
    message.max = object.max ?? undefined
    return message
  },
}

function createBaseDurationRange(): DurationRange {
  return { min: undefined, max: undefined }
}

export const DurationRange = {
  encode(
    message: DurationRange,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.min !== undefined) {
      Duration.encode(message.min, writer.uint32(10).fork()).ldelim()
    }
    if (message.max !== undefined) {
      Duration.encode(message.max, writer.uint32(18).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DurationRange {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseDurationRange()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.min = Duration.decode(reader, reader.uint32())
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.max = Duration.decode(reader, reader.uint32())
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): DurationRange {
    return {
      min: isSet(object.min) ? Duration.fromJSON(object.min) : undefined,
      max: isSet(object.max) ? Duration.fromJSON(object.max) : undefined,
    }
  },

  toJSON(message: DurationRange): unknown {
    const obj: any = {}
    if (message.min !== undefined) {
      obj.min = Duration.toJSON(message.min)
    }
    if (message.max !== undefined) {
      obj.max = Duration.toJSON(message.max)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<DurationRange>, I>>(
    base?: I,
  ): DurationRange {
    return DurationRange.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<DurationRange>, I>>(
    object: I,
  ): DurationRange {
    const message = createBaseDurationRange()
    message.min =
      object.min !== undefined && object.min !== null
        ? Duration.fromPartial(object.min)
        : undefined
    message.max =
      object.max !== undefined && object.max !== null
        ? Duration.fromPartial(object.max)
        : undefined
    return message
  },
}

function createBaseOverlaidSegments(): OverlaidSegments {
  return {
    timeRange: undefined,
    segmentation: undefined,
    percentileRanges: false,
    filter: undefined,
    x: undefined,
    y: undefined,
    color: undefined,
    opacity: undefined,
  }
}

export const OverlaidSegments = {
  encode(
    message: OverlaidSegments,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.timeRange !== undefined) {
      TimeRange.encode(message.timeRange, writer.uint32(10).fork()).ldelim()
    }
    if (message.segmentation !== undefined) {
      Segmentation.encode(
        message.segmentation,
        writer.uint32(18).fork(),
      ).ldelim()
    }
    if (message.percentileRanges !== false) {
      writer.uint32(24).bool(message.percentileRanges)
    }
    if (message.filter !== undefined) {
      SegmentProperty.encode(message.filter, writer.uint32(34).fork()).ldelim()
    }
    if (message.x !== undefined) {
      TimeSeries.encode(message.x, writer.uint32(42).fork()).ldelim()
    }
    if (message.y !== undefined) {
      TimeSeries.encode(message.y, writer.uint32(50).fork()).ldelim()
    }
    switch (message.color?.$case) {
      case 'constantColor':
        writer.uint32(82).string(message.color.value)
        break
      case 'variableColor':
        VariableColorSeg.encode(
          message.color.value,
          writer.uint32(90).fork(),
        ).ldelim()
        break
    }
    switch (message.opacity?.$case) {
      case 'constantOpacity':
        writer.uint32(165).float(message.opacity.value)
        break
      case 'variableOpacity':
        SegmentProperty.encode(
          message.opacity.value,
          writer.uint32(170).fork(),
        ).ldelim()
        break
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OverlaidSegments {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseOverlaidSegments()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.timeRange = TimeRange.decode(reader, reader.uint32())
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.segmentation = Segmentation.decode(reader, reader.uint32())
          continue
        case 3:
          if (tag !== 24) {
            break
          }

          message.percentileRanges = reader.bool()
          continue
        case 4:
          if (tag !== 34) {
            break
          }

          message.filter = SegmentProperty.decode(reader, reader.uint32())
          continue
        case 5:
          if (tag !== 42) {
            break
          }

          message.x = TimeSeries.decode(reader, reader.uint32())
          continue
        case 6:
          if (tag !== 50) {
            break
          }

          message.y = TimeSeries.decode(reader, reader.uint32())
          continue
        case 10:
          if (tag !== 82) {
            break
          }

          message.color = { $case: 'constantColor', value: reader.string() }
          continue
        case 11:
          if (tag !== 90) {
            break
          }

          message.color = {
            $case: 'variableColor',
            value: VariableColorSeg.decode(reader, reader.uint32()),
          }
          continue
        case 20:
          if (tag !== 165) {
            break
          }

          message.opacity = { $case: 'constantOpacity', value: reader.float() }
          continue
        case 21:
          if (tag !== 170) {
            break
          }

          message.opacity = {
            $case: 'variableOpacity',
            value: SegmentProperty.decode(reader, reader.uint32()),
          }
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): OverlaidSegments {
    return {
      timeRange: isSet(object.time_range)
        ? TimeRange.fromJSON(object.time_range)
        : undefined,
      segmentation: isSet(object.segmentation)
        ? Segmentation.fromJSON(object.segmentation)
        : undefined,
      percentileRanges: isSet(object.percentile_ranges)
        ? globalThis.Boolean(object.percentile_ranges)
        : false,
      filter: isSet(object.filter)
        ? SegmentProperty.fromJSON(object.filter)
        : undefined,
      x: isSet(object.x) ? TimeSeries.fromJSON(object.x) : undefined,
      y: isSet(object.y) ? TimeSeries.fromJSON(object.y) : undefined,
      color: isSet(object.constant_color)
        ? {
            $case: 'constantColor',
            value: globalThis.String(object.constant_color),
          }
        : isSet(object.variable_color)
        ? {
            $case: 'variableColor',
            value: VariableColorSeg.fromJSON(object.variable_color),
          }
        : undefined,
      opacity: isSet(object.constant_opacity)
        ? {
            $case: 'constantOpacity',
            value: globalThis.Number(object.constant_opacity),
          }
        : isSet(object.variable_opacity)
        ? {
            $case: 'variableOpacity',
            value: SegmentProperty.fromJSON(object.variable_opacity),
          }
        : undefined,
    }
  },

  toJSON(message: OverlaidSegments): unknown {
    const obj: any = {}
    if (message.timeRange !== undefined) {
      obj.time_range = TimeRange.toJSON(message.timeRange)
    }
    if (message.segmentation !== undefined) {
      obj.segmentation = Segmentation.toJSON(message.segmentation)
    }
    if (message.percentileRanges !== false) {
      obj.percentile_ranges = message.percentileRanges
    }
    if (message.filter !== undefined) {
      obj.filter = SegmentProperty.toJSON(message.filter)
    }
    if (message.x !== undefined) {
      obj.x = TimeSeries.toJSON(message.x)
    }
    if (message.y !== undefined) {
      obj.y = TimeSeries.toJSON(message.y)
    }
    if (message.color?.$case === 'constantColor') {
      obj.constant_color = message.color.value
    }
    if (message.color?.$case === 'variableColor') {
      obj.variable_color = VariableColorSeg.toJSON(message.color.value)
    }
    if (message.opacity?.$case === 'constantOpacity') {
      obj.constant_opacity = message.opacity.value
    }
    if (message.opacity?.$case === 'variableOpacity') {
      obj.variable_opacity = SegmentProperty.toJSON(message.opacity.value)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<OverlaidSegments>, I>>(
    base?: I,
  ): OverlaidSegments {
    return OverlaidSegments.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<OverlaidSegments>, I>>(
    object: I,
  ): OverlaidSegments {
    const message = createBaseOverlaidSegments()
    message.timeRange =
      object.timeRange !== undefined && object.timeRange !== null
        ? TimeRange.fromPartial(object.timeRange)
        : undefined
    message.segmentation =
      object.segmentation !== undefined && object.segmentation !== null
        ? Segmentation.fromPartial(object.segmentation)
        : undefined
    message.percentileRanges = object.percentileRanges ?? false
    message.filter =
      object.filter !== undefined && object.filter !== null
        ? SegmentProperty.fromPartial(object.filter)
        : undefined
    message.x =
      object.x !== undefined && object.x !== null
        ? TimeSeries.fromPartial(object.x)
        : undefined
    message.y =
      object.y !== undefined && object.y !== null
        ? TimeSeries.fromPartial(object.y)
        : undefined
    if (
      object.color?.$case === 'constantColor' &&
      object.color?.value !== undefined &&
      object.color?.value !== null
    ) {
      message.color = { $case: 'constantColor', value: object.color.value }
    }
    if (
      object.color?.$case === 'variableColor' &&
      object.color?.value !== undefined &&
      object.color?.value !== null
    ) {
      message.color = {
        $case: 'variableColor',
        value: VariableColorSeg.fromPartial(object.color.value),
      }
    }
    if (
      object.opacity?.$case === 'constantOpacity' &&
      object.opacity?.value !== undefined &&
      object.opacity?.value !== null
    ) {
      message.opacity = {
        $case: 'constantOpacity',
        value: object.opacity.value,
      }
    }
    if (
      object.opacity?.$case === 'variableOpacity' &&
      object.opacity?.value !== undefined &&
      object.opacity?.value !== null
    ) {
      message.opacity = {
        $case: 'variableOpacity',
        value: SegmentProperty.fromPartial(object.opacity.value),
      }
    }
    return message
  },
}

function createBaseAnalysis(): Analysis {
  return {
    id: '',
    meta: undefined,
    label: '',
    description: '',
    kind: undefined,
  }
}

export const Analysis = {
  encode(
    message: Analysis,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id)
    }
    if (message.meta !== undefined) {
      Meta.encode(message.meta, writer.uint32(18).fork()).ldelim()
    }
    if (message.label !== '') {
      writer.uint32(26).string(message.label)
    }
    if (message.description !== '') {
      writer.uint32(34).string(message.description)
    }
    switch (message.kind?.$case) {
      case 'trend':
        Trend.encode(message.kind.value, writer.uint32(82).fork()).ldelim()
        break
      case 'scatter':
        Scatter.encode(message.kind.value, writer.uint32(90).fork()).ldelim()
        break
      case 'parallelCoordinates':
        ParallelCoordinates.encode(
          message.kind.value,
          writer.uint32(98).fork(),
        ).ldelim()
        break
      case 'overlaidSegments':
        OverlaidSegments.encode(
          message.kind.value,
          writer.uint32(106).fork(),
        ).ldelim()
        break
      case 'qualityAnalysis':
        QualityAnalysis.encode(
          message.kind.value,
          writer.uint32(114).fork(),
        ).ldelim()
        break
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Analysis {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseAnalysis()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.id = reader.string()
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.meta = Meta.decode(reader, reader.uint32())
          continue
        case 3:
          if (tag !== 26) {
            break
          }

          message.label = reader.string()
          continue
        case 4:
          if (tag !== 34) {
            break
          }

          message.description = reader.string()
          continue
        case 10:
          if (tag !== 82) {
            break
          }

          message.kind = {
            $case: 'trend',
            value: Trend.decode(reader, reader.uint32()),
          }
          continue
        case 11:
          if (tag !== 90) {
            break
          }

          message.kind = {
            $case: 'scatter',
            value: Scatter.decode(reader, reader.uint32()),
          }
          continue
        case 12:
          if (tag !== 98) {
            break
          }

          message.kind = {
            $case: 'parallelCoordinates',
            value: ParallelCoordinates.decode(reader, reader.uint32()),
          }
          continue
        case 13:
          if (tag !== 106) {
            break
          }

          message.kind = {
            $case: 'overlaidSegments',
            value: OverlaidSegments.decode(reader, reader.uint32()),
          }
          continue
        case 14:
          if (tag !== 114) {
            break
          }

          message.kind = {
            $case: 'qualityAnalysis',
            value: QualityAnalysis.decode(reader, reader.uint32()),
          }
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): Analysis {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : '',
      meta: isSet(object.meta) ? Meta.fromJSON(object.meta) : undefined,
      label: isSet(object.label) ? globalThis.String(object.label) : '',
      description: isSet(object.description)
        ? globalThis.String(object.description)
        : '',
      kind: isSet(object.trend)
        ? { $case: 'trend', value: Trend.fromJSON(object.trend) }
        : isSet(object.scatter)
        ? { $case: 'scatter', value: Scatter.fromJSON(object.scatter) }
        : isSet(object.parallel_coordinates)
        ? {
            $case: 'parallelCoordinates',
            value: ParallelCoordinates.fromJSON(object.parallel_coordinates),
          }
        : isSet(object.overlaid_segments)
        ? {
            $case: 'overlaidSegments',
            value: OverlaidSegments.fromJSON(object.overlaid_segments),
          }
        : isSet(object.quality_analysis)
        ? {
            $case: 'qualityAnalysis',
            value: QualityAnalysis.fromJSON(object.quality_analysis),
          }
        : undefined,
    }
  },

  toJSON(message: Analysis): unknown {
    const obj: any = {}
    if (message.id !== '') {
      obj.id = message.id
    }
    if (message.meta !== undefined) {
      obj.meta = Meta.toJSON(message.meta)
    }
    if (message.label !== '') {
      obj.label = message.label
    }
    if (message.description !== '') {
      obj.description = message.description
    }
    if (message.kind?.$case === 'trend') {
      obj.trend = Trend.toJSON(message.kind.value)
    }
    if (message.kind?.$case === 'scatter') {
      obj.scatter = Scatter.toJSON(message.kind.value)
    }
    if (message.kind?.$case === 'parallelCoordinates') {
      obj.parallel_coordinates = ParallelCoordinates.toJSON(message.kind.value)
    }
    if (message.kind?.$case === 'overlaidSegments') {
      obj.overlaid_segments = OverlaidSegments.toJSON(message.kind.value)
    }
    if (message.kind?.$case === 'qualityAnalysis') {
      obj.quality_analysis = QualityAnalysis.toJSON(message.kind.value)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<Analysis>, I>>(base?: I): Analysis {
    return Analysis.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<Analysis>, I>>(object: I): Analysis {
    const message = createBaseAnalysis()
    message.id = object.id ?? ''
    message.meta =
      object.meta !== undefined && object.meta !== null
        ? Meta.fromPartial(object.meta)
        : undefined
    message.label = object.label ?? ''
    message.description = object.description ?? ''
    if (
      object.kind?.$case === 'trend' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'trend',
        value: Trend.fromPartial(object.kind.value),
      }
    }
    if (
      object.kind?.$case === 'scatter' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'scatter',
        value: Scatter.fromPartial(object.kind.value),
      }
    }
    if (
      object.kind?.$case === 'parallelCoordinates' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'parallelCoordinates',
        value: ParallelCoordinates.fromPartial(object.kind.value),
      }
    }
    if (
      object.kind?.$case === 'overlaidSegments' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'overlaidSegments',
        value: OverlaidSegments.fromPartial(object.kind.value),
      }
    }
    if (
      object.kind?.$case === 'qualityAnalysis' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'qualityAnalysis',
        value: QualityAnalysis.fromPartial(object.kind.value),
      }
    }
    return message
  },
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends globalThis.Array<infer U>
  ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string; value: unknown }
  ? { $case: T['$case']; value?: DeepPartial<T['value']> }
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>

type KeysOfUnion<T> = T extends T ? keyof T : never
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & {
      [K in Exclude<keyof I, KeysOfUnion<P>>]: never
    }

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000)
  const nanos = (date.getTime() % 1_000) * 1_000_000
  return { seconds, nanos }
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000
  millis += (t.nanos || 0) / 1_000_000
  return new globalThis.Date(millis)
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o
  } else if (typeof o === 'string') {
    return new globalThis.Date(o)
  } else {
    return fromTimestamp(Timestamp.fromJSON(o))
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER')
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is smaller than Number.MIN_SAFE_INTEGER')
  }
  return long.toNumber()
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any
  _m0.configure()
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined
}
