import React from 'react'
import { GqlMeFragment } from 'src/services'

export type AuthContextValue = {
  logout: () => void
  viewer: GqlMeFragment
}

export const AuthContext = React.createContext<AuthContextValue | undefined>(
  undefined,
)
