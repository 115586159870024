import { z } from 'zod'
import apiFetch from './apiFetch'
import {
  UpdateAssetDto,
  CreateAssetDto,
  CreateHierarchyAssetDto,
  UpdateHierarchyAssetDto,
  HierarchyAssetDto,
  InternalAssetDto,
} from './types'

export async function getHierarchyAssets(
  factory: string,
): Promise<HierarchyAssetDto[]> {
  const response = await apiFetch({
    path: '/internal/internalAssetHierarchy',
    query: {
      factory,
    },
  })
  return z.array(HierarchyAssetDto).parse(await response.json())
}

export async function getAssets(
  factory: string,
  inactive = true,
): Promise<InternalAssetDto[]> {
  const response = await apiFetch({
    path: '/internal/internalAssets',
    query: {
      inactive,
      view: 0,
      factory,
    },
  })
  return z.array(InternalAssetDto).parse(await response.json())
}

export async function updateHierarchyAsset(
  updatedAsset: UpdateHierarchyAssetDto,
): Promise<void> {
  await apiFetch({
    path: '/internal/internalAssetHierarchy',
    method: 'PUT',
    body: JSON.stringify(updatedAsset),
  })
}

export async function updateAsset(updatedAsset: UpdateAssetDto): Promise<void> {
  await apiFetch({
    path: '/internal/internalAssets',
    method: 'PUT',
    body: JSON.stringify(updatedAsset),
  })
}

export async function createHierarchyAsset(
  newAsset: CreateHierarchyAssetDto,
  factory: string,
): Promise<HierarchyAssetDto> {
  const response = await apiFetch({
    path: '/internal/internalAssetHierarchy',
    method: 'POST',
    body: JSON.stringify(newAsset),
    query: {
      factory,
    },
  })
  return HierarchyAssetDto.parse(await response.json())
}

export async function createAsset(
  newAsset: CreateAssetDto,
  factory: string,
): Promise<InternalAssetDto> {
  const response = await apiFetch({
    path: '/internal/internalAssets',
    method: 'POST',
    body: JSON.stringify(newAsset),
    query: {
      factory,
    },
  })
  return InternalAssetDto.parse(await response.json())
}

export async function deleteHierarchyAsset(id: number): Promise<void> {
  await apiFetch({
    path: `/internal/internalAssetHierarchy/${id}`,
    method: 'DELETE',
  })
}

export async function deleteAsset(id: number): Promise<void> {
  await apiFetch({
    path: `/internal/internalAssets/${id}`,
    method: 'DELETE',
  })
}
