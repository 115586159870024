import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { WorkspaceState } from '../../../../dex/workspace'
import workspace1 from '../../../../dex/workspace-e2111a78-c189-4c37-b1d4-c4dc05f37e63.json'
import workspace2 from '../../../../dex/workspace-eaac57c6-4a2d-490d-a3e0-eff836445260.json'

const workspacesSet = new Map<string, any>([
  ['e2111a78-c189-4c37-b1d4-c4dc05f37e63', workspace1],
  ['eaac57c6-4a2d-490d-a3e0-eff836445260', workspace2],
])

export const WORKSPACES_QUERY = 'workspaces_query'

export function useWorkspaces(): UseQueryResult<WorkspaceState[], Error> {
  return useQuery({
    queryKey: [WORKSPACES_QUERY],
    queryFn: fetchWorkspaces,
  })
}

function fetchWorkspaces(): WorkspaceState[] {
  return [
    WorkspaceState.fromJSON(workspace1),
    WorkspaceState.fromJSON(workspace2),
  ]
}

export function useWorkspace<T = WorkspaceState>(
  workspaceId: string,
  select?: (workspace: WorkspaceState) => T,
): UseQueryResult<T, Error> {
  return useQuery({
    queryKey: [WORKSPACES_QUERY, workspaceId],
    queryFn: () => fetchWorkspace(workspaceId),
    select,
  })
}

function fetchWorkspace(workspaceId: string): WorkspaceState {
  const workspace = workspacesSet.get(workspaceId)
  if (workspace) {
    return WorkspaceState.fromJSON(workspace)
  }
  throw new Error(`Workspace with id ${workspaceId} not found`)
}
