import React, { ReactNode, ErrorInfo } from 'react'
import { IntelecySpinner } from 'src/components/ui'
import { logError } from './utility/logging'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    logError(error, {
      source: 'error_boundary',
      errorInfo,
    })
  }

  public render(): JSX.Element {
    if (this.state.hasError) {
      return (
        <div style={{ marginTop: '200px', textAlign: 'center' }}>
          <IntelecySpinner />
          {<h2>Something went wrong. Try again...</h2>}
        </div>
      )
    }

    return <>{this.props.children}</>
  }
}

export default ErrorBoundary
