import { useEffect, useRef, useState } from 'react'
import { Field, Form, Formik, FormikProps } from 'formik'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import * as Yup from 'yup'
import { Button, Checkbox, Icon, Modal, Text, Tooltip } from 'src/components/ui'
import { zIndex } from 'src/utility/constants/StyleConstants'

type Props = {
  isLoading: boolean
  viewName: string
  siteName: string
  shared: boolean
  isOpen: boolean
  onClose: () => void
  onConfirm: ({ name, shared }: FormValues) => void
}
type FormValues = {
  name: string
  shared: boolean
}

const FormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  discoverable: Yup.boolean(),
})

export function EditViewModal({
  isLoading,
  isOpen,
  onClose,
  viewName,
  siteName,
  shared,
  onConfirm,
}: Props): JSX.Element {
  const [error] = useState<string | null>(null)
  const formikRef = useRef<FormikProps<FormValues>>(null)

  function handleSubmit({ name, shared }: FormValues): void {
    onConfirm({ name, shared })
  }

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue('name', viewName)
    }
  }, [formikRef, viewName, isOpen])

  return (
    <Formik
      initialValues={{
        name: viewName,
        shared: shared,
      }}
      validationSchema={FormSchema}
      onSubmit={handleSubmit}
      innerRef={formikRef}
    >
      {({ errors, touched, isValid, values, setValues, submitForm }) => (
        <Modal
          title={`Edit ${viewName}`}
          isOpen={isOpen}
          close={onClose}
          footer={
            <div className="flex w-full items-center justify-end gap-s pt-s">
              <Button
                variant="secondary"
                onClick={onClose}
                title="Cancel"
                disabled={isLoading}
                className="!py-[7px]"
              />
              <Button
                variant="primary"
                title="Save"
                onClick={submitForm}
                disabled={
                  isLoading ||
                  !isValid ||
                  (values.shared === shared && values.name === viewName)
                }
              />
            </div>
          }
          closeIconSize="regular"
        >
          <>
            <Form className="mb-s max-w-[320px]">
              <Text className="mt-xs">
                Change the name, or show/hide this view from the Discoverable
                Views panel
              </Text>
              <div className="relative my-m">
                <label className="mb-xs block" htmlFor="name">
                  <Text variant="description" bold className="my-xs">
                    Name
                  </Text>
                </label>
                <Field
                  name="name"
                  placeholder="Name your view"
                  className="flex w-full items-center rounded-2xs border-none pl-xs text-xs font-medium leading-[32px] outline-none ring-1 ring-border ring-offset-1 placeholder:font-thin"
                ></Field>
                {errors.name && (
                  <Text
                    variant="small"
                    className={`absolute bottom-[-18px] left-0 text-text-danger`}
                  >
                    {errors.name && touched.name ? errors.name : null}
                  </Text>
                )}
              </div>
              <div className="relative mt-[32px]">
                <div className="flex items-center gap-xs">
                  <Checkbox
                    value={values.shared}
                    onChange={value =>
                      setValues({ name: values.name, shared: value })
                    }
                  />
                  <Text variant="small" bold>
                    Make this view discoverable
                  </Text>
                  <Tooltip
                    zIndex={zIndex.modalLegendMenu}
                    render={() => (
                      <Text variant="description" className="max-w-[200px]">
                        When a view is{' '}
                        <span className="font-500">Discoverable</span>, anyone
                        that has access to{' '}
                        <span className="font-500">{siteName}</span> will be
                        able to visualise it in the{' '}
                        <span className="font-500">Saved Views</span> panel.
                      </Text>
                    )}
                    direction="bottom"
                  >
                    <Icon
                      icon={light('circle-question')}
                      size="small"
                      className="cursor-pointer"
                    />
                  </Tooltip>
                </div>
              </div>
              {error && (
                <div className="mt-4 text-xs font-normal text-red-500">
                  {error}
                </div>
              )}
            </Form>
          </>
        </Modal>
      )}
    </Formik>
  )
}
