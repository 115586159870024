import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Outlet } from 'react-router-dom'
import { Text, RoutingTabs, Icon } from 'src/components/ui'

export function OrgsSitesMainPage(): JSX.Element {
  const tabs = [
    {
      title: 'Organizations',
      path: ``,
    },
    {
      title: 'Sites',
      path: `sites`,
    },
    {
      title: 'My Site Invitations',
      path: `invitations`,
    },
  ]

  return (
    <div className="m-s mt-0 flex h-[calc(100%-1em)] flex-col rounded-2xs bg-background p-m">
      <div className="mb-s flex w-full items-center justify-start gap-l pb-s pl-xs">
        <Icon
          icon={light('building-user')}
          className="!h-[74px] !w-[93px] text-icon-secondary"
        />
        <div>
          <Text variant="description" bold>
            Organizations
          </Text>
          <Text variant="content" className="!mt-2xs mb-xs">
            An <span className="font-500">Organization</span> can contain
            multiple sites.
          </Text>
          <Text variant="description" className="mt-s">
            Sites
          </Text>
          <Text variant="content" className="!mt-2xs mb-xs">
            A <span className="font-500">Site</span> corresponds to a physical
            location and is a collection of all sensor data and models for that
            location.
          </Text>
        </div>
      </div>
      <RoutingTabs tabs={tabs} />
      <Outlet />
    </div>
  )
}
