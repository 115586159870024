/* eslint-disable tailwindcss/no-custom-classname */
import React from 'react'
import { NodeRendererProps } from '@nosferatu500/react-sortable-tree/node-renderer-default'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import { Icon } from 'src/components/ui'
import { Asset, Tag } from 'src/types'
import { useAssetHierarchy } from 'src/contexts/assetHierarchy/useAssetHierarchy'
import { isValidJson } from 'src/utility/stringValidator'
import { AssetItem } from '../assets.types'

const classnames = (...classes: string[]): string =>
  classes.filter(Boolean).join(' ')

// Check if a node is a descendant of another node.
// older - Potential ancestor of younger node
// younger - Potential descendant of older node
const isDescendant = (older: AssetItem, younger: AssetItem): boolean => {
  return (
    !!older.children &&
    typeof older.children !== 'function' &&
    older.children.some(
      child => child === younger || isDescendant(child, younger),
    )
  )
}

/**
 * This is a copy of:
 * https://github.com/nosferatu500/react-sortable-tree/blob/stable/src/node-renderer-default.tsx
 */

const defaultProps = {
  isSearchMatch: false,
  isSearchFocus: false,
  canDrag: false,
  toggleChildrenVisibility: undefined,
  buttons: [],
  className: '',
  style: {},
  parentNode: undefined,
  draggedNode: undefined,
  canDrop: false,
  title: undefined,
  subtitle: undefined,
  rowDirection: 'ltr',
}

interface AssetNodeRendererProps extends NodeRendererProps {
  // override TreeItem with AssetItem
  node: AssetItem
  parentNode?: AssetItem | undefined
  draggedNode?: AssetItem | undefined

  // our custom props
  handleUpdateDrop?: (asset: Asset, tags: Tag[]) => void
  onClickNode?: (asset: Asset) => void
  isSelected?: boolean
  onContextMenu?: (
    event: React.MouseEvent<HTMLDivElement>,
    asset: Asset,
  ) => void
  handleTableDrop: (tags: any[]) => void
}

export function AssetNodeRenderer(props: AssetNodeRendererProps): JSX.Element {
  props = { ...defaultProps, ...props }

  const {
    scaffoldBlockPxWidth,
    toggleChildrenVisibility,
    connectDragPreview,
    connectDragSource,
    isDragging,
    canDrop,
    canDrag,
    node,
    title,
    subtitle,
    draggedNode,
    path,
    treeIndex,
    isSearchMatch,
    isSearchFocus,
    buttons,
    className,
    style,
    didDrop,
    rowDirection,

    // our props
    isSelected,
    handleUpdateDrop = () => {},
    onClickNode = () => {},
    onContextMenu = () => {},
    handleTableDrop = () => {},

    ...otherProps
  } = props
  const nodeTitle = title || node.title
  const nodeSubtitle = subtitle || node.subtitle
  const rowDirectionClass = rowDirection === 'rtl' ? 'rst__rtl' : undefined
  const { id: targetId, setId, selectedTags } = useAssetHierarchy()

  const onDrop = (event: React.DragEvent<HTMLDivElement>): void => {
    const source = event.dataTransfer.getData('Text')

    if (isValidJson(source) && JSON.parse(source).id) {
      if (!selectedTags.length) {
        handleTableDrop([JSON.parse(source)])
      } else {
        handleTableDrop(selectedTags)
      }
    }
    event.currentTarget.className = 'assetNode'
    // don't do anything if dropping on the currect parent
    if (isSelected && source === 'AssetsTagList') return

    if (source === 'AssetsTagList' || source === 'PopUpTagList') {
      const selectedTags: Tag[] = JSON.parse(
        event.dataTransfer.getData('selectedTags'),
      )

      if (selectedTags.length > 0) {
        handleUpdateDrop(node.asset, selectedTags)
      }
    }
  }

  const onDragStart = (event: React.DragEvent<HTMLDivElement>): void => {
    // Needs this to show drag ghost for some reason, can also be used to indicate dragSource
    event.dataTransfer.setData('Text', 'AssetNode')
    event.dataTransfer.setData('draggedAsset', JSON.stringify(node.asset))
  }

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>): void => {
    event.preventDefault()
    event.currentTarget.className = 'assetNode'
  }

  const onDragOver = (event: React.DragEvent<HTMLDivElement>): void => {
    event.stopPropagation()
    event.preventDefault()
    setId(node.asset.assetId)
    event.currentTarget.className = 'assetNode dropHighlighted'
  }

  let handle
  if (canDrag) {
    handle =
      typeof node.children === 'function' && node.expanded ? (
        <div className="rst__loadingHandle">
          <div className="rst__loadingCircle">
            {Array.from({ length: 12 }).map((_, index) => (
              <div
                key={index}
                className={classnames(
                  'rst__loadingCirclePoint',
                  rowDirectionClass ?? '',
                )}
              />
            ))}
          </div>
        </div>
      ) : (
        connectDragSource(<div className="rst__moveHandle" />, {
          dropEffect: 'copy',
        })
      )
  }

  const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node)
  const isLandingPadActive = !didDrop && isDragging

  let buttonStyle = { left: -0.5 * scaffoldBlockPxWidth, right: 0 }
  if (rowDirection === 'rtl') {
    buttonStyle = { right: -0.5 * scaffoldBlockPxWidth, left: 0 }
  }

  return (
    <div
      style={{ height: '100%' }}
      {...otherProps}
      onDragLeave={event => onDragLeave(event)}
      onDragOver={event => onDragOver(event)}
      onDrop={event => onDrop(event)}
      onDragStart={event => onDragStart(event)}
      className={`assetNode ${
        targetId === node.asset.assetId ? 'dropHighlighted' : ''
      }`}
    >
      {toggleChildrenVisibility &&
        node.children &&
        (node.children.length > 0 || typeof node.children === 'function') && (
          <div>
            <button
              type="button"
              aria-label={node.expanded ? 'Collapse' : 'Expand'}
              className={classnames(
                node.expanded ? 'collapseAssetTree' : 'expandAssetTree',
                rowDirectionClass ?? '',
              )}
              style={buttonStyle}
              onClick={() =>
                toggleChildrenVisibility({
                  node,
                  path,
                  treeIndex,
                })
              }
            >
              <Icon
                icon={regular('angle-right')}
                className={classNames(
                  node.expanded ? 'rotate-90' : 'rotate-0',
                  'transition-all',
                )}
              />
            </button>

            {node.expanded && !isDragging && (
              <div
                style={{ width: scaffoldBlockPxWidth }}
                className={classnames(
                  'rst__lineChildren',
                  rowDirectionClass ?? '',
                )}
              />
            )}
          </div>
        )}

      <div
        className={classnames('rst__rowWrapper', rowDirectionClass ?? '')}
        onContextMenu={e => onContextMenu(e, node.asset)}
      >
        {/* Set the row preview to be used during drag and drop */}
        {connectDragPreview(
          <div
            className={classnames(
              'rst__row',
              isLandingPadActive ? 'rst__rowLandingPad' : '',
              isLandingPadActive && !canDrop ? 'rst__rowCancelPad' : '',
              isSearchMatch ? 'rst__rowSearchMatch' : '',
              isSearchFocus ? 'rst__rowSearchFocus' : '',
              rowDirectionClass ?? '',
              className ?? '',
            )}
            style={{
              opacity: isDraggedDescendant ? 0.5 : 1,
              ...style,
            }}
          >
            {handle}

            <div
              id={`assetNodeTarget_contents-${node.asset.assetId}`}
              className={classnames(
                'rst__rowContents',
                canDrag ? '' : 'rst__rowContentsDragDisabled',
                rowDirectionClass ?? '',
                isSelected ? 'selectedAsset' : '',
              )}
              onClick={() => onClickNode(node.asset)}
            >
              <div
                id={`assetNodeTarget_label-${node.asset.assetId}`}
                className={classnames('rst__rowLabel', rowDirectionClass ?? '')}
              >
                <span
                  id={`assetNodeTarget_title-${node.asset.assetId}`}
                  className={classnames(
                    'rst__rowTitle',
                    node.subtitle ? 'rst__rowTitleWithSubtitle' : '',
                  )}
                >
                  {typeof nodeTitle === 'function'
                    ? nodeTitle({
                        node,
                        path,
                        treeIndex,
                      })
                    : nodeTitle}
                </span>

                {nodeSubtitle && (
                  <span className="rst__rowSubtitle">
                    {typeof nodeSubtitle === 'function'
                      ? nodeSubtitle({
                          node,
                          path,
                          treeIndex,
                        })
                      : nodeSubtitle}
                  </span>
                )}
              </div>

              <div className="rst__rowToolbar">
                {buttons?.map((btn, index) => (
                  <div key={index} className="rst__toolbarButton">
                    {btn}
                  </div>
                ))}
              </div>
            </div>
          </div>,
        )}
      </div>
    </div>
  )
}
