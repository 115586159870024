export enum FetchStatus {
  notFetched = 'not fetched',
  pending = 'pending',
  success = 'success',
  fail = 'fail',
}

// maps react-query status to FetchStatus
export function queryStatusToFetchStatus(
  status: 'error' | 'success' | 'loading',
): FetchStatus {
  switch (status) {
    case 'error':
      return FetchStatus.fail
    case 'loading':
      return FetchStatus.pending
    case 'success':
      return FetchStatus.success
    default: {
      const discard: never = status
      throw new Error(`Unhandled status: ${discard}`)
    }
  }
}

// maps react-query mutation.status to FetchStatus
export function mutationStatusToFetchStatus(
  status: 'error' | 'idle' | 'loading' | 'success',
): FetchStatus {
  switch (status) {
    case 'error':
      return FetchStatus.fail
    case 'idle':
      return FetchStatus.notFetched
    case 'loading':
      return FetchStatus.pending
    case 'success':
      return FetchStatus.success
    default: {
      const x: never = status
      return x
    }
  }
}
