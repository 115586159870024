import React from 'react'
import { Button } from 'src/components/ui'

type RenderInput<T> = {
  state: T
  setState: React.Dispatch<React.SetStateAction<T>>
}

interface EditableFieldProps<T> {
  renderDisplay: (val: T) => JSX.Element
  renderInput: (val: RenderInput<T>) => JSX.Element
  saveDisabled: (val: T) => boolean
  isEditable?: boolean
  initialState: T
  onSave: (val: T) => void
  updateStatus: 'success' | 'error' | 'idle' | 'loading'
}

export function EditableField<T>({
  renderDisplay,
  renderInput,
  saveDisabled,
  initialState,
  isEditable = false,
  onSave,
  updateStatus: status,
}: EditableFieldProps<T>): JSX.Element {
  const [state, setState] = React.useState(initialState)
  const [isEditing, setIsEditing] = React.useState(false)
  const prevStatus = React.useRef(status)

  React.useEffect(() => {
    if (prevStatus.current === 'loading' && status === 'success') {
      setIsEditing(false)
    }
    prevStatus.current = status
  }, [status])

  if (!isEditable) return renderDisplay(state)

  return isEditing ? (
    <form
      className="grid auto-cols-max grid-flow-col items-center gap-[0.5em] !text-content"
      onSubmit={e => {
        e.preventDefault()
        onSave(state)
      }}
    >
      {renderInput({ state, setState })}
      <Button
        title="Save"
        type="submit"
        variant="primary"
        className="!py-3xs !text-content"
        disabled={saveDisabled(state)}
        isPending={status === 'loading'}
      />
      {status !== 'loading' && (
        <Button
          title="Cancel"
          variant="tertiary"
          className="!py-2xs !text-content"
          onClick={() => {
            setState(initialState)
            setIsEditing(false)
          }}
        />
      )}
    </form>
  ) : (
    <div className="grid auto-cols-max grid-flow-col items-center gap-[0.5em]">
      {renderDisplay(state)}
      <Button
        variant="tertiary"
        title="Edit"
        className="!py-2xs !text-content"
        onClick={() => setIsEditing(true)}
      />
    </div>
  )
}
