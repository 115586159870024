import { useNavigate } from 'react-router-dom'
import { ErrorPage } from 'pages/app'

export function NotFoundPage(): JSX.Element {
  const navigate = useNavigate()
  return (
    <ErrorPage
      message="Page not found"
      action={() => navigate('/')}
      actionTitle="Go to dashboard"
      info={[['URL', window.location.href]]}
    />
  )
}
