export const PagePadding = {
  y: 16,
  x: 16,
}

export const zIndex = {
  assetContextMenu: 9999,
  userMenu: 100,
  trendLegendMenu: 1,
  header: 999992,
  modal: 999999,
  modalLegendMenu: 1000000,
  banners: 999993,
}
