import { Theme } from './theme.types'

export const lightTheme: Theme = {
  background: {
    DEFAULT: '#FFFFFF',
    hover: '#F3F4F6',
    active: '#F3F4F6',
    secondary: {
      DEFAULT: '#F1F5F9',
      hover: '#CBD5E1',
      active: '#CBD5E1',
    },
    tertiary: {
      DEFAULT: '#D1D5DB',
      hover: '#9CA3AF',
      active: '#9CA3AF',
    },
    brand: {
      DEFAULT: '#10B981',
      hover: '#059669',
      active: '#059669',
      secondary: {
        DEFAULT: '#A7F3D0',
        hover: '#6EE7B7',
        active: '#6EE7B7',
      },
      tertiary: {
        DEFAULT: '#ECFDF5',
        hover: '#D1FAE5',
        active: '#D1FAE5',
      },
    },
    success: {
      DEFAULT: '#10B981',
      hover: '#059669',
      active: '#059669',
      secondary: {
        DEFAULT: '#A7F3D0',
        hover: '#6EE7B7',
        active: '#6EE7B7',
      },
      tertiary: {
        DEFAULT: '#ECFDF5',
        hover: '#D1FAE5',
        active: '#D1FAE5',
      },
    },
    warning: {
      DEFAULT: '#FBBF24',
      hover: '#F59E0B',
      active: '#F59E0B',
      secondary: {
        DEFAULT: '#FDE68A',
        hover: '#FCD34D',
        active: '#FCD34D',
      },
      tertiary: {
        DEFAULT: '#FFFBEB',
        hover: '#FEF3C7',
        active: '#FEF3C7',
      },
    },
    danger: {
      DEFAULT: '#EF4444',
      hover: '#DC2626',
      active: '#DC2626',
      secondary: {
        DEFAULT: '#FECACA',
        hover: '#FCA5A5',
        active: '#FCA5A5',
      },
      tertiary: {
        DEFAULT: '#FEE2E2',
        hover: '#FECACA',
        active: '#FECACA',
      },
    },
    disabled: {
      DEFAULT: '#D1D5DB',
      light: '#D1D5DB',
    },
  },
  text: {
    DEFAULT: '#111827',
    secondary: '#6B7280',
    tertiary: '#9CA3AF',
    brand: {
      DEFAULT: '#065F46',
      secondary: '#059669',
      tertiary: '#10B981',
      onbrand: {
        DEFAULT: '#FFFFFF',
        secondary: '#064E3B',
        tertiary: '#065F46',
      },
    },
    success: {
      DEFAULT: '#065F46',
      secondary: '#059669',
      tertiary: '#10B981',
      onbrand: {
        DEFAULT: '#FFFFFF',
        secondary: '#065F46',
        tertiary: '#065F46',
      },
    },
    warning: {
      DEFAULT: '#78350F',
      secondary: '#B45309',
      tertiary: '#D97706',
      onbrand: {
        DEFAULT: '#451A03',
        secondary: '#92400E',
        tertiary: '#78350F',
      },
    },
    danger: {
      DEFAULT: '#B91C1C',
      secondary: '#DC2626',
      tertiary: '#EF4444',
      onbrand: {
        DEFAULT: '#FEE2E2',
        secondary: '#B91C1C',
        tertiary: '#B91C1C',
      },
    },
    disabled: {
      DEFAULT: '#9CA3AF',
      ondisabled: '#9CA3AF',
    },
  },
  border: {
    DEFAULT: '#D1D5DB',
    secondary: '#6B7280',
    tertiary: '#374151',
    brand: {
      DEFAULT: '#065F46',
      secondary: '#059669',
      tertiary: '#10B981',
    },
    success: {
      DEFAULT: '#065F46',
      secondary: '#059669',
      tertiary: '#10B981',
    },
    warning: {
      DEFAULT: '#78350F',
      secondary: '#B45309',
      tertiary: '#D97706',
    },
    danger: {
      DEFAULT: '#B91C1C',
      secondary: '#DC2626',
      tertiary: '#EF4444',
    },
    disabled: '#9CA3AF',
  },
  icon: {
    DEFAULT: '#111827',
    secondary: '#6B7280',
    tertiary: '#9CA3AF',
    brand: {
      DEFAULT: '#065F46',
      secondary: '#059669',
      tertiary: '#10B981',
      onbrand: {
        DEFAULT: '#D1FAE5',
        secondary: '#065F46',
        tertiary: '#064E3B',
      },
    },
    success: {
      DEFAULT: '#065F46',
      secondary: '#059669',
      tertiary: '#10B981',
      onbrand: {
        DEFAULT: '#D1FAE5',
        secondary: '#065F46',
        tertiary: '#064E3B',
      },
    },
    warning: {
      DEFAULT: '#78350F',
      secondary: '#B45309',
      tertiary: '#D97706',
      onbrand: {
        DEFAULT: '#451A03',
        secondary: '#92400E',
        tertiary: '#78350F',
      },
    },
    danger: {
      DEFAULT: '#B91C1C',
      secondary: '#DC2626',
      tertiary: '#EF4444',
      onbrand: {
        DEFAULT: '#FEE2E2',
        secondary: '#B91C1C',
        tertiary: '#B91C1C',
      },
    },
    disabled: {
      DEFAULT: '#9CA3AF',
      ondisabled: '#9CA3AF',
    },
  },
}
