import React from 'react'

export type NavigationValue = {
  titleComponent: React.ReactNode
  setTitleComponent: (titleComponent: React.ReactNode) => void
}

export const NavigationContext = React.createContext<
  NavigationValue | undefined
>(undefined)
