import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { AggregationMap, IntrinsicMap } from 'data-explorer/data-explorer.types'
import { Button, Icon, Text } from 'src/components/ui'
import { OverlaidSegments } from 'src/dex/analysis'
import { SegmentProperty } from 'src/dex/segment_series'
import { SavedTimeSeries } from 'src/dex/time_series'

type Props = {
  color: OverlaidSegments['color']
  workspaceTimeSeries: SavedTimeSeries[]
}

export function VisualOptionsDisplay({
  color,
  workspaceTimeSeries,
}: Props): JSX.Element {
  if (color === undefined) {
    return (
      <Button
        variant="secondary"
        icon={light('gear')}
        title="Configure Color"
      />
    )
  }
  const functionAndTimeSeries =
    color.$case === 'variableColor' && color.value.variable
      ? getColorFunctionAndTimeSeries(color.value.variable, workspaceTimeSeries)
      : undefined
  return (
    <div className="flex items-center gap-xs">
      <Button
        variant="icon"
        className="!px-xs !py-2xs"
        icon={light('pencil')}
        iconSize="small"
      />
      <Button
        variant="icon"
        className="!px-xs !py-2xs"
        icon={light('trash-alt')}
        iconSize="small"
      />
      {color.$case === 'constantColor' ? (
        <Text>
          Color: {color.$case === 'constantColor' ? color.value : 'Some other'}
        </Text>
      ) : (
        <Text bold>
          Color: <Icon icon={regular('function')} />{' '}
          <span className="uppercase">{functionAndTimeSeries?.function}</span>(
          {functionAndTimeSeries?.timeSeries})
        </Text>
      )}
    </div>
  )
}

function getColorFunctionAndTimeSeries(
  color: SegmentProperty,
  workspaceTimeSeries: SavedTimeSeries[],
): {
  function?: string
  timeSeries?: string
} {
  switch (color.kind?.$case) {
    case 'tsAgg': {
      const agg = AggregationMap[color.kind.value.agg]
      const timeSeries = color.kind.value.ts
      if (timeSeries?.kind?.$case === 'tsRef') {
        const ts = workspaceTimeSeries.find(
          ts => ts.id === timeSeries.kind?.value,
        )
        return {
          function: agg,
          timeSeries: ts?.tsDef?.label || 'Unknown time series',
        }
      } else {
        return {
          function: agg,
          timeSeries: timeSeries?.kind?.value.label || 'Unknown time series',
        }
      }
    }
    case 'intrinsicProperty': {
      return {
        function: IntrinsicMap[color.kind.value],
        timeSeries: 'Segment',
      }
    }
  }
  return { function: undefined, timeSeries: undefined }
}
