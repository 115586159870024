import queryString from 'query-string'
import { API_ROOT } from '../config'
import { getToken } from './auth'
import traceparent from './traceparent'
import { ResponseError } from './responseError'
import fetcher from './fetcher'

function makeUrl(path: string, query?: { [key: string]: any }): string {
  if (query) {
    const q = queryString.stringify(query, { arrayFormat: 'comma' })
    return `${API_ROOT}${path}?${q}`
  }

  return `${API_ROOT}${path}`
}

async function makeRequest({
  path,
  query,
  method = 'GET',
  body,
}: ApiFetchRequest): Promise<Request> {
  const token = await getToken()
  const url = makeUrl(path, query)

  return new Request(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      traceparent: traceparent(),
    },
    body,
    credentials: 'include',
  })
}

interface ApiFetchRequest {
  path: string
  query?: { [key: string]: any }
  method?: string
  body?: string
}

export default async function apiFetch(
  req: ApiFetchRequest,
): Promise<Response> {
  const request = await makeRequest(req)
  const response = await fetcher(request)
  if (!response.ok) {
    const traceparent = request.headers.get('traceparent')
    throw new ResponseError(response, traceparent)
  }
  return response
}
