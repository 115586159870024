import React from 'react'

export function useTitle(title: string): void {
  React.useEffect(() => {
    document.title = `Intelecy - ${title}`

    // reset the title when leaving
    return () => {
      document.title = 'Intelecy'
    }
  }, [title])
}
