import { getHours, getMinutes } from 'date-fns'
import { Text } from 'src/components/ui'

export type TimeValue = {
  hour: number
  minutes: number
}

type Props = {
  label?: string
  value: number
  error: boolean
  handleChange: ({ hour, minutes }: TimeValue) => void
}

export default function TimeInput({
  label,
  value,
  error,
  handleChange,
}: Props): JSX.Element {
  return (
    <div className="flex items-center">
      <Text variant="description" className="ml-[6px]">
        {label}
      </Text>
      <div
        className={`mt-[6px] flex border-0 border-b
        border-solid px-2xs pb-2xs ${
          error ? 'border-border-danger' : 'border-border-brand'
        }`}
      >
        <input
          className="max-w-[50px] border-0 pb-0 text-center text-description font-bold"
          type={'number'}
          value={getHours(value).toString().padStart(2, '0')}
          onChange={e => {
            handleChange({
              hour: parseInt(e.target.value, 10),
              minutes: getMinutes(value),
            })
          }}
          min={0}
          max={23}
        />
        <Text variant="description" bold className="pb-3xs">
          :
        </Text>
        <input
          className="max-w-[50px] border-0 pb-0 text-center text-description font-bold"
          type={'number'}
          value={getMinutes(value).toString().padStart(2, '0')}
          onChange={e => {
            handleChange({
              hour: getHours(value),
              minutes: parseInt(e.target.value, 10),
            })
          }}
          min={0}
          max={59}
        />
      </div>
    </div>
  )
}
