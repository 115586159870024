import { GqlErrorScoreKind, GqlThresholdKind } from 'src/services'
import { baseTooltipOptions, trendTooltipOptions } from './tooltips'

interface Data {
  tagName?: string
  name?: string
  color?: string | undefined
  data: any[]
}

interface Break {
  from?: number
  to?: number
}

interface BaseChartOpts {
  data: Data[]
  height: string | number | null | undefined
  tooltipEnabled?: boolean
  staticTimeRange?: boolean
  threshold?: number
  thresholdType?: GqlThresholdKind
  errorScoreKind?: GqlErrorScoreKind
  yAxis?: Highcharts.YAxisOptions
  breaks?: Break[]
  setTimeRange: (value: { from: number; to: number }) => void
  setYAxisRange: (value: { min: number | null; max: number | null }) => void
  getGraphColor: (value?: string) => string | undefined
  yAxisPerData?: boolean
}

export const baseChartConfig = ({
  data,
  setTimeRange,
  height,
  tooltipEnabled,
  staticTimeRange,
  threshold,
  thresholdType,
  errorScoreKind,
  yAxis,
  breaks,
  setYAxisRange,
  getGraphColor,
  yAxisPerData,
}: BaseChartOpts): Highcharts.Options => {
  const yAxisOptions = (
    extra?: Highcharts.YAxisOptions,
  ): Highcharts.YAxisOptions => ({
    title: {
      text: undefined,
    },
    lineWidth: 1,
    lineColor: '#e6e6e6',
    labels: {
      x: -10,
    },
    offset: 0,
    ...yAxis,
    ...extra,
  })

  const getToolTip = (): Highcharts.TooltipOptions => {
    if (tooltipEnabled) {
      if (yAxisPerData) {
        return trendTooltipOptions
      }
      return baseTooltipOptions
    }
    return { enabled: false }
  }

  const yAxisOptionsList = yAxisPerData
    ? data.map((props, idx) =>
        yAxisOptions({
          alignTicks: false,
          gridLineWidth: idx > 0 ? 0 : 1,
          labels: {
            x: -10,
            style: {
              color: props.color || getGraphColor(props.tagName),
            },
          },
        }),
      )
    : [yAxisOptions()]

  const chart: Highcharts.Options = {
    chart: {
      marginLeft: 40,
      marginBottom: 20,
      spacingTop: 5,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0,
      backgroundColor: 'transparent',
      height,
      zoomType: staticTimeRange ? undefined : 'x',
      events: {
        selection: e => {
          e.preventDefault()
          setTimeRange({
            from: e.xAxis[0].min,
            to: e.xAxis[0].max,
          })
          return false
        },
        redraw() {
          setYAxisRange({
            min:
              errorScoreKind === 'SIGNED'
                ? -Math.max(
                    Math.abs(this.yAxis[0].min ?? 0),
                    Math.abs(this.yAxis[0].max ?? 0),
                  )
                : this.yAxis[0].min,
            max:
              errorScoreKind === 'SIGNED'
                ? Math.max(
                    Math.abs(this.yAxis[0].min ?? 0),
                    Math.abs(this.yAxis[0].max ?? 0),
                  )
                : this.yAxis[0].max,
          })
        },
      },
      animation: false,
      resetZoomButton: {
        theme: {
          display: 'none',
        },
      },
      style: {
        fontFamily: 'Roboto',
      },
    },
    time: { useUTC: false },
    credits: { enabled: false },
    title: undefined,
    legend: {
      enabled: false,
    },
    loading: {
      style: {
        backgroundColor: 'transparent',
        cursor: 'default',
      },
    },
    xAxis: {
      type: 'datetime',
      minRange: 1,
      lineColor: '#e6e6e6',
      breaks: breaks?.map(({ from, to }) => ({
        from,
        to,
        breakSize: 5000000,
      })),
    },
    yAxis: yAxisOptionsList,
    tooltip: getToolTip(),
    plotOptions: {
      series: {
        animation: false,
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },
        zones:
          threshold !== undefined
            ? thresholdType === 'UPPER'
              ? [{ value: threshold }, { color: '#EB364C' }]
              : [{ value: threshold, color: '#EB364C' }]
            : [],
      },
    },
    series: data.flatMap((props, idx) => {
      const yAxis = yAxisPerData ? idx : 0
      const main = ({
        name,
        color,
        data = [],
      }: Data): Highcharts.SeriesOptionsType => ({
        type: 'line',
        name,
        color: color || getGraphColor(props.tagName),
        step: 'left',
        data: [...data],
        dataGrouping: {
          enabled: false,
          groupPixelWidth: 50,
        },
        yAxis,
      })
      const area = ({
        name,
        color,
        data = [],
      }: Data): Highcharts.SeriesOptionsType => ({
        type: 'arearange',
        name,
        data: data.map(([x, , low, high]) => [x, low, high]),
        color: color || getGraphColor(props.tagName),
        fillOpacity: 0.1,
        step: 'left',
        dataGrouping: {
          enabled: false,
          groupPixelWidth: 50,
        },
        yAxis,
      })
      return (props.data || []).some(point => point.length > 2)
        ? [main(props), area(props)]
        : [main(props)]
    }),
  }
  return chart
}
