import React, { useEffect, useRef, useState } from 'react'
import { ImperativePanelHandle } from 'react-resizable-panels'
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from '@components/resizable'

type Props = {
  leftComponent: React.ReactNode
  rightComponent: React.ReactNode
  // minimum size in pixels
  minSize?: number
  // default size in percent
  defaultSize?: number
  collapsible?: boolean
  onResize?: () => void
}

export function ResizableLayout({
  leftComponent,
  rightComponent,
  minSize,
  defaultSize,
  collapsible,
  onResize,
}: Props): JSX.Element {
  const layoutRef = useRef<HTMLDivElement>(null)
  const sidebarRef = useRef<ImperativePanelHandle>(null)
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [size, setSize] = useState(0)

  useEffect(() => {
    if (defaultSize && sidebarRef.current) {
      sidebarRef.current.resize(defaultSize)
    }
  }, [defaultSize, sidebarRef])

  useEffect(() => {
    // Convert minSize in pixels to percentage
    const handleResize = (): void => {
      if (layoutRef.current && minSize) {
        const { width } = layoutRef.current.getBoundingClientRect()
        const minPercentage = (100 * minSize) / width

        // percentage cannot be over 100
        setSize(minPercentage > 100 ? 100 : minPercentage)
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [minSize])

  return (
    <div ref={layoutRef} className="h-full px-s">
      <ResizablePanelGroup direction="horizontal">
        <ResizablePanel
          onCollapse={() => setIsCollapsed(true)}
          onExpand={() => setIsCollapsed(false)}
          onResize={onResize}
          ref={sidebarRef}
          minSize={size}
          collapsible={collapsible}
          collapsedSize={0}
        >
          <div className="flex h-full flex-col">{leftComponent}</div>
        </ResizablePanel>
        <ResizableHandle
          withHandle
          collapsed={isCollapsed}
          onExpandClick={() => sidebarRef.current?.expand()}
        />
        <ResizablePanel>{rightComponent}</ResizablePanel>
      </ResizablePanelGroup>
    </div>
  )
}
