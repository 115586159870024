import { ModelTypes } from 'src/types'
import { ModelTypeStep } from './ModelType'
import { OutputStep } from './Output'
import { InputStep } from './Input'
import { NameStep } from './Name'

export interface Record {
  id?: string
  name?: string
  description?: string
  modelType?: ModelTypes | null
  outputTag?: string | null
  inputTags?: string[]
}

export interface Tag {
  label: string
  type?: string
  tagName: string
}

interface RenderData {
  model: Record
  updateModel: (record: Record) => void
}

interface Step {
  label: string
  completed: (model: Record) => boolean
  render: (data: RenderData) => JSX.Element
  maxWidth?: string
}

export const steps: Step[] = [
  {
    label: 'Type',
    completed: model => !!model.modelType,
    render: ({ model, updateModel }) => (
      <ModelTypeStep
        modelType={model.modelType}
        setModelType={modelType => {
          // When switching from ForecastModel to AnomalyModel, remove outputTag from inputTags
          if (modelType === 'AnomalyModel') {
            updateModel({
              modelType,
              inputTags: [
                ...(model.inputTags?.filter(t => t !== model.outputTag) ?? []),
              ],
            })
          }
          // When switching from AnomalyModel to ForecastModel, add outputTag to inputTags
          else {
            updateModel({
              modelType,
              inputTags:
                model.inputTags && model.outputTag
                  ? [model.outputTag, ...model.inputTags]
                  : model.inputTags,
            })
          }
        }}
      />
    ),
    maxWidth: '645px',
  },
  {
    label: 'Output',
    completed: model => !!model.outputTag,
    render: ({ model, updateModel }) => (
      <OutputStep
        modelType={model.modelType}
        outputTag={model.outputTag}
        setOutputTag={outputTag => {
          if (model.modelType === 'ForecastModel') {
            // If there's previous outputTag as input remove it
            const inputTags = model.inputTags
              ? model.inputTags.filter(tag => tag !== model.outputTag)
              : []
            // and add the new outputTag as input if defined
            if (outputTag) {
              inputTags.push(outputTag)
            }

            updateModel({ outputTag, inputTags })
          } else updateModel({ outputTag })
        }}
        model={model}
      />
    ),
  },
  {
    label: 'Input',
    completed: model => !!model.inputTags && model.inputTags.length > 0,
    render: ({ model, updateModel }) => (
      <InputStep
        outputTag={model.outputTag}
        inputTags={model.inputTags}
        addInputTag={tag =>
          updateModel({
            inputTags: model.inputTags && [...model.inputTags, tag],
          })
        }
        removeInputTag={tag =>
          updateModel({
            inputTags:
              model.inputTags &&
              model.inputTags.filter(inputTag => inputTag !== tag),
          })
        }
        model={model}
      />
    ),
  },
  {
    label: 'Name',
    completed: model => !!model.name,
    render: ({ model: { name, description }, updateModel }) => (
      <NameStep
        name={name}
        description={description}
        setDescription={description => updateModel({ description })}
        setName={name => updateModel({ name })}
      />
    ),
    maxWidth: '600px',
  },
]
