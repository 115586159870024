import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { FetchAnomalyModel, GqlAnomalyConditionsFragment } from 'src/services'
import { Icon, Text } from 'src/components/ui'
import { isThresholdSameAsOption } from '../util'
import { AddAnomalyThreshold } from './AddAnomalyThreshold'
import { thresholdOptions } from './defaultThresholds'

type Props = {
  model: FetchAnomalyModel
  open: boolean
  setOpen: (open: boolean) => void
  threshold: GqlAnomalyConditionsFragment
  onSave?: (threshold: GqlAnomalyConditionsFragment) => void
  onDelete?: () => void
  thresholds?: GqlAnomalyConditionsFragment[]
  readOnly?: boolean
  className?: string
}

export function AnomalyThreshold({
  model,
  open,
  setOpen,
  threshold,
  thresholds,
  onSave,
  onDelete,
  readOnly,
  className,
}: Props): JSX.Element {
  const [tempThreshold, setTempThreshold] = useState(threshold)
  useEffect(() => {
    setTempThreshold(threshold)
  }, [threshold])

  return (
    <div className={classNames('py-s', className)}>
      <div
        className={classNames(
          'flex w-full cursor-pointer select-none items-center',
          open && 'pb-s',
        )}
        onClick={() => (open ? setOpen(false) : setOpen(true))}
      >
        <div className="flex items-center">
          <Text>Threshold:&nbsp;</Text>
          <Text bold>
            {
              thresholdOptions.find(o => isThresholdSameAsOption(threshold, o))
                ?.label
            }
          </Text>
          <div className="mx-xs h-icon-big w-px bg-background-tertiary" />
          <Text className="inline">
            Value <span className="font-500">{tempThreshold.threshold}</span>
          </Text>
        </div>
        <Icon
          icon={light('chevron-down')}
          className={classNames(
            open ? 'rotate-180' : 'rotate-0',
            'transition-all duration-300 px-xs',
          )}
        />
      </div>
      {open && (
        <AddAnomalyThreshold
          initialThreshold={threshold}
          model={model}
          thresholds={thresholds}
          onCancel={() => setOpen(false)}
          onSubmit={onSave}
          isEdit
          onDelete={onDelete}
          readOnly={readOnly}
        />
      )}
    </div>
  )
}
