import { useRef } from 'react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useTheme } from 'styled-components'
import classNames from 'classnames'
import { Icon } from 'src/components/ui'

interface ColorProps {
  hover: boolean
  index: number
  inactive?: boolean
  onClick: () => void
}

const Color = ({
  hover,
  index,
  inactive,
  onClick,
}: ColorProps): JSX.Element => {
  const theme = useTheme()
  const ref = useRef<HTMLButtonElement>(null)
  return (
    <button
      className="group relative size-[1em] cursor-pointer overflow-hidden rounded-2xs border-2 border-solid"
      style={{
        borderColor: hover
          ? '#f09'
          : theme.colors.chart[index % theme.colors.chart.length],
        backgroundColor: !inactive
          ? hover
            ? '#f09'
            : theme.colors.chart[index % theme.colors.chart.length]
          : undefined,
      }}
      ref={ref}
      onClick={() => {
        if (ref.current) {
          ref.current.blur()
        }
        onClick()
      }}
    >
      <Icon
        size="xsmall"
        className={classNames(
          'absolute top-0 left-[1px] group-hover:block group-focus:block',
          inactive ? 'hidden' : 'block text-white',
        )}
        icon={regular('check')}
      />
    </button>
  )
}

export default Color
