import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Link } from 'react-router-dom'
import toast from 'react-hot-toast'
import queryString from 'query-string'
import {
  NEW,
  NOT_RUNNING,
  RUNNING,
  STREAMING,
  getModelTagsWithNoDataInLastMonth,
} from 'models/model/model.utils'
import {
  useActivateModelMutation,
  useDeactivateModelMutation,
  useStartModelTrainingMutation,
} from 'models/api'
import { Button, Card } from 'src/components/ui'
import { useSite } from 'src/contexts/site'
import useTimeRange from 'src/contexts/timeRange'
import { SiteRole } from 'src/types'
import { FetchModel } from 'src/services'
import { isModelEditing } from '../Overview/modelOverview.state'
import { TrendLink } from './TrendLink'
import { DeleteButton } from './DeleteButton'
import { CopyButton } from './CopyButton'
import { ArchiveButton } from './ArchiveButton'
import { IfLabsAccess } from './IfLabsAccess'

interface ActionsProps {
  className?: string
  model: FetchModel
}

export function ModelActions({ className, model }: ActionsProps): JSX.Element {
  const { timeRange } = useTimeRange()
  const { rootLink, viewerRole } = useSite()
  const isReader = viewerRole === SiteRole.READER

  const modelHasErrors =
    model.method.name === 'Nowcast' && model.inputTags.includes(model.tag)

  const noDataInLastMonth = getModelTagsWithNoDataInLastMonth(model)

  // mutations
  const activateModel = useActivateModelMutation()
  const deactivateModel = useDeactivateModelMutation()
  const startModelTraining = useStartModelTrainingMutation(model)

  // callbacks

  async function handleTrainModel(): Promise<void> {
    await startModelTraining.mutateAsync(
      {
        id: model.id,
      },
      {
        onError: () => {
          toast.error('Failed to start model training', {
            position: 'top-right',
          })
        },
      },
    )
  }

  async function handleActivate(): Promise<void> {
    await activateModel.mutateAsync(
      { id: model.id },
      {
        onError: () => {
          toast.error('Failed to activate model', { position: 'top-right' })
        },
      },
    )
  }

  async function handleDeactivate(): Promise<void> {
    await deactivateModel.mutateAsync(
      { id: model.id },
      {
        onError: () => {
          toast.error('Failed to deactivate model', { position: 'top-right' })
        },
      },
    )
  }

  return (
    <>
      <Card className={className}>
        <div className="grid grid-cols-action-buttons gap-[0.5em]">
          <Button
            title="View model data in trend"
            variant="secondary"
            as={isModelEditing.value ? undefined : TrendLink}
            timeRange={timeRange}
            model={model}
            tags={[model.tag, ...model.inputTags]}
            disabled={isModelEditing.value}
          />
          <CopyButton model={model} />
          {(() => {
            switch (model.state) {
              case RUNNING:
                return (
                  <>
                    {model.type.name === 'Forecast' && (
                      <Button
                        title="Go to forecast"
                        as={isModelEditing.value ? undefined : Link}
                        to={`${rootLink}/forecast?${queryString.stringify({
                          models: model.id,
                        })}`}
                        variant="secondary"
                        disabled={isReader || isModelEditing.value}
                      />
                    )}
                    <Button
                      title="Deactivate model"
                      variant="secondary"
                      buttonColor="danger"
                      onClick={handleDeactivate}
                      isPending={deactivateModel.isLoading}
                      disabled={isReader || isModelEditing.value}
                    />
                  </>
                )
              case NEW:
                return (
                  <Button
                    title="Train model"
                    variant="primary"
                    onClick={handleTrainModel}
                    isPending={startModelTraining.isLoading}
                    disabled={
                      modelHasErrors || isReader || isModelEditing.value
                    }
                  />
                )
              case NOT_RUNNING:
                return (
                  <Button
                    title="Activate model"
                    variant="primary"
                    onClick={handleActivate}
                    disabled={
                      noDataInLastMonth.length > 0 ||
                      isReader ||
                      isModelEditing.value
                    }
                    isPending={activateModel.isLoading}
                  ></Button>
                )
              case STREAMING:
                return model.type.name === 'Forecast' ? (
                  <Button
                    title="Go to forecast"
                    as={Link}
                    to={`${rootLink}/forecast?${queryString.stringify({
                      models: model.id,
                    })}`}
                    variant="secondary"
                    disabled={isReader || isModelEditing.value}
                  ></Button>
                ) : null
              default:
                return null
            }
          })()}
          {model.state !== STREAMING && <DeleteButton model={model} />}
          {(model.state === RUNNING || model.state === NOT_RUNNING) && (
            <ArchiveButton model={model} />
          )}
          {model.type.name === 'Forecast' && (
            <IfLabsAccess>
              <Button
                title="Model Analysis"
                variant="secondary"
                disabled={isModelEditing.value}
                as={isModelEditing ? undefined : Link}
                to={`${rootLink}/labs/model_analysis?${queryString.stringify({
                  model_id: model.id,
                })}`}
                icon={regular('flask')}
              />
            </IfLabsAccess>
          )}
        </div>
      </Card>
    </>
  )
}
