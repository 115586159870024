import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import { useRef, useState } from 'react'
import { Icon, Text } from 'src/components/ui'
import { useClickOutside } from 'src/utility'
import { GqlFactoryTrendViewFragment } from 'src/services'
import { ChartOptions } from 'src/types/chartTypes'
import { ViewDropdown } from './components'

export function TrendNavigation({
  views,
  viewId,
  charts,
  isViewChanged,
}: {
  views: GqlFactoryTrendViewFragment[]
  viewId?: string
  charts: ChartOptions[]
  isViewChanged: boolean
}): JSX.Element {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const viewName = views.find(view => view.id === viewId)?.name

  useClickOutside(dropdownRef, () => {
    setIsDropdownOpen(false)
  })

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className="flex cursor-pointer select-none items-center gap-xs"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <Text variant="title">
          <span className="font-500">Trend | </span>
          <span className={classNames(!viewName && 'text-text-tertiary')}>
            {viewName
              ? viewName
              : charts.length
              ? 'Unsaved'
              : 'Select a trend view'}
          </span>
          {isViewChanged && (
            <span className="text-text-tertiary"> (edited)</span>
          )}
        </Text>
        <Icon
          icon={light('chevron-down')}
          className={classNames(
            'transition-all',
            isDropdownOpen && 'rotate-180',
          )}
        />
      </div>
      <ViewDropdown
        isOpen={isDropdownOpen}
        setIsOpen={setIsDropdownOpen}
        views={views}
        viewId={viewId}
      />
    </div>
  )
}
