import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Accordion, Button, Icon, Text } from 'src/components/ui'
import { Trend } from 'src/dex/analysis'
import { SavedTimeSeries, TimeSeriesDef } from 'src/dex/time_series'
import { isDefined } from 'src/types'
import { extractTimeRangeDef } from 'pages/site/DataExplorerNew/data-explorer.utils'
import { SavedTimeRange } from 'src/dex/time_range'
import { TimeRangeDisplay } from '../../components'

type Props = {
  analysis: Trend
  workspaceTimeRanges: SavedTimeRange[]
  workspaceTimeSeries: SavedTimeSeries[]
}

export function TrendAnalysis({
  analysis,
  workspaceTimeRanges,
  workspaceTimeSeries,
}: Props): JSX.Element {
  const timeRange = analysis.timeRange
    ? extractTimeRangeDef(analysis.timeRange, workspaceTimeRanges)
    : undefined

  function getAnalysisTags(
    trendAnalysis: Trend,
    workspaceTagsAndTimeSeries: SavedTimeSeries[],
  ): Array<TimeSeriesDef> {
    return trendAnalysis.timeSeries
      .map((ts): TimeSeriesDef | undefined => {
        if (ts.kind?.$case === 'tsDef') return ts.kind.value
        if (ts.kind?.$case === 'tsRef') {
          const timeSeries = workspaceTagsAndTimeSeries.find(
            t => t.id === ts.kind?.value,
          )
          if (timeSeries) return timeSeries.tsDef
        }
        return undefined
      })
      .filter(isDefined)
  }

  return (
    <div className="flex flex-col gap-s">
      {timeRange && (
        <Accordion label="Time range" labelVariant="content" initialIsOpen>
          <TimeRangeDisplay timeRange={timeRange} />
        </Accordion>
      )}
      <Accordion
        label="Tags and Time Series"
        labelVariant="content"
        initialIsOpen
      >
        <div className="flex flex-col gap-s">
          <div className="flex items-center gap-2xs">
            <Icon
              size="xsmall"
              icon={light('info-circle')}
              className="text-text-brand"
            />
            <Text variant="description">
              Add one or more time series to the trend analysis.
            </Text>
          </div>
          {getAnalysisTags(analysis, workspaceTimeSeries).map(tag => {
            return (
              <div className="flex gap-xs" key={tag.label}>
                <Button
                  variant="icon"
                  icon={light('trash-alt')}
                  iconSize="small"
                  className="!px-xs !py-2xs"
                />
                <div className="flex flex-col gap-2xs">
                  <Text bold>
                    <Icon icon={regular('sensor')} /> {tag.label}
                  </Text>
                  <Text variant="description" bold>
                    {tag.description}
                  </Text>
                </div>
              </div>
            )
          })}
          <Button
            className="mt-s self-start"
            variant="icon-primary"
            icon={light('plus')}
            title="Add Time Series"
          />
        </div>
      </Accordion>
      <Accordion label="Result" labelVariant="content" initialIsOpen>
        <Button
          variant="icon-primary"
          icon={light('play')}
          title="Run Analysis"
        />
      </Accordion>
    </div>
  )
}
