import { ColDef, IRowNode } from '@ag-grid-community/core'
import classNames from 'classnames'
import { Text, Tooltip } from 'src/components/ui'
import { humanDateTime } from 'src/utility/time'
import { zIndex } from 'src/utility/constants/StyleConstants'
import { StatusCode } from '../opc-ua-connection.types'
import { TagMenu, UpdatedAtRenderer } from './components'

export function getTagHierarchyConfig(
  refreshRow: (node: IRowNode) => void,
): ColDef[] {
  return [
    {
      field: 'type',
      hide: true,
      lockVisible: true,
      suppressColumnsToolPanel: true,
    },
    { field: 'id', hide: true },
    {
      field: 'status',
      initialFlex: 200,
      resizable: true,
      minWidth: 150,
      cellRenderer: ({ value }: any) => {
        if (!value) return ''
        return (
          <div
            className={classNames(
              'flex items-center justify-center py-[2px] rounded-2xs w-[56px]',
              value === StatusCode.Good && 'bg-background-brand-hover',
              value === StatusCode.Bad && 'bg-background-danger-secondary',
            )}
          >
            <Text variant="description" className="text-center">
              {value}
            </Text>
          </div>
        )
      },
    },
    {
      field: 'value',
      initialFlex: 200,
      resizable: true,
      minWidth: 150,
      cellRenderer: ({ data }: any) => {
        if (data.value === null || data.value === undefined) return ''
        if (data.dataType === 'time.Time')
          return (
            <Tooltip zIndex={zIndex.modalLegendMenu} render={() => data.value}>
              {humanDateTime(data.value)}
            </Tooltip>
          )
        if (typeof data.value === 'boolean') return data.value.toString()
        return data.value
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Last value change',
      initialFlex: 200,
      resizable: true,
      minWidth: 150,
      cellRenderer: ({ value }: any) => {
        if (!value) return ''
        return <UpdatedAtRenderer value={value} />
      },
    },
    {
      field: 'dataType',
      initialFlex: 200,
      resizable: true,
      minWidth: 150,
      cellRenderer: ({ value }: any) => {
        if (value === 'time.Time') return 'datetime'
        return value ?? ''
      },
    },
    {
      field: 'actions',
      resizable: false,
      minWidth: 60,
      maxWidth: 60,
      cellClass: 'selector-cell',
      headerComponent: () => null,
      cellRenderer: ({ data, node }: { data: any; node: IRowNode }) => {
        return <TagMenu data={data} node={node} refreshRow={refreshRow} />
      },
    },
  ]
}
