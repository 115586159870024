import moment from 'moment-timezone'
import { z } from 'zod'
import apiFetch from './apiFetch'
import { StreamlitAppDto, StreamlitSessionDto } from './types'

export async function getStreamlitApps(
  factory: string,
): Promise<StreamlitAppDto[]> {
  const response = await apiFetch({ path: '/apps', query: { factory } })
  return z.array(StreamlitAppDto).parse(await response.json())
}

export async function startStreamlitSession(
  factory: string,
  slug: string,
): Promise<StreamlitSessionDto> {
  const response = await apiFetch({
    path: `/apps/${slug}/start`,
    method: 'POST',
    query: {
      user_tz: moment.tz.guess(),
      factory,
    },
  })
  return StreamlitSessionDto.parse(await response.json())
}

export async function verifyStreamlitSession(
  proxyPath: string,
): Promise<boolean> {
  const response = await apiFetch({
    path: `${proxyPath}/healthz`,
    method: 'GET',
  })

  return response.ok
}
