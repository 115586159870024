import { useParams } from 'react-router-dom'
import { add, sub } from 'date-fns'
import { TimeRangeProvider } from 'src/contexts/timeRange'
import { CursorProvider } from 'src/contexts/cursor'
import { FilterProvider } from 'src/contexts/filter'
import { ModelAnomaliesProvider } from 'src/contexts/modelAnomalies'
import { useModel, useModelTypes } from 'models/api'
import { useSite } from 'src/contexts/site'
import { useTitle } from 'src/utility'
import { Text, Spinner, Card } from 'src/components/ui'
import { metropolisDataRange } from 'src/utility/metropolis'
import { FetchModel } from 'src/services'
import { ErrorDisplay } from 'pages/app'
import { AnomalyDetectionModel, ForecastModel } from './variants'
import {
  AWAITING_ANOMALY_ANALYSIS,
  hasRunInitialInference,
} from './model.utils'

const offset = (model: FetchModel): number => {
  switch (model.method.name) {
    case 'Forecast 1H':
      return 60
    case 'Forecast 2H':
      return 120
    case 'Forecast 4H':
      return 240
    default:
      return 0
  }
}

const defaultToTime = (model: FetchModel): Date => {
  if (
    model.__typename === 'AnomalyModel' &&
    model.state === AWAITING_ANOMALY_ANALYSIS
  ) {
    const ts = model.initialInferenceEnd || model.activeTrainedModel?.dataEnd
    return ts ? ts : new Date()
  }

  if (
    model.__typename === 'ForecastModel' &&
    model.forecastHorizon &&
    hasRunInitialInference(model.state)
  ) {
    return add(new Date(), { seconds: model.forecastHorizon })
  }

  if (model.type.name === 'Forecast' && hasRunInitialInference(model.state)) {
    return add(new Date(), { minutes: offset(model) })
  }

  return new Date()
}

function defaultFromTime(model: FetchModel, defaultTo: Date): Date {
  if (model.state === AWAITING_ANOMALY_ANALYSIS) {
    if (model.initialInferenceStart) return model.initialInferenceStart
    return sub(defaultTo, { months: 6 })
  }

  if (model.__typename === 'ForecastModel' && model.forecastHorizon) {
    return sub(Date.now(), { days: 1 })
  }

  return sub(defaultTo, { days: 1 })
}

export function ModelPage(): JSX.Element | null {
  useTitle('Model')
  const { modelId } = useParams()
  if (!modelId) throw new Error('`modelId` route param missing')
  const modelQuery = useModel(modelId)
  const modelTypesQuery = useModelTypes()
  const { isWorkshop } = useSite()

  // if the model is loading, then return immediately
  if (modelQuery.isLoading || modelTypesQuery.isLoading) {
    return (
      <div className="m-[1em] mt-0">
        <Card>
          <Text variant="title" bold>
            Model
          </Text>
          <Spinner />
        </Card>
      </div>
    )
  }

  if (modelQuery.isError || !modelQuery.data || !modelTypesQuery.data) {
    return (
      <ErrorDisplay
        error={modelQuery.error}
        message="Failed to load model"
        action={modelQuery.refetch}
      />
    )
  }

  // we now have a valid model. Work out the time default range

  const defaultTo = defaultToTime(modelQuery.data)
  const defaultTimeRange = isWorkshop
    ? metropolisDataRange
    : {
        from: defaultFromTime(modelQuery.data, defaultTo).valueOf(),
        to: defaultTo.valueOf(),
      }

  return (
    <div className="m-[1em] mt-0">
      <TimeRangeProvider urlQuery defaultTimeRange={defaultTimeRange}>
        <CursorProvider>
          {modelQuery.data.__typename === 'ForecastModel' ? (
            <FilterProvider>
              <ForecastModel
                model={modelQuery.data}
                modelTypes={modelTypesQuery.data}
              />
            </FilterProvider>
          ) : (
            <FilterProvider condition={modelQuery.data.anomalyGenerationFilter}>
              <ModelAnomaliesProvider modelId={modelId}>
                <AnomalyDetectionModel
                  model={modelQuery.data}
                  modelTypes={modelTypesQuery.data}
                />
              </ModelAnomaliesProvider>
            </FilterProvider>
          )}
        </CursorProvider>
      </TimeRangeProvider>
    </div>
  )
}
