import { Banner } from 'src/components/ui'

export function NewModelInfo(): JSX.Element {
  return (
    <Banner className="col-span-2" variant="info">
      This is your newly created model! You can edit all parts of the
      configuration at this stage. After moving on to the training stage, the
      configuration settings are locked. Make sure to select a training period
      that explains the behavior of the output.
    </Banner>
  )
}
