import React from 'react'
import {
  last24Hours,
  TimeRangeContext,
  TimeRangeValue,
} from './TimeRangeContext'

export const useTimeRange = (): TimeRangeValue => {
  const context = React.useContext(TimeRangeContext)
  if (context === undefined) {
    console.warn(
      'useTimeRange used without a TimeRangeProvider or ForecastTimeRangeProvider',
    )
    return {
      timeRange: last24Hours(),
      live: false,
      isLiveUpdate: false,
      setTimeRange: () => {},
      setLive: () => {},
      undo: () => {},
      hasHistory: false,
    }
  }
  return context
}
