import { effect, Signal, signal } from '@preact/signals-react'
import { TailwindTheme } from 'src/style/themes'

function validateTailwindTheme(value: string | null): value is TailwindTheme {
  return Object.values(TailwindTheme).includes(value as TailwindTheme)
}

function getThemeFromLocalStorage(): TailwindTheme {
  const theme = localStorage.getItem('tailwindTheme')
  return validateTailwindTheme(theme) ? theme : TailwindTheme.Legacy
}

export const tailwindTheme: Signal<TailwindTheme> = signal(
  getThemeFromLocalStorage(),
)

effect(() => {
  // Set item to local storage when theme changes
  localStorage.setItem('tailwindTheme', tailwindTheme.value)
  // Set the theme classes on the body
  document.body.classList.remove(...Object.values(TailwindTheme))
  document.body.classList.add(tailwindTheme.value)
})
