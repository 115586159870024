import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { useSite } from 'src/contexts/site'
import * as api from 'src/services'
import { MODELS_QUERY_KEY } from 'models/api'

export function useDeleteModelMutation(): UseMutationResult<
  api.GqlModelIdStateFragment | null | undefined,
  unknown,
  api.GqlDeleteModelInput
> {
  const queryClient = useQueryClient()
  const { id: currentFactory } = useSite()

  return useMutation({
    mutationFn: async (input: api.GqlDeleteModelInput) =>
      api.deleteModel(input),
    onSuccess: () => {
      queryClient.invalidateQueries([MODELS_QUERY_KEY, currentFactory])
    },
  })
}
