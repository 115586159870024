import { Text, TextAreaInput, TextInput } from 'src/components/ui'

interface NameStepProps {
  name?: string
  description?: string
  setName: (name: string) => void
  setDescription: (description: string) => void
}

export function NameStep({
  name,
  description,
  setName,
  setDescription,
}: NameStepProps): JSX.Element {
  return (
    <>
      <Text variant="title" bold className="my-m text-center !text-3xl">
        What do you want to name this model?
      </Text>
      <div className="mb-m flex flex-col gap-s">
        <TextInput
          value={name || ''}
          onChange={e => setName(e.target.value)}
          placeholder="Model name"
          label="Model name"
        />
        <TextAreaInput
          inputClassName="max-h-[250px]"
          value={description || ''}
          onChange={e => setDescription(e.target.value)}
          placeholder="Descriptive text"
          label="Description"
          labelSuffix="(Optional)"
          minRows={3}
        />
      </div>
    </>
  )
}
