import { baseTooltipOptions } from 'src/components/ui'
import { TimeSeriesData } from 'src/types'

interface ForecastChartOpts {
  tagData?: any[]
  height?: string | number | null
  getGraphColor: (id?: string) => string
  modelId: string
  prediction?: TimeSeriesData[]
}

export const forecastChartConfig = ({
  tagData = [],
  height,
  getGraphColor,
  modelId,
  prediction = [],
}: ForecastChartOpts): Highcharts.Options => {
  const color = getGraphColor(modelId)
  const predictionData =
    tagData.length > 0 ? [tagData[tagData.length - 1], ...prediction] : []
  const predictionRangeData =
    tagData.length > 0
      ? [
          tagData[tagData.length - 1],
          ...prediction.map(([ts, , low, high]) => [ts, low, high]),
        ]
      : []

  return {
    chart: {
      marginLeft: 42,
      marginBottom: 20,
      spacingTop: 5,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0,
      backgroundColor: 'transparent',
      height,
      animation: false,
    },
    title: undefined,
    time: { useUTC: false },
    credits: { enabled: false },
    xAxis: { type: 'datetime' },
    yAxis: { title: { text: '' } },
    series: [
      {
        type: 'line',
        // pass in a copy of the data
        data: [...tagData],
        marker: { enabled: false },
        color,
      },
      {
        type: 'line',
        dashStyle: 'Dot',
        data: predictionData,
        marker: {
          radius: 3,
          symbol: 'circle',
        },
        color,
      },
      {
        type: 'arearange',
        data: predictionRangeData,
        marker: { enabled: false },
        fillOpacity: 0.1,
        color,
      },
    ],
    legend: { enabled: false },
    scrollbar: { enabled: false },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    tooltip: baseTooltipOptions,
  }
}
