import { ClientError } from 'graphql-request'

export function parseApiErrorMessage(
  error: Error,
  fallbackMessage?: string,
): string {
  return error instanceof ClientError
    ? error.response.errors?.[0].message ?? fallbackMessage ?? ''
    : error.message ?? fallbackMessage ?? ''
}
