import { Link } from 'react-router-dom'
import { exactDuration, humanDateTime, humanDuration } from 'src/utility/time'
import { useSite } from 'src/contexts/site'
import { Text, Button, Spinner, Card } from 'src/components/ui'
import { Anomaly } from 'src/services'

interface NavigationProps {
  anomaly: Anomaly
  className?: string
}

export function Navigation({
  anomaly,
  className,
}: NavigationProps): JSX.Element {
  const { rootLink } = useSite()

  return (
    <Card className={className}>
      {!anomaly ? (
        <Spinner />
      ) : (
        <div className="grid auto-cols-[1fr] grid-flow-col gap-[1em]">
          {anomaly.previous && (
            <div>
              <Text variant="content-rubik" bold className="mb-xs">
                Previous anomaly
              </Text>
              <div className="mb-[0.5em] text-text-tertiary">
                <Text className="my-xs">Id: {anomaly.previous.id}</Text>
                <div>
                  <Text className="my-xs">Start time:</Text>
                  <Text className="my-xs">
                    {humanDateTime(anomaly.previous.start)}
                  </Text>
                </div>
                {anomaly.previous.end && (
                  <Text className="my-xs">
                    Duration:{' '}
                    <span
                      title={exactDuration(
                        anomaly.previous.start,
                        anomaly.previous.end,
                      )}
                    >
                      {humanDuration(
                        anomaly.previous.start,
                        anomaly.previous.end,
                      )}
                    </span>
                  </Text>
                )}
                <Text className="my-xs">
                  Peak score:{' '}
                  {anomaly.previous.score
                    ? anomaly.previous.score.toFixed(4)
                    : '-'}
                </Text>
              </div>
              <Button
                title="Go to prev anomaly"
                as={Link}
                to={`${rootLink}/anomalies/${anomaly.previous.id}`}
                variant="primary"
              />
            </div>
          )}
          {anomaly.next && (
            <div>
              <Text variant="content-rubik" bold className="mb-xs">
                Next anomaly
              </Text>
              <div className="mb-[0.5em] text-text-tertiary">
                <Text className="my-xs">Id: {anomaly.next.id}</Text>
                <div>
                  <Text className="my-xs">Start time:</Text>
                  <Text className="my-xs">
                    {humanDateTime(anomaly.next.start)}
                  </Text>
                </div>
                {anomaly.next.end && (
                  <Text className="my-xs">
                    Duration:{' '}
                    <span
                      title={exactDuration(
                        anomaly.next.start,
                        anomaly.next.end,
                      )}
                    >
                      {humanDuration(anomaly.next.start, anomaly.next.end)}
                    </span>
                  </Text>
                )}
                <Text className="my-xs">
                  Peak score:{' '}
                  {anomaly.next.score ? anomaly.next.score.toFixed(4) : '-'}
                </Text>
              </div>
              <Button
                title="Go to next anomaly"
                as={Link}
                to={`${rootLink}/anomalies/${anomaly.next.id}`}
                variant="primary"
              />
            </div>
          )}
        </div>
      )}
    </Card>
  )
}
