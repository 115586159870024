import { Signal, signal } from '@preact/signals-react'
import { millisecondsToSeconds } from 'date-fns'
import { FetchModel, GqlModelTypeFragment } from 'src/services'
import { getModelOffset, NEW } from '../../model.utils'

type TrainingPeriod = {
  from?: number
  to?: number
  period?: string
}

type ModelValues = {
  name: Signal<string>
  description: Signal<string>
  typeId: Signal<string>
  methodId: Signal<string>
  forecastHorizon: Signal<number | null>
  trainingPeriod: Signal<TrainingPeriod>
}

export const isModelEditing = signal(false)

export const modelValues: ModelValues = {
  name: signal(''),
  description: signal(''),
  typeId: signal(''),
  methodId: signal(''),
  trainingPeriod: signal({ period: 'P1Y' }),
  forecastHorizon: signal(null),
}

export function initializeModelValues(
  model: FetchModel,
  modelTypes: GqlModelTypeFragment[],
): void {
  const getTrainingPeriod = (): any =>
    model.trainingStart && model.trainingEnd
      ? {
          from: model.trainingStart.valueOf(),
          to: model.trainingEnd.valueOf(),
        }
      : { period: model.trainingPeriod || 'P1Y' }

  const modelMethodAndHorizon = getModelMethodAndHorizon(model, modelTypes)
  modelValues.name.value = model.name
  modelValues.description.value = model.description
  modelValues.typeId.value = model.type.id
  modelValues.methodId.value = modelMethodAndHorizon.methodId
  modelValues.trainingPeriod.value = getTrainingPeriod()
  modelValues.forecastHorizon.value = modelMethodAndHorizon.forecastHorizon
}

export function getModelMethodAndHorizon(
  model: FetchModel,
  modelTypes: GqlModelTypeFragment[],
): {
  methodId: string
  forecastHorizon: number | null
} {
  const currentMethodAndHorizon = {
    methodId: model.method.id,
    forecastHorizon:
      model.__typename === 'ForecastModel'
        ? model.forecastHorizon ?? null
        : null,
  }

  // If model cannot be changed return the current method and horizon
  if (model.state !== NEW || model.__typename !== 'ForecastModel') {
    return currentMethodAndHorizon
  }

  // Find the forecast method with horizon
  const forecastWithHorizonMethod = modelTypes
    .find(({ name }) => name === 'Forecast')
    ?.methods.find(({ name }) => name === 'Forecast with horizon')?.id

  if (!forecastWithHorizonMethod) {
    return currentMethodAndHorizon
  }

  // If model has old method, return updated method and horizon
  if (model.method.name !== 'Forecast with horizon') {
    return {
      methodId: forecastWithHorizonMethod,
      forecastHorizon: millisecondsToSeconds(getModelOffset(model)),
    }
  }

  return currentMethodAndHorizon
}
