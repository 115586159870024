import { humanDuration } from 'src/utility/time'
import { anomalyDisplayTimeRange } from 'src/types'
import { Text } from 'src/components/ui'
import { TableAnomaly } from 'anomalies/anomaly.types'

interface DurationProps {
  anomaly: TableAnomaly
}

export function AnomalyDuration({ anomaly }: DurationProps): JSX.Element {
  const { from, to } = anomalyDisplayTimeRange(anomaly)
  return <Text>{humanDuration(from, to)}</Text>
}
