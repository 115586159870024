import { format } from 'date-fns'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { TimeRange } from 'src/types'

export const prettyDate = (date: number): string =>
  moment(date).format('DD/MM/YYYY')
export const prettyTime = (date: number): string =>
  moment(date).format('HH:mm:ss')
export const humanDate = (date: moment.MomentInput): string =>
  date ? moment.utc(date).local().format('DD MMM YYYY') : '-'
export const humanDateTime = (date: moment.MomentInput): string =>
  date ? moment.utc(date).local().format('DD MMM YYYY, HH:mm') : '-'
export const timeAgo = (date: moment.MomentInput): string =>
  moment.utc(date).fromNow()
export const humanTime = (date: moment.MomentInput): string =>
  date ? moment.utc(date).local().format('HH:mm') : '-'
export const humanTimeWithSeconds = (date: Date | undefined): string =>
  date ? format(date, 'HH:mm:ss') : '-'

const prettyDuration = (duration: moment.DurationInputArg1): string =>
  `Last ${moment
    .duration(duration)
    .humanize()
    .replace(/^a/, '1')
    .replace(/^1 year/, '12 months')}`

interface PrettyDateRangeInput {
  timeInterval: string
  fromDate?: Date | null
  toDate?: Date | null
}

const humanDateOrEmpty = (date: moment.MomentInput): string =>
  date ? humanDate(date) : ''

export const prettyDateRange = ({
  timeInterval,
  fromDate,
  toDate,
}: PrettyDateRangeInput): string =>
  timeInterval && timeInterval !== 'range'
    ? prettyDuration(timeInterval)
    : `${humanDateOrEmpty(fromDate)} - ${humanDateOrEmpty(toDate)}`

interface TimeRangeOutput {
  from: string
  to: string
}

export const timeRangeUrlProps = (timeRange: TimeRange): TimeRangeOutput => ({
  from: moment(timeRange.from).format('YYYYMMDDTHHmmssZZ'),
  to: moment(timeRange.to).format('YYYYMMDDTHHmmssZZ'),
})

export const humanDuration = (
  from: moment.MomentInput,
  to: moment.MomentInput,
): string => moment.duration(moment(to).diff(moment(from))).humanize()

export const exactDuration = (from: Date, to: Date | undefined): string => {
  const duration = moment.duration(moment(to).diff(moment(from)))
  const days = duration.as('days')
  const time = moment.utc(duration.as('milliseconds')).format('HH:mm:ss')

  return days >= 1 ? `${Math.floor(days)}:${time}` : time
}

export const useTimeAgo = (
  date: Date | undefined,
  interval: number = 60000,
): string => {
  const [tics, setTics] = useState(0)

  useEffect(() => {
    if (date) {
      const intervalId = setInterval(() => {
        setTics(prev => prev + 1)
      }, interval)
      return () => clearInterval(intervalId)
    }
    return undefined
  }, [date, interval, tics])

  return timeAgo(date)
}
