import { SeriesOptions } from 'src/types/chartTypes'

export enum ModalState {
  Add,
  Save,
  Share,
  Unsaved,
  None,
}

export type SaveTrendProps = {
  name: string
  shared: boolean
  duplicate?: boolean
  clearAfterSave?: boolean
}

export enum AddChartType {
  Single,
  Multiple,
}

export type DnDItem = {
  series: SeriesOptions
  sourceChartId: number
}
