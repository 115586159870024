import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { minutesToMilliseconds } from 'date-fns'
import { useSite } from 'src/contexts/site'
import * as api from 'src/services'
import { Asset } from 'src/types'

const mergeAsset = (
  asset: api.InternalAssetDto,
  ha: api.HierarchyAssetDto,
): Asset => ({
  ...ha,
  ...asset,
  assetId: asset.id,
  hierarchyId: ha.id,
})

export const ASSETS_QUERY_KEY = 'fetchAssets'

export function useAssets(): UseQueryResult<Asset[]> {
  const { id: factory } = useSite()
  return useQuery({
    queryKey: [ASSETS_QUERY_KEY, factory],
    queryFn: () => getAssets(factory),
    staleTime: minutesToMilliseconds(5),
  })
}

async function getAssets(factory: string): Promise<Asset[]> {
  const haAssets = await api.getHierarchyAssets(factory)
  const assets = await api.getAssets(factory, true)

  const assetMap = new Map(assets.map(a => [a.key, a]))
  return haAssets
    .filter(a => a.viewType === 0)
    .reduce((acc: Asset[], ha) => {
      const asset = assetMap.get(ha.key)
      if (asset) {
        return [...acc, mergeAsset(asset, ha)]
      }
      return acc
    }, [])
}
