import { useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { RoutingTabs, Spinner } from 'src/components/ui'
import { useNavigationContext } from 'src/contexts/navigation'
import { ErrorDisplay } from 'pages/app'
import { useGateway } from '../api'
import { GatewayOverview } from './gateway-details'
import { GatewayDetailsNavigation } from './GatewayDetailsNavigation'

const tabs = [
  {
    title: 'OPC-UA Connections',
    path: '',
  },
]
export function GatewayDetailsPage(): JSX.Element {
  const { orgId, siteId, gatewayId } = useParams()
  if (!orgId || !siteId || !gatewayId)
    throw new Error('Missing orgId, siteId or gatewayId')
  const { setTitleComponent } = useNavigationContext()

  const gatewayQuery = useGateway(siteId, gatewayId)

  useEffect(() => {
    setTitleComponent(
      <GatewayDetailsNavigation
        orgId={orgId}
        siteId={siteId}
        gatewayId={gatewayId}
      />,
    )
    return () => setTitleComponent(null)
  }, [gatewayId, orgId, setTitleComponent, siteId])

  if (gatewayQuery.isError) {
    return (
      <ErrorDisplay
        message="Failed to fetch gateway"
        error={gatewayQuery.error}
        action={gatewayQuery.refetch}
      />
    )
  }

  return (
    <div className="flex h-full flex-col p-s">
      <div className="flex flex-1 flex-col gap-m rounded-2xs bg-background px-s py-m">
        {gatewayQuery.isLoading ? (
          <Spinner />
        ) : (
          <>
            <GatewayOverview gateway={gatewayQuery.data} />
            <div className="flex flex-1 flex-col gap-m">
              <RoutingTabs tabs={tabs} />
              <Outlet />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
