import { getRangeIcon } from 'data-explorer/data-explorer.utils'
import { Button, Icon, Text } from 'src/components/ui'
import { SavedTimeRange } from 'src/dex/time_range'

type Props = {
  timeRange: SavedTimeRange
}

export function TimeRangeCard({ timeRange }: Props): JSX.Element {
  return (
    <>
      <div
        onClick={() => {
          // TODO: Navigate to time range details page
          // navigate(`time-range/${timeRange.id}`)
        }}
        className="flex w-[400px] cursor-pointer flex-col gap-s rounded-2xs border border-solid border-border p-m pr-s transition duration-300 hover:shadow-button"
        key={timeRange.id}
      >
        <div className="flex items-center ">
          <Icon
            icon={getRangeIcon(timeRange.trDef?.kind?.$case ?? 'static')}
            className="mr-s !h-[30px] !w-[36px] text-icon-secondary"
          />
          <div>
            <Text variant="description">Time Range</Text>
            <Text variant="content" bold className="mb-xs mt-2xs">
              {timeRange.trDef?.label ?? timeRange.id}
            </Text>
          </div>
        </div>
        <div className="flex w-full items-start justify-between gap-s">
          <Button
            variant={'secondary'}
            title="Copy"
            className="w-full"
            onClick={e => {
              e.stopPropagation()
              console.log('Copy')
            }}
          />
          <Button
            variant={'secondary'}
            buttonColor="danger"
            title="Delete"
            className="w-full"
            onClick={e => {
              e.stopPropagation()
              console.log('Delete')
            }}
          />
        </div>
      </div>
    </>
  )
}
