import { HomeOrganization } from './organizationTypes'
import { SiteRole } from './roleTypes'

export type Site = {
  id: string
  name: string
  orgName: string
  orgId: string
  viewerRole: SiteRole | undefined
  numOfGateways: number
  numOfUsers: number
}

export type SiteUser = {
  id: string
  name?: string
  email: string
  role: SiteRole
  status: string
  userTag: string
  addedBy: string
  homeOrganization: HomeOrganization
  inviteDaysLeft?: number
}

export type SiteInvitation = {
  id: string
  site: {
    siteName: string
    orgName: string
  }
  role: SiteRole
  state: SiteInvitationStatus
  inviter: string
  validUntil: string
}

export enum SiteInvitationStatus {
  ACCEPTED = 'Accepted',
  ACTIVE = 'Active',
  CANCELLED = 'Canceled',
  DECLINED = 'Declined',
  EXPIRED = 'Expired',
}
