import { isDefined } from './utility'
import { TimeRange } from '.'

// With the new anomaly builder we really have 3 states:
// - Live:
//   - we have crossed the anomaly threshold and not gone under
//   - inProgress = true, to = undefined
// - "Monitoring":
//   - we have gone under the threshold but are waiting to see if we go over again, currently 1hr
//   - inProgress = true, to is defined
// - Complete:
//   - The anomaly has completed.
//   - inProgress = false, to is defined

export interface AnomalyLike {
  from: number
  to?: number
  inProgress: boolean
}

export type AnomalyState = 'live' | 'monitoring' | 'complete'

export function anomalyState({ to, inProgress }: AnomalyLike): AnomalyState {
  if (inProgress) {
    // for some crazy reason, `to` is sometimes NaN instead of undefined
    // That should be tracked down, but for now, handle it
    return isDefined(to) && Number.isInteger(to) ? 'monitoring' : 'live'
  }
  return 'complete'
}

export function anomalyDisplayTimeRange(anomaly: AnomalyLike): TimeRange {
  const state = anomalyState(anomaly)
  const { from } = anomaly
  switch (state) {
    case 'live':
    case 'monitoring':
      return { from, to: Date.now() }
    case 'complete': {
      // `to` should always be defined here, but just in case...
      const to = anomaly.to ?? Date.now()
      return { from, to }
    }
    default:
      throw new Error('Unhandled anomaly state')
  }
}

export function anomalyDuration(anomaly: AnomalyLike): number {
  const { from, to } = anomalyDisplayTimeRange(anomaly)
  return to - from
}

export type AnomalyColor = 'red' | 'yellow' | 'green'
