export function Logo(): JSX.Element {
  return (
    <svg
      width="128"
      viewBox="0 0 381 297"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M190.5 12.2C193.869 12.2 196.6 9.46894 196.6 6.10001C196.6 2.73107 193.869 0 190.5 0C187.131 0 184.4 2.73107 184.4 6.10001C184.4 9.46894 187.131 12.2 190.5 12.2Z"
        fill="#333333"
      />
      <path
        d="M190.5 36.9C193.869 36.9 196.6 34.1689 196.6 30.8C196.6 27.431 193.869 24.7 190.5 24.7C187.131 24.7 184.4 27.431 184.4 30.8C184.4 34.1689 187.131 36.9 190.5 36.9Z"
        fill="#333333"
      />
      <path
        d="M190.5 61.5999C193.869 61.5999 196.6 58.8688 196.6 55.4999C196.6 52.131 193.869 49.3999 190.5 49.3999C187.131 49.3999 184.4 52.131 184.4 55.4999C184.4 58.8688 187.131 61.5999 190.5 61.5999Z"
        fill="#333333"
      />
      <path
        d="M190.5 86.3001C193.869 86.3001 196.6 83.569 196.6 80.2001C196.6 76.8312 193.869 74.1001 190.5 74.1001C187.131 74.1001 184.4 76.8312 184.4 80.2001C184.4 83.569 187.131 86.3001 190.5 86.3001Z"
        fill="#333333"
      />
      <path
        d="M190.5 111C193.869 111 196.6 108.269 196.6 104.9C196.6 101.531 193.869 98.8 190.5 98.8C187.131 98.8 184.4 101.531 184.4 104.9C184.4 108.269 187.131 111 190.5 111Z"
        fill="#333333"
      />
      <path
        d="M190.5 135.7C193.869 135.7 196.6 132.969 196.6 129.6C196.6 126.231 193.869 123.5 190.5 123.5C187.131 123.5 184.4 126.231 184.4 129.6C184.4 132.969 187.131 135.7 190.5 135.7Z"
        fill="#333333"
      />
      <path
        d="M190.5 160.4C193.869 160.4 196.6 157.669 196.6 154.3C196.6 150.931 193.869 148.2 190.5 148.2C187.131 148.2 184.4 150.931 184.4 154.3C184.4 157.669 187.131 160.4 190.5 160.4Z"
        fill="#333333"
      />
      <path
        d="M169.1 24.5999C172.469 24.5999 175.2 21.8688 175.2 18.4999C175.2 15.131 172.469 12.3999 169.1 12.3999C165.731 12.3999 163 15.131 163 18.4999C163 21.8688 165.731 24.5999 169.1 24.5999Z"
        fill="#333333"
      />
      <path
        d="M169.1 49.3001C172.469 49.3001 175.2 46.569 175.2 43.2001C175.2 39.8312 172.469 37.1001 169.1 37.1001C165.731 37.1001 163 39.8312 163 43.2001C163 46.569 165.731 49.3001 169.1 49.3001Z"
        fill="#333333"
      />
      <path
        d="M169.1 74C172.469 74 175.2 71.269 175.2 67.9001C175.2 64.5311 172.469 61.8 169.1 61.8C165.731 61.8 163 64.5311 163 67.9001C163 71.269 165.731 74 169.1 74Z"
        fill="#333333"
      />
      <path
        d="M169.1 98.7C172.469 98.7 175.2 95.9689 175.2 92.6C175.2 89.2311 172.469 86.5 169.1 86.5C165.731 86.5 163 89.2311 163 92.6C163 95.9689 165.731 98.7 169.1 98.7Z"
        fill="#333333"
      />
      <path
        d="M169.1 123.4C172.469 123.4 175.2 120.669 175.2 117.3C175.2 113.931 172.469 111.2 169.1 111.2C165.731 111.2 163 113.931 163 117.3C163 120.669 165.731 123.4 169.1 123.4Z"
        fill="#333333"
      />
      <path
        d="M169.1 148.1C172.469 148.1 175.2 145.369 175.2 142C175.2 138.631 172.469 135.9 169.1 135.9C165.731 135.9 163 138.631 163 142C163 145.369 165.731 148.1 169.1 148.1Z"
        fill="#333333"
      />
      <path
        d="M147.7 36.9C151.069 36.9 153.8 34.1689 153.8 30.8C153.8 27.431 151.069 24.7 147.7 24.7C144.331 24.7 141.6 27.431 141.6 30.8C141.6 34.1689 144.331 36.9 147.7 36.9Z"
        fill="#333333"
      />
      <path
        d="M147.7 61.5999C151.069 61.5999 153.8 58.8688 153.8 55.4999C153.8 52.131 151.069 49.3999 147.7 49.3999C144.331 49.3999 141.6 52.131 141.6 55.4999C141.6 58.8688 144.331 61.5999 147.7 61.5999Z"
        fill="#333333"
      />
      <path
        d="M147.7 86.3001C151.069 86.3001 153.8 83.569 153.8 80.2001C153.8 76.8312 151.069 74.1001 147.7 74.1001C144.331 74.1001 141.6 76.8312 141.6 80.2001C141.6 83.569 144.331 86.3001 147.7 86.3001Z"
        fill="#333333"
      />
      <path
        d="M147.7 111C151.069 111 153.8 108.269 153.8 104.9C153.8 101.531 151.069 98.8 147.7 98.8C144.331 98.8 141.6 101.531 141.6 104.9C141.6 108.269 144.331 111 147.7 111Z"
        fill="#333333"
      />
      <path
        d="M147.7 135.7C151.069 135.7 153.8 132.969 153.8 129.6C153.8 126.231 151.069 123.5 147.7 123.5C144.331 123.5 141.6 126.231 141.6 129.6C141.6 132.969 144.331 135.7 147.7 135.7Z"
        fill="#333333"
      />
      <path
        d="M126.4 49.3001C129.769 49.3001 132.5 46.569 132.5 43.2001C132.5 39.8312 129.769 37.1001 126.4 37.1001C123.031 37.1001 120.3 39.8312 120.3 43.2001C120.3 46.569 123.031 49.3001 126.4 49.3001Z"
        fill="#333333"
      />
      <path
        d="M126.4 74C129.769 74 132.5 71.269 132.5 67.9001C132.5 64.5311 129.769 61.8 126.4 61.8C123.031 61.8 120.3 64.5311 120.3 67.9001C120.3 71.269 123.031 74 126.4 74Z"
        fill="#333333"
      />
      <path
        d="M126.4 98.7C129.769 98.7 132.5 95.9689 132.5 92.6C132.5 89.2311 129.769 86.5 126.4 86.5C123.031 86.5 120.3 89.2311 120.3 92.6C120.3 95.9689 123.031 98.7 126.4 98.7Z"
        fill="#333333"
      />
      <path
        d="M126.4 123.4C129.769 123.4 132.5 120.669 132.5 117.3C132.5 113.931 129.769 111.2 126.4 111.2C123.031 111.2 120.3 113.931 120.3 117.3C120.3 120.669 123.031 123.4 126.4 123.4Z"
        fill="#333333"
      />
      <path
        d="M211.9 24.5999C215.269 24.5999 218 21.8688 218 18.4999C218 15.131 215.269 12.3999 211.9 12.3999C208.531 12.3999 205.8 15.131 205.8 18.4999C205.8 21.8688 208.531 24.5999 211.9 24.5999Z"
        fill="#333333"
      />
      <path
        d="M211.9 49.3001C215.269 49.3001 218 46.569 218 43.2001C218 39.8312 215.269 37.1001 211.9 37.1001C208.531 37.1001 205.8 39.8312 205.8 43.2001C205.8 46.569 208.531 49.3001 211.9 49.3001Z"
        fill="#333333"
      />
      <path
        d="M211.9 74C215.269 74 218 71.269 218 67.9001C218 64.5311 215.269 61.8 211.9 61.8C208.531 61.8 205.8 64.5311 205.8 67.9001C205.8 71.269 208.531 74 211.9 74Z"
        fill="#333333"
      />
      <path
        d="M211.9 98.7C215.269 98.7 218 95.9689 218 92.6C218 89.2311 215.269 86.5 211.9 86.5C208.531 86.5 205.8 89.2311 205.8 92.6C205.8 95.9689 208.531 98.7 211.9 98.7Z"
        fill="#333333"
      />
      <path
        d="M211.9 123.4C215.269 123.4 218 120.669 218 117.3C218 113.931 215.269 111.2 211.9 111.2C208.531 111.2 205.8 113.931 205.8 117.3C205.8 120.669 208.531 123.4 211.9 123.4Z"
        fill="#333333"
      />
      <path
        d="M211.9 148.1C215.269 148.1 218 145.369 218 142C218 138.631 215.269 135.9 211.9 135.9C208.531 135.9 205.8 138.631 205.8 142C205.8 145.369 208.531 148.1 211.9 148.1Z"
        fill="#333333"
      />
      <path
        d="M233.3 36.9C236.669 36.9 239.4 34.1689 239.4 30.8C239.4 27.431 236.669 24.7 233.3 24.7C229.931 24.7 227.2 27.431 227.2 30.8C227.2 34.1689 229.931 36.9 233.3 36.9Z"
        fill="#333333"
      />
      <path
        d="M233.3 61.5999C236.669 61.5999 239.4 58.8688 239.4 55.4999C239.4 52.131 236.669 49.3999 233.3 49.3999C229.931 49.3999 227.2 52.131 227.2 55.4999C227.2 58.8688 229.931 61.5999 233.3 61.5999Z"
        fill="#333333"
      />
      <path
        d="M233.3 86.3001C236.669 86.3001 239.4 83.569 239.4 80.2001C239.4 76.8312 236.669 74.1001 233.3 74.1001C229.931 74.1001 227.2 76.8312 227.2 80.2001C227.2 83.569 229.931 86.3001 233.3 86.3001Z"
        fill="#333333"
      />
      <path
        d="M233.3 111C236.669 111 239.4 108.269 239.4 104.9C239.4 101.531 236.669 98.8 233.3 98.8C229.931 98.8 227.2 101.531 227.2 104.9C227.2 108.269 229.931 111 233.3 111Z"
        fill="#333333"
      />
      <path
        d="M233.3 135.7C236.669 135.7 239.4 132.969 239.4 129.6C239.4 126.231 236.669 123.5 233.3 123.5C229.931 123.5 227.2 126.231 227.2 129.6C227.2 132.969 229.931 135.7 233.3 135.7Z"
        fill="#333333"
      />
      <path
        d="M254.7 49.3001C258.069 49.3001 260.8 46.569 260.8 43.2001C260.8 39.8312 258.069 37.1001 254.7 37.1001C251.331 37.1001 248.6 39.8312 248.6 43.2001C248.6 46.569 251.331 49.3001 254.7 49.3001Z"
        fill="#333333"
      />
      <path
        d="M254.7 74C258.069 74 260.8 71.269 260.8 67.9001C260.8 64.5311 258.069 61.8 254.7 61.8C251.331 61.8 248.6 64.5311 248.6 67.9001C248.6 71.269 251.331 74 254.7 74Z"
        fill="#333333"
      />
      <path
        d="M254.7 98.7C258.069 98.7 260.8 95.9689 260.8 92.6C260.8 89.2311 258.069 86.5 254.7 86.5C251.331 86.5 248.6 89.2311 248.6 92.6C248.6 95.9689 251.331 98.7 254.7 98.7Z"
        fill="#333333"
      />
      <path
        d="M254.7 123.4C258.069 123.4 260.8 120.669 260.8 117.3C260.8 113.931 258.069 111.2 254.7 111.2C251.331 111.2 248.6 113.931 248.6 117.3C248.6 120.669 251.331 123.4 254.7 123.4Z"
        fill="#333333"
      />
      <path
        d="M6.40001 271.4C6.00001 271.8 5.40001 272.1 4.60001 272.1H2.5C1.8 272.1 1.2 271.9 0.699997 271.4C0.299997 271 0 270.4 0 269.6V196C0 195.3 0.199997 194.7 0.699997 194.2C1.1 193.8 1.7 193.5 2.5 193.5H4.60001C5.30001 193.5 5.90001 193.7 6.40001 194.2C6.80001 194.6 7.10001 195.2 7.10001 196V269.6C7.10001 270.3 6.90001 270.9 6.40001 271.4Z"
        fill="#333333"
      />
      <path
        d="M73.2 271.4C72.8 271.8 72.2001 272.1 71.4001 272.1H69.5001C68.8001 272.1 68.2 271.9 67.7 271.4C67.3 271 67.0001 270.4 67.0001 269.6V238.3C67.0001 232.2 65.5 227.5 62.6 224.2C59.6 220.8 55.4001 219.2 49.9001 219.2C44.5001 219.2 40.2001 220.9 37.0001 224.3C33.8001 227.7 32.2 232.4 32.2 238.4V269.7C32.2 270.4 32.0001 271 31.5001 271.5C31.0001 272 30.5 272.2 29.7 272.2H27.8C27.1 272.2 26.5001 272 26.0001 271.5C25.6001 271.1 25.3 270.5 25.3 269.7V216.3C25.3 215.6 25.5001 215 26.0001 214.5C26.4001 214.1 27 213.8 27.8 213.8H29.7C30.4 213.8 31.0001 214 31.5001 214.5C31.9001 214.9 32.2 215.5 32.2 216.3V221.6C34.4 218.7 37.0001 216.5 39.9001 215C42.8001 213.5 46.5 212.8 51.1 212.8C58.4 212.8 64.1001 215.1 68.0001 219.8C72.0001 224.5 73.9001 230.5 73.9001 237.9V269.8C73.8001 270.3 73.6 270.9 73.2 271.4Z"
        fill="#333333"
      />
      <path
        d="M102.9 262.4C104.3 264.5 106.6 265.6 109.8 265.6H115.9C116.6 265.6 117.2 265.8 117.7 266.3C118.1 266.7 118.4 267.3 118.4 268.1V269.7C118.4 270.4 118.2 271 117.7 271.5C117.2 272 116.7 272.2 115.9 272.2H109.3C103.8 272.2 99.8001 270.5 97.5001 267.2C95.2001 263.9 94.0001 259.3 94.0001 253.5V220.4H85.3C84.6 220.4 84.0001 220.2 83.5001 219.7C83.1001 219.3 82.8 218.7 82.8 217.9V216.3C82.8 215.6 83.0001 215 83.5001 214.5C83.9001 214.1 84.5 213.8 85.3 213.8H94.0001V195C94.0001 194.3 94.2 193.7 94.7 193.2C95.2 192.7 95.7001 192.5 96.5001 192.5H98.4001C99.1001 192.5 99.7 192.7 100.2 193.2C100.6 193.6 100.9 194.2 100.9 195V213.8H114.8C115.5 213.8 116.1 214 116.6 214.5C117 214.9 117.3 215.5 117.3 216.3V217.9C117.3 218.6 117.1 219.2 116.6 219.7C116.2 220.1 115.6 220.4 114.8 220.4H100.9V253C100.8 257.1 101.5 260.2 102.9 262.4Z"
        fill="#333333"
      />
      <path
        d="M168.2 220.4C172.6 225.6 174.8 232.6 174.8 241.6V243.3C174.8 244 174.6 244.6 174.1 245.1C173.6 245.5 173 245.8 172.2 245.8H132.3V246.9C132.4 250.4 133.3 253.7 134.7 256.7C136.2 259.7 138.2 262.2 140.8 264C143.4 265.8 146.5 266.7 150 266.7C154.1 266.7 157.5 265.9 160 264.3C162.6 262.7 164.4 261.1 165.6 259.4C166.3 258.5 166.8 257.9 167.1 257.7C167.4 257.5 168.1 257.4 169 257.4H170.8C171.5 257.4 172 257.6 172.5 258C172.9 258.4 173.2 258.9 173.2 259.5C173.2 261.1 172.2 263 170.2 265.2C168.2 267.4 165.5 269.3 162 270.9C158.5 272.5 154.5 273.3 150.1 273.3C143 273.3 137.3 271 132.9 266.3C128.6 261.6 126.1 255.4 125.5 247.5L125.4 243L125.5 238.5C126.1 230.7 128.5 224.5 132.8 219.8C137.1 215.1 142.8 212.7 150 212.7C157.8 212.6 163.8 215.2 168.2 220.4ZM167.9 239.4V239C167.9 233.2 166.3 228.5 163 224.8C159.7 221.1 155.4 219.2 149.9 219.2C144.4 219.2 140.1 221.1 136.9 224.8C133.7 228.5 132.1 233.3 132.1 239V239.4H167.9Z"
        fill="#333333"
      />
      <path
        d="M195 271.4C194.6 271.8 194 272.1 193.2 272.1H191.3C190.6 272.1 190 271.9 189.5 271.4C189.1 271 188.8 270.4 188.8 269.6V194.9C188.8 194.2 189 193.6 189.5 193.1C189.9 192.7 190.5 192.4 191.3 192.4H193.2C193.9 192.4 194.5 192.6 195 193.1C195.5 193.6 195.7 194.1 195.7 194.9V269.6C195.6 270.3 195.4 270.9 195 271.4Z"
        fill="#333333"
      />
      <path
        d="M253 220.4C257.4 225.6 259.6 232.6 259.6 241.6V243.3C259.6 244 259.4 244.6 258.9 245.1C258.4 245.5 257.8 245.8 257 245.8H217.1V246.9C217.2 250.4 218.1 253.7 219.5 256.7C221 259.7 223 262.2 225.6 264C228.2 265.8 231.3 266.7 234.8 266.7C238.9 266.7 242.3 265.9 244.8 264.3C247.4 262.7 249.2 261.1 250.4 259.4C251.1 258.5 251.6 257.9 251.9 257.7C252.2 257.5 252.9 257.4 253.8 257.4H255.6C256.3 257.4 256.8 257.6 257.3 258C257.7 258.4 258 258.9 258 259.5C258 261.1 257 263 255 265.2C253 267.4 250.3 269.3 246.8 270.9C243.3 272.5 239.3 273.3 234.9 273.3C227.8 273.3 222.1 271 217.7 266.3C213.4 261.6 210.9 255.4 210.3 247.5L210.2 243L210.3 238.5C210.9 230.7 213.3 224.5 217.6 219.8C221.9 215.1 227.6 212.7 234.8 212.7C242.6 212.6 248.6 215.2 253 220.4ZM252.7 239.4V239C252.7 233.2 251.1 228.5 247.8 224.8C244.5 221.1 240.2 219.2 234.7 219.2C229.2 219.2 224.9 221.1 221.7 224.8C218.5 228.5 216.9 233.3 216.9 239V239.4H252.7Z"
        fill="#333333"
      />
      <path
        d="M283.6 261.8C286.8 265.1 291.1 266.7 296.4 266.7C300.5 266.7 303.9 265.8 306.6 264.1C309.3 262.3 311.4 259.4 312.9 255.3C313.3 254.4 313.6 253.8 314 253.4C314.3 253.1 314.9 252.9 315.6 252.9H317.2C317.9 252.9 318.5 253.1 319 253.6C319.5 254.1 319.7 254.7 319.7 255.3C319.6 258.2 318.6 261 316.9 263.8C315.2 266.5 312.6 268.8 309.1 270.5C305.6 272.3 301.4 273.1 296.5 273.1C288.7 273.1 282.7 270.8 278.4 266.3C274.1 261.8 271.9 255.4 271.7 247.3L271.6 242.8L271.7 238.3C271.9 230.2 274.2 223.8 278.4 219.3C282.7 214.8 288.7 212.5 296.5 212.5C301.4 212.5 305.6 213.4 309.1 215.1C312.6 216.9 315.2 219.1 316.9 221.8C318.6 224.5 319.6 227.4 319.7 230.3C319.8 231 319.6 231.6 319 232C318.5 232.5 317.9 232.7 317.2 232.7H315.6C314.9 232.7 314.3 232.5 314 232.2C313.7 231.9 313.3 231.2 312.9 230.3C311.4 226.2 309.3 223.3 306.6 221.5C303.9 219.7 300.5 218.9 296.4 218.9C291 218.9 286.7 220.5 283.6 223.8C280.4 227.1 278.7 232 278.5 238.8L278.4 242.7L278.5 246.6C278.7 253.6 280.4 258.5 283.6 261.8Z"
        fill="#333333"
      />
      <path
        d="M333.1 214.4C333.5 214 334.1 213.7 334.9 213.7H336.8C337.5 213.7 338.1 213.9 338.6 214.4C339 214.8 339.3 215.4 339.3 216.2V247.5C339.3 253.6 340.8 258.3 343.7 261.6C346.7 265 350.9 266.6 356.4 266.6C361.8 266.6 366.1 264.9 369.3 261.5C372.5 258.1 374.1 253.4 374.1 247.4V216.1C374.1 215.4 374.3 214.8 374.8 214.3C375.3 213.8 375.8 213.6 376.6 213.6H378.5C379.2 213.6 379.8 213.8 380.3 214.3C380.7 214.7 381 215.3 381 216.1V269.5C381 270.2 380.8 270.8 380.3 271.3C379.9 271.7 379.3 272 378.5 272H376.6C375.9 272 375.3 271.8 374.8 271.3C374.4 270.9 374.1 270.3 374.1 269.5V264.2C371.9 267.1 369.3 269.3 366.4 270.8C363.5 272.3 359.8 273 355.2 273C347.9 273 342.2 270.7 338.3 266C334.3 261.3 332.4 255.3 332.4 247.9V216C332.4 215.5 332.7 214.9 333.1 214.4Z"
        fill="#333333"
      />
      <path
        d="M380.3 214.5C379.8 214 379.2 213.8 378.4 213.8H376.6C375.9 213.8 375.3 214 374.8 214.5C374.4 215 374.1 215.6 374.1 216.4V221.6V239C374.2 239.7 374.2 241.1 374.2 243C374.2 244.9 374.2 246.3 374.1 247V264.4V268.9V272.1C374.1 278.9 372.6 283.6 369.5 286.3C366.4 289 361.7 290.3 355.3 290.3C346.9 290.3 341.5 286.9 339.2 280.1C338.9 279.2 338.5 278.6 338.1 278.2C337.7 277.9 337.1 277.7 336.5 277.7H334.9C334.2 277.7 333.6 277.9 333.2 278.4C332.7 278.8 332.5 279.4 332.5 280.1C332.5 282 333.2 284.3 334.6 286.9C336 289.5 338.4 291.8 341.9 293.8C345.4 295.8 350.1 296.8 355.9 296.8C365.2 296.8 371.7 294.5 375.4 289.9C379.1 285.3 381 279 381 271V216.4C381 215.6 380.8 215 380.3 214.5Z"
        fill="#333333"
      />
    </svg>
  )
}
