import { differenceInSeconds, formatISO } from 'date-fns'
import { TimeRange } from 'src/types'
import { graphqlApi } from '../graphQL'
import {
  GqlErrorScoreKind,
  GqlTransform,
  GqlTsDataFragment,
} from '../graphqlTypes'

export interface FetchModelAnomalyScoreRequest {
  id: string
  timeRange: TimeRange
  errorScoreKind: GqlErrorScoreKind
  transform: GqlTransform
}

export async function fetchModelAnomalyScore({
  id,
  timeRange: { from, to },
  errorScoreKind,
  transform,
}: FetchModelAnomalyScoreRequest): Promise<GqlTsDataFragment[]> {
  const seconds = differenceInSeconds(to, from)
  const minNumberOfDataPoints = 400
  const interval = Math.floor(seconds / minNumberOfDataPoints)

  const api = await graphqlApi()
  const data = await api.FetchModelAnomalyScore({
    id,
    from: formatISO(from),
    to: formatISO(to),
    interval,
    errorScoreKind,
    transform,
  })

  if (data.node?.__typename === 'AnomalyModel') {
    return data.node.errorScore2.data
  }

  // FIXME this is probably an error case
  return []
}
