import { durationSeconds, Node } from './ast'
import { Lexer } from './lexer'
import { Parser } from './parser'

// returns the max duration in seconds
export function maxDuration(node: Node): number {
  switch (node.kind) {
    case 'or':
      return Math.max(...node.children.map(maxDuration))
    case 'and':
      return Math.max(...node.children.map(maxDuration))
    case 'not':
      return maxDuration(node.child)
    case 'duration':
      return durationSeconds(node)
    case 'comparison':
      return 0
    case 'inCheck':
      return 0
    case 'error':
      return 0
    default: {
      const exhaustiveCheck: never = node
      return exhaustiveCheck
    }
  }
}

export function stringify(n: Node): string {
  switch (n.kind) {
    case 'or':
      return `(${n.children.map(stringify).join(' or ')})`
    case 'and':
      return `(${n.children.map(stringify).join(' and ')})`
    case 'not':
      return `not ${stringify(n.child)}`
    case 'duration':
      return `for(${n.duration}${n.unit}, ${stringify(n.child)})`
    case 'comparison':
      return `'${n.id}' ${n.comparator} ${n.value}`
    case 'inCheck':
      return `'${n.id}'${n.not ? ' NOT ' : ' '}IN (${n.values.join(',')})`
    case 'error':
      throw new Error(n.msg)
    default: {
      const exhaustiveCheck: never = n
      return exhaustiveCheck
    }
  }
}

export function parse(rule: string): Node {
  const lexer = new Lexer(rule)
  const parser = new Parser(lexer)
  return parser.parse()
}
