import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import { Icon, Text } from 'src/components/ui'
import { IconSize } from '../../icons/Icon'

type LabelVariant = 'small' | 'description' | 'content' | 'title'

const iconSize: Record<LabelVariant, IconSize> = {
  small: 'xsmall',
  description: 'small',
  content: 'regular',
  title: 'regular',
}

type BaseProps = {
  label: string
  labelVariant?: LabelVariant
  children: React.ReactNode
  disabled?: boolean
  className?: string
  contentClassName?: string
}

type ExternalStateProps = BaseProps & {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

type InternalStateProps = BaseProps & {
  initialIsOpen?: boolean
}

export function ExternalStateAccordion({
  label,
  labelVariant = 'content',
  isOpen,
  setIsOpen,
  disabled,
  className,
  contentClassName,
  children,
}: ExternalStateProps): JSX.Element {
  return (
    <div
      data-testid="accordion"
      className={classNames(
        'w-full p-xs rounded-2xs border border-solid border-border',
        disabled && 'opacity-50',
        className,
      )}
    >
      <div
        className={classNames(
          'flex justify-between items-center select-none',
          isOpen && 'pb-xs border-0 border-b border-solid border-border',
          !disabled && 'cursor-pointer',
        )}
        onClick={() => {
          if (disabled) return
          setIsOpen(!isOpen)
        }}
      >
        <Text variant={labelVariant} bold>
          {label}
        </Text>
        <Icon
          size={iconSize[labelVariant]}
          icon={light('chevron-down')}
          className={classNames('transition', isOpen && 'rotate-180')}
        />
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className={classNames('pt-xs', contentClassName)}
            data-testid="accordion-content"
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export function Accordion({
  initialIsOpen = false,
  ...props
}: InternalStateProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(initialIsOpen)

  return (
    <ExternalStateAccordion {...props} isOpen={isOpen} setIsOpen={setIsOpen} />
  )
}
