import { useNavigate, useParams } from 'react-router-dom'
import { useGateway } from 'gateway/api'
import { Spinner } from 'src/components/ui'
import { ErrorDisplay } from 'pages/app'
import { OpcUaConnectionOverview } from './opc-ua-details/OpcUaConnectionOverview'
import { formatLabel } from './opc-ua-connection.utils'
import { useOpcConnections } from './opc-ua-connection.api'

export function OpcUaDetailsTab(): JSX.Element {
  const { orgId, siteId, gatewayId, connectionId } = useParams()
  if (!orgId || !siteId || !gatewayId || !connectionId)
    throw new Error('Missing orgId, siteId, gatewayId or connectionId')

  const connectionsQuery = useOpcConnections(siteId, gatewayId)
  const gatewayQuery = useGateway(siteId, gatewayId)
  const navigate = useNavigate()

  if (connectionsQuery.isLoading || gatewayQuery.isLoading) return <Spinner />

  if (connectionsQuery.isError || gatewayQuery.isError) {
    const errorQuery = connectionsQuery.isError
      ? connectionsQuery
      : gatewayQuery
    return (
      <ErrorDisplay
        error={errorQuery.error}
        message="Something went wrong"
        action={errorQuery.refetch}
      />
    )
  }

  const currentOpcConnection = connectionsQuery.data.find(
    el => el.id === connectionId,
  )

  // If we came to here and connection is undefined,
  // it means that the connection was not found and
  // we should display an error page
  if (!currentOpcConnection) {
    return (
      <ErrorDisplay
        error={new Error('Connection not found')}
        message="Connection not found"
        actionTitle="Back to gateway details"
        action={() =>
          navigate(
            `/settings/orgs/${orgId}/sites/${siteId}/gateways/${gatewayId}`,
          )
        }
      />
    )
  }

  // If connection is not completed yet redirect to config page
  if (
    currentOpcConnection.configurationState !== 'COMPLETE' &&
    !connectionsQuery.isRefetching
  ) {
    navigate('configuration')
  }

  return (
    <OpcUaConnectionOverview
      name={currentOpcConnection.name}
      serverEndpoint={currentOpcConnection.endpoint}
      anonymous={!currentOpcConnection.username}
      securityType={formatLabel(currentOpcConnection.securityMode ?? '')}
      securityPolicy={formatLabel(currentOpcConnection.securityPolicy ?? '')}
      gateway={gatewayQuery.data}
    />
  )
}
