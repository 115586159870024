import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { DynamicTable, Text, Spinner } from 'src/components/ui'
import { useSite } from 'src/contexts/site'
import { useModels } from 'models/api'
import { TableModel } from 'models/model.types'
import { getModelTableConfig } from 'models/models'

type Props = {
  tagName: string
}

export function ConnectedModels({ tagName }: Props): JSX.Element {
  const modelsQuery = useModels()
  const { rootLink, id: factory } = useSite()
  const navigate = useNavigate()

  useEffect(() => {
    if (modelsQuery.isError) {
      toast.error('Failed to load connected models', {
        position: 'top-right',
      })
    }
  }, [modelsQuery.isError])

  const includedInModels: TableModel[] = (modelsQuery.data ?? [])
    ?.filter(
      model =>
        model.inputTags.map(t => t.tagName).includes(tagName) ||
        model.tag.tagName === tagName,
    )
    .map(m => {
      const {
        tag: { tagName, displayName },
      } = m
      return {
        ...m,
        tagDisplayName: displayName || tagName,
        link: `${rootLink}/models/${m.id}`,
      }
    })

  const actions = {
    selectRow: ({ id }: any) => {
      navigate(`${rootLink}/models/${id}`)
    },
  }

  return (
    <div className="flex flex-col gap-xs rounded-2xs border border-solid border-border bg-background p-[1em] transition-all hover:border-border">
      <Text variant="title" bold>
        Connected Models
      </Text>
      {modelsQuery.isLoading ? (
        <Spinner />
      ) : (
        <DynamicTable
          id={`${factory}-tagConnectedModels`}
          data={includedInModels}
          config={getModelTableConfig()}
          actions={actions}
          className="!h-[600px]"
        />
      )}
    </div>
  )
}
