import { SelectInput } from 'src/components/ui'

type Option = {
  value: string
  label: string
}

interface TagInputProps {
  value?: string
  onChange: (value: string | undefined) => void
  tags: Record<string, string>
}

export function TagInput({
  value,
  onChange,
  tags,
}: TagInputProps): JSX.Element {
  const options: Option[] = Object.entries(tags).map(
    ([tagName, displayName]) => ({
      value: tagName,
      label: displayName || tagName,
    }),
  )

  const getOption = (value?: string): Option | null => {
    return options.find(o => o.value === value) || null
  }

  return (
    <div className="w-[380px]">
      <SelectInput
        options={options}
        onChange={value => onChange(value)}
        value={getOption(value) || undefined}
      />
    </div>
  )
}
