import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { minutesToMilliseconds } from 'date-fns'
import { useSite } from 'src/contexts/site'
import * as api from 'src/services'

export const FETCH_TAGS_QUERY = 'fetchTags'
export function useTags<T = api.TagDto[]>(
  select?: (data: api.TagDto[]) => T,
): UseQueryResult<T, Error> {
  const { id: currentFactory } = useSite()
  return useQuery({
    queryKey: [FETCH_TAGS_QUERY, currentFactory],
    queryFn: () => api.getTags(currentFactory),
    // Stale time for 10 minutes
    staleTime: minutesToMilliseconds(10),
    select,
  })
}

export function useTagsBySiteId<T = api.TagDto[]>(
  siteId: string,
  select?: (data: api.TagDto[]) => T,
): UseQueryResult<T, Error> {
  return useQuery({
    queryKey: [FETCH_TAGS_QUERY, siteId],
    queryFn: () => api.getTags(siteId),
    staleTime: minutesToMilliseconds(10),
    select,
  })
}

export function useTag(
  tagName: string,
): UseQueryResult<api.TagDto | undefined, Error> {
  return useTags(data => data.find(t => t.tagName === tagName))
}

export const FETCH_TAG_QUERY = 'fetchTag'
export function useTagGql(
  tagName: string,
  shouldLoad = true,
): UseQueryResult<api.GqlTagDetailsFragment, Error> {
  const { id: currentFactory } = useSite()
  return useQuery({
    queryKey: [FETCH_TAG_QUERY, currentFactory, tagName],
    queryFn: () => api.getTag(currentFactory, tagName),
    enabled: shouldLoad,
  })
}
