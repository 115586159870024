import classNames from 'classnames'
import { ForecastTimeRangeProvider } from 'src/contexts/timeRange'
import { CursorProvider } from 'src/contexts/cursor'
import { ForecastChart } from '../Chart'

function getGridColumnStyles(activeModels: string[]): string {
  if (activeModels.length < 5) {
    return 'grid-cols-1'
  } else if (activeModels.length >= 5 && activeModels.length < 9) {
    return 'grid-cols-2'
  } else {
    return 'grid-cols-3'
  }
}
interface ChartListProps {
  activeModels: string[]
  removeModel: (modelId: string) => void
  className?: string
}

export function ChartList({
  activeModels,
  removeModel,
  className,
}: ChartListProps): JSX.Element {
  return (
    <ForecastTimeRangeProvider activeModels={activeModels}>
      <CursorProvider>
        <div
          className={classNames(
            'overflow-y-auto grid gap-2xs auto-rows-fr',
            getGridColumnStyles(activeModels),
            className,
          )}
        >
          {activeModels.map(modelId => (
            <ForecastChart
              key={modelId}
              modelId={modelId}
              removeChart={() => removeModel(modelId)}
            />
          ))}
        </div>
      </CursorProvider>
    </ForecastTimeRangeProvider>
  )
}
