import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useParams, Outlet } from 'react-router-dom'
import { useOrganization } from 'orgs-sites/org/api'
import { ErrorDisplay } from 'pages/app'
import { Icon, Text, RoutingTabs, Spinner } from 'src/components/ui'

export function OrgDetailsPage(): JSX.Element {
  const { orgId } = useParams()
  if (!orgId) throw new Error('`orgId` route param missing')
  const orgQuery = useOrganization(orgId)

  if (orgQuery.isLoading) {
    return <Spinner />
  }

  if (orgQuery.isError) {
    return (
      <ErrorDisplay
        error={orgQuery.error}
        message="Failed to load organization"
        action={orgQuery.refetch}
      />
    )
  }

  if (!orgQuery.data) {
    return (
      <ErrorDisplay
        message="Organization not found"
        error={new Error('Organization not found')}
      />
    )
  }

  const tabs = [
    {
      title: 'Organization Users',
      path: ``,
    },
    {
      title: 'Sites',
      path: `sites`,
    },
  ]

  return (
    <div className="m-s mt-0 flex h-[calc(100%-1em)] flex-col rounded-2xs bg-background p-m">
      <div className="mb-l flex w-full items-start justify-start gap-l">
        <Icon
          icon={light('house-building')}
          className="!h-[30px] !w-[36px] text-icon-secondary"
        />
        <div>
          <Text variant="description" className="mb-2xs">
            {orgQuery.data.isHome ? 'Your home organization' : 'Organization'}
          </Text>
          <Text variant="content" bold className="mb-xs">
            {orgQuery.data.name}
          </Text>
          <Text variant="small" className="mb-xs mt-s">
            Users in organization
          </Text>
          <Text variant="description" bold>
            {orgQuery.data.userCount || 0}
          </Text>
        </div>
        <div>
          <Text variant="description" className="mb-2xs">
            Your role
          </Text>
          <Text variant="content" bold className="mb-xs">
            {orgQuery.data.viewerRole}
          </Text>
          <Text variant="small" className="mb-xs mt-s">
            Sites in organization
          </Text>
          <Text variant="description" bold>
            {orgQuery.data.numOfSites || 'No sites'}
          </Text>
        </div>
      </div>
      <RoutingTabs tabs={tabs} />
      <Outlet />
    </div>
  )
}
