import React from 'react'
import { Node } from 'src/conditions/ast'

export type FilterPeriod = {
  from: number
  // to may be undefined if it is the last period
  to?: number
}

export type FilterValue = {
  // undefined means there is no filter to apply
  filter?: FilterPeriod[]
  condition?: Node
  setCondition: React.Dispatch<React.SetStateAction<Node | undefined>>
  ids: string[]
}

export const FilterContext = React.createContext<FilterValue | undefined>(
  undefined,
)
