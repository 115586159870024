import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { memo, useEffect } from 'react'
import toast from 'react-hot-toast'
import ReactMarkdown from 'react-markdown'
import { useParams } from 'react-router-dom'
import { ErrorDisplay } from 'pages/app/components'
import { useGatewayReleaseBySemver } from 'orgs-sites/site'
import {
  Banner,
  BreadcrumbNavigation,
  Button,
  Spinner,
  Text,
} from 'src/components/ui'
import { useNavigationContext } from 'src/contexts/navigation'
import { GatewayRelease } from 'src/services'
import { GatewayInstallGuideLink } from './components'

export const GatewayReleaseDetailsPage = memo(
  function GatewayReleaseDetailsPage(): JSX.Element {
    const { semver } = useParams()
    if (!semver) throw new Error('semver is required')
    const releaseQuery = useGatewayReleaseBySemver(semver)

    const { setTitleComponent } = useNavigationContext()
    useEffect(() => {
      if (releaseQuery.data) {
        setTitleComponent(
          <BreadcrumbNavigation
            navigationPath={{
              label: 'Downloads',
              url: '/downloads',
              child: {
                label: 'Gateways',
                url: '',
                child: {
                  label: releaseQuery.data.semver,
                  url: `/downloads/gateway-release/${releaseQuery.data.semver}`,
                },
              },
            }}
          />,
        )
      }
      return () => setTitleComponent(null)
    }, [releaseQuery.data, setTitleComponent])

    if (releaseQuery.isLoading) return <Spinner />
    if (releaseQuery.isError) {
      return (
        <ErrorDisplay
          error={releaseQuery.error}
          message="Failed to fetch data"
        />
      )
    }
    if (!releaseQuery.data)
      return (
        <ErrorDisplay
          error={new Error('Not found')}
          message="Release not found"
          action={releaseQuery.refetch}
        />
      )

    function handleCopy(release?: GatewayRelease): void {
      if (!release) return
      navigator.clipboard.writeText(release.url).then(() => {
        setTimeout(
          () =>
            toast.success(
              <div className="flex flex-col gap-3xs">
                <Text bold>URL was successfully copied!</Text>
                <Text>This URL is only valid for 24 hours.</Text>
              </div>,
            ),
          300,
        )
      })
    }

    function handleDownloadClick(release?: GatewayRelease): void {
      if (!release?.url) return
      const aTag = document.createElement('a')
      aTag.href = release.url
      aTag.download = release.summary
      aTag.click()
      setTimeout(() => toast.success('Download started'), 300)
    }

    return (
      <div className="m-s mt-0 flex min-w-[450px] flex-col gap-s rounded-xs bg-background px-m py-s">
        <div className="flex max-w-[1000px] flex-col gap-3xs">
          <Text variant="title" bold>
            Gateway Release {releaseQuery.data.semver}
          </Text>
          <Text variant="description">
            For detailed instructions use <GatewayInstallGuideLink />
          </Text>
          <div className="mt-xs flex items-center gap-xs">
            {releaseQuery.data.tags.map((tag: string) => (
              <div
                key={tag}
                className="rounded-full border border-solid border-border px-xs py-2xs"
              >
                <Text variant="description" bold>
                  {tag}
                </Text>
              </div>
            ))}
          </div>
          {releaseQuery.data.isBeta && (
            <Banner variant="warning" className="my-s">
              <div className="flex flex-col">
                <Text bold>BETA Version</Text>
                <Text variant="description">
                  Do not install unless directed by Intelecy.
                </Text>
              </div>
            </Banner>
          )}
          <div className="flex flex-col gap-m medium:flex-row">
            <div className="gatewayRelease w-full min-w-[380px] max-w-[500px]">
              <ReactMarkdown>
                {releaseQuery.data.fullDescription ?? ''}
              </ReactMarkdown>
            </div>
            <div className="mt-s flex w-full min-w-[460px] max-w-[580px] flex-col gap-xs self-start rounded-2xs border border-solid border-border p-s">
              <div className="flex flex-col gap-3xs">
                <Text variant="description" bold>
                  SHA256
                </Text>
                <Text
                  variant="code"
                  className="!text-description tracking-tighter"
                >
                  {releaseQuery.data.sha256Hash}
                </Text>
              </div>
              <div className="flex flex-col gap-3xs">
                <Text variant="description" bold>
                  File Name
                </Text>
                <Text
                  variant="code"
                  className="!text-description tracking-tighter"
                >
                  {releaseQuery.data.fileName}
                </Text>
              </div>
            </div>
          </div>
        </div>
        <div className="h-px w-full bg-background-disabled" />
        <div className="flex max-w-[1000px] flex-col gap-3xs">
          <div className="flex flex-col gap-xs">
            <Text variant="small" className="max-w-[340px]">
              Generate a download URL that can be used to download the installer
              on a different host or download directly on current host.
            </Text>
            <div className="flex items-center justify-start gap-xs">
              <Button
                variant="primary"
                className="!py-[7px]"
                title="Copy Download Link"
                icon={light('link')}
                onClick={() => handleCopy(releaseQuery.data)}
              />
              <Text variant="small">Or</Text>
              <Button
                onClick={() => handleDownloadClick(releaseQuery.data)}
                variant="primary"
                className="!py-[7px]"
                title="Download Installer"
                icon={light('download')}
              />
            </div>
          </div>
        </div>
      </div>
    )
  },
)
