interface SpinnerProps {
  inline?: boolean
  small?: boolean
  color?: string
}

export function Spinner({ inline, small, color }: SpinnerProps): JSX.Element {
  const spinnerColor = color || 'rgba(0, 0, 0, 0.12)'

  return (
    <div
      className={`animate-spin rounded-full border-solid ${
        inline ? 'inline-block' : 'mx-auto my-4'
      } ${
        small
          ? 'h-[1em] w-[1em] border-[0.125em]'
          : 'h-[4em] w-[4em] border-[0.5em]'
      }`}
      style={{
        animationDuration: '1.5s',
        borderColor: spinnerColor,
        borderLeftColor: 'transparent',
      }}
    />
  )
}
