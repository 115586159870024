import { Node } from 'src/conditions/ast'
import {
  comparatorString,
  FormCondition,
  parseCondition,
} from '../ConditionForm'

interface DisplayConditionProps {
  condition: Node | undefined
  // a map of tagName to displayName
  tags: Record<string, string>
  className?: string
  containerClassName?: string
}

export function DisplayCondition({
  condition,
  tags,
  className,
  containerClassName,
}: DisplayConditionProps): JSX.Element {
  if (!condition) {
    return <span>No conditions defined</span>
  }

  let formConditions: FormCondition[]
  try {
    formConditions = parseCondition(condition)
  } catch (error) {
    console.error(error)
    return <span>Unable to parse existing filter</span>
  }

  // return the display name if available
  const tagName = (name: string | undefined): string | undefined => {
    if (!name) return undefined
    return tags[name] || name
  }

  const lines = formConditions.map(cond => {
    const timeCond =
      cond.duration > 0 ? (
        <>
          {' '}
          for at least <b>{cond.duration}</b>{' '}
          {cond.duration > 1 ? 'minutes' : 'minute'}
        </>
      ) : (
        ''
      )
    if (cond.value2) {
      return (
        <div className={className} key={cond.id}>
          <b>{tagName(cond.tag)}</b> <b>{comparatorString(cond.comparator)}</b>{' '}
          <b>{cond.value1}</b> and <b>{cond.value2}</b>
          {timeCond}
        </div>
      )
    }
    return (
      <div className={className} key={cond.id}>
        <b>{tagName(cond.tag)}</b> <b>{comparatorString(cond.comparator)}</b>{' '}
        <b>{cond.value1}</b>
        {timeCond}
      </div>
    )
  })

  return <div className={containerClassName}>{lines}</div>
}
