import {
  createAuth0Client,
  Auth0Client,
  RedirectLoginResult,
} from '@auth0/auth0-spa-js'
import { AUTH_CLIENT_ID } from '../config'

let client: Auth0Client

async function auth0Client(): Promise<Auth0Client> {
  if (!client) {
    // this just creates a client and calls `checkSession`
    client = await createAuth0Client({
      domain: 'intelecy.eu.auth0.com',
      clientId: AUTH_CLIENT_ID,
      authorizationParams: {
        redirect_uri: `${window.location.origin}/callback`,
      },
    })
  }
  return client
}

export async function getToken(): Promise<any> {
  const auth0 = await auth0Client()
  return auth0.getTokenSilently()
}

export async function isAuthenticated(): Promise<boolean> {
  const auth0 = await auth0Client()
  return auth0.isAuthenticated()
}

export async function loginWithRedirect(): Promise<void> {
  const auth0 = await auth0Client()
  return auth0.loginWithRedirect({
    appState: {
      url: `${window.location.pathname}${window.location.search}`,
    },
  })
}

export async function handleRedirectCallback(): Promise<RedirectLoginResult> {
  const auth0 = await auth0Client()
  return auth0.handleRedirectCallback()
}

export async function logout(): Promise<void> {
  const auth0 = await auth0Client()
  return auth0.logout({ logoutParams: { returnTo: window.location.origin } })
}
