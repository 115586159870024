import moment from 'moment'

export const isWorkshopFactory = (factory_id: string): boolean => {
  return factory_id === 'F4M4S5R3ZFGT' || factory_id === 'F23VZKEVVXXY'
}

export const disableForWorkshop = (
  title: string,
  factory_id: string,
): boolean => {
  if (isWorkshopFactory(factory_id)) {
    if (
      title === 'Assets' ||
      title === 'Alarms' ||
      title === 'Labs' ||
      title === 'Forecast'
    ) {
      return true
    }
  }
  return false
}

export const metropolisDataRange = {
  from: moment('2021-11-01 00:00:00').valueOf(),
  to: moment('2021-11-07 23:59:00').valueOf(),
}
