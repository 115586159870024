import { useAuth } from 'src/contexts/auth'

type Props = {
  children: JSX.Element
}

export function IfLabsAccess({ children }: Props): JSX.Element | null {
  const {
    viewer: { hasAppAccess, email },
  } = useAuth()
  if (!(hasAppAccess && email && email.endsWith('@intelecy.com'))) return null

  return children
}
