import { legacyTheme } from './legacy'
import { lightTheme } from './light'

export enum TailwindTheme {
  Light = 'light',
  Legacy = 'legacy',
}

export const tailwindThemeOptions = [
  { label: 'Legacy', value: TailwindTheme.Legacy },
  { label: 'Light', value: TailwindTheme.Light },
]
const tailwindThemes = {
  [TailwindTheme.Legacy]: legacyTheme,
  [TailwindTheme.Light]: lightTheme,
}

export function getThemeObject(theme: TailwindTheme): Theme {
  return tailwindThemes[theme]
}

type BackgroundTypeColors = {
  DEFAULT: string
  hover: string
  active: string
  secondary: {
    DEFAULT: string
    hover: string
    active: string
  }
  tertiary: {
    DEFAULT: string
    hover: string
    active: string
  }
}

type BackgroundDisabledColors = {
  DEFAULT: string
  light: string
}

type BackgroundTypes = BackgroundTypeColors & {
  brand: BackgroundTypeColors
  success: BackgroundTypeColors
  warning: BackgroundTypeColors
  danger: BackgroundTypeColors
  disabled: BackgroundDisabledColors
}

type TextTypeColors = {
  DEFAULT: string
  secondary: string
  tertiary: string
}

type TextTypeColorsExtended = TextTypeColors & {
  onbrand: TextTypeColors
}

type TextDisabledColors = {
  DEFAULT: string
  ondisabled: string
}

type TextTypes = TextTypeColors & {
  brand: TextTypeColorsExtended
  success: TextTypeColorsExtended
  warning: TextTypeColorsExtended
  danger: TextTypeColorsExtended
  disabled: TextDisabledColors
}

type BorderTypeColors = {
  DEFAULT: string
  secondary: string
  tertiary: string
}

type BorderTypes = BorderTypeColors & {
  brand: BorderTypeColors
  success: BorderTypeColors
  warning: BorderTypeColors
  danger: BorderTypeColors
  disabled: string
}

type IconTypeColors = {
  DEFAULT: string
  secondary: string
  tertiary: string
}

type IconTypeColrosExtended = IconTypeColors & {
  onbrand: IconTypeColors
}

type IconDisabledColors = {
  DEFAULT: string
  ondisabled: string
}

type IconTypes = IconTypeColors & {
  brand: IconTypeColrosExtended
  success: IconTypeColrosExtended
  warning: IconTypeColrosExtended
  danger: IconTypeColrosExtended
  disabled: IconDisabledColors
}

export type Theme = {
  background: BackgroundTypes
  text: TextTypes
  border: BorderTypes
  icon: IconTypes
}
