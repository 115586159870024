import classNames from 'classnames'
import { Text } from 'src/components/ui'

interface LegendProps {
  data: {
    [name: string]: string
  }
}

export function Legend({ data }: LegendProps): JSX.Element {
  return (
    <ul className="m-0 flex list-none justify-end gap-[1em] p-0">
      {Object.entries(data).map(([name, color]) => (
        <li
          className={classNames(
            'flex items-center',
            'before:block before:content-[""] before:h-[1em] before:w-[1em] before:mr-[0.2em] before:bg-[var(--bg-color)]',
          )}
          style={{ '--bg-color': color } as any}
          key={name}
        >
          <Text>{name}</Text>
        </li>
      ))}
    </ul>
  )
}
