import { Button } from 'src/components/ui'
import { GqlGatewayFragment } from 'src/services'
import { OpcUaConnectionConfiguration } from '../opc-ua-connection.types'
import { ConnectionVerifier } from './opc-ua-connection-details-step'
import { ConnectionDetails } from './opc-ua-connection-details-step/ConnectionDetails'

type Props = {
  disabled?: boolean
  gateway: GqlGatewayFragment
  config: OpcUaConnectionConfiguration
  setConfig: (config: OpcUaConnectionConfiguration) => void
  verifyConnection: () => void
  handleSave: () => void
}

export function OpcUaConnectionDetailsStep({
  disabled,
  gateway,
  config,
  setConfig,
  verifyConnection,
  handleSave,
}: Props): JSX.Element {
  return (
    <div>
      <div className="flex justify-between gap-s">
        <ConnectionDetails
          disabled={disabled}
          selectedGateway={gateway}
          serverEndpoint={config.serverEndpoint}
          connectionName={config.connectionName}
          handleEndpointChange={e =>
            setConfig({ ...config, serverEndpoint: e.target.value })
          }
          handleNameChange={e =>
            setConfig({ ...config, connectionName: e.target.value })
          }
        />
        <ConnectionVerifier
          disabled={disabled}
          config={config}
          setConfig={setConfig}
          verifyConnection={verifyConnection}
        />
      </div>
      <div className="mt-xs flex justify-end">
        {!disabled && (
          <Button
            variant="primary"
            title="Save"
            onClick={handleSave}
            disabled={
              !config.connectionName || config.connectionState !== 'VERIFIED'
            }
          />
        )}
      </div>
    </div>
  )
}
