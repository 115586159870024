import { Scatter } from 'src/dex/analysis'
import { Aggregation, IntrinsicProperty } from 'src/dex/segment_series'

export type ScatterAxes = Scatter['axes']

export const IntrinsicMap: Record<IntrinsicProperty, string> = {
  [IntrinsicProperty.INTRINSIC_PROPERTY_UNSPECIFIED]: 'Unspecified',
  [IntrinsicProperty.INTRINSIC_PROPERTY_START]: 'Start',
  [IntrinsicProperty.INTRINSIC_PROPERTY_END]: 'End',
  [IntrinsicProperty.INTRINSIC_PROPERTY_DURATION_SECONDS]: 'Duration Seconds',
  [IntrinsicProperty.INTRINSIC_PROPERTY_DURATION_MINUTES]: 'Duration Minutes',
  [IntrinsicProperty.INTRINSIC_PROPERTY_DURATION_HOURS]: 'Duration Hours',
  [IntrinsicProperty.INTRINSIC_PROPERTY_DURATION_DAYS]: 'Duration Days',
  [IntrinsicProperty.INTRINSIC_PROPERTY_ORDINAL_NUMBER]: 'Ordinal Number',
  [IntrinsicProperty.UNRECOGNIZED]: 'Unrecognized',
}

export const AggregationMap: Record<Aggregation, string> = {
  [Aggregation.AGGREGATION_UNSPECIFIED]: 'Unspecified',
  [Aggregation.AGGREGATION_FIRST]: 'First',
  [Aggregation.AGGREGATION_LAST]: 'Last',
  [Aggregation.AGGREGATION_MIN]: 'Min',
  [Aggregation.AGGREGATION_MAX]: 'Max',
  [Aggregation.AGGREGATION_MEAN]: 'Mean',
  [Aggregation.AGGREGATION_STD]: 'Std',
  [Aggregation.AGGREGATION_MAD]: 'Mad',
  [Aggregation.AGGREGATION_SKEW]: 'Skew',
  [Aggregation.AGGREGATION_CHANGE]: 'Change',
  [Aggregation.AGGREGATION_SPAN]: 'Span',
  [Aggregation.AGGREGATION_MEDIAN]: 'Median',
  [Aggregation.AGGREGATION_AUC_SECONDS]: 'Auc Seconds',
  [Aggregation.AGGREGATION_AUC_HOURS]: 'Auc Hours',
  [Aggregation.UNRECOGNIZED]: 'Unrecognized',
}
