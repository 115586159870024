import { Text } from 'src/components/ui'
import { GqlGatewayFragment } from 'src/services'
import { GatewayIcon } from './GatewayIcon'

type Props = {
  gateway: GqlGatewayFragment
}
export function GatewayOverview({ gateway }: Props): JSX.Element {
  return (
    <div className="flex w-full justify-between">
      <div className="flex gap-s px-s">
        <GatewayIcon />
        <div className="flex gap-3xl">
          <div className="flex flex-col gap-s">
            <div className="flex flex-col gap-2xs">
              <Text variant="description">Gateway</Text>
              <Text bold>{gateway.hostname}</Text>
            </div>
            <div className="flex flex-col gap-2xs">
              <Text variant="description">Hostname</Text>
              <Text bold>{gateway.hostname} </Text>
            </div>
          </div>
          <div className="flex flex-col gap-s">
            <div className="flex flex-col gap-2xs">
              <Text variant="description">Gateway ID</Text>
              <Text bold>{gateway.id}</Text>
            </div>
            <div className="flex flex-col gap-2xs">
              <Text variant="description">Version</Text>
              <Text bold>{gateway.version} </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
