import { RefObject, useEffect, useState } from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'

export function useChartHeight(
  ref: RefObject<HighchartsReact.RefObject>,
): number | undefined {
  const [height, setHeight] = useState<number>()

  useEffect(() => {
    const handleRender: Highcharts.EventCallbackFunction<
      Highcharts.Chart
    > = e => setHeight(e?.target.plotHeight)
    const chart = ref.current?.chart

    if (chart) {
      setHeight(chart.plotHeight)
      Highcharts.addEvent(chart, 'render', handleRender)
    }

    return () => {
      if (chart) {
        Highcharts.removeEvent(chart, 'render', handleRender)
      }
    }
  }, [ref])

  return height
}
