import { TimeSeriesData } from 'src/types'
import { Comparison, durationSeconds, InCheck, Node } from './ast'
import { parse } from '.'

// [timestamp (epoch seconds), value]
export type DataPoint<T = number> = [number, T]

type CompareFunction<T> = (node: Comparison) => (x: T) => boolean
type InCheckFunction<T> = (node: InCheck) => (x: T) => boolean

// Functions for individual values

const compareFunction = (node: Comparison): ((x: number) => boolean) => {
  switch (node.comparator) {
    case '=':
      return x => x === node.value
    case '!=':
      return x => x !== node.value
    case '>':
      return x => x > node.value
    case '>=':
      return x => x >= node.value
    case '<':
      return x => x < node.value
    case '<=':
      return x => x <= node.value
    default: {
      const exhaustiveCheck: never = node.comparator
      return exhaustiveCheck
    }
  }
}

const inCheckFunction = (node: InCheck): ((x: number) => boolean) => {
  if (node.not) {
    return x => !node.values.includes(x)
  }
  return x => node.values.includes(x)
}

// Function for aggregated data

type Agg = {
  avg: number
  min: number
  max: number
}

const aggCompareFunction = (node: Comparison): ((agg: Agg) => boolean) => {
  switch (node.comparator) {
    case '=':
      // this is not ideal
      return ({ avg }) => avg === node.value
    case '!=':
      // this is not ideal
      return ({ avg }) => avg !== node.value
    case '>':
      return ({ min }) => min > node.value
    case '>=':
      return ({ min }) => min >= node.value
    case '<':
      return ({ max }) => max < node.value
    case '<=':
      return ({ max }) => max <= node.value
    default: {
      const exhaustiveCheck: never = node.comparator
      return exhaustiveCheck
    }
  }
}

// again, not ideal. We really want to check individual values
const aggInCheckFunction = (node: InCheck): ((agg: Agg) => boolean) => {
  if (node.not) {
    return ({ avg }) => !node.values.includes(avg)
  }
  return ({ avg }) => node.values.includes(avg)
}

// Datapoint functions

// Applies a function to each datapoint value and emits the resulting
// time series. Consecutive points with the same value are dropped
export const applyFunction = <T>(
  fn: (x: T) => boolean,
  data: DataPoint<T>[],
): DataPoint<boolean>[] => {
  return data.reduce((acc: DataPoint<boolean>[], [ts, x]) => {
    const value = fn(x)
    // if the accumulator array is empty, or the boolean value has changed,
    // append the cur value to the acc array
    if (acc.length === 0 || acc[acc.length - 1][1] !== value) {
      return [...acc, [ts, value]]
    }
    return acc
  }, [])
}

export const applyDuration = (
  duration: number, // seconds
  data: DataPoint<boolean>[],
): DataPoint<boolean>[] => {
  if (duration <= 0) {
    throw new Error(`duration must be > 0, received: ${duration}`)
  }

  return data.reduce((acc: DataPoint<boolean>[], [ts, x], i) => {
    // if we have not stored any point
    if (acc.length === 0) {
      acc.push([ts, false])
    }

    if (x) {
      // see if we are long enough in the true state
      const next = data[i + 1]
      if (next === undefined || next[0] - ts > duration) {
        acc.push([ts + duration, true])
      }
    } else if (acc[acc.length - 1][1]) {
      // only store false if the previous value was true
      acc.push([ts, false])
    }

    return acc
  }, [])
}

// Generates the union of multiple sets
const union = <T>(sets: Set<T>[]): Set<T> => {
  const union = new Set<T>()
  for (const set of sets) {
    for (const elem of set) {
      union.add(elem)
    }
  }
  return union
}

// ugly!! javascript doesn't let you apply functions on
// iterators, only loop
const collect = <T>(iter: IterableIterator<T>): T[] => {
  const out: T[] = []
  for (const x of iter) {
    out.push(x)
  }
  return out
}

// merges multiple distinct time series into a single time series
// with an array of values representing each of the input series. Missing
// values are filled forward or undefined if there is no value
// to fill forward
export const mergeFillForward2 = <T>(
  data: DataPoint<T>[][],
): DataPoint<(T | undefined)[]>[] => {
  const indexesOfMin = (xs: (number | undefined)[]): number[] => {
    let lowest: number[] = []
    for (let i = 0; i < xs.length; i++) {
      const x = xs[i]
      if (x !== undefined) {
        if (lowest.length === 0) lowest = [i]
        else {
          const low = xs[lowest[0]] as number
          if (x < low) lowest = [i]
          else if (x === low) lowest.push(i)
        }
      }
    }
    return lowest
  }

  const indexes = data.map(() => 0)
  const curr: (T | undefined)[] = data.map(() => undefined)
  const result: DataPoint<(T | undefined)[]>[] = []

  while (data.some((col, colIdx) => indexes[colIdx] < col.length)) {
    // get column with the min timestamp
    const ts = data.map((col, colIdx) => col[indexes[colIdx]]?.[0])
    const idxs = indexesOfMin(ts)
    for (const idx of idxs) {
      curr[idx] = data[idx][indexes[idx]][1]
    }

    result.push([ts[idxs[0]], [...curr]])
    for (const idx of idxs) {
      indexes[idx]++
    }
  }

  return result
}

// merges multiple distinct time series into a single time series
// with an array of values representing each of the input series. Missing
// values are filled forward or undefined if there is no value
// to fill forward
export const mergeFillForward = <T>(
  data: DataPoint<T>[][],
): DataPoint<(T | undefined)[]>[] => {
  const { map } = data
    // map into a single array of [ts, index, value]
    .flatMap((series, i) =>
      series.map(([ts, x]): [number, number, T] => [ts, i, x]),
    )
    // sort by timestamp
    .sort(([a], [b]) => a - b)
    // group by timestamp into a Map
    .reduce(
      (
        acc: { map: Map<number, (T | undefined)[]>; curr: (T | undefined)[] },
        [ts, i, x],
      ) => {
        // use Array.from(...) to make a copy
        const value = Array.from(acc.map.get(ts) || acc.curr)
        value[i] = x
        acc.map.set(ts, value)
        return { map: acc.map, curr: value }
      },
      { map: new Map(), curr: data.map(() => undefined) },
    )

  return collect(map.entries())
}

// A type assertion for an DataPoint with an array of possibly undefined values
const areAllValuesDefined = <T>(
  value: DataPoint<(T | undefined)[]>,
): value is DataPoint<T[]> => value[1].every(v => v !== undefined)

// takes multiple arrays of data points and applies the passed function
// for each timestamp
export const applyBoolean = (
  fn: (values: boolean[]) => boolean,
  data: DataPoint<boolean>[][],
): DataPoint<boolean>[] => {
  // merge the data sets and remove any rows with undefined points
  const merged = mergeFillForward(data).filter(areAllValuesDefined)
  return applyFunction(fn, merged)
}

// boolean combinators
export const and = (values: boolean[]): boolean => values.every(x => x)
export const or = (values: boolean[]): boolean => values.some(x => x)

// Evaluator

type EvalInput<T> = {
  [id: string]: DataPoint<T>[]
}

type EvalFn<T> = (values: EvalInput<T>) => DataPoint<boolean>[]

type Evaluator<T> = {
  ids: Set<string>
  fn: EvalFn<T>
}

function generateEvaluator<T>(
  compareFunction: CompareFunction<T>,
  inCheckFunction: InCheckFunction<T>,
): (node: Node) => Evaluator<T> {
  function gen(node: Node): Evaluator<T> {
    switch (node.kind) {
      case 'or': {
        const children = node.children.map(n => gen(n))
        return {
          ids: union(children.map(c => c.ids)),
          fn: values => {
            const data = children.map(c => c.fn(values))
            return applyBoolean(or, data)
          },
        }
      }
      case 'and': {
        const children = node.children.map(n => gen(n))
        return {
          ids: union(children.map(c => c.ids)),
          fn: values => {
            const data = children.map(c => c.fn(values))
            return applyBoolean(and, data)
          },
        }
      }
      case 'not': {
        const child = gen(node.child)
        return {
          ids: child.ids,
          fn: values => applyFunction(x => !x, child.fn(values)),
        }
      }
      case 'duration': {
        const child = gen(node.child)
        return {
          ids: child.ids,
          fn: values => applyDuration(durationSeconds(node), child.fn(values)),
        }
      }
      case 'comparison': {
        const fn = compareFunction(node)
        return {
          ids: new Set([node.id]),
          fn: values => applyFunction(fn, values[node.id]),
        }
      }
      case 'inCheck': {
        const fn = inCheckFunction(node)
        return {
          ids: new Set([node.id]),
          fn: values => applyFunction(fn, values[node.id]),
        }
      }
      case 'error':
        throw new Error(node.msg)
      default: {
        const exhaustiveCheck: never = node
        return exhaustiveCheck
      }
    }
  }
  return gen
}

const generateNumberEvaluator = generateEvaluator(
  compareFunction,
  inCheckFunction,
)

const generateAggregatedEvaluator = generateEvaluator(
  aggCompareFunction,
  aggInCheckFunction,
)

type LogicFunc = (x: { [id: string]: number }) => boolean

export class Condition {
  public ids: string[]

  private evaluator: Evaluator<number>

  private aggEvaluator: Evaluator<Agg>

  constructor(node: Node) {
    this.evaluator = generateNumberEvaluator(node)
    this.aggEvaluator = generateAggregatedEvaluator(node)
    this.ids = [...this.evaluator.ids]
  }

  public static fromString(rule: string): Condition {
    const node = parse(rule)
    return new Condition(node)
  }

  public evaluate: LogicFunc = x => {
    // convert the map of single values to a map of series
    const series: { [id: string]: DataPoint[] } = {}
    for (const [k, v] of Object.entries(x)) {
      series[k] = [[0, v]]
    }

    const result = this.evaluator.fn(series)

    // return the value of the first result
    return result[0][1]
  }

  public evaluateSeries: EvalFn<number> = x => this.evaluator.fn(x)

  public evaluateAggregatedSeries(input: {
    [id: string]: TimeSeriesData[]
  }): DataPoint<boolean>[] {
    // transform the TimeSeriesData to DataPoint<Agg>
    const data: EvalInput<Agg> = {}
    for (const [id, value] of Object.entries(input)) {
      data[id] = value.map(([ts, avg, min, max]) => [
        ts / 1000,
        { avg, min: min ?? avg, max: max ?? avg },
      ])
    }

    // evaluate and return the timestamps to ms
    return this.aggEvaluator.fn(data).map(([ts, v]) => [ts * 1000, v])
  }
}
