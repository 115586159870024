import React from 'react'
import { SiteContext, SiteContextValue } from './SiteContext'

export const useSite = (): SiteContextValue => {
  const site = React.useContext(SiteContext)
  if (!site) {
    throw new Error('useSite outside of SiteProvider')
  }
  return site
}
