import { useModels } from 'models/api'
import { useTags } from 'tags/api'
import { SeriesId } from 'src/types/chartTypes'
import { FetchStatus, queryStatusToFetchStatus } from 'src/types'
import { GqlModelBaseFragment, TagDto } from 'src/services'

type FetchData<T> = {
  data: T
  status: FetchStatus
}

export function useChartMetadata(
  seriesIds: SeriesId[],
): FetchData<TagDto | GqlModelBaseFragment | undefined>[] {
  const tagsQuery = useTags()
  const modelsQuery = useModels()

  return seriesIds.map(series => {
    switch (series.type) {
      case 'tag': {
        const status = queryStatusToFetchStatus(tagsQuery.status)
        const data = tagsQuery.data?.find(t => t.tagName === series.id)
        return { data, status }
      }
      case 'forecast':
      case 'prediction':
      case 'spikeScore':
      case 'shortScore': {
        const status = queryStatusToFetchStatus(modelsQuery.status)
        const data = modelsQuery.data?.find(m => m.id === series.id)
        return { data, status }
      }
      default: {
        const discard: never = series
        throw new Error(`Unhandled series type: ${discard}`)
      }
    }
  })
}
