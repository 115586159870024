import React from 'react'
import { AuthContext, AuthContextValue } from '.'

export function useAuth(): AuthContextValue {
  const context = React.useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth outside of AuthProvider')
  }
  return context
}
