import { DynamicTable, Text, Spinner, Banner, Button } from 'src/components/ui'
import { useSite } from 'src/contexts/site'
import { TagAction } from 'pages/site/models'
import { getTagOutputListConfig } from 'models/model/table-configs'
import { useTags } from 'tags/api'
import { ModelTypes } from 'src/types'
import { TagDto } from 'src/services'
import { Record } from '../steps'
import { DisplayOutputTag } from './components'

interface OutputStepProps {
  modelType?: ModelTypes | null
  outputTag?: string | null
  setOutputTag: (tag: string | null) => void
  model: Record
}

export function OutputStep({
  modelType,
  outputTag,
  setOutputTag,
  model,
}: OutputStepProps): JSX.Element {
  const tagsQuery = useTags()

  if (tagsQuery.isLoading) {
    return <Spinner />
  }

  if (tagsQuery.isError) {
    return (
      <Banner
        variant="error"
        className="my-s"
        rightComponent={
          <Button
            variant="primary"
            onClick={() => tagsQuery.refetch()}
            title="Try again"
          />
        }
      >
        Failed to load tags
      </Banner>
    )
  }

  return (
    <OutputStepWithData
      modelType={modelType}
      outputTag={outputTag}
      setOutputTag={setOutputTag}
      model={model}
      tags={tagsQuery.data}
    />
  )
}

function OutputStepWithData({
  modelType,
  outputTag,
  setOutputTag,
  model,
  tags,
}: OutputStepProps & { tags: TagDto[] }): JSX.Element {
  const { id: factory } = useSite()

  const actions = {
    renderAction: ({ data: tag }: any) => (
      <TagAction
        type="Set"
        tag={tag}
        tagAction={setOutputTag}
        modelType={model.modelType}
        modelOutputTagName={model.outputTag}
      />
    ),
  }

  return (
    <>
      <Text variant="title" bold className="my-m text-center !text-3xl">
        {modelType === 'ForecastModel'
          ? 'Which tag do you want to predict?'
          : modelType === 'AnomalyModel'
          ? 'Which tag do you want to identify anomalies for?'
          : 'Which tag should be the output?'}
      </Text>
      {modelType === 'AnomalyModel' && (
        <Text className="my-s">
          This is the tag you are trying to detect anomalies on. Anomalies will
          be flagged when something unexpected happens in the value of this tag.
        </Text>
      )}
      {outputTag ? (
        <DisplayOutputTag outputTag={outputTag} setOutputTag={setOutputTag} />
      ) : (
        <div className="flex flex-col" style={{ marginBottom: '0.5em' }}>
          <DynamicTable
            id={`${factory}-OutputStep`}
            data={
              tags.map(t => ({
                ...t,
                displayName: t.displayName || t.tagName,
                label: t.overriddenLabel || t.automaticLabel,
              })) ?? []
            }
            config={getTagOutputListConfig(actions)}
            fuzzySearch
            fuzzySearchField={['displayName', 'parentName', 'label']}
            className="!h-[500px]"
            rowHeight={60}
          />
        </div>
      )}
    </>
  )
}
