export function CardHeader({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  return (
    <div className="mb-s grid grid-flow-col items-center justify-start gap-xs text-text-tertiary">
      {children}
    </div>
  )
}
