import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import * as api from 'src/services'
import { viewerQueryKey } from './account.get'

export function useUpdateConsentMutation(): UseMutationResult<
  api.GqlUpdateUserConsentMutation,
  unknown,
  api.GqlUpdateUserConsentInput
> {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (input: api.GqlUpdateUserConsentInput) =>
      api.updateUserConsent(input),
    onSuccess: (data, variables) => {
      queryClient.setQueryData<api.GqlMeFragment>([viewerQueryKey], old => {
        // if there is an existing value for viewer (and there should be)
        // update the consent values
        if (old) {
          return {
            ...old,
            ...variables,
          }
        }
        return undefined
      })
    },
  })
}

export function useUpdateDefaultFactoryMutation(): UseMutationResult<
  void,
  unknown,
  api.GqlUpdateUserDefaultFactoryInput
> {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (input: api.GqlUpdateUserDefaultFactoryInput) => {
      await api.updateDefaultFactory(input)
    },
    onSuccess: () => {
      queryClient.invalidateQueries([viewerQueryKey])
    },
  })
}
