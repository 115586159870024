import styled from 'styled-components'

export const Body = styled.div`
  width: 100%;
  height: calc(100% - 135px);
  position: relative;
`

export const CollapseButtonWrapper = styled.div`
  position: absolute;
  top: 76px;
  right: 30px;
  z-index: 1;
`

export const NavigationButton = styled.button`
  background: none;
  color: #333;
  border: none;
  padding: 0;
  margin-right: 5px;

  & > i {
    cursor: pointer;
    font-size: 1.3em;
  }

  & > i:hover {
    filter: brightness(180%);
    transition: filter 0.3s ease-in;
  }
`

export const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ItemFoundContainer = styled.div`
  font-weight: 600;
  color: #9b9b9b;
`
