import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import * as api from 'src/services'
import { ANOMALY } from './anomalies.get'

export function useSetAnomalyStateMutation(): UseMutationResult<
  api.GqlFetchAnomalyFragment | undefined | null,
  unknown,
  api.GqlSetAnomalyStateInput
> {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (input: api.GqlSetAnomalyStateInput) =>
      api.setAnomalyState(input),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([ANOMALY, variables.anomalyId])
    },
  })
}

export function useAddAnomalyCommentMutation(): UseMutationResult<
  api.GqlAnomalyCommentBaseFragment | undefined | null,
  unknown,
  api.GqlAddAnomalyCommentInput
> {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (input: api.GqlAddAnomalyCommentInput) =>
      api.addAnomalyComment(input),
    onSuccess: (_, variables) => {
      // on success, update redux
      queryClient.invalidateQueries([ANOMALY, variables.anomalyId])
    },
  })
}
