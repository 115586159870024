import React from 'react'
import { CursorContext } from './CursorContext'

export const CursorProvider = ({
  children,
}: {
  children?: React.ReactNode
}): JSX.Element => {
  const [cursor, setCursor] = React.useState<number>()

  return (
    <CursorContext.Provider value={[cursor, setCursor]}>
      {children}
    </CursorContext.Provider>
  )
}
