import HighchartsReact from 'highcharts-react-official'
import { RefObject, useEffect } from 'react'

interface UseLoadingProps {
  ref: RefObject<HighchartsReact.RefObject>
  loading: boolean
  data: { data?: unknown[] }[]
}

export function useLoading({ ref, loading, data = [] }: UseLoadingProps): void {
  useEffect(() => {
    if (ref.current) {
      if (data.some(({ data = [] }) => data.length > 0)) {
        ref.current.chart.hideLoading()
      } else {
        if (loading) {
          ref.current.chart.showLoading('Loading...')
        } else {
          ref.current.chart.showLoading('No data available for this time range')
        }
      }
    }
  }, [loading, ref, data])
}
