export class ResponseError extends Error {
  response: Response

  traceparent: string | null

  constructor(response: Response, traceparent?: string | null) {
    super(`Unexpected response code: ${response.status}`)
    this.name = 'ResponseError'
    this.response = response
    this.traceparent = traceparent ?? null
  }
}
