import { nanoid } from 'nanoid'
import { Node, Comparator, Comparison, duration } from 'src/conditions/ast'

export type FormComparator = '=' | '!=' | '>' | '>=' | '<' | '<=' | 'between'

export interface FormCondition {
  id: string
  tag?: string
  comparator?: FormComparator
  value1?: number
  value2?: number
  // duration in minutes
  duration: number
}

export const comparatorString = (comparator?: FormComparator): string => {
  switch (comparator) {
    case '=':
      return 'is equal to'
    case '!=':
      return 'is not equal to'
    case '>':
      return 'is greater than'
    case '>=':
      return 'is greater than or equal to'
    case '<':
      return 'is less than'
    case '<=':
      return 'is less than or equal to'
    case 'between':
      return 'is between (inclusive)'
    default:
      throw new Error('bad comparator')
  }
}

const makeId = (): string => `condition-${nanoid()}`

const createBetweenNode = (
  tag: string,
  value1: number,
  value2: number,
): Node => {
  return {
    kind: 'and',
    children: [
      {
        kind: 'comparison',
        id: tag,
        value: value1,
        comparator: value1 <= value2 ? '>=' : '<=',
      },
      {
        kind: 'comparison',
        id: tag,
        value: value2,
        comparator: value1 > value2 ? '>=' : '<=',
      },
    ],
  }
}

const createComparisonNode = (
  tag: string,
  value1: number,
  comparator: Comparator,
): Node => {
  return {
    kind: 'comparison',
    id: tag,
    value: value1,
    comparator,
  }
}

export const joinNodes = (nodes: Node[]): Node => {
  if (nodes.length === 1) {
    return nodes[0]
  }

  return {
    kind: 'and',
    children: nodes,
  }
}

// turns a FromCondition into a valid Node (or null)
const createBaseNode = (condition: FormCondition): Node | null => {
  if (
    condition.comparator === undefined ||
    condition.tag === undefined ||
    condition.value1 === undefined
  ) {
    return null
  }

  // check between case
  if (condition.comparator === 'between') {
    if (condition.value2 === undefined) {
      return null
    }
    return createBetweenNode(condition.tag, condition.value1, condition.value2)
  }

  // otherwise we are a comparison
  return createComparisonNode(
    condition.tag,
    condition.value1,
    condition.comparator,
  )
}

export const createNode = (formCondition: FormCondition): Node | null => {
  const node = createBaseNode(formCondition)
  if (node && formCondition.duration > 0) {
    return duration(node, formCondition.duration, 'm')
  }
  return node
}

const areBetweenComparisons = (c0: Comparison, c1: Comparison): boolean => {
  return (
    c0.id === c1.id &&
    ((c0.comparator === '>=' && c1.comparator === '<=') ||
      (c0.comparator === '<=' && c1.comparator === '>='))
  )
}

const isBetween = (cond: Node): boolean => {
  return (
    cond.kind === 'and' &&
    cond.children.length === 2 &&
    cond.children[0].kind === 'comparison' &&
    cond.children[1].kind === 'comparison' &&
    areBetweenComparisons(cond.children[0], cond.children[1])
  )
}

const makeFormComparator = (
  comparator: Comparator,
  negate: boolean,
): FormComparator => {
  if (negate) {
    switch (comparator) {
      case '=':
        return '!='
      case '!=':
        return '='
      case '<':
        return '>='
      case '<=':
        return '>'
      case '>':
        return '<='
      case '>=':
        return '<'
      default:
        throw new Error(`Invalid comparator ${comparator}`)
    }
  }
  return comparator
}

const parseSingleCondition = (condition: Node): FormCondition => {
  // first check for duration
  let duration = 0
  if (condition.kind === 'duration') {
    if (condition.unit !== 'm') {
      throw new Error(
        `Expected duration unit to be 'm', recieved ${condition.unit}`,
      )
    }
    duration = condition.duration
    condition = condition.child
  }

  // then check for between
  if (condition.kind === 'and') {
    // anything other than a between here is an error
    if (
      condition.children.length === 2 &&
      condition.children[0].kind === 'comparison' &&
      condition.children[1].kind === 'comparison'
    ) {
      return {
        id: makeId(),
        tag: condition.children[0].id,
        comparator: 'between',
        value1: condition.children[0].value,
        value2: condition.children[1].value,
        duration,
      }
    }
    throw new Error(`Expected between condition, recieved ${condition}`)
  }

  const negate = condition.kind === 'not'
  const cond = condition.kind === 'not' ? condition.child : condition

  // Our condition should now be a comparison
  if (cond.kind !== 'comparison') {
    throw new Error(`Expected comparison condition, received ${cond}`)
  }

  const comparator = makeFormComparator(cond.comparator, negate)

  return {
    id: makeId(),
    tag: cond.id,
    comparator,
    value1: cond.value,
    value2: undefined,
    duration,
  }
}

export const parseCondition = (condition?: Node): FormCondition[] => {
  if (!condition) {
    // FIXME this might need to be an empty FormCondition
    return []
  }

  // The top level condition can be one of 3 things:
  // - a multi-condition AND
  // - a single between AND
  // - a single comparison condition

  if (condition.kind === 'and' && !isBetween(condition)) {
    // We have multiple conditions, so parse each one
    return condition.children.map(child => parseSingleCondition(child))
  }

  // We should have a single condition, so parse it
  return [parseSingleCondition(condition)]
}

export const newFormCondition = (): FormCondition => {
  return {
    id: makeId(),
    tag: undefined,
    comparator: undefined,
    value1: undefined,
    value2: undefined,
    duration: 0,
  }
}
