const COMPARATORS = ['=', '!=', '>', '>=', '<', '<='] as const
export type Comparator = (typeof COMPARATORS)[number]

export const isComparator = (comparator: string): comparator is Comparator =>
  COMPARATORS.includes(comparator as Comparator)

const DURATION_UNITS = ['s', 'm'] as const
export type DurationUnit = (typeof DURATION_UNITS)[number]

export const isDurationUnit = (unit: string): unit is DurationUnit =>
  DURATION_UNITS.includes(unit as DurationUnit)

export interface Or {
  kind: 'or'
  children: Node[]
}

export const or = (children: Node[]): Or => ({
  kind: 'or',
  children,
})

export interface And {
  kind: 'and'
  children: Node[]
}

export const and = (children: Node[]): And => ({
  kind: 'and',
  children,
})

export interface Not {
  kind: 'not'
  child: Node
}

export const not = (child: Node): Not => ({
  kind: 'not',
  child,
})

export interface Duration {
  kind: 'duration'
  child: Node
  duration: number
  unit: DurationUnit
}

export const duration = (
  child: Node,
  duration: number,
  unit: DurationUnit,
): Duration => ({
  kind: 'duration',
  child,
  duration,
  unit,
})

export const durationSeconds = (node: Duration): number => {
  switch (node.unit) {
    case 's':
      return node.duration
    case 'm':
      return 60 * node.duration
    default: {
      const exhaustiveCheck: never = node.unit
      return exhaustiveCheck
    }
  }
}

export interface Comparison {
  kind: 'comparison'
  id: string
  comparator: Comparator
  value: number
}

export const comparison = (
  id: string,
  comparator: Comparator,
  value: number,
): Comparison => ({
  kind: 'comparison',
  id,
  comparator,
  value,
})

export interface InCheck {
  kind: 'inCheck'
  id: string
  not: boolean
  values: number[]
}

export const inCheck = (
  id: string,
  not: boolean,
  values: number[],
): InCheck => ({
  kind: 'inCheck',
  id,
  not,
  values,
})

export type Error = {
  kind: 'error'
  msg: string
}

export type Node = Or | And | Not | Duration | Comparison | InCheck | Error
