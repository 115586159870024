import { getHours, getMinutes, setHours, setMinutes } from 'date-fns'
import DatePicker from '../DateInput/DatePicker'
import TimeInput, { TimeValue } from '../TimeInput/TimeInput'

type Props = {
  handleChange: (date: Date) => void
  value: Date
  maxDate?: Date
  error?: boolean
  showTimeOption?: boolean
}

export default function DateTimeInput({
  handleChange,
  value,
  maxDate,
  error,
  showTimeOption = true,
}: Props): JSX.Element {
  function handleDateChange(date: Date, includeTime?: boolean): void {
    if (includeTime) {
      handleChange(date)
      return
    }

    const newDateTime = setMinutes(
      setHours(new Date(date), getHours(value)),
      getMinutes(value),
    )
    handleChange(newDateTime)
  }

  function handleTimeChange(newValue: TimeValue): void {
    const newTime = setHours(value || Date.now(), newValue.hour).setMinutes(
      newValue.minutes,
    )
    handleChange(new Date(newTime))
  }

  return (
    <div className="flex flex-col">
      <DatePicker
        handleDateChange={(date: Date, includeTime?: boolean) =>
          handleDateChange(date, includeTime)
        }
        error={error}
        maxDate={maxDate}
        value={value}
      />
      {showTimeOption && (
        <>
          <div className="mx-auto mt-2xs h-px w-full bg-background-tertiary" />
          <TimeInput
            value={value.valueOf()}
            label="Time:"
            error={!!error}
            handleChange={handleTimeChange}
          />
        </>
      )}
    </div>
  )
}
