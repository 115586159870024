import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { ErrorDisplay } from 'pages/app'
import { isDefined } from 'src/types'
import { useNavigationContext } from 'src/contexts/navigation'
import {
  DynamicTable,
  Icon,
  ProgressBar,
  ProgressBarVariant,
  Spinner,
  Text,
} from 'src/components/ui'
import { useTagsBySiteId } from 'pages/site/tags/api'
import { TagDto } from 'src/services'
import {
  useOpcConnection,
  useOpcSubscription,
  useOpcSubscriptionNodes,
} from '../opc-ua-connection.api'
import { getOpcSubscriptionTableConfig } from './opc-ua-subs.config'
import { OpcUaSubscriptionNavigation } from './OpcUaSubsriptionNavigation'

export function OpcUaSubscriptionPage(): JSX.Element {
  const { orgId, siteId, gatewayId, connectionId, subscriptionId } = useParams()
  if (!orgId || !siteId || !gatewayId || !connectionId || !subscriptionId)
    throw new Error('siteId, gatewayId or subscriptionId is not defined')
  const connectionQuery = useOpcConnection(siteId, gatewayId, connectionId)
  const subscriptionQuery = useOpcSubscription(subscriptionId)
  const nodesQuery = useOpcSubscriptionNodes(subscriptionId)
  const tagsQuery = useTagsBySiteId(
    siteId,
    data => new Map(data.map(tag => [tag.tagName, tag])),
  )

  // Fetch all pages of nodes
  useEffect(() => {
    if (nodesQuery.hasNextPage && !nodesQuery.isFetching) {
      nodesQuery.fetchNextPage()
    }
  }, [nodesQuery])

  const { setTitleComponent } = useNavigationContext()
  useEffect(() => {
    setTitleComponent(
      <OpcUaSubscriptionNavigation
        orgId={orgId}
        siteId={siteId}
        gatewayId={gatewayId}
        opcConnectionId={connectionId}
        subscriptionId={subscriptionId}
      />,
    )
    return () => setTitleComponent(null)
  }, [
    connectionId,
    gatewayId,
    orgId,
    siteId,
    subscriptionId,
    setTitleComponent,
  ])

  if (
    subscriptionQuery.isLoading ||
    connectionQuery.isLoading ||
    tagsQuery.isLoading
  )
    return <Spinner />

  if (
    subscriptionQuery.isError ||
    connectionQuery.isError ||
    tagsQuery.isError
  ) {
    const errorQuery = subscriptionQuery.isError
      ? subscriptionQuery
      : connectionQuery.isError
      ? connectionQuery
      : tagsQuery
    return (
      <ErrorDisplay
        error={errorQuery.error}
        message="Something went wrong"
        action={errorQuery.refetch}
      />
    )
  }

  // wrapper to fetch the tag associated with the nodeId
  const getTag = (nodeId: string): TagDto | undefined => {
    const tag = tagsQuery.data.get(nodeId)
    if (tag) {
      return tag
    }

    // special hack for tine since their tagnames do not match the nodeIds
    if (siteId == 'FKWN2ZSTX1BG') {
      // ns=3;s=0:_3508_HT_slave_inn_PV.SetP => _3508_HT_slave_inn_PV.SetP
      const parts = nodeId.split(':')
      if (parts.length == 2) {
        return tagsQuery.data.get(parts[1])
      }
    }

    // no match found
    return undefined
  }

  // Filter out nodes that are not defined
  // and map them to the format required by the table
  const nodes =
    nodesQuery.data?.pages
      .map(p => p.items)
      .flat()
      .filter(isDefined)
      .map(n => n.nodeId) ?? []

  // Map nodes to include tag display name
  // Only if all nodes are fetched already
  const nodesWithTags = nodesQuery.hasNextPage
    ? []
    : nodes.map(id => {
        const tag = getTag(id)
        if (tag) {
          return {
            id,
            tagName: tag.tagName,
            displayName: tag.displayName ?? tag.tagName,
          }
        }
        return {
          id,
          tagName: id,
          displayName: id,
        }
      })

  return (
    <div className="h-full p-s">
      <div className="flex h-full flex-col rounded-2xs bg-background px-s py-m">
        <div className="flex items-center gap-xl">
          <Icon
            icon={light('cloud-arrow-up')}
            className="-mr-m size-[40px] text-icon-tertiary"
          />
          <div>
            <Text variant="description">Subscription ID</Text>
            <Text bold>{subscriptionQuery.data.id}</Text>
          </div>
          <div>
            <Text variant="description">Server Endpoint</Text>
            <Text bold>{connectionQuery.data.endpoint}</Text>
          </div>
        </div>
        <div className="flex flex-1 flex-col">
          <div className="grid h-full pt-m">
            {nodesQuery.isLoading || nodesQuery.hasNextPage ? (
              <>
                <ProgressBar
                  variant={ProgressBarVariant.THIN}
                  value={Math.round(
                    ((nodes?.length ?? 0) /
                      (subscriptionQuery.data.nodes?.totalCount ?? 1)) *
                      100,
                  )}
                />
                <Spinner />
              </>
            ) : (
              <DynamicTable
                id={`${subscriptionId}-OpcSubscription`}
                config={getOpcSubscriptionTableConfig({ siteId })}
                data={nodesWithTags}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
