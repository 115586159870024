import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Button, Icon, Text } from 'src/components/ui'
import { TimeSeriesDef } from 'src/dex/time_series'

type Props = {
  axis: TimeSeriesDef
}

export function OverlaidSegmentsAxisDisplay({ axis }: Props): JSX.Element {
  return (
    <div className="flex items-center gap-xs">
      <Button
        variant="icon"
        className="!px-xs !py-2xs"
        icon={light('pencil')}
        iconSize="small"
      />
      <div className="flex flex-col gap-2xs">
        <div className="flex items-center gap-xs">
          <Icon size="regular" icon={regular('square-y')} />
          <Icon icon={regular('arrow-right')} />
          <Text bold>{axis.label}</Text>
        </div>
        <div className="flex items-center gap-xs">
          <Icon icon={regular('arrow-turn-down-right')} />
          <Text>{axis.description}</Text>
        </div>
      </div>
    </div>
  )
}
