export function isValidEmail(email: string): boolean {
  return /\S+@\S+\.\S+/.test(email)
}

export function isValidJson(json: string): boolean {
  try {
    JSON.parse(json)
    return true
  } catch (e) {
    return false
  }
}

export function isValidUrl(url: string): boolean {
  try {
    new URL(url)
    return true
  } catch (err) {
    return false
  }
}

export function parsePage(x: string | null): number {
  if (!x) return 0
  const n = parseInt(x, 10)
  if (isNaN(n) || n < 0) return 0
  return n
}
