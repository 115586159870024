import { useEffect } from 'react'
import { useTheme } from 'styled-components'
import HighchartsReact from 'highcharts-react-official'
import { useFilter, FilterPeriod } from 'src/contexts/filter'
import useTimeRange from 'src/contexts/timeRange'
import { useChartWidth } from './useChartWidth'
import { useChartHeight } from './useChartHeight'

interface TimeRange {
  from: number
  to: number
}

// converts an array of filter pass areas to an
// array of filtered out periods
const inverse = (filter: FilterPeriod[], timeRange: TimeRange): TimeRange[] => {
  let nextFrom = timeRange.from
  const res: TimeRange[] = []
  for (const { from, to } of filter) {
    res.push({ from: nextFrom, to: from })
    nextFrom = to || timeRange.to
  }
  if (nextFrom !== timeRange.to) {
    res.push({ from: nextFrom, to: timeRange.to })
  }
  return res
}

interface UseFilterAreas {
  ref: React.RefObject<HighchartsReact.RefObject>
  desaturate?: boolean
}

export function useFilterAreas({
  ref,
  desaturate = false,
}: UseFilterAreas): void {
  const { filter } = useFilter()
  const { timeRange } = useTimeRange()
  const width = useChartWidth(ref)
  const height = useChartHeight(ref)
  const theme = useTheme()

  useEffect(() => {
    function generateAreas(): Highcharts.SVGElement[] {
      if (filter && ref.current) {
        const { xAxis, plotTop, plotHeight, renderer } = ref.current.chart
        return inverse(filter, timeRange).flatMap(({ from, to }) => {
          const begin = xAxis[0].toPixels(from, false)
          const stop = xAxis[0].toPixels(to, false)

          if (desaturate) {
            return [
              renderer
                .rect(begin, plotTop, stop - begin, plotHeight)
                .attr({
                  style: 'mix-blend-mode: saturation',
                  fill: 'white',
                  zIndex: 3,
                })
                .add(),
              renderer
                .rect(begin, plotTop, stop - begin, plotHeight)
                .attr({
                  fill: theme.colors.filter,
                  zIndex: 3,
                })
                .add(),
            ]
          }
          return renderer
            .rect(begin, plotTop, stop - begin, plotHeight)
            .attr({
              fill: theme.colors.filter,
              zIndex: 3,
            })
            .add()
        })
      }
      return []
    }

    const areas = generateAreas()
    return () => {
      areas.forEach(area => area.destroy())
    }
  }, [ref, width, height, filter, timeRange, theme.colors.filter, desaturate])
}
