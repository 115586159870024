import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { minutesToMilliseconds } from 'date-fns'
import {
  GqlModelBaseFragment,
  GqlModelBase_ForecastModel_Fragment,
} from 'src/services'
import * as api from 'src/services'
import { useSite } from 'src/contexts/site'
export const MODELS_QUERY_KEY = 'models'
export const MODEL_QUERY_KEY = 'model'

export function useModels<T = api.GqlModelBaseFragment[]>(
  select?: (data: api.GqlModelBaseFragment[]) => T,
): UseQueryResult<T> {
  const { id: currentFactory } = useSite()

  return useQuery({
    queryKey: [MODELS_QUERY_KEY, currentFactory],
    queryFn: () => api.fetchModels({ factory: currentFactory }),
    select,
  })
}

export function useModel<T = api.FetchModel>(
  modelId: string,
  select?: (data: api.FetchModel | null | undefined) => T,
): UseQueryResult<T> {
  return useQuery({
    queryKey: [MODEL_QUERY_KEY, modelId],
    queryFn: () => api.fetchModel({ modelId }),
    select,
  })
}

function isForecastModel(
  model: GqlModelBaseFragment,
): model is GqlModelBase_ForecastModel_Fragment {
  return model.__typename === 'ForecastModel'
}

export function useForecastModels(): UseQueryResult<
  GqlModelBase_ForecastModel_Fragment[]
> {
  return useModels(data => data.filter(isForecastModel))
}

function isAnomalyModel(
  model: GqlModelBaseFragment,
): model is api.GqlModelBase_AnomalyModel_Fragment {
  return model.__typename === 'AnomalyModel'
}

export function useAnomalyModels(): UseQueryResult<
  api.GqlModelBase_AnomalyModel_Fragment[]
> {
  return useModels(data => data.filter(isAnomalyModel))
}

export function useModelTypes(): UseQueryResult<api.GqlModelTypeFragment[]> {
  return useQuery({
    queryKey: ['modelTypes'],
    queryFn: api.fetchModelTypes,
    staleTime: minutesToMilliseconds(60),
  })
}

export function useModelCreditInfo(): UseQueryResult<api.CreditInfoDto> {
  const { id: factory } = useSite()
  return useQuery({
    queryKey: ['getCreditInfo', factory],
    queryFn: () => api.getCreditInfo(factory),
  })
}

export function useFavouriteModelsIds(): UseQueryResult<Set<string>> {
  return useModels(
    data => new Set(data.filter(m => m.viewerHasStarred).map(m => m.id)),
  )
}
