import { hasMatch } from 'fzy.js'

const textMatcher = ({ filterOption, value, filterText }: any): boolean => {
  if (filterText == null) {
    return false
  }
  const index = value.lastIndexOf(filterText)
  const pattern = filterText.replaceAll(' ', '')
  switch (filterOption) {
    case 'contains':
      return hasMatch(pattern, value ?? '')
    case 'notContains':
      return value.indexOf(filterText) < 0
    case 'equals':
      return value === filterText
    case 'notEqual':
      return value != filterText
    case 'startsWith':
      return value.indexOf(filterText) === 0
    case 'endsWith':
      return index >= 0 && index === value.length - filterText.length
    default:
      console.warn('invalid filter type ')
      return false
  }
}

export function getBaseFilterParams(fuzzySearch = false): any {
  return {
    filters: [
      {
        filter: 'agTextColumnFilter',
        filterParams: {
          buttons: ['clear'],
          textMatcher: fuzzySearch ? textMatcher : undefined,
        },
      },
      {
        filter: 'agSetColumnFilter',
        filterParams: { buttons: ['clear'] },
      },
    ],
  }
}

// If the path for the fuzzy search is for example tag.tagName,
// extract the nested value from the object
export function getNestedValue(obj: any, path: string): string {
  return path.split('.').reduce((currentObject, key) => {
    return currentObject && key in currentObject
      ? currentObject[key]
      : undefined
  }, obj)
}
