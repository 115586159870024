import { z } from 'zod'
import apiFetch from './apiFetch'

export const CreditInfoDto = z.object({
  creditsUsed: z.number(),
  creditLimit: z.number().optional().nullable(),
  activeModelCredits: z.number().optional().nullable(),
})

export type CreditInfoDto = z.infer<typeof CreditInfoDto>

export async function getCreditInfo(factory: string): Promise<CreditInfoDto> {
  const response = await apiFetch({
    path: '/subscription',
    query: { factory },
  })
  return CreditInfoDto.parse(await response.json())
}
