import React from 'react'
import toast from 'react-hot-toast'
import { OnlyModelProps, SiteRole } from 'src/types'
import { Button, ConfirmModal } from 'src/components/ui'
import { useSite } from 'src/contexts/site'
import { useArchiveModelMutation } from 'models/api'
import { isModelEditing } from '../Overview/modelOverview.state'

export function ArchiveButton({ model }: OnlyModelProps): JSX.Element {
  const [showConfirm, setShowConfirm] = React.useState(false)
  const { viewerRole } = useSite()
  const isReader = viewerRole === SiteRole.READER

  const archiveModel = useArchiveModelMutation()

  async function onConfirm(): Promise<void> {
    await archiveModel.mutateAsync(
      { id: model.id },
      {
        onError: () => {
          setShowConfirm(false)
          // We're adding timeout to make the toast more visible
          // we ensure the modal closes first before triggering the toast
          setTimeout(
            () =>
              toast.error('Failed to archive model', { position: 'top-right' }),
            300,
          )
        },
      },
    )
    setShowConfirm(false)
  }

  return (
    <>
      {showConfirm && (
        <ConfirmModal
          text={
            <>
              Are you sure you want to archive <b>{model.name}</b>?
            </>
          }
          subText="The model will remain visible, but no further changes can be made."
          pending={archiveModel.isLoading}
          renderConfirmButton={() => (
            <Button
              title="Archive"
              variant="primary"
              buttonColor="danger"
              disabled={archiveModel.isLoading}
              onClick={() => onConfirm()}
            />
          )}
          isOpen={showConfirm}
          setIsOpen={setShowConfirm}
        />
      )}
      <Button
        title="Archive model"
        variant="secondary"
        buttonColor="danger"
        onClick={() => setShowConfirm(true)}
        disabled={isReader || isModelEditing.value}
      />
    </>
  )
}
