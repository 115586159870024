import React from 'react'
import { minutesToSeconds } from 'date-fns'
import toast from 'react-hot-toast'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useNavigate } from 'react-router-dom'
import { Button, Spinner, Stepper } from 'src/components/ui'
import { CreateModel, GqlModelTypeFragment, TagDto } from 'src/services'
import { isDefined, ModelTypes } from 'src/types'
import { useTitle } from 'src/utility'
import { ErrorDisplay } from 'pages/app'
import { useTags } from 'pages/site/tags/api'
import { useCreateModelMutation, useModelTypes } from '../api'
import { ForecastDefaultMethod } from '../model/model.utils'
import { steps, Record } from './steps'

function modelTypenameToModelTypes(modelTypename?: ModelTypes | null): string {
  if (modelTypename === 'AnomalyModel') return 'Anomaly Detection'
  if (modelTypename === 'ForecastModel') return 'Forecast'
  return ''
}

const initModel: Record = {
  modelType: null,
  outputTag: null,
  inputTags: [],
  name: '',
}

export function CreateModelPage(): JSX.Element {
  useTitle('Create model')
  const modelTypesQuery = useModelTypes()
  const tagsQuery = useTags()

  if (modelTypesQuery.isLoading || tagsQuery.isLoading) {
    return <Spinner />
  }

  if (modelTypesQuery.isError) {
    return (
      <ErrorDisplay
        error={modelTypesQuery.error}
        message="Failed to load model types"
      />
    )
  }

  if (tagsQuery.isError) {
    return (
      <ErrorDisplay error={tagsQuery.error} message="Failed to load tags" />
    )
  }

  return (
    <CreateModelInner modelTypes={modelTypesQuery.data} tags={tagsQuery.data} />
  )
}

type CreateModelInnerProps = {
  modelTypes: GqlModelTypeFragment[]
  tags: TagDto[]
}

const CreateModelInner = React.memo(function CreateModelInner({
  modelTypes,
  tags,
}: CreateModelInnerProps): JSX.Element {
  const [step, setStep] = React.useState(0)
  const [model, setModel] = React.useState(initModel)
  const createModelMutation = useCreateModelMutation()
  const { forecastHorizon } = useFlags()
  const navigate = useNavigate()

  const Step = steps[step].render

  const handleNextStep = (nextStep: number): void => {
    if (nextStep > step) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      steps[nextStep - 1].completed(model) && setStep(nextStep)
    } else {
      setStep(nextStep)
    }
  }

  const handleUpdateModel = (updates: Record): void => {
    if (updates && updates.modelType) {
      setStep(1)
    }
    setModel(model => ({ ...model, ...updates }))
  }

  async function createModel(): Promise<void> {
    const modelType = modelTypes.find(
      ({ name }) => modelTypenameToModelTypes(model.modelType) === name,
    )
    if (!modelType) {
      console.error(`Could not find model type: ${model.modelType}`)
      return
    }

    const forecastMethodName = forecastHorizon
      ? ForecastDefaultMethod.FORECAST_WITH_HORIZON
      : ForecastDefaultMethod.FORECAST_1H

    const method = modelType.methods.find(({ name }) =>
      model.modelType === 'ForecastModel'
        ? name === forecastMethodName
        : name === 'Nowcast',
    )
    if (!method) {
      console.error(`Could not find model method: ${model.modelType}`)
      return
    }
    const tag = tags.find(tag => tag.tagName === model.outputTag)
    if (!tag || !tags) return
    const modelToCreate: Omit<CreateModel, 'factory'> = {
      name: model.name ?? '',
      typeId: modelType.id,
      methodId: method.id,
      description: model.description ?? '',
      tagId: tag.tagNodeId,
      forecastHorizon:
        model.modelType === 'ForecastModel' && forecastHorizon
          ? minutesToSeconds(60)
          : undefined,
      inputTagIds:
        (model.inputTags &&
          model.inputTags
            .map(
              tagName => tags.find(tag => tag.tagName === tagName)?.tagNodeId,
            )
            .filter(isDefined)) ??
        [],
      trainingPeriod: 'P1Y',
    }

    await createModelMutation.mutateAsync(modelToCreate, {
      onSuccess: data => {
        navigate(`../${data.id}`, { state: { edit: true } })
      },
      onError: () => {
        toast.error('Failed to create model', { position: 'top-right' })
      },
    })
  }

  const showNext = step > 0 && step < steps.length - 1
  const showCreate = step === steps.length - 1

  return (
    <div
      className="mx-auto my-0 p-[1em]"
      style={{ maxWidth: steps[step].maxWidth ?? 1200 }}
    >
      <div className="flex w-full justify-center">
        <Stepper
          steps={steps.map((s, i) => ({
            label: s.label,
            state:
              step === i
                ? 'active'
                : s.completed(model)
                ? 'completed'
                : 'default',
          }))}
          onClick={handleNextStep}
        />
      </div>
      <Step model={model} updateModel={handleUpdateModel} />
      <div className="flex justify-between">
        {step === 0 ? (
          <div />
        ) : (
          <Button
            variant="secondary"
            title="Back"
            onClick={() => setStep(step => step - 1)}
          />
        )}
        {showNext && (
          <Button
            title="Next"
            variant={steps[step].completed(model) ? 'primary' : 'secondary'}
            disabled={!steps[step].completed(model)}
            onClick={() => handleNextStep(step + 1)}
          />
        )}
        {showCreate && (
          <Button
            variant="primary"
            title="Create model"
            disabled={steps.some(step => !step.completed(model))}
            onClick={createModel}
            isPending={createModelMutation.isLoading}
          />
        )}
      </div>
    </div>
  )
})
