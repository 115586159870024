import { useEffect, useMemo } from 'react'
import {
  getArray,
  triggerChartRedraw,
  useTitle,
  useUrlQuery,
} from 'src/utility'
import { ResizableLayout } from 'src/components/ui'
import { ForecastsTab } from './tabs'
import { ChartList } from './components'

export function ForecastPage(): JSX.Element {
  useTitle('Forecast')
  const { query, update } = useUrlQuery()
  const activeModels = useMemo(() => getArray(query.models), [query.models])

  const removeModel = (modelId: string): void =>
    update({
      models: activeModels.filter(id => id !== modelId),
    })

  useEffect(() => {
    triggerChartRedraw()
  }, [activeModels])

  return (
    <ResizableLayout
      collapsible
      leftComponent={<ForecastsTab />}
      rightComponent={
        <ChartList
          activeModels={activeModels}
          removeModel={removeModel}
          className="max-h-[calc(100vh-57px)]"
        />
      }
      minSize={500}
      defaultSize={30}
      onResize={triggerChartRedraw}
    />
  )
}
