import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { minutesToMilliseconds } from 'date-fns'
import * as api from 'src/services'

export const viewerQueryKey = 'viewerQuery'

export function useViewerQuery<T = api.GqlMeFragment>(
  select?: (data: api.GqlMeFragment) => T,
): UseQueryResult<T> {
  return useQuery({
    queryKey: [viewerQueryKey],
    queryFn: async () => api.fetchMe(),
    // we don't want to user to be "magically" logged out
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    // cache the value forever, but background refresh every 10min
    staleTime: minutesToMilliseconds(10),
    cacheTime: Infinity,
    select,
  })
}
