import classNames from 'classnames'

type Props = {
  modelType: string
  children: React.ReactNode
}

export function PropertyContainer({ modelType, children }: Props): JSX.Element {
  return (
    <div
      className={classNames(
        'flex items-center gap-2xs',
        modelType === 'Anomaly Detection'
          ? 'max-w-[220px] min-w-[220px]'
          : 'max-w-[160px] min-w-[160px]',
      )}
    >
      {children}
    </div>
  )
}
