import classNames from 'classnames'
import React from 'react'
import { Text } from 'src/components/ui'

interface TabsProps {
  defaultIndex?: number
  tabs: string[]
  renderContent: (activeTab: string) => JSX.Element
  contentClassName?: string
  containerClassName?: string
}

export function Tabs({
  defaultIndex = 0,
  tabs,
  renderContent,
  contentClassName,
  containerClassName,
}: TabsProps): JSX.Element {
  const [activeTab, setActiveTab] = React.useState(tabs[defaultIndex])

  return (
    <div
      className={classNames('flex flex-1 flex-col gap-xs', containerClassName)}
    >
      <div className="flex justify-start">
        {tabs.map(tab => (
          <div
            data-testid={`tab-${tab}`}
            className={`cursor-pointer border-0 border-solid px-s ${
              tab === activeTab
                ? 'border-b-2 border-border-brand'
                : 'border-b border-border-secondary'
            }`}
            key={tab}
            onClick={() => setActiveTab(tab)}
          >
            <Text
              variant="content-rubik"
              bold={tab === activeTab}
              className="!mb-xs whitespace-nowrap"
            >
              {tab}
            </Text>
          </div>
        ))}
      </div>
      <div
        className={classNames(
          'flex max-h-[calc(100%-32px)] flex-1 flex-col p-xs',
          contentClassName,
        )}
      >
        {renderContent(activeTab)}
      </div>
    </div>
  )
}
