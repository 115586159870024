import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { format } from 'date-fns'
import {
  SegmentationDef,
  SlidingWindow,
  ValueChange,
} from 'src/dex/segmentation'
import { SavedTimeSeries, TimeSeriesExpression } from 'src/dex/time_series'
import { Icon, Text } from 'src/components/ui'
import { getColorForDependency } from 'data-explorer/data-explorer.utils'

type Props = {
  segmentation: SegmentationDef
  timeSeries: SavedTimeSeries[]
}

export function SegmentationConditionDisplay({
  segmentation,
  timeSeries,
}: Props): JSX.Element {
  switch (segmentation.kind?.$case) {
    case 'condition':
      return (
        <TimeSeriesConditionDisplay
          condition={segmentation.kind.value}
          timeSeries={timeSeries}
        />
      )
    case 'valueChange':
      return (
        <ValueChangeConditionDisplay
          condition={segmentation.kind.value}
          timeSeries={timeSeries}
        />
      )
    case 'slidingWindow':
      return (
        <SlidingWindowConditionDisplay condition={segmentation.kind.value} />
      )
    default:
      return <></>
  }
}

type TimeSeriesConditionDisplayProps = {
  condition: TimeSeriesExpression
  timeSeries: SavedTimeSeries[]
}

function TimeSeriesConditionDisplay({
  condition,
  timeSeries,
}: TimeSeriesConditionDisplayProps): JSX.Element {
  const { expression, dependencies } = condition

  const conditionDisplayParts: Array<React.ReactNode> = []

  let currentIndex = 0
  for (const [key, value] of Object.entries(dependencies)) {
    const regex = new RegExp(`\\b${key}\\b`, 'g')
    const dependencyLabel =
      value.kind?.$case === 'tsDef'
        ? value.kind.value.label
        : timeSeries.find(ts => ts.id === value.kind?.value)?.tsDef?.label ??
          'Unknown'

    let match
    while ((match = regex.exec(expression)) !== null) {
      if (match.index > currentIndex) {
        conditionDisplayParts.push(expression.slice(currentIndex, match.index))
      }
      conditionDisplayParts.push(
        <span
          className="font-500"
          style={{ color: getColorForDependency(key) }}
        >
          <Icon size="regular" icon={regular('sensor')} /> {dependencyLabel}
        </span>,
      )
      currentIndex = match.index + key.length
    }
  }
  if (currentIndex < expression.length) {
    conditionDisplayParts.push(expression.slice(currentIndex))
  }

  return <Text variant="description">{conditionDisplayParts}</Text>
}

type ValueChangeConditionDisplayProps = {
  condition: ValueChange
  timeSeries: SavedTimeSeries[]
}

function ValueChangeConditionDisplay({
  condition,
  timeSeries,
}: ValueChangeConditionDisplayProps): JSX.Element {
  const label =
    condition.timeSeries?.kind?.$case === 'tsDef'
      ? condition.timeSeries.kind.value.label
      : timeSeries.find(ts => ts.id === condition.timeSeries?.kind?.value)
          ?.tsDef?.label ?? 'Unknown'
  return (
    <Text variant="description">
      Contiguous constant values from{' '}
      <Icon size="regular" icon={regular('sensor')} />{' '}
      <span className="font-500">{label}</span>
    </Text>
  )
}

type SlidingWindowConditionDisplayProps = {
  condition: SlidingWindow
}

function SlidingWindowConditionDisplay({
  condition,
}: SlidingWindowConditionDisplayProps): JSX.Element {
  const length = format(
    new Date((condition.length?.seconds ?? 0) * 1000),
    'HH:mm:ss',
  )
  const shift = format(
    new Date((condition.shift?.seconds ?? 0) * 1000),
    'HH:mm:ss',
  )
  return (
    <Text variant="description">
      Length: {length}, Shift: {shift}
    </Text>
  )
}
