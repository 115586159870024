import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useClickOutside } from 'src/utility'
import { useSiteCredits } from 'orgs-sites/site/api'
import { useModelCreditInfo } from 'models/api'
import { useSite } from 'src/contexts/site'
import { Icon, Text } from 'src/components/ui'

function pluralizeCredits(value: number | undefined): string {
  if (value === 1) {
    return '1 credit'
  }
  return `${value} credits`
}

export function CreditStatus(): JSX.Element {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false)
  const [isTooltipOpened, setIsTooltipOpened] = useState(false)
  const [dropdownAlignmentClass, setDropdownAlignmentClass] = useState('left-0')
  const [tooltipAlignmentClass, setTooltipAlignmentClass] =
    useState('left-[-8px]')
  const { id: factory } = useSite()

  const subscriptionInfoQuery = useModelCreditInfo()
  const siteCreditsQuery = useSiteCredits({ siteId: factory })

  const tooltipRef = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  useClickOutside(menuRef, () => setIsDropdownOpened(false))

  useEffect(() => {
    handleDropdownAlignment()
  }, [isDropdownOpened])

  useEffect(() => {
    handleTooltipAlignment()
  }, [isTooltipOpened])

  useEffect(() => {
    function handleResize(): void {
      handleDropdownAlignment()
      handleTooltipAlignment()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (siteCreditsQuery.isError)
      toast.error('Failed to fetch site credits', { position: 'top-right' })
  }, [siteCreditsQuery.isError])

  useEffect(() => {
    if (subscriptionInfoQuery.isError)
      toast.error('Failed to fetch subscription info', {
        position: 'top-right',
      })
  }, [subscriptionInfoQuery.isError])

  function handleTooltipAlignment(): void {
    const tooltip = tooltipRef.current
    const menuWrapper = menuRef.current
    if (tooltip && menuWrapper) {
      const tooltipRect = tooltip.getBoundingClientRect()
      const tooltipRight = tooltipRect.right
      const tooltipWidth = tooltipRect.width

      const menuWrapperRect = menuWrapper.getBoundingClientRect()
      const menuWrapperLeft = menuWrapperRect.left

      const windowWidth = window.innerWidth

      if (tooltipRight > windowWidth - 10) {
        setTooltipAlignmentClass(`left-[calc((-100%+${tooltipWidth}px))]`)
      }

      if (menuWrapperLeft + tooltipWidth < windowWidth - 10) {
        setTooltipAlignmentClass('left-[-8px]')
      }
    }
  }

  function handleDropdownAlignment(): void {
    const dropdown = dropdownRef.current
    const menuWrapper = menuRef.current
    if (dropdown && menuWrapper) {
      const dropdownRect = dropdown.getBoundingClientRect()
      const dropdownRight = dropdownRect.right
      const dropdownWidth = dropdownRect.width

      const menuWrapperRect = menuWrapper.getBoundingClientRect()
      const menuWrapperLeft = menuWrapperRect.left

      const windowWidth = window.innerWidth

      if (dropdownRight > windowWidth - 10) {
        setDropdownAlignmentClass('right-0')
      }

      if (menuWrapperLeft + dropdownWidth < windowWidth - 10) {
        setDropdownAlignmentClass('left-0')
      }
    }
  }

  if (
    siteCreditsQuery.isLoading ||
    subscriptionInfoQuery.isLoading ||
    !subscriptionInfoQuery.data ||
    !siteCreditsQuery.data
  ) {
    return <></>
  }

  const haveActiveType = !!subscriptionInfoQuery.data.activeModelCredits

  const availableCredits = haveActiveType
    ? siteCreditsQuery.data.activeModelCreditsUsage?.total || 0
    : (subscriptionInfoQuery.data.creditLimit || 0) -
      (subscriptionInfoQuery.data.creditsUsed || 0)

  const totalCredits = haveActiveType
    ? subscriptionInfoQuery.data.activeModelCredits || 0
    : subscriptionInfoQuery.data.creditLimit || 0

  return (
    <div
      ref={menuRef}
      className={`relative inline-flex items-center justify-between rounded-2xs border border-solid bg-background p-xs ${
        isDropdownOpened ? 'border-border-brand' : 'border-border'
      }`}
      onClick={() => haveActiveType && setIsDropdownOpened(!isDropdownOpened)}
    >
      <div
        className="group relative flex items-center justify-center"
        onMouseEnter={() => setIsTooltipOpened(true)}
        onMouseLeave={() => setIsTooltipOpened(false)}
      >
        <Icon icon={regular('circle-info')} />
        {isTooltipOpened && !isDropdownOpened && (
          <>
            {haveActiveType ? (
              <div
                ref={tooltipRef}
                className={`absolute ${tooltipAlignmentClass} top-[calc(100%+5px)] z-20 rounded-xs border border-solid border-border bg-background-tertiary-active px-s py-xs after:absolute after:content-[''] ${
                  tooltipAlignmentClass !== 'left-[-8px]'
                    ? 'after:left-[50%] after:translate-x-[-50%]'
                    : 'after:left-[10px]'
                } after:left-[10px] after:top-[-10px] after:border-[5px] after:border-solid after:border-transparent after:border-b-border-secondary`}
              >
                <div className="flex items-center justify-between">
                  <Text
                    variant="description"
                    className="w-[200px] whitespace-nowrap text-white"
                  >
                    <span className="font-500">Active Batch</span> models
                    consume:
                  </Text>
                  <Text
                    variant="description"
                    className="whitespace-nowrap text-white"
                    bold
                  >
                    {pluralizeCredits(
                      siteCreditsQuery.data.activeModelCreditsUsage?.batch
                        .creditsPerModel,
                    )}
                  </Text>
                </div>
                <div className="flex items-center justify-between">
                  <Text
                    variant="description"
                    className="w-[200px] whitespace-nowrap text-white"
                  >
                    <span className="font-bold">Active Streaming </span> models
                    consume:
                  </Text>
                  <Text
                    variant="description"
                    className="whitespace-nowrap text-white"
                    bold
                  >
                    {pluralizeCredits(
                      siteCreditsQuery.data.activeModelCreditsUsage?.streaming
                        .creditsPerModel,
                    )}
                  </Text>
                </div>
                <Text variant="description" className="text-white">
                  For more information, please check the{' '}
                  <span className="font-500">Documents</span> section, or
                  contact <span className="font-500">Intelecy</span> directly.
                </Text>
              </div>
            ) : (
              <div
                ref={tooltipRef}
                className={`absolute ${tooltipAlignmentClass} top-[calc(100%+5px)] z-20 min-w-[250px] rounded-xs border border-solid border-border bg-background-tertiary-active px-s py-xs after:absolute after:content-[''] ${
                  tooltipAlignmentClass !== 'left-[-8px]'
                    ? 'after:left-[50%] after:translate-x-[-50%]'
                    : 'after:left-[10px]'
                } after:left-[10px] after:top-[-10px] after:border-[5px] after:border-solid after:border-transparent after:border-b-border-secondary`}
              >
                <Text variant="description" className="text-white">
                  The total training credits available is determined by your
                  Intelecy license. Each model trained consumes one credit. When
                  there are no training credits available, you will no longer be
                  able to train models.
                </Text>
              </div>
            )}
          </>
        )}
      </div>
      <Text
        variant="description"
        bold
        className="!my-0 mx-xs whitespace-nowrap"
      >
        Credits:{' '}
        <span
          className={`${
            availableCredits < 1 && !haveActiveType
              ? 'text-text-danger'
              : 'text-text-brand'
          } ml-xs font-bold`}
        >
          {availableCredits.toString()}
        </span>
        <span className="text-text-disabled"> / {totalCredits.toString()}</span>
      </Text>
      {haveActiveType && (
        <Icon
          icon={light('chevron-down')}
          className={`transition duration-300 ${
            isDropdownOpened && 'rotate-180'
          }`}
        />
      )}
      {isDropdownOpened && siteCreditsQuery.data.activeModelCreditsUsage && (
        <div
          ref={dropdownRef}
          className={`${dropdownAlignmentClass} absolute top-[calc(100%+10px)] z-20 min-w-[240px] whitespace-nowrap rounded-2xs bg-background shadow-[0px_2px_2px_rgba(0,0,0,0.25)]`}
        >
          <div className="flex items-center justify-between p-xs">
            <Text variant="description" className="w-[160px]">
              <span className="font-500">Batch </span>models running:
            </Text>
            <Text variant="description" className="text-text-tertiary">
              <span className="font-500 text-text-brand">
                {siteCreditsQuery.data.activeModelCreditsUsage?.batch.models.toString()}{' '}
              </span>
              {pluralizeCredits(
                siteCreditsQuery.data.activeModelCreditsUsage?.batch.total,
              )}
            </Text>
          </div>
          <hr className="my-0" />
          <div className="flex items-center justify-between p-xs">
            <Text variant="description" className="w-[160px]">
              <span className="font-500">Streaming </span>models running:
            </Text>
            <Text variant="description" className="text-text-tertiary">
              <span className="font-500 text-text-brand">
                {siteCreditsQuery.data.activeModelCreditsUsage?.streaming.models.toString()}{' '}
              </span>
              {pluralizeCredits(
                siteCreditsQuery.data.activeModelCreditsUsage?.streaming.total,
              )}
            </Text>
          </div>
        </div>
      )}
    </div>
  )
}
