import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import { Icon, Text } from 'src/components/ui'

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  index: number
  state: 'disabled' | 'active' | 'complete' | 'active-complete'
  title: string
  children: React.ReactNode
}

export function StepAccordion({
  isOpen,
  setIsOpen,
  index,
  state,
  title,
  children,
}: Props): JSX.Element {
  return (
    <div className="w-full rounded-2xs border-[0.5px] border-solid border-border p-s">
      <div
        className="flex items-center justify-between"
        onClick={() => !(state === 'disabled') && setIsOpen(!isOpen)}
      >
        <div className="flex items-center gap-s">
          <div
            className={classNames(
              'flex items-center justify-center rounded-full w-[40px] aspect-square',
              state === 'disabled' && 'bg-background-hover text-text-tertiary',
              state === 'active' &&
                'border-[2px] border-solid border-border-warning',
              state === 'complete' && 'bg-background-success',
              state === 'active-complete' &&
                'border-[2px] border-solid border-border-brand',
            )}
          >
            <Text variant="title">{index}</Text>
          </div>
          <Text
            variant="content-rubik"
            bold
            className={classNames(state === 'disabled' && 'text-text-tertiary')}
          >
            {title}
          </Text>
        </div>
        <Icon
          icon={light('chevron-down')}
          className={classNames(
            'transition duration-300',
            isOpen && 'rotate-180',
          )}
        />
      </div>
      {isOpen && <div className="mt-s">{children}</div>}
    </div>
  )
}
