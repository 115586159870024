import React from 'react'
import { Tag } from 'src/types'

export type AssetHierarchyValue = {
  id?: number
  setId: (id: number | undefined) => void
  selectedTags: Tag[]
  setSelectedTags: (tags: Tag[]) => void
}

export const AssetHierarchyContext = React.createContext<
  AssetHierarchyValue | undefined
>(undefined)
