import { IRowNode } from '@ag-grid-community/core'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

export function constructRoute(node: IRowNode): string[] {
  const route: string[] = [node.key!]
  let currentNode = node

  while (currentNode.parent?.key) {
    route.unshift(currentNode.parent.key)
    currentNode = currentNode.parent
  }

  return route
}

export function getNodeIcon(type: string): {
  icon: IconProp
  className?: string
} {
  switch (type) {
    case 'VariableOpcNode':
      return {
        icon: light('circle-y'),
      }
    case 'ObjectOpcNode':
      return { icon: light('folder'), className: 'text-icon-warning' }
    default:
      return { icon: light('folder'), className: 'text-icon-warning' }
  }
}
