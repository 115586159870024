export const prettyErrorScore = (n: number): string => {
  const rounded = n.toLocaleString('en', { maximumSignificantDigits: 3 })
  return n < 0.0001 && n > -0.0001
    ? Number(rounded).toExponential().replace('e', ' * 10^')
    : rounded
}

export const prettyTooltipValue = (n: number): string =>
  n > 10000
    ? `${n.toFixed(0)}`
    : `${n?.toPrecision(5)}`.replace(/0*$/g, '').replace(/\.$/, '')

export const format = (n: undefined | null | number): null | string => {
  if (n === undefined || n === null || Number.isNaN(n) || !Number.isFinite(n))
    return null

  if (Math.abs(n) < 1) {
    const string = n.toLocaleString('en', { maximumSignificantDigits: 2 })
    if (string.length > 6) {
      return Number(string).toExponential()
    }
    return string
  }
  const string = n.toLocaleString('en').replace(/,/g, '')
  if (string.length > 6) {
    return Number(
      n.toLocaleString('en', { maximumSignificantDigits: 2 }).replace(/,/g, ''),
    ).toExponential()
  }
  return string
}
