import ReactSlider from 'react-slider'
import classNames from 'classnames'
import { GqlThresholdKind } from 'src/services'

const Thumb = (props: any, { value }: any): JSX.Element => {
  const propsWithClassNames = {
    ...props,
    className: classNames(
      props.className,
      '-ml-xs flex h-[20px] w-[20px] cursor-grab items-center bg-background rounded-full shadow-threshold-slider-thumb outline-none',
    ),
  }
  return (
    <div key={props.key}>
      <div
        className={classNames(
          'absolute',
          value > 0.5
            ? 'translate-x-[calc(-100%-16px)] translate-y-1/2'
            : 'translate-x-[calc(-100%-16px)] -translate-y-1/2',
        )}
        style={{
          bottom: props.style.bottom,
        }}
      >
        {value}
      </div>
      <div {...propsWithClassNames} />
    </div>
  )
}

const Track = (
  props: any,
  state: any,
  thresholdType: GqlThresholdKind,
): JSX.Element => {
  const propsWithClassNames = {
    ...props,
    className: classNames(
      props.className,
      'w-2xs my-[10px]',
      thresholdType === 'UPPER' &&
        state.index === 0 &&
        'bg-background-brand-tertiary',
      thresholdType === 'UPPER' && state.index === 1 && 'bg-background-danger',
      thresholdType === 'LOWER' && state.index === 0 && 'bg-background-danger',
      thresholdType === 'LOWER' &&
        state.index === 1 &&
        'bg-background-brand-tertiary',
    ),
  }
  return <div {...propsWithClassNames} />
}

type ThresholdSliderProps = {
  value: number
  onChange: (value: number) => void
  yAxisRange: {
    min: number
    max: number
  }
  thresholdType: GqlThresholdKind
}
const ThresholdSlider = ({
  value,
  onChange,
  yAxisRange,
  thresholdType,
}: ThresholdSliderProps): JSX.Element => {
  return value >= yAxisRange.min && value <= yAxisRange.max ? (
    <div className="absolute inset-y-0 right-2xs flex pb-[20px] pt-[5px]">
      <ReactSlider
        className="my-[-10px]"
        value={value}
        onChange={onChange}
        min={yAxisRange.min}
        max={yAxisRange.max}
        step={0.001}
        orientation="vertical"
        invert={true}
        renderTrack={(props, state) => Track(props, state, thresholdType)}
        renderThumb={Thumb}
      />
    </div>
  ) : (
    <div
      className={classNames(
        'ml-2xs w-2xs absolute top-0 right-0 bottom-0 flex mt-[5px] mb-[20px]',
        value > yAxisRange.max
          ? 'bg-background-success-secondary'
          : 'bg-background-danger',
      )}
    />
  )
}

export default ThresholdSlider
