import { ChartOptions } from 'src/types/chartTypes'
import { encodeCharts, parseCharts } from './chartsEncoding'

// Serializer/Deserializer pairs

export type Serde<T> = {
  serialize: (value: T) => string
  deserialize: (value: string) => T
}

export const idSerde: Serde<string> = {
  serialize: x => x,
  deserialize: x => x,
}

export const jsonSerde: Serde<any> = {
  serialize: JSON.stringify,
  deserialize: JSON.parse,
}

export const chartsSerde: Serde<ChartOptions[]> = {
  serialize: encodeCharts,
  deserialize: parseCharts,
}
