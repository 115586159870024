import { useState, useEffect, useCallback, ReactNode, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { isEqual } from 'lodash'
import { ChartLayoutConfig, ChartOptions } from 'src/types/chartTypes'
import { GqlFactoryTrendViewFragment } from 'src/services'
import { ChartsContext, ChartsContextType } from './ChartsContext'
import {
  encodeCharts,
  parseCharts,
  validateIsFullWidth,
  validateSize,
} from './chartsEncoding'
import * as Charts from './charts.utils'
import { useSessionValue } from './useSessionValue'
import { chartsSerde, idSerde, jsonSerde } from './serde'

// the default config settings if not otherwise specified
const defaultConfig: ChartLayoutConfig = {
  selectedSize: 'medium',
  isFullWidth: false,
}

type State = {
  config: ChartLayoutConfig
  charts: ChartOptions[]
  viewId: string | undefined
}

const defaultState: State = {
  config: defaultConfig,
  charts: [],
  viewId: undefined,
}

type InitializeParams = {
  // from url params
  urlSize: string | null
  urlIsFullWidth: string | null
  urlCharts: string | null
  // from session
  sessionConfig: ChartLayoutConfig | undefined
  sessionCharts: ChartOptions[] | undefined
  sessionTrendViewId: string | undefined
  // trend view
  trendViewState: State | undefined
}

function intitialize({
  urlSize,
  urlIsFullWidth,
  urlCharts,
  sessionConfig,
  sessionCharts,
  sessionTrendViewId,
  trendViewState,
}: InitializeParams): State {
  // Check the URL
  // If there are charts in the URL then we load everything from the URL
  if (urlCharts) {
    const charts = parseCharts(urlCharts)
    const config = {
      selectedSize: validateSize(urlSize),
      isFullWidth: validateIsFullWidth(urlIsFullWidth),
    }
    return {
      charts,
      config,
      viewId: trendViewState?.viewId,
    }
  }

  if (trendViewState) {
    // if we have a trend view and the session matches, use it
    if (sessionCharts && sessionTrendViewId === trendViewState.viewId) {
      return {
        charts: sessionCharts,
        config: sessionConfig ?? defaultConfig,
        viewId: trendViewState.viewId,
      }
    }

    // otherwise, just use the trend view
    return trendViewState
  }

  if (sessionCharts) {
    return {
      charts: sessionCharts,
      config: sessionConfig ?? defaultConfig,
      viewId: sessionTrendViewId,
    }
  }

  // default
  return defaultState
}

function parseTrendView(trendView: GqlFactoryTrendViewFragment): State {
  const { charts, config } = JSON.parse(trendView.data)
  return {
    charts: parseCharts(JSON.stringify(charts)),
    config,
    viewId: trendView.id,
  }
}

interface ChartsProviderProps {
  children: ReactNode
  id: string
  trendView?: GqlFactoryTrendViewFragment
}

export function ChartsProvider({
  children,
  id,
  trendView,
}: ChartsProviderProps): JSX.Element {
  // We use the trend view id as a key for ChartsProvider
  // because we want it to reload on trendview change.
  // See: https://react.dev/learn/you-might-not-need-an-effect#resetting-all-state-when-a-prop-changes
  return (
    <ChartsProviderInner key={trendView?.id} id={id} trendView={trendView}>
      {children}
    </ChartsProviderInner>
  )
}

function ChartsProviderInner({
  children,
  id,
  trendView,
}: ChartsProviderProps): JSX.Element {
  const navigate = useNavigate()

  // load params from url
  const urlParams = new URLSearchParams(window.location.search)

  // session storage values
  const sessionConfig = useSessionValue<ChartLayoutConfig>(
    `${id}-config`,
    jsonSerde,
  )
  const sessionCharts = useSessionValue(`${id}-charts`, chartsSerde)
  const sessionTrendViewId = useSessionValue(`${id}-id`, idSerde)

  // parse the trend view
  // the useMemo shouldn't strictly be necessary here, since we should
  // be reloaded on trendView change
  const trendViewState = useMemo(
    () => (trendView ? parseTrendView(trendView) : undefined),
    [trendView],
  )

  const [state, setState] = useState<State>(() =>
    intitialize({
      urlSize: urlParams.get('size'),
      urlIsFullWidth: urlParams.get('isFullWidth'),
      urlCharts: urlParams.get('charts'),
      sessionConfig: sessionConfig.get(),
      sessionCharts: sessionCharts.get(),
      sessionTrendViewId: sessionTrendViewId.get(),
      trendViewState,
    }),
  )

  const setCharts = useCallback(
    (update: React.SetStateAction<ChartOptions[]>): void =>
      setState(s => {
        const charts = typeof update === 'function' ? update(s.charts) : update
        return { ...s, charts }
      }),
    [],
  )

  const setChartConfig = useCallback(
    (update: React.SetStateAction<ChartLayoutConfig>): void =>
      setState(s => {
        const config = typeof update === 'function' ? update(s.config) : update
        return { ...s, config }
      }),
    [],
  )

  // Whether the current charts or config differ from the saved trend view
  const isViewChanged = useMemo(() => {
    if (trendViewState) {
      return !(
        isEqual(trendViewState.charts, state.charts) &&
        isEqual(trendViewState.config, state.config)
      )
    }
    return false
  }, [state.charts, state.config, trendViewState])

  // make sure the session storage and URL settings match the current state
  useEffect(() => {
    // Update session storage when charts or config change
    if (state.viewId) {
      sessionTrendViewId.set(state.viewId)
    } else {
      sessionTrendViewId.remove()
    }
    sessionCharts.set(state.charts)
    sessionConfig.set(state.config)

    // get the latest version of search params
    const params = new URLSearchParams(window.location.search)

    // Clear charts from the query params
    params.delete('charts')
    params.set('size', state.config.selectedSize)
    params.set('isFullWidth', state.config.isFullWidth.toString())

    if (state.viewId && !trendView) {
      navigate(`${state.viewId}?${params}`)
    } else {
      // this is what `setSearchParams` actually does
      navigate('?' + params)
    }
  }, [
    navigate,
    sessionCharts,
    sessionConfig,
    sessionTrendViewId,
    state.charts,
    state.config,
    state.viewId,
    trendView,
  ])

  const addChart = useCallback(
    (chart: ChartOptions) => {
      setCharts(charts => Charts.addChart(charts, chart))
    },
    [setCharts],
  )

  const removeChart = useCallback(
    (id: number) => {
      setCharts(charts => Charts.removeChart(charts, id))
    },
    [setCharts],
  )

  const setChart = useCallback(
    (id: number, chart: ChartOptions) => {
      setCharts(charts => Charts.updateChart(charts, id, chart))
    },
    [setCharts],
  )

  const setChartOrder = useCallback(
    (newOrder: number[]) => {
      setCharts(charts => {
        // avoid changing the charts object unnecessarily
        const currentOrder = charts.map(chart => chart.id)
        if (isEqual(currentOrder, newOrder)) {
          return charts
        }
        return Charts.reorder(charts, newOrder)
      })
    },
    [setCharts],
  )

  const generateChartUrl = useCallback(() => {
    const params = new URLSearchParams(window.location.search)
    if (state.charts.length > 0) {
      params.set('charts', encodeCharts(state.charts))
    } else {
      params.delete('charts')
    }
    params.set('size', state.config.selectedSize)
    params.set('isFullWidth', state.config.isFullWidth.toString())

    return `${window.location.origin}${window.location.pathname}?${params}`
  }, [state.charts, state.config.selectedSize, state.config.isFullWidth])

  const loadClearView = useCallback(() => {
    if (trendView) {
      navigate('..')
    }
    sessionTrendViewId.remove()
    sessionCharts.remove()
    sessionConfig.remove()
    setState(defaultState)
  }, [navigate, sessionCharts, sessionConfig, sessionTrendViewId, trendView])

  const resetViewChanges = useCallback(() => {
    if (trendViewState) {
      setState(trendViewState)
    } else {
      setState(defaultState)
    }
  }, [trendViewState])

  const contextValue: ChartsContextType = {
    chartConfig: state.config,
    charts: state.charts,
    isViewChanged,
    addChart,
    removeChart,
    setChart,
    setChartOrder,
    setChartConfig,
    generateChartUrl,
    resetViewChanges,
    loadClearView,
  }

  return (
    <ChartsContext.Provider value={contextValue}>
      {children}
    </ChartsContext.Provider>
  )
}
