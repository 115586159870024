import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { TimeSeriesData } from 'src/types'
import * as api from 'src/services'
import { processTimeSeries } from 'src/utility/timeSeries'
import useTimeRange from 'src/contexts/timeRange'
import { timeSeriesRefetch } from 'trend/api'

export function modelAnomalyScoreQuery(
  req: api.FetchModelAnomalyScoreRequest,
): UseQueryOptions<TimeSeriesData[]> {
  return {
    queryKey: ['modelAnomalyScore', req],
    queryFn: async () => {
      const data = await api.fetchModelAnomalyScore(req)
      return processTimeSeries(data)
    },
    refetchInterval: () => timeSeriesRefetch(req.timeRange),
  }
}

interface UseModelAnomalyScore {
  id: string
  errorScoreKind: api.GqlErrorScoreKind
  transform: api.GqlTransform
}

export function useModelAnomalyScore({
  id,
  errorScoreKind,
  transform,
}: UseModelAnomalyScore): UseQueryResult<TimeSeriesData[]> {
  const { timeRange } = useTimeRange()
  const query = modelAnomalyScoreQuery({
    id,
    errorScoreKind,
    transform,
    timeRange,
  })
  return useQuery(query)
}
