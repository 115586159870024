import Highcharts from 'highcharts/highstock'
import { prettyDate, prettyTime } from 'src/utility/time'
import { prettyTooltipValue as format } from 'src/utility/numbers'

Highcharts.wrap(
  Highcharts.Tooltip.prototype,
  'refresh',
  function (this: any, proceed, points, event) {
    if (event === undefined) {
      // drop refresh calls triggered by mouse
      proceed.call(this, points)
    } else if (
      window.location.pathname !== '/trend' &&
      window.location.pathname !== '/forecast' &&
      !window.location.pathname.startsWith('/anomalies') &&
      !window.location.pathname.startsWith('/models')
    ) {
      // unless we're on old pages
      proceed.call(this, points, event)
    }
  },
)

Highcharts.wrap(
  Highcharts.Tooltip.prototype,
  'hide',
  function (this: any, proceed, ...args) {
    // drop hide calls on new pages
    if (
      window.location.pathname !== '/trend' &&
      !window.location.pathname.startsWith('/anomalies')
    ) {
      proceed.call(this, args)
    }
  },
)

function positioner(
  this: Highcharts.Tooltip,
  length: number,
  height: number,
  point: Highcharts.TooltipPositionerPointObject,
): Highcharts.PositionObject {
  const { plotHeight, plotLeft, plotTop } = this.chart
  const padding = 8

  return {
    x:
      point.plotX < 0
        ? plotLeft
        : length + padding > point.plotX
        ? point.plotX + plotLeft + padding
        : point.plotX + plotLeft - (length + padding),
    y: (plotHeight - height) / 2 + plotTop,
  }
}

function formatter(this: Highcharts.TooltipFormatterContextObject): string {
  const subtle = (value: string): string =>
    `<div style="color: gray; font-size: 0.75em">${value}</div>`

  const points = (this.points ?? [])
    .filter(point => point.series.type === 'line')
    .filter(point => point.series.visible)
    .map(point => ({
      color: point.series.userOptions.color,
      // this is a hack because we store extra information in
      // the series object
      unit: (point.series.userOptions as any).engUnit,
      timestamp: point.x,
      value: point.y,
      // use the series index for sorting
      index: point.series.userOptions.index ?? 0,
    }))

  // keep constant order
  points.sort((a, b) => a.index - b.index)

  const timestamp = Math.max(...points.map(({ timestamp }) => timestamp))

  return [
    subtle(prettyDate(timestamp)),
    subtle(prettyTime(timestamp)),
    `<div style="
      display: grid;
      grid-template-columns: auto auto auto;
      column-gap: 0.2em;
    ">`,
    ...points.map(
      ({
        value,
        color,
        unit,
      }) => `<div style="height: 1em; width: 1em; background-color: ${color}; margin-right: 0.2em;"></div>
            <span style="font-weight: bold; justify-self: end;">${format(
              value,
            )}</span>
            <div>${unit && !['None', '-'].includes(unit) ? unit : ''}</div>`,
    ),
    '</div>',
  ].join('')
}

export const trendTooltipOptions: Highcharts.TooltipOptions = {
  animation: false,
  borderRadius: 8,
  borderWidth: 0,
  followPointer: false,
  formatter,
  padding: 6,
  positioner,
  shadow: false,
  shared: true,
  style: {
    color: 'black',
    fontSize: '1em',
  },
  useHTML: true,
}
