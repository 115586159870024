interface Props {
  size?: string | number
}

export function IntelecyIcon({ size = '1em' }: Props): JSX.Element {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="#333333">
      <path d="M7.99482 1.2162C8.33067 1.2162 8.60292 0.943944 8.60292 0.6081C8.60292 0.272256 8.33067 0 7.99482 0C7.65898 0 7.38672 0.272256 7.38672 0.6081C7.38672 0.943944 7.65898 1.2162 7.99482 1.2162Z" />
      <path d="M7.99482 3.6786C8.33067 3.6786 8.60292 3.40635 8.60292 3.0705C8.60292 2.73466 8.33067 2.4624 7.99482 2.4624C7.65898 2.4624 7.38672 2.73466 7.38672 3.0705C7.38672 3.40635 7.65898 3.6786 7.99482 3.6786Z" />
      <path d="M7.99482 6.14101C8.33067 6.14101 8.60292 5.86875 8.60292 5.53291C8.60292 5.19706 8.33067 4.9248 7.99482 4.9248C7.65898 4.9248 7.38672 5.19706 7.38672 5.53291C7.38672 5.86875 7.65898 6.14101 7.99482 6.14101Z" />
      <path d="M7.99482 8.60292C8.33067 8.60292 8.60292 8.33066 8.60292 7.99482C8.60292 7.65897 8.33067 7.38672 7.99482 7.38672C7.65898 7.38672 7.38672 7.65897 7.38672 7.99482C7.38672 8.33066 7.65898 8.60292 7.99482 8.60292Z" />
      <path d="M7.99482 11.0653C8.33067 11.0653 8.60292 10.7931 8.60292 10.4572C8.60292 10.1214 8.33067 9.84912 7.99482 9.84912C7.65898 9.84912 7.38672 10.1214 7.38672 10.4572C7.38672 10.7931 7.65898 11.0653 7.99482 11.0653Z" />
      <path d="M7.99482 13.5277C8.33067 13.5277 8.60292 13.2555 8.60292 12.9196C8.60292 12.5838 8.33067 12.3115 7.99482 12.3115C7.65898 12.3115 7.38672 12.5838 7.38672 12.9196C7.38672 13.2555 7.65898 13.5277 7.99482 13.5277Z" />
      <path d="M7.99482 15.9901C8.33067 15.9901 8.60292 15.7179 8.60292 15.382C8.60292 15.0462 8.33067 14.7739 7.99482 14.7739C7.65898 14.7739 7.38672 15.0462 7.38672 15.382C7.38672 15.7179 7.65898 15.9901 7.99482 15.9901Z" />
      <path d="M5.86176 2.45253C6.1976 2.45253 6.46986 2.18027 6.46986 1.84443C6.46986 1.50858 6.1976 1.23633 5.86176 1.23633C5.52592 1.23633 5.25366 1.50858 5.25366 1.84443C5.25366 2.18027 5.52592 2.45253 5.86176 2.45253Z" />
      <path d="M5.86176 4.91444C6.1976 4.91444 6.46986 4.64218 6.46986 4.30634C6.46986 3.9705 6.1976 3.69824 5.86176 3.69824C5.52592 3.69824 5.25366 3.9705 5.25366 4.30634C5.25366 4.64218 5.52592 4.91444 5.86176 4.91444Z" />
      <path d="M5.86176 7.37684C6.1976 7.37684 6.46986 7.10459 6.46986 6.76874C6.46986 6.4329 6.1976 6.16064 5.86176 6.16064C5.52592 6.16064 5.25366 6.4329 5.25366 6.76874C5.25366 7.10459 5.52592 7.37684 5.86176 7.37684Z" />
      <path d="M5.86176 9.83924C6.1976 9.83924 6.46986 9.56699 6.46986 9.23114C6.46986 8.8953 6.1976 8.62305 5.86176 8.62305C5.52592 8.62305 5.25366 8.8953 5.25366 9.23114C5.25366 9.56699 5.52592 9.83924 5.86176 9.83924Z" />
      <path d="M5.86176 12.3017C6.1976 12.3017 6.46986 12.0294 6.46986 11.6936C6.46986 11.3577 6.1976 11.0854 5.86176 11.0854C5.52592 11.0854 5.25366 11.3577 5.25366 11.6936C5.25366 12.0294 5.52592 12.3017 5.86176 12.3017Z" />
      <path d="M5.86176 14.764C6.1976 14.764 6.46986 14.4918 6.46986 14.156C6.46986 13.8201 6.1976 13.5479 5.86176 13.5479C5.52592 13.5479 5.25366 13.8201 5.25366 14.156C5.25366 14.4918 5.52592 14.764 5.86176 14.764Z" />
      <path d="M3.72822 3.6786C4.06406 3.6786 4.33632 3.40635 4.33632 3.0705C4.33632 2.73466 4.06406 2.4624 3.72822 2.4624C3.39237 2.4624 3.12012 2.73466 3.12012 3.0705C3.12012 3.40635 3.39237 3.6786 3.72822 3.6786Z" />
      <path d="M3.72822 6.14101C4.06406 6.14101 4.33632 5.86875 4.33632 5.53291C4.33632 5.19706 4.06406 4.9248 3.72822 4.9248C3.39237 4.9248 3.12012 5.19706 3.12012 5.53291C3.12012 5.86875 3.39237 6.14101 3.72822 6.14101Z" />
      <path d="M3.72822 8.60292C4.06406 8.60292 4.33632 8.33066 4.33632 7.99482C4.33632 7.65897 4.06406 7.38672 3.72822 7.38672C3.39237 7.38672 3.12012 7.65897 3.12012 7.99482C3.12012 8.33066 3.39237 8.60292 3.72822 8.60292Z" />
      <path d="M3.72822 11.0653C4.06406 11.0653 4.33632 10.7931 4.33632 10.4572C4.33632 10.1214 4.06406 9.84912 3.72822 9.84912C3.39237 9.84912 3.12012 10.1214 3.12012 10.4572C3.12012 10.7931 3.39237 11.0653 3.72822 11.0653Z" />
      <path d="M3.72822 13.5277C4.06406 13.5277 4.33632 13.2555 4.33632 12.9196C4.33632 12.5838 4.06406 12.3115 3.72822 12.3115C3.39237 12.3115 3.12012 12.5838 3.12012 12.9196C3.12012 13.2555 3.39237 13.5277 3.72822 13.5277Z" />
      <path d="M1.60493 4.91444C1.94077 4.91444 2.21303 4.64218 2.21303 4.30634C2.21303 3.9705 1.94077 3.69824 1.60493 3.69824C1.26908 3.69824 0.996826 3.9705 0.996826 4.30634C0.996826 4.64218 1.26908 4.91444 1.60493 4.91444Z" />
      <path d="M1.60493 7.37684C1.94077 7.37684 2.21303 7.10459 2.21303 6.76874C2.21303 6.4329 1.94077 6.16064 1.60493 6.16064C1.26908 6.16064 0.996826 6.4329 0.996826 6.76874C0.996826 7.10459 1.26908 7.37684 1.60493 7.37684Z" />
      <path d="M1.60493 9.83924C1.94077 9.83924 2.21303 9.56699 2.21303 9.23114C2.21303 8.8953 1.94077 8.62305 1.60493 8.62305C1.26908 8.62305 0.996826 8.8953 0.996826 9.23114C0.996826 9.56699 1.26908 9.83924 1.60493 9.83924Z" />
      <path d="M1.60493 12.3017C1.94077 12.3017 2.21303 12.0294 2.21303 11.6936C2.21303 11.3577 1.94077 11.0854 1.60493 11.0854C1.26908 11.0854 0.996826 11.3577 0.996826 11.6936C0.996826 12.0294 1.26908 12.3017 1.60493 12.3017Z" />
      <path d="M10.1284 2.45253C10.4642 2.45253 10.7365 2.18027 10.7365 1.84443C10.7365 1.50858 10.4642 1.23633 10.1284 1.23633C9.79252 1.23633 9.52026 1.50858 9.52026 1.84443C9.52026 2.18027 9.79252 2.45253 10.1284 2.45253Z" />
      <path d="M10.1284 4.91444C10.4642 4.91444 10.7365 4.64218 10.7365 4.30634C10.7365 3.9705 10.4642 3.69824 10.1284 3.69824C9.79252 3.69824 9.52026 3.9705 9.52026 4.30634C9.52026 4.64218 9.79252 4.91444 10.1284 4.91444Z" />
      <path d="M10.1284 7.37684C10.4642 7.37684 10.7365 7.10459 10.7365 6.76874C10.7365 6.4329 10.4642 6.16064 10.1284 6.16064C9.79252 6.16064 9.52026 6.4329 9.52026 6.76874C9.52026 7.10459 9.79252 7.37684 10.1284 7.37684Z" />
      <path d="M10.1284 9.83924C10.4642 9.83924 10.7365 9.56699 10.7365 9.23114C10.7365 8.8953 10.4642 8.62305 10.1284 8.62305C9.79252 8.62305 9.52026 8.8953 9.52026 9.23114C9.52026 9.56699 9.79252 9.83924 10.1284 9.83924Z" />
      <path d="M10.1284 12.3017C10.4642 12.3017 10.7365 12.0294 10.7365 11.6936C10.7365 11.3577 10.4642 11.0854 10.1284 11.0854C9.79252 11.0854 9.52026 11.3577 9.52026 11.6936C9.52026 12.0294 9.79252 12.3017 10.1284 12.3017Z" />
      <path d="M10.1284 14.764C10.4642 14.764 10.7365 14.4918 10.7365 14.156C10.7365 13.8201 10.4642 13.5479 10.1284 13.5479C9.79252 13.5479 9.52026 13.8201 9.52026 14.156C9.52026 14.4918 9.79252 14.764 10.1284 14.764Z" />
      <path d="M12.2617 3.6786C12.5975 3.6786 12.8698 3.40635 12.8698 3.0705C12.8698 2.73466 12.5975 2.4624 12.2617 2.4624C11.9258 2.4624 11.6536 2.73466 11.6536 3.0705C11.6536 3.40635 11.9258 3.6786 12.2617 3.6786Z" />
      <path d="M12.2617 6.14101C12.5975 6.14101 12.8698 5.86875 12.8698 5.53291C12.8698 5.19706 12.5975 4.9248 12.2617 4.9248C11.9258 4.9248 11.6536 5.19706 11.6536 5.53291C11.6536 5.86875 11.9258 6.14101 12.2617 6.14101Z" />
      <path d="M12.2617 8.60292C12.5975 8.60292 12.8698 8.33066 12.8698 7.99482C12.8698 7.65897 12.5975 7.38672 12.2617 7.38672C11.9258 7.38672 11.6536 7.65897 11.6536 7.99482C11.6536 8.33066 11.9258 8.60292 12.2617 8.60292Z" />
      <path d="M12.2617 11.0653C12.5975 11.0653 12.8698 10.7931 12.8698 10.4572C12.8698 10.1214 12.5975 9.84912 12.2617 9.84912C11.9258 9.84912 11.6536 10.1214 11.6536 10.4572C11.6536 10.7931 11.9258 11.0653 12.2617 11.0653Z" />
      <path d="M12.2617 13.5277C12.5975 13.5277 12.8698 13.2555 12.8698 12.9196C12.8698 12.5838 12.5975 12.3115 12.2617 12.3115C11.9258 12.3115 11.6536 12.5838 11.6536 12.9196C11.6536 13.2555 11.9258 13.5277 12.2617 13.5277Z" />
      <path d="M14.395 4.91444C14.7308 4.91444 15.0031 4.64218 15.0031 4.30634C15.0031 3.9705 14.7308 3.69824 14.395 3.69824C14.0591 3.69824 13.7869 3.9705 13.7869 4.30634C13.7869 4.64218 14.0591 4.91444 14.395 4.91444Z" />
      <path d="M14.395 7.37684C14.7308 7.37684 15.0031 7.10459 15.0031 6.76874C15.0031 6.4329 14.7308 6.16064 14.395 6.16064C14.0591 6.16064 13.7869 6.4329 13.7869 6.76874C13.7869 7.10459 14.0591 7.37684 14.395 7.37684Z" />
      <path d="M14.395 9.83924C14.7308 9.83924 15.0031 9.56699 15.0031 9.23114C15.0031 8.8953 14.7308 8.62305 14.395 8.62305C14.0591 8.62305 13.7869 8.8953 13.7869 9.23114C13.7869 9.56699 14.0591 9.83924 14.395 9.83924Z" />
      <path d="M14.395 12.3017C14.7308 12.3017 15.0031 12.0294 15.0031 11.6936C15.0031 11.3577 14.7308 11.0854 14.395 11.0854C14.0591 11.0854 13.7869 11.3577 13.7869 11.6936C13.7869 12.0294 14.0591 12.3017 14.395 12.3017Z" />
    </svg>
  )
}
