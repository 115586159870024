import { isDefined } from 'src/types'
import { graphqlApi } from '../graphQL'
import {
  GqlAddAnomalyCommentInput,
  GqlAnomalyCommentBaseFragment,
  GqlAnomalyCountByStateQueryVariables,
  GqlAnomalyState,
  GqlFetchAnomalyFragment,
  GqlLatestCommentFragment,
  GqlSetAnomalyStateInput,
} from '../graphqlTypes'
import { Anomaly, AnomalyBase, LatestComment } from './anomaly.types'
import {
  mapApiToAnomaly,
  mapApiToBaseAnomaly,
  mapApiToLatestComment,
} from './anomaly.mappers'

export type StateCount = {
  state: GqlAnomalyState
  count: number
}

export async function fetchAnomalyCountByState(
  vars: GqlAnomalyCountByStateQueryVariables,
): Promise<StateCount[]> {
  const api = await graphqlApi()
  const response = await api.AnomalyCountByState(vars)
  return response.factory?.anomalyCountByState ?? []
}

export async function fetchAnomalies(
  factory: string,
  from: string,
  to: string,
): Promise<AnomalyBase[]> {
  const api = await graphqlApi()
  const res = await api.FetchAnomalies({ factory, from, to })
  return (
    res.factory?.anomalies?.edges?.map(e => e?.node).filter(isDefined) ?? []
  ).map(mapApiToBaseAnomaly)
}

export async function fetchAnomaly(
  factory: string,
  anomaly: string,
): Promise<Anomaly | undefined> {
  const api = await graphqlApi()
  const res = await api.FetchAnomaly({ factory, anomaly })
  const apiAnomaly = res.factory?.anomaly
  return apiAnomaly ? mapApiToAnomaly(apiAnomaly) : undefined
}

export async function fetchLatestAnomalyComments(
  factory: string,
): Promise<LatestComment[]> {
  const api = await graphqlApi()
  const res = await api.FetchLatestComments({ factory })
  return res.factory?.latestAnomalyComments.map(mapApiToLatestComment) ?? []
}

export async function addAnomalyComment(
  input: GqlAddAnomalyCommentInput,
): Promise<GqlAnomalyCommentBaseFragment | undefined | null> {
  const api = await graphqlApi()
  const res = await api.AddAnomalyComment({ input })
  return res.addAnomalyComment?.comment
}

export async function setAnomalyState(
  input: GqlSetAnomalyStateInput,
): Promise<GqlFetchAnomalyFragment | undefined | null> {
  input.state = input.state.replaceAll(' ', '_') as GqlAnomalyState
  const api = await graphqlApi()
  const res = await api.SetAnomalyState({ input })
  return res.setAnomalyState?.anomaly
}
