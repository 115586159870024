import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { TimeSeriesData } from 'src/types'
import * as api from 'src/services'
import { processTimeSeries } from 'src/utility/timeSeries'
import useTimeRange from 'src/contexts/timeRange'
import { timeSeriesRefetch } from 'trend/api'

export function modelPredictionQuery(
  req: api.FetchModelPrediction,
): UseQueryOptions<TimeSeriesData[]> {
  return {
    queryKey: ['modelPrediction', req],
    queryFn: async () => {
      const data = await api.fetchModelPrediction(req)
      return processTimeSeries(data)
    },
    refetchInterval: () => timeSeriesRefetch(req.timeRange),
  }
}

interface UseModelPrediction {
  id: string
}

export function useModelPrediction({
  id,
}: UseModelPrediction): UseQueryResult<TimeSeriesData[]> {
  const { timeRange } = useTimeRange()
  const query = modelPredictionQuery({ id, timeRange })
  return useQuery(query)
}
