import { useFlags } from 'launchdarkly-react-client-sdk'
import { useModal } from 'react-modal-hook'
import { useNavigate, useParams } from 'react-router-dom'
import { sort } from 'semver'
import { useGatewayReleases, useGateways } from 'gateway/api'
import { ErrorDisplay } from 'pages/app'
import { DynamicTable, Spinner, Text } from 'src/components/ui'
import { gatewaysTableConfig } from './site-gateways.config'
import { GatewayReleasesModal } from './GatewayReleasesModal'

export function SiteGateways(): JSX.Element {
  const { orgId, siteId } = useParams()
  if (!siteId || !orgId) throw new Error('route param missing')
  const gatewaysQuery = useGateways(siteId)
  const gatewayReleasesQuery = useGatewayReleases()
  const { gatewayDetails } = useFlags()
  const navigate = useNavigate()

  function handleRowClick(row: any): void {
    if (!gatewayDetails) return
    navigate(`/settings/orgs/${orgId}/sites/${siteId}/gateways/${row.id}`)
  }

  const [showModal, closeModal] = useModal(() => (
    <GatewayReleasesModal onClose={closeModal} />
  ))

  if (gatewaysQuery.isLoading || gatewayReleasesQuery.isLoading) {
    return <Spinner />
  }

  if (gatewaysQuery.isError || gatewayReleasesQuery.isError) {
    const errorQuery = gatewaysQuery.isError
      ? gatewaysQuery
      : gatewayReleasesQuery
    return <ErrorDisplay error={errorQuery.error} action={errorQuery.refetch} />
  }

  const latestVersion = sort(
    gatewayReleasesQuery.data.filter(r => !r.isBeta).map(r => r.semver),
  ).pop()

  function handleUpgradeClick(): void {
    showModal()
  }

  return (
    <div className="flex flex-1 flex-col">
      <div className="my-m">
        <Text variant="description" className="my-xs">
          All of the Intelecy gateways installed at this site.
        </Text>
      </div>
      <DynamicTable
        actions={{
          selectRow: handleRowClick,
        }}
        id={`${siteId}-Gateways`}
        data={gatewaysQuery.data || []}
        config={gatewaysTableConfig(
          handleUpgradeClick,
          orgId,
          siteId,
          latestVersion,
          gatewayDetails,
        )}
      />
    </div>
  )
}
