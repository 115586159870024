import styled, { keyframes } from 'styled-components'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Icon } from 'src/components/ui'

const filterTagInit = keyframes`
  0% {
    opacity: 0;
    top: -20%;
  }
  100% {
    opacity: 100;
  }
`

type CompProps = {
  small: boolean
}

const Comp = styled.div<CompProps>`
  height: ${props => (props.small ? 'auto' : '40px')};
  font-size: ${props => (props.small ? '12px' : '1em')};
  min-width: 85px;
  width: fit-content;
  border-radius: 50px;
  margin: 0 10px 0 0;
  padding: 0 15px;
  display: flex;
  align-items: center;
  color: white;
  animation: ${filterTagInit} 0.3s ease-in-out;
  top: 0;
  left: 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.color || '#545454'};

  & > i:hover {
    cursor: pointer;
    filter: brightness(80%);
    transition: filter 0.2s;
  }
`

const ValueText = styled.span`
  min-width: 40px;
  padding-right: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: clip;
  font-weight: 500;
`

type FilterTagProps = {
  color: string
  value: string
  name?: string // display name
  onClickCross?: (value: string) => void
  small?: boolean
}

export function FilterTag({
  color,
  value,
  name,
  onClickCross = () => {},
  small = false,
}: FilterTagProps): JSX.Element {
  return (
    <Comp small={small} color={color}>
      <ValueText>{name || value}</ValueText>
      {onClickCross && (
        <Icon
          icon={light('times')}
          size="big"
          onClick={() => onClickCross(value)}
        />
      )}
    </Comp>
  )
}
