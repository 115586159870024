import { Organization, Site } from 'src/types'

export function siteCompare(siteA: Site, siteB: Site): number {
  if (siteA.orgName.toLowerCase() < siteB.orgName.toLowerCase()) {
    return -1
  } else if (siteA.orgName.toLowerCase() > siteB.orgName.toLowerCase()) {
    return 1
  } else {
    if (siteA.name.toLowerCase() < siteB.name.toLowerCase()) {
      return -1
    } else if (siteA.name.toLowerCase() > siteB.name.toLowerCase()) {
      return 1
    }
    return 0
  }
}

export function orgCompare(orgA: Organization, orgB: Organization): number {
  if (orgA.name.toLowerCase() < orgB.name.toLowerCase()) {
    return -1
  } else if (orgA.name.toLowerCase() > orgB.name.toLowerCase()) {
    return 1
  }
  return 0
}
