import { Theme } from './theme.types'

export const legacyTheme: Theme = {
  background: {
    DEFAULT: '#FFFFFF',
    hover: '#ECECEC',
    active: '#106D10',
    secondary: {
      DEFAULT: '#D7E5ED',
      hover: '#CBD5E1',
      active: '#CBD5E1',
    },
    tertiary: {
      DEFAULT: '#D9D9D9',
      hover: '#9CA3AF',
      active: '#333333',
    },
    brand: {
      DEFAULT: '#00DC00', // Replaced with legacy
      hover: '#CCF8CC', // Replaced with legacy
      active: '#106D10',
      secondary: {
        DEFAULT: '#1C961C', // Replaced with legacy
        hover: '#1C961C', // Replaced with legacy
        active: '#106D10',
      },
      tertiary: {
        DEFAULT: '#387DA6',
        hover: '#D1FAE5',
        active: '#D1FAE5',
      },
    },
    success: {
      DEFAULT: '#CCF8CC', // Replaced with legacy
      hover: '#B8DFB8', // Replaced with legacy
      active: '#B8DFB8',
      secondary: {
        DEFAULT: '#387DA6',
        hover: '#6EE7B7',
        active: '#6EE7B7',
      },
      tertiary: {
        DEFAULT: '#ECFDF5',
        hover: '#D1FAE5',
        active: '#D1FAE5',
      },
    },
    warning: {
      DEFAULT: '#FFF1CC', // Replaced with legacy
      hover: '#FFF5D6', // Replaced with legacy
      active: '#FFF5D6',
      secondary: {
        DEFAULT: '#FFF1CC',
        hover: '#FCD34D',
        active: '#FCD34D',
      },
      tertiary: {
        DEFAULT: '#FFFBEB',
        hover: '#FEF3C7',
        active: '#FEF3C7',
      },
    },
    danger: {
      DEFAULT: '#F02020', // Replaced with legacy
      hover: '#C11515', // Replaced with legacy
      active: '#C11515',
      secondary: {
        DEFAULT: '#F9BFBF',
        hover: '#FCA5A5',
        active: '#FCA5A5',
      },
      tertiary: {
        DEFAULT: '#E89D9D',
        hover: '#FCD2D2',
        active: '#FCD2D2',
      },
    },
    disabled: {
      DEFAULT: '#D9D9D9',
      light: '#F5F5F5',
    },
  },
  text: {
    DEFAULT: '#000000', // Replaced with legacy
    secondary: '#333333', // Replaced with legacy
    tertiary: '#ABABAB', // Replaced with legacy
    brand: {
      DEFAULT: '#00DC00', // Replaced with legacy
      secondary: '#1C961C', // Replaced with legacy
      tertiary: '#10B981',
      onbrand: {
        DEFAULT: '#106D10',
        secondary: '#064E3B',
        tertiary: '#065F46',
      },
    },
    success: {
      DEFAULT: '#1C961C', // Replaced with legacy
      secondary: '#387DA6', // Replaced with legacy
      tertiary: '#10B981',
      onbrand: {
        DEFAULT: '#FFFFFF',
        secondary: '#065F46',
        tertiary: '#065F46',
      },
    },
    warning: {
      DEFAULT: '#FFB800', // Replaced with legacy
      secondary: '#B45309',
      tertiary: '#D97706',
      onbrand: {
        DEFAULT: '#451A03',
        secondary: '#92400E',
        tertiary: '#78350F',
      },
    },
    danger: {
      DEFAULT: '#F02020', // Replaced with legacy
      secondary: '#C11515', // Replaced with legacy
      tertiary: '#EF4444',
      onbrand: {
        DEFAULT: '#FEE2E2',
        secondary: '#B91C1C',
        tertiary: '#B91C1C',
      },
    },
    disabled: {
      DEFAULT: '#D9D9D9', // Replaced with legacy
      ondisabled: '#9CA3AF',
    },
  },
  border: {
    DEFAULT: '#ECECEC', // Replaced with legacy
    secondary: '#ABABAB', // Replaced with legacy
    tertiary: '#000000', // Replaced with legacy
    brand: {
      DEFAULT: '#00DC00', // Replaced with legacy
      secondary: '#CCF8CC', // Replaced with legacy
      tertiary: '#1C961C', // Replaced with legacy
    },
    success: {
      DEFAULT: '#00DC00', // Replaced with legacy
      secondary: '#387DA6', // Replaced with legacy
      tertiary: '#10B981',
    },
    warning: {
      DEFAULT: '#FFB800', // Replaced with legacy
      secondary: '#B45309',
      tertiary: '#D97706',
    },
    danger: {
      DEFAULT: '#F02020', // Replaced with legacy
      secondary: '#DC2626',
      tertiary: '#EF4444',
    },
    disabled: '#D9D9D9',
  },
  icon: {
    DEFAULT: '#111827',
    secondary: '#D9D9D9', // Replaced with legacy
    tertiary: '#9CA3AF',
    brand: {
      DEFAULT: '#00DC00', // Replaced with legacy
      secondary: '#059669',
      tertiary: '#10B981',
      onbrand: {
        DEFAULT: '#D1FAE5',
        secondary: '#065F46',
        tertiary: '#064E3B',
      },
    },
    success: {
      DEFAULT: '#00DC00', // Replaced with legacy
      secondary: '#387DA6', // Replaced with legacy
      tertiary: '#10B981',
      onbrand: {
        DEFAULT: '#D1FAE5',
        secondary: '#065F46',
        tertiary: '#064E3B',
      },
    },
    warning: {
      DEFAULT: '#FFB800', // Replaced with legacy
      secondary: '#E89D9D',
      tertiary: '#D97706',
      onbrand: {
        DEFAULT: '#451A03',
        secondary: '#92400E',
        tertiary: '#78350F',
      },
    },
    danger: {
      DEFAULT: '#F02020', // Replaced with legacy
      secondary: '#E89D9D', // Replaced with legacy
      tertiary: '#EF4444',
      onbrand: {
        DEFAULT: '#FEE2E2',
        secondary: '#B91C1C',
        tertiary: '#B91C1C',
      },
    },
    disabled: {
      DEFAULT: '#9CA3AF',
      ondisabled: '#9CA3AF',
    },
  },
}
