// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.1
//   protoc               v5.26.1
// source: time_range.proto

/* eslint-disable */
import _m0 from 'protobufjs/minimal'
import { Meta } from './common'
import { Duration } from './google/protobuf/duration'
import { Timestamp } from './google/protobuf/timestamp'

export const protobufPackage = 'types.v1'

export interface TimeRangeStatic {
  start: Date | undefined
  end: Date | undefined
}

export interface TimeRangeRelativeToNow {
  duration: Duration | undefined
}

export interface TimeRangeDef {
  label: string
  description: string
  kind?:
    | { $case: 'static'; value: TimeRangeStatic }
    | { $case: 'relative'; value: TimeRangeRelativeToNow }
    | undefined
}

export interface SavedTimeRange {
  id: string
  meta: Meta | undefined
  trDef: TimeRangeDef | undefined
}

/** A TimeSeries is either a reference to a "saved" time series, _or_ an inline definition */
export interface TimeRange {
  kind?:
    | { $case: 'trRef'; value: string }
    | { $case: 'trDef'; value: TimeRangeDef }
    | undefined
}

function createBaseTimeRangeStatic(): TimeRangeStatic {
  return { start: undefined, end: undefined }
}

export const TimeRangeStatic = {
  encode(
    message: TimeRangeStatic,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.start !== undefined) {
      Timestamp.encode(
        toTimestamp(message.start),
        writer.uint32(10).fork(),
      ).ldelim()
    }
    if (message.end !== undefined) {
      Timestamp.encode(
        toTimestamp(message.end),
        writer.uint32(18).fork(),
      ).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TimeRangeStatic {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseTimeRangeStatic()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.start = fromTimestamp(
            Timestamp.decode(reader, reader.uint32()),
          )
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.end = fromTimestamp(Timestamp.decode(reader, reader.uint32()))
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): TimeRangeStatic {
    return {
      start: isSet(object.start) ? fromJsonTimestamp(object.start) : undefined,
      end: isSet(object.end) ? fromJsonTimestamp(object.end) : undefined,
    }
  },

  toJSON(message: TimeRangeStatic): unknown {
    const obj: any = {}
    if (message.start !== undefined) {
      obj.start = message.start.toISOString()
    }
    if (message.end !== undefined) {
      obj.end = message.end.toISOString()
    }
    return obj
  },

  create<I extends Exact<DeepPartial<TimeRangeStatic>, I>>(
    base?: I,
  ): TimeRangeStatic {
    return TimeRangeStatic.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<TimeRangeStatic>, I>>(
    object: I,
  ): TimeRangeStatic {
    const message = createBaseTimeRangeStatic()
    message.start = object.start ?? undefined
    message.end = object.end ?? undefined
    return message
  },
}

function createBaseTimeRangeRelativeToNow(): TimeRangeRelativeToNow {
  return { duration: undefined }
}

export const TimeRangeRelativeToNow = {
  encode(
    message: TimeRangeRelativeToNow,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.duration !== undefined) {
      Duration.encode(message.duration, writer.uint32(10).fork()).ldelim()
    }
    return writer
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): TimeRangeRelativeToNow {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseTimeRangeRelativeToNow()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.duration = Duration.decode(reader, reader.uint32())
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): TimeRangeRelativeToNow {
    return {
      duration: isSet(object.duration)
        ? Duration.fromJSON(object.duration)
        : undefined,
    }
  },

  toJSON(message: TimeRangeRelativeToNow): unknown {
    const obj: any = {}
    if (message.duration !== undefined) {
      obj.duration = Duration.toJSON(message.duration)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<TimeRangeRelativeToNow>, I>>(
    base?: I,
  ): TimeRangeRelativeToNow {
    return TimeRangeRelativeToNow.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<TimeRangeRelativeToNow>, I>>(
    object: I,
  ): TimeRangeRelativeToNow {
    const message = createBaseTimeRangeRelativeToNow()
    message.duration =
      object.duration !== undefined && object.duration !== null
        ? Duration.fromPartial(object.duration)
        : undefined
    return message
  },
}

function createBaseTimeRangeDef(): TimeRangeDef {
  return { label: '', description: '', kind: undefined }
}

export const TimeRangeDef = {
  encode(
    message: TimeRangeDef,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.label !== '') {
      writer.uint32(10).string(message.label)
    }
    if (message.description !== '') {
      writer.uint32(18).string(message.description)
    }
    switch (message.kind?.$case) {
      case 'static':
        TimeRangeStatic.encode(
          message.kind.value,
          writer.uint32(802).fork(),
        ).ldelim()
        break
      case 'relative':
        TimeRangeRelativeToNow.encode(
          message.kind.value,
          writer.uint32(810).fork(),
        ).ldelim()
        break
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TimeRangeDef {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseTimeRangeDef()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.label = reader.string()
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.description = reader.string()
          continue
        case 100:
          if (tag !== 802) {
            break
          }

          message.kind = {
            $case: 'static',
            value: TimeRangeStatic.decode(reader, reader.uint32()),
          }
          continue
        case 101:
          if (tag !== 810) {
            break
          }

          message.kind = {
            $case: 'relative',
            value: TimeRangeRelativeToNow.decode(reader, reader.uint32()),
          }
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): TimeRangeDef {
    return {
      label: isSet(object.label) ? globalThis.String(object.label) : '',
      description: isSet(object.description)
        ? globalThis.String(object.description)
        : '',
      kind: isSet(object.static)
        ? { $case: 'static', value: TimeRangeStatic.fromJSON(object.static) }
        : isSet(object.relative)
        ? {
            $case: 'relative',
            value: TimeRangeRelativeToNow.fromJSON(object.relative),
          }
        : undefined,
    }
  },

  toJSON(message: TimeRangeDef): unknown {
    const obj: any = {}
    if (message.label !== '') {
      obj.label = message.label
    }
    if (message.description !== '') {
      obj.description = message.description
    }
    if (message.kind?.$case === 'static') {
      obj.static = TimeRangeStatic.toJSON(message.kind.value)
    }
    if (message.kind?.$case === 'relative') {
      obj.relative = TimeRangeRelativeToNow.toJSON(message.kind.value)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<TimeRangeDef>, I>>(
    base?: I,
  ): TimeRangeDef {
    return TimeRangeDef.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<TimeRangeDef>, I>>(
    object: I,
  ): TimeRangeDef {
    const message = createBaseTimeRangeDef()
    message.label = object.label ?? ''
    message.description = object.description ?? ''
    if (
      object.kind?.$case === 'static' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'static',
        value: TimeRangeStatic.fromPartial(object.kind.value),
      }
    }
    if (
      object.kind?.$case === 'relative' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'relative',
        value: TimeRangeRelativeToNow.fromPartial(object.kind.value),
      }
    }
    return message
  },
}

function createBaseSavedTimeRange(): SavedTimeRange {
  return { id: '', meta: undefined, trDef: undefined }
}

export const SavedTimeRange = {
  encode(
    message: SavedTimeRange,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id)
    }
    if (message.meta !== undefined) {
      Meta.encode(message.meta, writer.uint32(18).fork()).ldelim()
    }
    if (message.trDef !== undefined) {
      TimeRangeDef.encode(message.trDef, writer.uint32(26).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SavedTimeRange {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseSavedTimeRange()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.id = reader.string()
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.meta = Meta.decode(reader, reader.uint32())
          continue
        case 3:
          if (tag !== 26) {
            break
          }

          message.trDef = TimeRangeDef.decode(reader, reader.uint32())
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): SavedTimeRange {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : '',
      meta: isSet(object.meta) ? Meta.fromJSON(object.meta) : undefined,
      trDef: isSet(object.tr_def)
        ? TimeRangeDef.fromJSON(object.tr_def)
        : undefined,
    }
  },

  toJSON(message: SavedTimeRange): unknown {
    const obj: any = {}
    if (message.id !== '') {
      obj.id = message.id
    }
    if (message.meta !== undefined) {
      obj.meta = Meta.toJSON(message.meta)
    }
    if (message.trDef !== undefined) {
      obj.tr_def = TimeRangeDef.toJSON(message.trDef)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<SavedTimeRange>, I>>(
    base?: I,
  ): SavedTimeRange {
    return SavedTimeRange.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<SavedTimeRange>, I>>(
    object: I,
  ): SavedTimeRange {
    const message = createBaseSavedTimeRange()
    message.id = object.id ?? ''
    message.meta =
      object.meta !== undefined && object.meta !== null
        ? Meta.fromPartial(object.meta)
        : undefined
    message.trDef =
      object.trDef !== undefined && object.trDef !== null
        ? TimeRangeDef.fromPartial(object.trDef)
        : undefined
    return message
  },
}

function createBaseTimeRange(): TimeRange {
  return { kind: undefined }
}

export const TimeRange = {
  encode(
    message: TimeRange,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    switch (message.kind?.$case) {
      case 'trRef':
        writer.uint32(10).string(message.kind.value)
        break
      case 'trDef':
        TimeRangeDef.encode(
          message.kind.value,
          writer.uint32(18).fork(),
        ).ldelim()
        break
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TimeRange {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseTimeRange()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.kind = { $case: 'trRef', value: reader.string() }
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.kind = {
            $case: 'trDef',
            value: TimeRangeDef.decode(reader, reader.uint32()),
          }
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): TimeRange {
    return {
      kind: isSet(object.tr_ref)
        ? { $case: 'trRef', value: globalThis.String(object.tr_ref) }
        : isSet(object.tr_def)
        ? { $case: 'trDef', value: TimeRangeDef.fromJSON(object.tr_def) }
        : undefined,
    }
  },

  toJSON(message: TimeRange): unknown {
    const obj: any = {}
    if (message.kind?.$case === 'trRef') {
      obj.tr_ref = message.kind.value
    }
    if (message.kind?.$case === 'trDef') {
      obj.tr_def = TimeRangeDef.toJSON(message.kind.value)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<TimeRange>, I>>(base?: I): TimeRange {
    return TimeRange.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<TimeRange>, I>>(
    object: I,
  ): TimeRange {
    const message = createBaseTimeRange()
    if (
      object.kind?.$case === 'trRef' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = { $case: 'trRef', value: object.kind.value }
    }
    if (
      object.kind?.$case === 'trDef' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'trDef',
        value: TimeRangeDef.fromPartial(object.kind.value),
      }
    }
    return message
  },
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends globalThis.Array<infer U>
  ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string; value: unknown }
  ? { $case: T['$case']; value?: DeepPartial<T['value']> }
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>

type KeysOfUnion<T> = T extends T ? keyof T : never
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & {
      [K in Exclude<keyof I, KeysOfUnion<P>>]: never
    }

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000)
  const nanos = (date.getTime() % 1_000) * 1_000_000
  return { seconds, nanos }
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000
  millis += (t.nanos || 0) / 1_000_000
  return new globalThis.Date(millis)
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o
  } else if (typeof o === 'string') {
    return new globalThis.Date(o)
  } else {
    return fromTimestamp(Timestamp.fromJSON(o))
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined
}
