// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.1
//   protoc               v5.26.1
// source: segment_series.proto

/* eslint-disable */
import _m0 from 'protobufjs/minimal'
import { Meta } from './common'
import { Segmentation, SegmentationModification } from './segmentation'
import { TimeSeries } from './time_series'

export const protobufPackage = 'types.v1'

export enum IntrinsicProperty {
  INTRINSIC_PROPERTY_UNSPECIFIED = 0,
  INTRINSIC_PROPERTY_START = 1,
  INTRINSIC_PROPERTY_END = 2,
  INTRINSIC_PROPERTY_DURATION_SECONDS = 4,
  INTRINSIC_PROPERTY_DURATION_MINUTES = 5,
  INTRINSIC_PROPERTY_DURATION_HOURS = 6,
  INTRINSIC_PROPERTY_DURATION_DAYS = 7,
  INTRINSIC_PROPERTY_ORDINAL_NUMBER = 8,
  UNRECOGNIZED = -1,
}

export function intrinsicPropertyFromJSON(object: any): IntrinsicProperty {
  switch (object) {
    case 0:
    case 'INTRINSIC_PROPERTY_UNSPECIFIED':
      return IntrinsicProperty.INTRINSIC_PROPERTY_UNSPECIFIED
    case 1:
    case 'INTRINSIC_PROPERTY_START':
      return IntrinsicProperty.INTRINSIC_PROPERTY_START
    case 2:
    case 'INTRINSIC_PROPERTY_END':
      return IntrinsicProperty.INTRINSIC_PROPERTY_END
    case 4:
    case 'INTRINSIC_PROPERTY_DURATION_SECONDS':
      return IntrinsicProperty.INTRINSIC_PROPERTY_DURATION_SECONDS
    case 5:
    case 'INTRINSIC_PROPERTY_DURATION_MINUTES':
      return IntrinsicProperty.INTRINSIC_PROPERTY_DURATION_MINUTES
    case 6:
    case 'INTRINSIC_PROPERTY_DURATION_HOURS':
      return IntrinsicProperty.INTRINSIC_PROPERTY_DURATION_HOURS
    case 7:
    case 'INTRINSIC_PROPERTY_DURATION_DAYS':
      return IntrinsicProperty.INTRINSIC_PROPERTY_DURATION_DAYS
    case 8:
    case 'INTRINSIC_PROPERTY_ORDINAL_NUMBER':
      return IntrinsicProperty.INTRINSIC_PROPERTY_ORDINAL_NUMBER
    case -1:
    case 'UNRECOGNIZED':
    default:
      return IntrinsicProperty.UNRECOGNIZED
  }
}

export function intrinsicPropertyToJSON(object: IntrinsicProperty): string {
  switch (object) {
    case IntrinsicProperty.INTRINSIC_PROPERTY_UNSPECIFIED:
      return 'INTRINSIC_PROPERTY_UNSPECIFIED'
    case IntrinsicProperty.INTRINSIC_PROPERTY_START:
      return 'INTRINSIC_PROPERTY_START'
    case IntrinsicProperty.INTRINSIC_PROPERTY_END:
      return 'INTRINSIC_PROPERTY_END'
    case IntrinsicProperty.INTRINSIC_PROPERTY_DURATION_SECONDS:
      return 'INTRINSIC_PROPERTY_DURATION_SECONDS'
    case IntrinsicProperty.INTRINSIC_PROPERTY_DURATION_MINUTES:
      return 'INTRINSIC_PROPERTY_DURATION_MINUTES'
    case IntrinsicProperty.INTRINSIC_PROPERTY_DURATION_HOURS:
      return 'INTRINSIC_PROPERTY_DURATION_HOURS'
    case IntrinsicProperty.INTRINSIC_PROPERTY_DURATION_DAYS:
      return 'INTRINSIC_PROPERTY_DURATION_DAYS'
    case IntrinsicProperty.INTRINSIC_PROPERTY_ORDINAL_NUMBER:
      return 'INTRINSIC_PROPERTY_ORDINAL_NUMBER'
    case IntrinsicProperty.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED'
  }
}

export enum Aggregation {
  AGGREGATION_UNSPECIFIED = 0,
  AGGREGATION_FIRST = 1,
  AGGREGATION_LAST = 2,
  AGGREGATION_MIN = 3,
  AGGREGATION_MAX = 4,
  AGGREGATION_MEAN = 5,
  AGGREGATION_STD = 6,
  AGGREGATION_MAD = 7,
  AGGREGATION_SKEW = 8,
  AGGREGATION_CHANGE = 9,
  AGGREGATION_SPAN = 10,
  AGGREGATION_MEDIAN = 11,
  AGGREGATION_AUC_SECONDS = 12,
  AGGREGATION_AUC_HOURS = 13,
  UNRECOGNIZED = -1,
}

export function aggregationFromJSON(object: any): Aggregation {
  switch (object) {
    case 0:
    case 'AGGREGATION_UNSPECIFIED':
      return Aggregation.AGGREGATION_UNSPECIFIED
    case 1:
    case 'AGGREGATION_FIRST':
      return Aggregation.AGGREGATION_FIRST
    case 2:
    case 'AGGREGATION_LAST':
      return Aggregation.AGGREGATION_LAST
    case 3:
    case 'AGGREGATION_MIN':
      return Aggregation.AGGREGATION_MIN
    case 4:
    case 'AGGREGATION_MAX':
      return Aggregation.AGGREGATION_MAX
    case 5:
    case 'AGGREGATION_MEAN':
      return Aggregation.AGGREGATION_MEAN
    case 6:
    case 'AGGREGATION_STD':
      return Aggregation.AGGREGATION_STD
    case 7:
    case 'AGGREGATION_MAD':
      return Aggregation.AGGREGATION_MAD
    case 8:
    case 'AGGREGATION_SKEW':
      return Aggregation.AGGREGATION_SKEW
    case 9:
    case 'AGGREGATION_CHANGE':
      return Aggregation.AGGREGATION_CHANGE
    case 10:
    case 'AGGREGATION_SPAN':
      return Aggregation.AGGREGATION_SPAN
    case 11:
    case 'AGGREGATION_MEDIAN':
      return Aggregation.AGGREGATION_MEDIAN
    case 12:
    case 'AGGREGATION_AUC_SECONDS':
      return Aggregation.AGGREGATION_AUC_SECONDS
    case 13:
    case 'AGGREGATION_AUC_HOURS':
      return Aggregation.AGGREGATION_AUC_HOURS
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Aggregation.UNRECOGNIZED
  }
}

export function aggregationToJSON(object: Aggregation): string {
  switch (object) {
    case Aggregation.AGGREGATION_UNSPECIFIED:
      return 'AGGREGATION_UNSPECIFIED'
    case Aggregation.AGGREGATION_FIRST:
      return 'AGGREGATION_FIRST'
    case Aggregation.AGGREGATION_LAST:
      return 'AGGREGATION_LAST'
    case Aggregation.AGGREGATION_MIN:
      return 'AGGREGATION_MIN'
    case Aggregation.AGGREGATION_MAX:
      return 'AGGREGATION_MAX'
    case Aggregation.AGGREGATION_MEAN:
      return 'AGGREGATION_MEAN'
    case Aggregation.AGGREGATION_STD:
      return 'AGGREGATION_STD'
    case Aggregation.AGGREGATION_MAD:
      return 'AGGREGATION_MAD'
    case Aggregation.AGGREGATION_SKEW:
      return 'AGGREGATION_SKEW'
    case Aggregation.AGGREGATION_CHANGE:
      return 'AGGREGATION_CHANGE'
    case Aggregation.AGGREGATION_SPAN:
      return 'AGGREGATION_SPAN'
    case Aggregation.AGGREGATION_MEDIAN:
      return 'AGGREGATION_MEDIAN'
    case Aggregation.AGGREGATION_AUC_SECONDS:
      return 'AGGREGATION_AUC_SECONDS'
    case Aggregation.AGGREGATION_AUC_HOURS:
      return 'AGGREGATION_AUC_HOURS'
    case Aggregation.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED'
  }
}

export interface SegmentSeries {
  kind?:
    | { $case: 'segSRef'; value: string }
    | { $case: 'segSDef'; value: SegmentSeriesDef }
    | undefined
}

export interface SavedSegmentSeries {
  id: string
  meta: Meta | undefined
  tsDef: SegmentSeriesDef | undefined
}

export interface SegmentSeriesDef {
  label: string
  description: string
  data: SegmentSeriesData | undefined
}

export interface SegmentSeriesData {
  segmentation: Segmentation | undefined
  property: SegmentProperty | undefined
}

export interface SegmentProperty {
  label: string
  description: string
  kind?:
    | { $case: 'tsAgg'; value: TsAgg }
    | { $case: 'intrinsicProperty'; value: IntrinsicProperty }
    | { $case: 'expression'; value: SegmentPropertyExpression }
    | { $case: 'withMod'; value: PropertyWithSegmentationModification }
    | undefined
}

export interface TsAgg {
  ts: TimeSeries | undefined
  agg: Aggregation
}

export interface SegmentPropertyExpression {
  dependencies: { [key: string]: SegmentProperty }
  expression: string
  filter?:
    | { $case: 'filterExpression'; value: string }
    | { $case: 'filterSegmentProperty'; value: SegmentProperty }
    | undefined
}

export interface SegmentPropertyExpression_DependenciesEntry {
  key: string
  value: SegmentProperty | undefined
}

export interface PropertyWithSegmentationModification {
  modification: SegmentationModification | undefined
  coreProperty: SegmentProperty | undefined
}

function createBaseSegmentSeries(): SegmentSeries {
  return { kind: undefined }
}

export const SegmentSeries = {
  encode(
    message: SegmentSeries,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    switch (message.kind?.$case) {
      case 'segSRef':
        writer.uint32(10).string(message.kind.value)
        break
      case 'segSDef':
        SegmentSeriesDef.encode(
          message.kind.value,
          writer.uint32(18).fork(),
        ).ldelim()
        break
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SegmentSeries {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseSegmentSeries()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.kind = { $case: 'segSRef', value: reader.string() }
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.kind = {
            $case: 'segSDef',
            value: SegmentSeriesDef.decode(reader, reader.uint32()),
          }
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): SegmentSeries {
    return {
      kind: isSet(object.seg_s_ref)
        ? { $case: 'segSRef', value: globalThis.String(object.seg_s_ref) }
        : isSet(object.seg_s_def)
        ? {
            $case: 'segSDef',
            value: SegmentSeriesDef.fromJSON(object.seg_s_def),
          }
        : undefined,
    }
  },

  toJSON(message: SegmentSeries): unknown {
    const obj: any = {}
    if (message.kind?.$case === 'segSRef') {
      obj.seg_s_ref = message.kind.value
    }
    if (message.kind?.$case === 'segSDef') {
      obj.seg_s_def = SegmentSeriesDef.toJSON(message.kind.value)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<SegmentSeries>, I>>(
    base?: I,
  ): SegmentSeries {
    return SegmentSeries.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<SegmentSeries>, I>>(
    object: I,
  ): SegmentSeries {
    const message = createBaseSegmentSeries()
    if (
      object.kind?.$case === 'segSRef' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = { $case: 'segSRef', value: object.kind.value }
    }
    if (
      object.kind?.$case === 'segSDef' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'segSDef',
        value: SegmentSeriesDef.fromPartial(object.kind.value),
      }
    }
    return message
  },
}

function createBaseSavedSegmentSeries(): SavedSegmentSeries {
  return { id: '', meta: undefined, tsDef: undefined }
}

export const SavedSegmentSeries = {
  encode(
    message: SavedSegmentSeries,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id)
    }
    if (message.meta !== undefined) {
      Meta.encode(message.meta, writer.uint32(18).fork()).ldelim()
    }
    if (message.tsDef !== undefined) {
      SegmentSeriesDef.encode(message.tsDef, writer.uint32(26).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SavedSegmentSeries {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseSavedSegmentSeries()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.id = reader.string()
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.meta = Meta.decode(reader, reader.uint32())
          continue
        case 3:
          if (tag !== 26) {
            break
          }

          message.tsDef = SegmentSeriesDef.decode(reader, reader.uint32())
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): SavedSegmentSeries {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : '',
      meta: isSet(object.meta) ? Meta.fromJSON(object.meta) : undefined,
      tsDef: isSet(object.ts_def)
        ? SegmentSeriesDef.fromJSON(object.ts_def)
        : undefined,
    }
  },

  toJSON(message: SavedSegmentSeries): unknown {
    const obj: any = {}
    if (message.id !== '') {
      obj.id = message.id
    }
    if (message.meta !== undefined) {
      obj.meta = Meta.toJSON(message.meta)
    }
    if (message.tsDef !== undefined) {
      obj.ts_def = SegmentSeriesDef.toJSON(message.tsDef)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<SavedSegmentSeries>, I>>(
    base?: I,
  ): SavedSegmentSeries {
    return SavedSegmentSeries.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<SavedSegmentSeries>, I>>(
    object: I,
  ): SavedSegmentSeries {
    const message = createBaseSavedSegmentSeries()
    message.id = object.id ?? ''
    message.meta =
      object.meta !== undefined && object.meta !== null
        ? Meta.fromPartial(object.meta)
        : undefined
    message.tsDef =
      object.tsDef !== undefined && object.tsDef !== null
        ? SegmentSeriesDef.fromPartial(object.tsDef)
        : undefined
    return message
  },
}

function createBaseSegmentSeriesDef(): SegmentSeriesDef {
  return { label: '', description: '', data: undefined }
}

export const SegmentSeriesDef = {
  encode(
    message: SegmentSeriesDef,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.label !== '') {
      writer.uint32(10).string(message.label)
    }
    if (message.description !== '') {
      writer.uint32(18).string(message.description)
    }
    if (message.data !== undefined) {
      SegmentSeriesData.encode(message.data, writer.uint32(26).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SegmentSeriesDef {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseSegmentSeriesDef()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.label = reader.string()
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.description = reader.string()
          continue
        case 3:
          if (tag !== 26) {
            break
          }

          message.data = SegmentSeriesData.decode(reader, reader.uint32())
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): SegmentSeriesDef {
    return {
      label: isSet(object.label) ? globalThis.String(object.label) : '',
      description: isSet(object.description)
        ? globalThis.String(object.description)
        : '',
      data: isSet(object.data)
        ? SegmentSeriesData.fromJSON(object.data)
        : undefined,
    }
  },

  toJSON(message: SegmentSeriesDef): unknown {
    const obj: any = {}
    if (message.label !== '') {
      obj.label = message.label
    }
    if (message.description !== '') {
      obj.description = message.description
    }
    if (message.data !== undefined) {
      obj.data = SegmentSeriesData.toJSON(message.data)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<SegmentSeriesDef>, I>>(
    base?: I,
  ): SegmentSeriesDef {
    return SegmentSeriesDef.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<SegmentSeriesDef>, I>>(
    object: I,
  ): SegmentSeriesDef {
    const message = createBaseSegmentSeriesDef()
    message.label = object.label ?? ''
    message.description = object.description ?? ''
    message.data =
      object.data !== undefined && object.data !== null
        ? SegmentSeriesData.fromPartial(object.data)
        : undefined
    return message
  },
}

function createBaseSegmentSeriesData(): SegmentSeriesData {
  return { segmentation: undefined, property: undefined }
}

export const SegmentSeriesData = {
  encode(
    message: SegmentSeriesData,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.segmentation !== undefined) {
      Segmentation.encode(
        message.segmentation,
        writer.uint32(10).fork(),
      ).ldelim()
    }
    if (message.property !== undefined) {
      SegmentProperty.encode(
        message.property,
        writer.uint32(18).fork(),
      ).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SegmentSeriesData {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseSegmentSeriesData()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.segmentation = Segmentation.decode(reader, reader.uint32())
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.property = SegmentProperty.decode(reader, reader.uint32())
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): SegmentSeriesData {
    return {
      segmentation: isSet(object.segmentation)
        ? Segmentation.fromJSON(object.segmentation)
        : undefined,
      property: isSet(object.property)
        ? SegmentProperty.fromJSON(object.property)
        : undefined,
    }
  },

  toJSON(message: SegmentSeriesData): unknown {
    const obj: any = {}
    if (message.segmentation !== undefined) {
      obj.segmentation = Segmentation.toJSON(message.segmentation)
    }
    if (message.property !== undefined) {
      obj.property = SegmentProperty.toJSON(message.property)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<SegmentSeriesData>, I>>(
    base?: I,
  ): SegmentSeriesData {
    return SegmentSeriesData.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<SegmentSeriesData>, I>>(
    object: I,
  ): SegmentSeriesData {
    const message = createBaseSegmentSeriesData()
    message.segmentation =
      object.segmentation !== undefined && object.segmentation !== null
        ? Segmentation.fromPartial(object.segmentation)
        : undefined
    message.property =
      object.property !== undefined && object.property !== null
        ? SegmentProperty.fromPartial(object.property)
        : undefined
    return message
  },
}

function createBaseSegmentProperty(): SegmentProperty {
  return { label: '', description: '', kind: undefined }
}

export const SegmentProperty = {
  encode(
    message: SegmentProperty,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.label !== '') {
      writer.uint32(10).string(message.label)
    }
    if (message.description !== '') {
      writer.uint32(18).string(message.description)
    }
    switch (message.kind?.$case) {
      case 'tsAgg':
        TsAgg.encode(message.kind.value, writer.uint32(82).fork()).ldelim()
        break
      case 'intrinsicProperty':
        writer.uint32(88).int32(message.kind.value)
        break
      case 'expression':
        SegmentPropertyExpression.encode(
          message.kind.value,
          writer.uint32(98).fork(),
        ).ldelim()
        break
      case 'withMod':
        PropertyWithSegmentationModification.encode(
          message.kind.value,
          writer.uint32(106).fork(),
        ).ldelim()
        break
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SegmentProperty {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseSegmentProperty()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.label = reader.string()
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.description = reader.string()
          continue
        case 10:
          if (tag !== 82) {
            break
          }

          message.kind = {
            $case: 'tsAgg',
            value: TsAgg.decode(reader, reader.uint32()),
          }
          continue
        case 11:
          if (tag !== 88) {
            break
          }

          message.kind = {
            $case: 'intrinsicProperty',
            value: reader.int32() as any,
          }
          continue
        case 12:
          if (tag !== 98) {
            break
          }

          message.kind = {
            $case: 'expression',
            value: SegmentPropertyExpression.decode(reader, reader.uint32()),
          }
          continue
        case 13:
          if (tag !== 106) {
            break
          }

          message.kind = {
            $case: 'withMod',
            value: PropertyWithSegmentationModification.decode(
              reader,
              reader.uint32(),
            ),
          }
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): SegmentProperty {
    return {
      label: isSet(object.label) ? globalThis.String(object.label) : '',
      description: isSet(object.description)
        ? globalThis.String(object.description)
        : '',
      kind: isSet(object.ts_agg)
        ? { $case: 'tsAgg', value: TsAgg.fromJSON(object.ts_agg) }
        : isSet(object.intrinsic_property)
        ? {
            $case: 'intrinsicProperty',
            value: intrinsicPropertyFromJSON(object.intrinsic_property),
          }
        : isSet(object.expression)
        ? {
            $case: 'expression',
            value: SegmentPropertyExpression.fromJSON(object.expression),
          }
        : isSet(object.with_mod)
        ? {
            $case: 'withMod',
            value: PropertyWithSegmentationModification.fromJSON(
              object.with_mod,
            ),
          }
        : undefined,
    }
  },

  toJSON(message: SegmentProperty): unknown {
    const obj: any = {}
    if (message.label !== '') {
      obj.label = message.label
    }
    if (message.description !== '') {
      obj.description = message.description
    }
    if (message.kind?.$case === 'tsAgg') {
      obj.ts_agg = TsAgg.toJSON(message.kind.value)
    }
    if (message.kind?.$case === 'intrinsicProperty') {
      obj.intrinsic_property = intrinsicPropertyToJSON(message.kind.value)
    }
    if (message.kind?.$case === 'expression') {
      obj.expression = SegmentPropertyExpression.toJSON(message.kind.value)
    }
    if (message.kind?.$case === 'withMod') {
      obj.with_mod = PropertyWithSegmentationModification.toJSON(
        message.kind.value,
      )
    }
    return obj
  },

  create<I extends Exact<DeepPartial<SegmentProperty>, I>>(
    base?: I,
  ): SegmentProperty {
    return SegmentProperty.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<SegmentProperty>, I>>(
    object: I,
  ): SegmentProperty {
    const message = createBaseSegmentProperty()
    message.label = object.label ?? ''
    message.description = object.description ?? ''
    if (
      object.kind?.$case === 'tsAgg' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'tsAgg',
        value: TsAgg.fromPartial(object.kind.value),
      }
    }
    if (
      object.kind?.$case === 'intrinsicProperty' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = { $case: 'intrinsicProperty', value: object.kind.value }
    }
    if (
      object.kind?.$case === 'expression' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'expression',
        value: SegmentPropertyExpression.fromPartial(object.kind.value),
      }
    }
    if (
      object.kind?.$case === 'withMod' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'withMod',
        value: PropertyWithSegmentationModification.fromPartial(
          object.kind.value,
        ),
      }
    }
    return message
  },
}

function createBaseTsAgg(): TsAgg {
  return { ts: undefined, agg: 0 }
}

export const TsAgg = {
  encode(message: TsAgg, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ts !== undefined) {
      TimeSeries.encode(message.ts, writer.uint32(10).fork()).ldelim()
    }
    if (message.agg !== 0) {
      writer.uint32(16).int32(message.agg)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TsAgg {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseTsAgg()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.ts = TimeSeries.decode(reader, reader.uint32())
          continue
        case 2:
          if (tag !== 16) {
            break
          }

          message.agg = reader.int32() as any
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): TsAgg {
    return {
      ts: isSet(object.ts) ? TimeSeries.fromJSON(object.ts) : undefined,
      agg: isSet(object.agg) ? aggregationFromJSON(object.agg) : 0,
    }
  },

  toJSON(message: TsAgg): unknown {
    const obj: any = {}
    if (message.ts !== undefined) {
      obj.ts = TimeSeries.toJSON(message.ts)
    }
    if (message.agg !== 0) {
      obj.agg = aggregationToJSON(message.agg)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<TsAgg>, I>>(base?: I): TsAgg {
    return TsAgg.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<TsAgg>, I>>(object: I): TsAgg {
    const message = createBaseTsAgg()
    message.ts =
      object.ts !== undefined && object.ts !== null
        ? TimeSeries.fromPartial(object.ts)
        : undefined
    message.agg = object.agg ?? 0
    return message
  },
}

function createBaseSegmentPropertyExpression(): SegmentPropertyExpression {
  return { dependencies: {}, expression: '', filter: undefined }
}

export const SegmentPropertyExpression = {
  encode(
    message: SegmentPropertyExpression,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    Object.entries(message.dependencies).forEach(([key, value]) => {
      SegmentPropertyExpression_DependenciesEntry.encode(
        { key: key as any, value },
        writer.uint32(10).fork(),
      ).ldelim()
    })
    if (message.expression !== '') {
      writer.uint32(18).string(message.expression)
    }
    switch (message.filter?.$case) {
      case 'filterExpression':
        writer.uint32(82).string(message.filter.value)
        break
      case 'filterSegmentProperty':
        SegmentProperty.encode(
          message.filter.value,
          writer.uint32(90).fork(),
        ).ldelim()
        break
    }
    return writer
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SegmentPropertyExpression {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseSegmentPropertyExpression()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          const entry1 = SegmentPropertyExpression_DependenciesEntry.decode(
            reader,
            reader.uint32(),
          )
          if (entry1.value !== undefined) {
            message.dependencies[entry1.key] = entry1.value
          }
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.expression = reader.string()
          continue
        case 10:
          if (tag !== 82) {
            break
          }

          message.filter = { $case: 'filterExpression', value: reader.string() }
          continue
        case 11:
          if (tag !== 90) {
            break
          }

          message.filter = {
            $case: 'filterSegmentProperty',
            value: SegmentProperty.decode(reader, reader.uint32()),
          }
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): SegmentPropertyExpression {
    return {
      dependencies: isObject(object.dependencies)
        ? Object.entries(object.dependencies).reduce<{
            [key: string]: SegmentProperty
          }>((acc, [key, value]) => {
            acc[key] = SegmentProperty.fromJSON(value)
            return acc
          }, {})
        : {},
      expression: isSet(object.expression)
        ? globalThis.String(object.expression)
        : '',
      filter: isSet(object.filter_expression)
        ? {
            $case: 'filterExpression',
            value: globalThis.String(object.filter_expression),
          }
        : isSet(object.filter_segment_property)
        ? {
            $case: 'filterSegmentProperty',
            value: SegmentProperty.fromJSON(object.filter_segment_property),
          }
        : undefined,
    }
  },

  toJSON(message: SegmentPropertyExpression): unknown {
    const obj: any = {}
    if (message.dependencies) {
      const entries = Object.entries(message.dependencies)
      if (entries.length > 0) {
        obj.dependencies = {}
        entries.forEach(([k, v]) => {
          obj.dependencies[k] = SegmentProperty.toJSON(v)
        })
      }
    }
    if (message.expression !== '') {
      obj.expression = message.expression
    }
    if (message.filter?.$case === 'filterExpression') {
      obj.filter_expression = message.filter.value
    }
    if (message.filter?.$case === 'filterSegmentProperty') {
      obj.filter_segment_property = SegmentProperty.toJSON(message.filter.value)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<SegmentPropertyExpression>, I>>(
    base?: I,
  ): SegmentPropertyExpression {
    return SegmentPropertyExpression.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<SegmentPropertyExpression>, I>>(
    object: I,
  ): SegmentPropertyExpression {
    const message = createBaseSegmentPropertyExpression()
    message.dependencies = Object.entries(object.dependencies ?? {}).reduce<{
      [key: string]: SegmentProperty
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = SegmentProperty.fromPartial(value)
      }
      return acc
    }, {})
    message.expression = object.expression ?? ''
    if (
      object.filter?.$case === 'filterExpression' &&
      object.filter?.value !== undefined &&
      object.filter?.value !== null
    ) {
      message.filter = { $case: 'filterExpression', value: object.filter.value }
    }
    if (
      object.filter?.$case === 'filterSegmentProperty' &&
      object.filter?.value !== undefined &&
      object.filter?.value !== null
    ) {
      message.filter = {
        $case: 'filterSegmentProperty',
        value: SegmentProperty.fromPartial(object.filter.value),
      }
    }
    return message
  },
}

function createBaseSegmentPropertyExpression_DependenciesEntry(): SegmentPropertyExpression_DependenciesEntry {
  return { key: '', value: undefined }
}

export const SegmentPropertyExpression_DependenciesEntry = {
  encode(
    message: SegmentPropertyExpression_DependenciesEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key)
    }
    if (message.value !== undefined) {
      SegmentProperty.encode(message.value, writer.uint32(18).fork()).ldelim()
    }
    return writer
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SegmentPropertyExpression_DependenciesEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseSegmentPropertyExpression_DependenciesEntry()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.key = reader.string()
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.value = SegmentProperty.decode(reader, reader.uint32())
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): SegmentPropertyExpression_DependenciesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : '',
      value: isSet(object.value)
        ? SegmentProperty.fromJSON(object.value)
        : undefined,
    }
  },

  toJSON(message: SegmentPropertyExpression_DependenciesEntry): unknown {
    const obj: any = {}
    if (message.key !== '') {
      obj.key = message.key
    }
    if (message.value !== undefined) {
      obj.value = SegmentProperty.toJSON(message.value)
    }
    return obj
  },

  create<
    I extends Exact<
      DeepPartial<SegmentPropertyExpression_DependenciesEntry>,
      I
    >,
  >(base?: I): SegmentPropertyExpression_DependenciesEntry {
    return SegmentPropertyExpression_DependenciesEntry.fromPartial(
      base ?? ({} as any),
    )
  },
  fromPartial<
    I extends Exact<
      DeepPartial<SegmentPropertyExpression_DependenciesEntry>,
      I
    >,
  >(object: I): SegmentPropertyExpression_DependenciesEntry {
    const message = createBaseSegmentPropertyExpression_DependenciesEntry()
    message.key = object.key ?? ''
    message.value =
      object.value !== undefined && object.value !== null
        ? SegmentProperty.fromPartial(object.value)
        : undefined
    return message
  },
}

function createBasePropertyWithSegmentationModification(): PropertyWithSegmentationModification {
  return { modification: undefined, coreProperty: undefined }
}

export const PropertyWithSegmentationModification = {
  encode(
    message: PropertyWithSegmentationModification,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.modification !== undefined) {
      SegmentationModification.encode(
        message.modification,
        writer.uint32(10).fork(),
      ).ldelim()
    }
    if (message.coreProperty !== undefined) {
      SegmentProperty.encode(
        message.coreProperty,
        writer.uint32(18).fork(),
      ).ldelim()
    }
    return writer
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PropertyWithSegmentationModification {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBasePropertyWithSegmentationModification()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.modification = SegmentationModification.decode(
            reader,
            reader.uint32(),
          )
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.coreProperty = SegmentProperty.decode(reader, reader.uint32())
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): PropertyWithSegmentationModification {
    return {
      modification: isSet(object.modification)
        ? SegmentationModification.fromJSON(object.modification)
        : undefined,
      coreProperty: isSet(object.core_property)
        ? SegmentProperty.fromJSON(object.core_property)
        : undefined,
    }
  },

  toJSON(message: PropertyWithSegmentationModification): unknown {
    const obj: any = {}
    if (message.modification !== undefined) {
      obj.modification = SegmentationModification.toJSON(message.modification)
    }
    if (message.coreProperty !== undefined) {
      obj.core_property = SegmentProperty.toJSON(message.coreProperty)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<PropertyWithSegmentationModification>, I>>(
    base?: I,
  ): PropertyWithSegmentationModification {
    return PropertyWithSegmentationModification.fromPartial(base ?? ({} as any))
  },
  fromPartial<
    I extends Exact<DeepPartial<PropertyWithSegmentationModification>, I>,
  >(object: I): PropertyWithSegmentationModification {
    const message = createBasePropertyWithSegmentationModification()
    message.modification =
      object.modification !== undefined && object.modification !== null
        ? SegmentationModification.fromPartial(object.modification)
        : undefined
    message.coreProperty =
      object.coreProperty !== undefined && object.coreProperty !== null
        ? SegmentProperty.fromPartial(object.coreProperty)
        : undefined
    return message
  },
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends globalThis.Array<infer U>
  ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string; value: unknown }
  ? { $case: T['$case']; value?: DeepPartial<T['value']> }
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>

type KeysOfUnion<T> = T extends T ? keyof T : never
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & {
      [K in Exclude<keyof I, KeysOfUnion<P>>]: never
    }

function isObject(value: any): boolean {
  return typeof value === 'object' && value !== null
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined
}
