import CheckedIcon from './images/check.svg'
type Props = {
  value: boolean
  onChange: (value: boolean) => void
  disabled?: boolean
}

export default function Checkbox({
  value,
  onChange,
  disabled,
}: Props): JSX.Element {
  return (
    <div
      role="checkbox"
      onClick={() => {
        if (disabled) return
        onChange(!value)
      }}
      className={`flex size-[14px] items-center justify-center rounded-[2px] ${
        value
          ? 'bg-background-brand'
          : 'border border-solid border-border-secondary'
      }`}
    >
      {value && <img src={CheckedIcon} alt="checked" />}
    </div>
  )
}
