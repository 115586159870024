import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import * as api from 'src/services'
import { useSite } from 'src/contexts/site'
import { FETCH_TAGS_QUERY, FETCH_TAG_QUERY } from 'tags/api'

export function useUpdateTagMutation(): UseMutationResult<
  api.GqlUpdatedTagFragment | null | undefined,
  unknown,
  api.GqlUpdateTagInput
> {
  const { id: currentFactory } = useSite()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (input: api.GqlUpdateTagInput) => api.updateTag(input),
    onSuccess: data => {
      if (data) {
        queryClient.invalidateQueries([
          FETCH_TAG_QUERY,
          currentFactory,
          data.tagName,
        ])
      }
      queryClient.invalidateQueries([FETCH_TAGS_QUERY, currentFactory])
    },
  })
}

export function useUpdateTagsMutation(): UseMutationResult<
  void,
  unknown,
  api.TagUpdate[]
> {
  const { id: currentFactory } = useSite()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (input: api.TagUpdate[]) =>
      api.updateTags(currentFactory, input),
    onSuccess: (_, vars) => {
      vars.forEach(t =>
        queryClient.invalidateQueries([
          FETCH_TAG_QUERY,
          currentFactory,
          t.tagName,
        ]),
      )
      queryClient.invalidateQueries([FETCH_TAGS_QUERY, currentFactory])
    },
  })
}
