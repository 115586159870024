import { FetchAnomalyModel, GqlAnomalyConditionsFragment } from 'src/services'

const defaultAnomalyConditions: GqlAnomalyConditionsFragment[] = [
  {
    threshold: 0.25,
    thresholdKind: 'UPPER',
    errorScore: {
      kind: 'ABSOLUTE',
      transform: 'NONE',
    },
  },
  {
    threshold: 0.1,
    thresholdKind: 'UPPER',
    errorScore: {
      kind: 'ABSOLUTE',
      transform: 'SMOOTHED',
    },
  },
]

export const getAnomalyThresholds = (
  model: FetchAnomalyModel,
): GqlAnomalyConditionsFragment[] => {
  return model.anomalyConditions ?? defaultAnomalyConditions
}
