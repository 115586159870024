import { useSparklineData } from 'tags/api'
import { getGraphColor } from 'src/utility/graphUtils/graphColors'
import { Sparkline } from './Sparkline'

interface TagSparklineProps {
  id: string
}

export function TagSparkline({ id }: TagSparklineProps): JSX.Element {
  const { data = [], isLoading } = useSparklineData(id)

  return (
    <Sparkline
      data={data.map(([, val]) => val)}
      isPending={isLoading}
      color={getGraphColor(id)}
    />
  )
}
