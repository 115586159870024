import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { getStreamlitApps, StreamlitAppDto } from 'src/services'

// get the streamlit apps mapped by slug
export function useStreamlitApps(
  factoryId: string,
): UseQueryResult<StreamlitAppDto[]> {
  return useQuery({
    queryKey: ['getStreamlitApps', factoryId],
    queryFn: async () => getStreamlitApps(factoryId),
  })
}
