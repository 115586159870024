import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import * as api from 'src/services'
import { MODEL_QUERY_KEY } from 'models/api'

type Variables = Omit<api.UpdateModel, 'id'>

export function useUpdateModelMutation(
  model: api.FetchModel,
): UseMutationResult<
  api.GqlModelBaseFragment | null | undefined,
  unknown,
  Variables
> {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (input: Variables) => {
      return api.updateModel({
        ...model,
        trainingPeriod: model.trainingPeriod ?? undefined,
        // override with any updates
        ...input,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries([MODEL_QUERY_KEY, model.id])
    },
  })
}

export function useUpdateModelFilterMutation(): UseMutationResult<
  api.GqlUpdateModelFilterFragment | undefined,
  unknown,
  api.UpdateModelFilter
> {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (input: api.UpdateModelFilter) =>
      api.updateModelFilter(input),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([MODEL_QUERY_KEY, variables.id])
    },
  })
}

interface ThresholdVariables {
  conditions: api.GqlAnomalyConditionInput[]
}

export function useUpdateModelAnomalyConditions({
  id,
}: {
  id: string
}): UseMutationResult<
  api.GqlUpdateModelAnomalyConditionsFragment | undefined,
  unknown,
  ThresholdVariables
> {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ conditions }: ThresholdVariables) => {
      const data = await api.updateModelAnomalyConditions({
        id,
        anomalyConditions: conditions,
      })

      return data
    },
    onSuccess: data => {
      // on success, invalidate query
      if (data && 'anomalyConditions' in data) {
        queryClient.invalidateQueries([MODEL_QUERY_KEY, id])
      }
    },
  })
}
