import { useEffect } from 'react'

export function useClickOutside(
  ref: React.RefObject<HTMLDivElement>,
  callback: () => void,
): void {
  useEffect(() => {
    const handleClick = (e: any): void => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback()
      }
    }
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [callback, ref])
}
