import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

const GlobalStyle = createGlobalStyle`
  ${normalize}

  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    overflow-y: auto;
    font-family: 'Roboto', 'Arial', 'Helvetica', sans-serif;
    font-weight: 300;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.87);
  }
`

export default GlobalStyle
