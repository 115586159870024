import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import { millisecondsToSeconds, secondsToMilliseconds } from 'date-fns'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { debounce } from 'lodash'
import { useMemo, useState } from 'react'
import { useTheme } from 'styled-components'
import queryString from 'query-string'
import { useModel } from 'models/api'
import { getOffsets, secondsToOption } from 'models/model/model.utils'
import { IfLabsAccess } from 'models/model'
import {
  Button,
  Checkbox,
  Icon,
  NumberInput,
  SelectInput,
  Text,
} from 'src/components/ui'
import { useSite } from 'src/contexts/site'
import { SeriesOptions } from 'src/types/chartTypes'
import useTimeRange from 'src/contexts/timeRange'
import { ChartData } from '../../../useChartData'

type SeriesConfigProps = {
  series: SeriesOptions
  data: ChartData
  colorIndex: number
  minDisabled?: boolean
  maxDisabled?: boolean
  setOptions?: (options: Partial<SeriesOptions>) => void
}

export function SeriesConfig({
  series,
  minDisabled = false,
  maxDisabled = false,
  data,
  colorIndex,
  setOptions,
}: SeriesConfigProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const [minError, setMinError] = useState('')
  const [maxError, setMaxError] = useState('')
  const { rootLink } = useSite()
  const { timeRange } = useTimeRange()
  const [localSeries, setLocalSeries] = useState(series)

  const theme = useTheme()
  const { trendMarkers } = useFlags()

  const debouncedSetOptions = useMemo(
    () => debounce(options => setOptions && setOptions(options), 500),
    [setOptions],
  )
  const color = theme.colors.chart[colorIndex % theme.colors.chart.length]
  return (
    <div
      className={classNames(
        trendMarkers
          ? 'py-xs'
          : 'w-[245px] border-0 border-t border-solid border-border py-xs',
      )}
    >
      <div
        className="flex cursor-pointer items-center justify-between gap-l"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center gap-2xs">
          <div
            className="aspect-square w-[13px] rounded-full"
            style={{ backgroundColor: color }}
          />
          <Text
            variant="small"
            bold
            className="max-w-[150px] overflow-hidden text-ellipsis"
          >
            {series.type === 'tag'
              ? data.displayName || data.name || series.id
              : data.name || series.id}
          </Text>
        </div>
        <Icon
          icon={light('chevron-down')}
          className={classNames('transition-all', isOpen && 'rotate-180')}
        />
      </div>
      {isOpen && (
        <div className="flex flex-col gap-xs">
          <div className="flex items-center gap-2xs py-xs">
            {theme.colors.chart.map((color, i) => (
              <div
                key={i}
                onClick={e => {
                  e.preventDefault()
                  return setOptions && setOptions({ colorIndex: i })
                }}
                className={classNames(
                  'aspect-square cursor-pointer rounded-full relative',
                  colorIndex === i
                    ? 'w-[8px] border border-solid m-[3px]'
                    : 'w-[14px]',
                )}
                style={{
                  backgroundColor: color,
                  borderColor: color,
                }}
              >
                <div
                  className={`absolute left-1/2 top-1/2 aspect-square w-[14px] -translate-x-1/2 -translate-y-1/2 rounded-full border border-solid ${
                    colorIndex === i ? 'block' : 'hidden'
                  }`}
                  style={{ borderColor: color }}
                />
              </div>
            ))}
          </div>
          <div className="flex items-center gap-xs">
            <Checkbox
              value={!series.disableRange}
              onChange={checked =>
                setOptions && setOptions({ disableRange: !checked })
              }
            />
            <Text variant="small" bold>
              {['forecast', 'prediction'].includes(series.type)
                ? 'Display prediction interval'
                : 'Display min/max range'}
            </Text>
          </div>
          {series.type === 'forecast' && (
            <OffsetSelector
              id={series.id}
              offsetMs={(series as { offsetMs: number }).offsetMs}
              setOptions={o => setOptions && setOptions(o)}
            />
          )}

          <div className="flex items-center justify-between">
            <div className="flex w-[100px] items-center gap-xs">
              <Text variant="small" bold>
                Min:
              </Text>
              <NumberInput
                error={!!minError}
                value={localSeries.min}
                allowUndefined
                disabled={minDisabled}
                onChange={val => {
                  const seriesOptions = localSeries
                  if (
                    val &&
                    seriesOptions &&
                    seriesOptions.max &&
                    val > seriesOptions.max
                  ) {
                    setMinError('Min value must be less than max value')
                    return null
                  }
                  setMinError('')
                  setLocalSeries({
                    ...localSeries,
                    min: val,
                  })
                  return debouncedSetOptions({ min: val })
                }}
                placeholder="Auto"
              />
            </div>

            <div className="flex w-[100px] items-center gap-xs">
              <Text variant="small" bold>
                Max:
              </Text>
              <NumberInput
                error={!!maxError && !minError}
                value={localSeries.max}
                allowUndefined
                disabled={maxDisabled}
                onChange={val => {
                  const seriesOptions = localSeries
                  if (
                    val &&
                    seriesOptions &&
                    seriesOptions.min &&
                    val < seriesOptions.min
                  ) {
                    setMaxError('Max value must exceed min value')
                    return null
                  }
                  setMaxError('')
                  setLocalSeries({
                    ...localSeries,
                    max: val,
                  })
                  return debouncedSetOptions({ max: val })
                }}
                placeholder="Auto"
              />
            </div>
          </div>
          {!!minError && (
            <Text variant="small" bold className="text-text-danger">
              {minError}
            </Text>
          )}
          {!!maxError && (
            <Text variant="small" bold className="text-text-danger">
              {maxError}
            </Text>
          )}

          {series.type !== 'tag' && (
            <Button
              as="a"
              href={`${rootLink}/models/${series.id}`}
              textVariant="description"
              variant="secondary"
              title="Go to model"
            />
          )}
          <IfLabsAccess>
            <div className="flex items-center justify-between gap-xs">
              <Button
                textVariant="description"
                title="Tag Analysis"
                variant="secondary"
                as="a"
                href={`${rootLink}/labs/tag_analysis?${queryString.stringify({
                  tag_id: series.id,
                  from: new Date(timeRange.from).toISOString(),
                  to: new Date(timeRange.to).toISOString(),
                })}`}
                target="_blank"
              />
              <Button
                textVariant="description"
                title="Similarity Search"
                variant="secondary"
                as="a"
                href={`${rootLink}/labs/similarity_search?${queryString.stringify(
                  {
                    tag_id: series.id,
                    from: new Date(timeRange.from).toISOString(),
                    to: new Date(timeRange.to).toISOString(),
                  },
                )}`}
                target="_blank"
              />
            </div>
          </IfLabsAccess>
        </div>
      )}
    </div>
  )
}

interface OffsetSelectorProps {
  id: any
  offsetMs: number
  setOptions: (options: Partial<SeriesOptions>) => void
}

const OffsetSelector = ({
  id,
  offsetMs,
  setOptions,
}: OffsetSelectorProps): JSX.Element => {
  const { data: model } = useModel(id)

  if (!model) return <></>

  const selectedOption = secondsToOption(millisecondsToSeconds(offsetMs))

  return (
    <div className="flex items-center justify-between gap-s">
      <label>
        <Text variant="small" bold className="whitespace-nowrap">
          Offset
        </Text>
      </label>
      <div className="w-full">
        <SelectInput
          value={{
            label: selectedOption.label,
            value: selectedOption.value.toString(),
          }}
          options={getOffsets(model).map(({ label, value }) => ({
            label,
            value: value.toString(),
          }))}
          onChange={value =>
            value &&
            setOptions({ offsetMs: secondsToMilliseconds(parseInt(value)) })
          }
        />
      </div>
    </div>
  )
}
