import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import toast from 'react-hot-toast'
import { useRemoveSiteUserMutation } from 'orgs-sites/site/api'
import { useRemoveOrganizationUserMutation } from 'orgs-sites/org/api'
import { Icon, Modal, Text } from 'src/components/ui'
import { OrganizationUser, SiteUser } from 'src/types'
import { UserType } from './user.types'

interface OrgUserProps {
  user: OrganizationUser
  type: UserType.ORG_USER
  orgId: string
  siteId?: undefined
}

interface SiteUserProps {
  user: SiteUser
  type: UserType.SITE_USER
  siteId: string
  orgId?: undefined
}

type Props = (OrgUserProps | SiteUserProps) & {
  onClose: () => void
  content: string
}

export function RemoveUserModal({
  type,
  orgId,
  siteId,
  user,
  onClose,
  content,
}: Props): JSX.Element {
  const removeSiteUserMutation = useRemoveSiteUserMutation()
  const removeOrgUserMutation = useRemoveOrganizationUserMutation()

  async function removeOrgUser(): Promise<void> {
    if (type !== UserType.ORG_USER) return
    await removeOrgUserMutation.mutateAsync(
      {
        orgId,
        userId: user.id,
      },
      {
        onSuccess: data => {
          const name = data.removeCustomerUser?.userEdge.node?.name ?? 'Unknown'
          toast.success(
            <Text>
              <span className="font-500">{name}</span> has been removed from
              organization
            </Text>,
            { position: 'top-right' },
          )
        },
        onError: () => {
          onClose()
          toast.error('Failed to remove the user from the organization.', {
            position: 'top-right',
          })
        },
      },
    )
    onClose()
  }

  async function removeSiteUser(): Promise<void> {
    if (type !== UserType.SITE_USER) return
    await removeSiteUserMutation.mutateAsync(
      { userId: user.id, siteId },
      {
        onSuccess: () => {
          toast.success(
            <Text>
              <span className="font-500">{`${user.name || user.email} `}</span>{' '}
              has been removed from the site.
            </Text>,
            { position: 'top-right' },
          )
        },
        onError: () => {
          onClose()
          toast.error('Failed to remove user from site.', {
            position: 'top-right',
          })
        },
      },
    )
    onClose()
  }
  return (
    <Modal
      title="Remove User"
      isOpen
      close={onClose}
      footer={
        <Modal.Footer
          onCancel={onClose}
          onConfirm={
            type === UserType.ORG_USER ? removeOrgUser : removeSiteUser
          }
          confirmLabel="Remove User"
          type="danger"
          buttonDisabled={false}
        />
      }
    >
      <div className="flex flex-col items-center">
        <Icon icon={light('triangle-exclamation')} className="mt-m size-2xl" />
        <Text variant="title" bold className="mt-[33px]">
          Are you sure you want to{' '}
          <span className="text-text-danger">remove</span> {user.name}?
        </Text>
        <Text variant="description" className="my-m w-[398px]">
          {content}
        </Text>
      </div>
    </Modal>
  )
}
