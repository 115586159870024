import { FetchForecastModel, GqlModelTypeFragment } from 'src/services'
import { Banner } from 'src/components/ui'
import { ERROR, NEW, hasRunInitialInference } from 'models/model/model.utils'
import {
  ConfigWarning,
  InputTags,
  ModelActions,
  ModelOverview,
  NewModelInfo,
  OutputTag,
} from '../../components'
import { OutputTagAndHistoricalForecast } from './sections'

interface ForecastModelProps {
  model: FetchForecastModel
  modelTypes: GqlModelTypeFragment[]
}

export function ForecastModel({
  model,
  modelTypes,
}: ForecastModelProps): JSX.Element {
  return (
    <div className="flex flex-col gap-[1em]">
      {model.state === NEW && <NewModelInfo />}
      <div className="flex flex-col gap-[1em] medium:flex-row">
        <ModelOverview
          modelTypes={modelTypes}
          model={model}
          className="flex flex-1 flex-col"
        />
        <ModelActions
          model={model}
          className="medium:min-w-[calc(300px-1em)]"
        />
      </div>
      <ConfigWarning model={model} />
      {model.state === ERROR && model.errorMessage && (
        <Banner className="col-span-2" variant="error">
          {`Error message: ${model.errorMessage}`}
        </Banner>
      )}
      {hasRunInitialInference(model.state) ? (
        <OutputTagAndHistoricalForecast model={model} />
      ) : (
        <OutputTag model={model} />
      )}
      <InputTags model={model} />
    </div>
  )
}
