import { parseISO } from 'date-fns'
import { GqlAgentReleaseFragment } from '../graphqlTypes'
import { GatewayRelease } from './gateway.types'

export function mapApiToGatewayRelease(
  release: GqlAgentReleaseFragment,
): GatewayRelease {
  return {
    ...release,
    release: parseISO(release.release),
  }
}
