import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import {
  GqlFactoryTrendViewFragment,
  GqlUpdateTrendViewInput,
  updateTrendView,
} from 'src/services'
import { FETCH_FACTORY_TREND_VIEWS_QUERY } from './trend.get'

export function useUpdateFactoryTrendView(): UseMutationResult<
  GqlFactoryTrendViewFragment,
  unknown,
  GqlUpdateTrendViewInput
> {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (input: GqlUpdateTrendViewInput) => {
      const trendView = await updateTrendView(input)
      if (!trendView) {
        throw new Error('Trend view update failed')
      }
      return trendView
    },
    onSuccess: data => {
      queryClient.invalidateQueries({
        queryKey: [FETCH_FACTORY_TREND_VIEWS_QUERY, data.factoryId],
      })
    },
  })
}
