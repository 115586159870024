import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { MODELS_QUERY_KEY, MODEL_QUERY_KEY } from 'models/api'
import { FetchModel, GqlModelState, fetchModel } from 'src/services'

type FetchModelMutationVariables = {
  modelId: string
  currentState?: GqlModelState
}

export function useFetchModelMutation(): UseMutationResult<
  FetchModel | null | undefined,
  unknown,
  FetchModelMutationVariables
> {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ modelId }: FetchModelMutationVariables) =>
      fetchModel({ modelId }),
    onSuccess: (data, vars) => {
      if (data && vars.currentState && data.state !== vars.currentState) {
        queryClient.invalidateQueries([MODEL_QUERY_KEY, vars.modelId])
        queryClient.invalidateQueries([MODELS_QUERY_KEY])
      }
    },
  })
}
