export function GatewayIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9998 37.1429C10.532 37.1429 2.85693 29.4677 2.85693 20C2.85693 10.5323 10.532 2.85718 19.9998 2.85718C29.4675 2.85718 37.1426 10.5323 37.1426 20C37.1426 29.4677 29.4675 37.1429 19.9998 37.1429ZM5.71408 20C5.71408 27.8897 12.11 34.2857 19.9998 34.2857C27.8895 34.2857 34.2855 27.8897 34.2855 20C34.2855 12.1102 27.8895 5.71432 19.9998 5.71432C12.11 5.71432 5.71408 12.1102 5.71408 20ZM21.4729 12.8125H21.4284V8.57146H18.5712V12.8125H18.5266H15.6694L17.3836 15.0982L18.8569 17.0625L19.9998 18.5863L21.1426 17.0625L22.6159 15.0982L24.3301 12.8125H21.4729ZM19.9998 21.4249L18.8569 22.9487L17.3836 24.913L15.6694 27.1987H18.5266H18.5712V31.4286H21.4284V27.1987H21.4729H24.3301L22.6159 24.913L21.1426 22.9487L19.9998 21.4249ZM8.60693 21.1429L7.08312 20L8.60693 18.8572L10.5712 17.3839L12.8569 15.6697V18.5269V18.5715H17.1426V21.4286H12.8569V21.4732V24.3303L10.5712 22.6162L8.60693 21.1429ZM32.9165 20L31.3926 18.8572L29.4284 17.3839L27.1426 15.6697V18.5269V18.5715H22.8569V21.4286H27.1426V21.4732V24.3303L29.4284 22.6162L31.3926 21.1429L32.9165 20Z"
        fill="#ABABAB"
      />
    </svg>
  )
}
