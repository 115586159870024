import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Text, Button, Icon } from 'src/components/ui'
import { isValidUrl } from 'src/utility/stringValidator'
import {
  ConnectionStatus,
  OpcUaConnectionConfiguration,
} from '../../opc-ua-connection.types'

type Props = {
  disabled?: boolean
  config: OpcUaConnectionConfiguration
  setConfig: (config: OpcUaConnectionConfiguration) => void
  verifyConnection: () => void
}

export function ConnectionVerifier({
  disabled,
  config,
  verifyConnection,
}: Props): JSX.Element {
  const status = config.connectionState ?? ConnectionStatus.UNVERIFIED
  return (
    <div className="flex flex-1 flex-col gap-s rounded-2xs border border-solid border-border p-s">
      <div>
        <Text variant="description">Verify OPC-UA Configuration</Text>
        <Text bold>
          Verify the connection details provided. You can move forward, after a
          succesful connection
        </Text>
      </div>
      <div>
        {status === ConnectionStatus.UNVERIFIED && (
          <Button
            variant="primary"
            disabled={disabled || !isValidUrl(config.serverEndpoint)}
            onClick={verifyConnection}
            title="Verify Connection Details"
          />
        )}
        {status === ConnectionStatus.VERIFYING && (
          <div className="flex flex-1 flex-col gap-2xl">
            <div>
              <Button
                variant="primary"
                disabled
                title="Please wait for the verification to complete"
              />
            </div>
            <div className="flex flex-col items-center gap-xs">
              <Icon icon={light('circle-info')} size="big" />
              <Text
                variant="description"
                bold
                className="max-w-[225px] text-center"
              >
                Do not close or refresh your browser until the verification
                completes.
              </Text>
            </div>
          </div>
        )}
        {status === ConnectionStatus.VERIFIED && (
          <div>
            <div className="inline-flex cursor-default items-center gap-xs rounded-2xs border border-solid border-border-brand py-[7px] pl-xs pr-s">
              <Icon
                icon={light('circle-check')}
                className="text-icon-brand"
                size="regular"
              />
              <Text bold>Connected</Text>
            </div>
            <div className="mt-s w-fit border-0 border-y border-dashed border-border-tertiary py-xs">
              <Text
                variant="code"
                className="w-fit whitespace-pre-line !text-small !leading-small"
              >
                {config.connectionVerifyText}
              </Text>
            </div>
          </div>
        )}
        {status === ConnectionStatus.FAILED && (
          <div>
            <div className="inline-flex cursor-default items-center gap-xs rounded-2xs border border-solid border-border-danger py-[7px] pl-xs pr-s">
              <Icon
                icon={light('circle-exclamation')}
                className="text-text-danger"
                size="regular"
              />
              <Text bold>Error</Text>
            </div>
            <div className="mt-s w-fit border-0 border-y border-dashed border-border-tertiary py-xs">
              <Text
                variant="code"
                className="w-fit whitespace-pre-line !text-small !leading-small"
              >
                {config.connectionVerifyText}
              </Text>
            </div>
            <Button
              variant="primary"
              className="mt-s"
              onClick={verifyConnection}
              title="Try again"
            />
          </div>
        )}
      </div>
    </div>
  )
}
