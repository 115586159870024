import { useEffect, useRef } from 'react'
import { shallowEqual } from 'react-redux'

// See: https://stackoverflow.com/questions/54095994/react-useeffect-comparing-objects

const useShallowEqualMemoize = (value: any): any => {
  const ref = useRef()
  if (!shallowEqual(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

export const useShallowEqualEffect = (
  effect: React.EffectCallback,
  deps: React.DependencyList,
): void => {
  useEffect(effect, [effect, ...deps.map(useShallowEqualMemoize)])
}
