import { TimeSeriesData } from 'src/types'

export const tsdMin = ([, value, min]: TimeSeriesData): number =>
  min !== undefined ? min : value

export const tsdMax = ([, value, , max]: TimeSeriesData): number =>
  max !== undefined ? max : value

export const tsdArrayMin = (tsds: TimeSeriesData[]): number =>
  Math.min(...tsds.map(tsd => tsdMin(tsd)))

export const tsdArrayMax = (tsds: TimeSeriesData[]): number =>
  Math.max(...tsds.map(tsd => tsdMax(tsd)))

export const tsdArrayMinMax = (tsds: TimeSeriesData[]): [number, number] =>
  tsds.reduce(
    (acc: [number, number], tsd) => {
      const min = tsdMin(tsd)
      const max = tsdMax(tsd)
      const nextMin = Number.isNaN(acc[0]) || min < acc[0] ? min : acc[0]
      const nextMax = Number.isNaN(acc[1]) || max > acc[1] ? max : acc[1]
      return [nextMin, nextMax]
    },
    [NaN, NaN],
  )
