// Utility types for dealing with Highcharts types

// See: https://github.com/highcharts/highcharts/issues/13738
export interface PlotPoint extends Highcharts.Point {
  plotX: number
  plotY: number
  plotHigh?: number
  plotLow?: number
}

export const isPlotPoint = (p: Highcharts.Point): p is PlotPoint => {
  const x = p as PlotPoint
  return x.plotX !== undefined && x.plotY !== undefined
}

// this ugly thing is because Highcharts colors do not map directly
// onto CSS colors. We only use string colors
export const mapColor = (
  c:
    | string
    | Highcharts.GradientColorObject
    | Highcharts.PatternObject
    | undefined,
): string | undefined => {
  if (typeof c === 'string') {
    return c
  }
  return undefined
}

interface ChartXMouseEvent extends MouseEvent {
  chartX: number
}

export const isChartXMouseEvent = (e: MouseEvent): e is ChartXMouseEvent =>
  (e as ChartXMouseEvent).chartX !== undefined
