import { useState, useEffect, useRef } from 'react'
import HighchartsReact from 'highcharts-react-official'
import useTimeRange from 'src/contexts/timeRange'
import { useChartWidth } from './useChartWidth'
import { useChartHeight } from './useChartHeight'

interface UseNowLine {
  ref: React.RefObject<HighchartsReact.RefObject>
}

export function useNowLine({ ref }: UseNowLine): void {
  const [now, setNow] = useState(Date.now())
  const oldNow = useRef(now)
  const line = useRef<Highcharts.SVGElement>()
  const width = useChartWidth(ref)
  const { timeRange } = useTimeRange()
  const height = useChartHeight(ref)

  // update `now` every second
  useEffect(() => {
    const interval = setInterval(() => setNow(Date.now()), 2000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  // create the now line
  useEffect(() => {
    if (ref.current) {
      const { plotLeft, plotTop, plotHeight, renderer } = ref.current.chart
      line.current = renderer
        .g()
        .css({
          display: 'none',
        })
        .add()

      renderer
        .path([
          ['M', plotLeft, plotTop],
          ['L', plotLeft, plotTop + plotHeight],
        ])
        .attr({
          'stroke-width': 2,
          stroke: 'black',
          zIndex: 3,
        })
        .add(line.current)

      renderer
        .text('Now', plotLeft + 5, plotTop + plotHeight - 5)
        .css({ fontSize: '1.2em' })
        .add(line.current)
    }

    return () => {
      if (line.current) {
        line.current.destroy()
        // prevent double destroy
        line.current = undefined
      }
    }
  }, [ref, height])

  // update the now line
  useEffect(() => {
    if (line.current && ref.current) {
      const { xAxis, plotLeft, plotWidth } = ref.current.chart
      const x = xAxis[0].toPixels(now, false)

      // check if `now` is within the plot range
      if (x < plotLeft || x > plotLeft + plotWidth) {
        line.current.css({
          display: 'none',
          transition: 'none',
        })
      } else {
        line.current.css({
          transform: `translate3d(${x - plotLeft}px, 0, 0)`,
          display: 'initial',
          transition: oldNow.current === now ? 'none' : 'transform 1s linear',
        })
      }
    }
    oldNow.current = now
  }, [ref, width, height, timeRange, now])
}
