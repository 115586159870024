import { useNavigate, useParams } from 'react-router-dom'
import { Button, DynamicTable, Spinner } from 'src/components/ui'
import { ErrorDisplay } from 'pages/app'
import { useOpcConnections } from 'opc-ua/opc-ua-connection.api'
// TODO: This will get moved
import { getOpcConnectionsTableConfig } from 'src/components/ui/tables/DynamicTable'

export function OpcConnections(): JSX.Element {
  const { orgId, siteId, gatewayId } = useParams()
  if (!siteId || !gatewayId || !orgId)
    throw new Error('Missing siteId or gatewayId or orgId')

  const navigate = useNavigate()
  const opcConnectionsQuery = useOpcConnections(siteId, gatewayId)

  const handleRowClick = (row: any): void => {
    navigate(
      `/settings/orgs/${orgId}/sites/${siteId}/gateways/${gatewayId}/opc-ua/${row.id}`,
    )
  }

  const handleCreateOpcConnection = (): void => {
    navigate(
      `/settings/orgs/${orgId}/sites/${siteId}/gateways/${gatewayId}/opc-ua/configuration`,
    )
  }

  if (opcConnectionsQuery.isError) {
    return (
      <ErrorDisplay
        error={opcConnectionsQuery.error}
        action={opcConnectionsQuery.refetch}
        message="Failed to fetch OPC-UA connections"
      />
    )
  }

  if (opcConnectionsQuery.isLoading) {
    return <Spinner />
  }

  return (
    <div className="flex flex-1 flex-col gap-s">
      <div className="flex items-center justify-between">
        <Button
          variant="primary"
          title="Create OPC-UA Connection"
          onClick={handleCreateOpcConnection}
        />
      </div>
      <div className="h-full min-h-[400px]">
        <DynamicTable
          data={opcConnectionsQuery.data}
          config={getOpcConnectionsTableConfig()}
          id={`${gatewayId}-gatewayOpc`}
          actions={{
            selectRow: handleRowClick,
          }}
        />
      </div>
    </div>
  )
}
