import { Site } from './siteTypes'
import { OrganizationRole } from '.'

export type Organization = {
  id: string
  name: string
  viewerRole: OrganizationRole
  numOfSites: number
  isHome: boolean
  sites?: Site[]
  userCount: number
}

export type OrganizationUser = {
  id: string
  name?: string
  email: string
  role: OrganizationRole
  // siteCount: number
  status: OrganizationStatus
  userTag: string
  inviteDaysLeft?: number
  homeOrganization: HomeOrganization
}

export enum OrganizationStatus {
  ACTIVE = 'Active',
  PENDING = 'Pending',
  INACTIVE = 'Inactive',
}

export type HomeOrganization = {
  id: string
  name: string
}
