import { useSearchParams } from 'react-router-dom'
import { parsePage } from 'src/utility/stringValidator'

type ModelFilters = {
  filter: string
  showMine: boolean
  showStarred: boolean
  page: number
  pageSize: number
  setFilter: (filter: string) => void
  setShowMine: (value: boolean) => void
  setShowStarred: (value: boolean) => void
  setPage: (page: number) => void
  setPageSize: (pageSize: number) => void
}

export function useModelFilters(): ModelFilters {
  const [search, setSearch] = useSearchParams()

  function setFilter(filter: string): void {
    if (filter === '') {
      search.delete('filter')
    } else {
      search.set('filter', filter)
    }
    setSearch(search)
  }

  function setShowMine(value: boolean): void {
    if (value) {
      search.set('mine', 'true')
    } else {
      search.delete('mine')
    }
    setSearch(search)
  }

  function setShowStarred(value: boolean): void {
    if (value) {
      search.set('starred', 'true')
    } else {
      search.delete('starred')
    }
    setSearch(search)
  }

  function setPage(page: number): void {
    if (page === 0) {
      search.delete('page')
    } else {
      search.set('page', page.toString())
    }
    setSearch(search)
  }

  const page = parsePage(search.get('page'))

  function setPageSize(pageSize: number): void {
    if (pageSize === 10) {
      search.delete('pageSize')
    } else {
      search.set('pageSize', pageSize.toString())
    }
    setSearch(search)
  }

  const pageSize = parsePage(search.get('pageSize'))

  return {
    filter: search.get('filter') || '',
    showMine: search.get('mine') === 'true',
    showStarred: search.get('starred') === 'true',
    page,
    pageSize,
    setFilter,
    setShowMine,
    setShowStarred,
    setPage,
    setPageSize,
  }
}
