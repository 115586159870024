import { Navigate, useLocation } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import * as api from 'src/services'
import * as path from 'src/utility/path'
import { ErrorDisplay } from 'pages/app'
import { initializeState } from './RetryState'

interface StreamlitConnectorProps {
  factory: string
  slug: string
}

// Handles `/<base>/:slug`, e.g. `/site/FWC1CQSD3S42/labs/hello`
// It has one job: to start a session for the given slug and
// redirect to ``/<base>/slug/sessionId`
export function StreamlitConnector({
  factory,
  slug,
}: StreamlitConnectorProps): JSX.Element {
  const location = useLocation()
  const { pathname } = location
  const state = initializeState(location.state)

  const connect = useQuery({
    queryKey: ['startStreamlitSession', factory, slug],
    queryFn: () => api.startStreamlitSession(factory, slug),
  })

  if (connect.isSuccess) {
    return (
      <Navigate
        to={{
          pathname: path.join(pathname, connect.data.id),
          search: state.search,
        }}
        state={state}
      />
    )
  }

  if (connect.isError) {
    return (
      <ErrorDisplay
        error={connect.error}
        message="Unable to start to application"
      />
    )
  }

  // FIXME we can potentially give some useful feedback here
  // Connecting...
  return <></>
}
