// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.1
//   protoc               v5.26.1
// source: common.proto

/* eslint-disable */
import _m0 from 'protobufjs/minimal'
import { Timestamp } from './google/protobuf/timestamp'

export const protobufPackage = 'types.v1'

export interface Meta {
  /** monotonically increasing */
  serial: number
  /** datetime of creation */
  createdAt: Date | undefined
  /** datetime of last update */
  lastUpdated: Date | undefined
  /** what version of the streamlit app updated this file */
  lastUpdatedByAppVersion: string
  starred: boolean
}

function createBaseMeta(): Meta {
  return {
    serial: 0,
    createdAt: undefined,
    lastUpdated: undefined,
    lastUpdatedByAppVersion: '',
    starred: false,
  }
}

export const Meta = {
  encode(message: Meta, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.serial !== 0) {
      writer.uint32(8).uint32(message.serial)
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(
        toTimestamp(message.createdAt),
        writer.uint32(18).fork(),
      ).ldelim()
    }
    if (message.lastUpdated !== undefined) {
      Timestamp.encode(
        toTimestamp(message.lastUpdated),
        writer.uint32(26).fork(),
      ).ldelim()
    }
    if (message.lastUpdatedByAppVersion !== '') {
      writer.uint32(34).string(message.lastUpdatedByAppVersion)
    }
    if (message.starred !== false) {
      writer.uint32(40).bool(message.starred)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Meta {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseMeta()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break
          }

          message.serial = reader.uint32()
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.createdAt = fromTimestamp(
            Timestamp.decode(reader, reader.uint32()),
          )
          continue
        case 3:
          if (tag !== 26) {
            break
          }

          message.lastUpdated = fromTimestamp(
            Timestamp.decode(reader, reader.uint32()),
          )
          continue
        case 4:
          if (tag !== 34) {
            break
          }

          message.lastUpdatedByAppVersion = reader.string()
          continue
        case 5:
          if (tag !== 40) {
            break
          }

          message.starred = reader.bool()
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): Meta {
    return {
      serial: isSet(object.serial) ? globalThis.Number(object.serial) : 0,
      createdAt: isSet(object.created_at)
        ? fromJsonTimestamp(object.created_at)
        : undefined,
      lastUpdated: isSet(object.last_updated)
        ? fromJsonTimestamp(object.last_updated)
        : undefined,
      lastUpdatedByAppVersion: isSet(object.last_updated_by_app_version)
        ? globalThis.String(object.last_updated_by_app_version)
        : '',
      starred: isSet(object.starred)
        ? globalThis.Boolean(object.starred)
        : false,
    }
  },

  toJSON(message: Meta): unknown {
    const obj: any = {}
    if (message.serial !== 0) {
      obj.serial = Math.round(message.serial)
    }
    if (message.createdAt !== undefined) {
      obj.created_at = message.createdAt.toISOString()
    }
    if (message.lastUpdated !== undefined) {
      obj.last_updated = message.lastUpdated.toISOString()
    }
    if (message.lastUpdatedByAppVersion !== '') {
      obj.last_updated_by_app_version = message.lastUpdatedByAppVersion
    }
    if (message.starred !== false) {
      obj.starred = message.starred
    }
    return obj
  },

  create<I extends Exact<DeepPartial<Meta>, I>>(base?: I): Meta {
    return Meta.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<Meta>, I>>(object: I): Meta {
    const message = createBaseMeta()
    message.serial = object.serial ?? 0
    message.createdAt = object.createdAt ?? undefined
    message.lastUpdated = object.lastUpdated ?? undefined
    message.lastUpdatedByAppVersion = object.lastUpdatedByAppVersion ?? ''
    message.starred = object.starred ?? false
    return message
  },
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends globalThis.Array<infer U>
  ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string; value: unknown }
  ? { $case: T['$case']; value?: DeepPartial<T['value']> }
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>

type KeysOfUnion<T> = T extends T ? keyof T : never
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & {
      [K in Exclude<keyof I, KeysOfUnion<P>>]: never
    }

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000)
  const nanos = (date.getTime() % 1_000) * 1_000_000
  return { seconds, nanos }
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000
  millis += (t.nanos || 0) / 1_000_000
  return new globalThis.Date(millis)
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o
  } else if (typeof o === 'string') {
    return new globalThis.Date(o)
  } else {
    return fromTimestamp(Timestamp.fromJSON(o))
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined
}
