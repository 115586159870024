import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import * as api from 'src/services'
import { ORGANIZATION_QUERY } from './org.get'

export function useAddOrgUserMutation(): UseMutationResult<
  api.GqlAddUserMutation,
  Error,
  api.GqlAddUserInput
> {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: api.addUser,
    onSuccess: (_, input) => {
      queryClient.invalidateQueries([ORGANIZATION_QUERY, input.customerId])
    },
  })
}
