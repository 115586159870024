import React from 'react'
import { sub } from 'date-fns'
import { TimeRange } from 'src/types'

export const last24Hours = (): TimeRange => {
  const now = new Date()
  return {
    from: sub(now, { hours: 24 }).valueOf(),
    to: now.valueOf(),
  }
}

// when setting live to true, we can also indicate the
// period to select (this is for the quick selects)
export type LiveInput = { live: false } | { live: true; period?: number }

export type TimeRangeValue = {
  timeRange: TimeRange
  live: boolean
  setTimeRange: (timeRange: TimeRange) => void
  setLive: (input: LiveInput) => void
  undo: () => void
  hasHistory: boolean
  isLiveUpdate: boolean
}

export const TimeRangeContext = React.createContext<TimeRangeValue | undefined>(
  undefined,
)
