import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import {
  GqlCreateTrendViewInput,
  GqlFactoryTrendViewFragment,
  createTrendView,
} from 'src/services'
import { FETCH_FACTORY_TREND_VIEWS_QUERY } from './trend.get'

export function useCreateFactoryTrendView(): UseMutationResult<
  GqlFactoryTrendViewFragment,
  unknown,
  GqlCreateTrendViewInput
> {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (input: GqlCreateTrendViewInput) => {
      const trendView = await createTrendView(input)
      if (!trendView) {
        throw new Error('Trend view creation failed')
      }
      return trendView
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [FETCH_FACTORY_TREND_VIEWS_QUERY, variables.factoryId],
      })
    },
  })
}
