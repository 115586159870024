import { useEffect, useRef, useState } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
import { useTheme } from 'styled-components'
import { useLoading } from 'src/components/ui'
import { ChartData } from 'pages/site/trend/trend/components/Chart/useChartData'
import { baseScatterChartConfig } from './baseScatterChartConfig'

export type Props = {
  chartData_x: ChartData
  chartData_y: ChartData
  isPending: boolean
  colorIndex?: number
}

function minMax(chartData: ChartData): [number, number] {
  const values = chartData.data.map(([, v]) => v)
  return [Math.min(...values), Math.max(...values)]
}

export function ScatterChart({
  chartData_x,
  chartData_y,
  isPending,
  colorIndex,
}: Props): JSX.Element {
  const ref = useRef<HighchartsReact.RefObject>(null)
  const theme = useTheme()
  const [chartOptions, setChartOptions] = useState<any>(
    baseScatterChartConfig({ colorIndex, theme }),
  )

  useLoading({
    ref,
    loading: isPending,
    data: chartOptions?.series,
  })

  useEffect(() => {
    if (!chartData_x?.data || !chartData_y?.data) return

    type XYTime = { x: number; y: number; time: number }
    const combinedData = chartData_x.data.flatMap<XYTime>(
      ([time_x, value_x]) => {
        const point = chartData_y.data.find(el => el[0] === time_x)
        return point ? [{ x: value_x, y: point[1], time: time_x }] : []
      },
    )
    // add 10% padding to min/max
    const [xMin, xMax] = minMax(chartData_x)
    const [yMin, yMax] = minMax(chartData_y)
    const xPadding = (xMax - xMin) * 0.1
    const yPadding = (yMax - yMin) * 0.1

    setChartOptions((prevOptions: any) => ({
      ...prevOptions,
      xAxis: {
        ...prevOptions.xAxis,
        min: xMin - xPadding,
        max: xMax + xPadding,
      },
      yAxis: {
        ...prevOptions.yAxis,
        min: yMin - yPadding,
        max: yMax + yPadding,
      },
      series: [
        {
          turboThreshold: 0,
          data: combinedData,
          cursor: 'crosshair',
        },
      ],
    }))
  }, [chartData_x, chartData_y])

  useEffect(() => {
    const newOptions = {
      ...chartOptions,
      ...baseScatterChartConfig({ colorIndex, theme }),
    }
    if (
      newOptions.plotOptions.scatter.marker.fillColor !==
      chartOptions.plotOptions.scatter.marker.fillColor
    ) {
      setChartOptions(newOptions)
    }
  }, [chartOptions, colorIndex, theme])

  return (
    <div className="relative size-full px-xs">
      <HighchartsReact
        ref={ref}
        containerProps={{ className: 'w-full h-full' }}
        highcharts={Highcharts}
        options={chartOptions}
      />
    </div>
  )
}
