import { IRowNode } from '@ag-grid-community/core'
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useRef, useState } from 'react'
import { Icon, Text } from 'src/components/ui'
import { useClickOutside } from 'src/utility'

type Props = {
  data: any
  node: IRowNode
  refreshRow: (node: IRowNode) => void
}

function CopyNodeIDItem({ data }: { data: any }): JSX.Element {
  const [isCopied, setIsCopied] = useState(false)

  const handleCopy = (): void => {
    setIsCopied(true)
    navigator.clipboard.writeText(data.id)
    setTimeout(() => {
      setIsCopied(false)
    }, 3000)
  }
  return (
    <div
      onClick={e => {
        e.stopPropagation()
        e.preventDefault()
        handleCopy()
      }}
      className="flex cursor-pointer items-center gap-2xs rounded-2xs p-xs hover:bg-background-hover"
    >
      <Icon icon={light('link')} className="w-m" />
      <Text>{isCopied ? 'Node ID Copied!' : 'Copy Node ID'}</Text>
    </div>
  )
}

export function TagMenu({ data, node, refreshRow }: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)

  useClickOutside(menuRef, () => {
    setIsOpen(false)
  })

  const menuItems = [
    {
      label: 'Refresh row',
      icon: light('refresh'),
      onClick: () => refreshRow(node),
    },
    {
      label: 'Copy Node ID',
      customComponent: CopyNodeIDItem,
    },
  ]

  return (
    <div className="relative flex justify-start" ref={menuRef}>
      <Icon
        size="regular"
        icon={regular('ellipsis')}
        className="cursor-pointer px-2xs"
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <div className="absolute right-[calc(100%-20px)] top-[calc(100%)] z-20 rounded-2xs bg-background shadow-md">
          {menuItems.map((i, index) => {
            if (i.customComponent) {
              return <i.customComponent key={i.label} data={data} />
            } else {
              return (
                <div
                  key={i.label}
                  onClick={() => {
                    i.onClick()
                    setIsOpen(false)
                  }}
                  className="flex cursor-pointer items-center gap-2xs rounded-2xs p-xs hover:bg-background-hover"
                >
                  <Icon icon={i.icon} className="min-w-m" />
                  <Text>{i.label}</Text>
                  {index !== menuItems.length - 1 && (
                    <div className="h-px w-full bg-background-tertiary" />
                  )}
                </div>
              )
            }
          })}
        </div>
      )}
    </div>
  )
}
