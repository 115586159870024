const subType = 'subscriptions'

type Subscription = {
  topic: string
  entity: string
  event: string
  expire: string
  lastState?: string
}

type Subscriptions = { [key: string]: Subscription }

export const addSubscription = (subscription: Subscription): void => {
  const initSubscriptions = {}
  const item = window.localStorage.getItem(subType)
  const subscriptions: Subscriptions = item
    ? JSON.parse(item)
    : initSubscriptions
  const valueToStore = { ...subscriptions, [subscription.entity]: subscription }
  window.localStorage.setItem(subType, JSON.stringify(valueToStore))
}

export const removeSubscription = (entity: string): void => {
  const item = window.localStorage.getItem(subType)
  const subscriptions = item && JSON.parse(item)
  delete subscriptions[entity]
  window.localStorage.setItem('subscriptions', JSON.stringify(subscriptions))
}

export const getSubscriptions = (): Subscriptions => {
  const item = window.localStorage.getItem(subType)
  return item ? JSON.parse(item) : {}
}

export const updateSubscription = (entity: string, lastState: string): void => {
  const subscriptions = getSubscriptions()
  const subscription = subscriptions[entity]
  if (subscription) {
    subscription.lastState = lastState
    addSubscription(subscription)
  }
}
