import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { formatISO } from 'date-fns'
import {
  Anomaly,
  AnomalyBase,
  fetchAnomaly as fetchAnomalyRequest,
  fetchAnomalies as fetchAnomaliesRequest,
  LatestComment,
  fetchLatestAnomalyComments,
  StateCount,
  fetchAnomalyCountByState,
} from 'src/services'
import { useSite } from 'src/contexts/site'

export const ANOMALY = 'anomaly'
const ANOMALIES = 'anomalies'
const LATEST_COMMENTS_KEY = 'fetchLatestAnomalyComments'

export function useAnomaly(
  factory: string,
  anomaly: string,
): UseQueryResult<Anomaly, Error> {
  return useQuery({
    queryKey: [ANOMALY, anomaly],
    queryFn: async () => {
      return fetchAnomaly(factory, anomaly)
    },
  })
}

async function fetchAnomaly(
  factory: string,
  anomalyId: string,
): Promise<Anomaly> {
  const anomaly = await fetchAnomalyRequest(factory, anomalyId)
  if (!anomaly) {
    throw new Error('Anomaly not found')
  }
  return anomaly
}

export function useAnomalies(
  factory: string,
  from: number,
  to: number,
): UseQueryResult<AnomalyBase[], Error> {
  return useQuery({
    queryKey: [ANOMALIES, from, to, factory],
    queryFn: () => fetchAnomalies(factory, from, to),
  })
}

async function fetchAnomalies(
  factory: string,
  from: number,
  to: number,
): Promise<AnomalyBase[]> {
  const f = formatISO(from)
  const t = formatISO(to)
  return fetchAnomaliesRequest(factory, f, t)
}

export function useLatestAnomalyComments(): UseQueryResult<LatestComment[]> {
  const { id: factory } = useSite()
  return useQuery({
    queryKey: [LATEST_COMMENTS_KEY, factory],
    queryFn: async () => fetchLatestAnomalyComments(factory),
  })
}

export function useAnomalyCountByState(): UseQueryResult<StateCount[]> {
  const { id } = useSite()
  const req = { id }
  return useQuery({
    queryKey: ['fetchAnomalyCountByState', req],
    queryFn: () => fetchAnomalyCountByState(req),
  })
}
