import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Listbox, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Field, Form, Formik } from 'formik'
import { Fragment, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { isValidEmail } from 'src/utility/stringValidator'
import { Site, SiteRole, siteRoleToGql } from 'src/types'
import { useOrganizationUsers } from 'orgs-sites/org/api'
import { Button, Icon, Text, Modal } from 'src/components/ui'
import { parseApiErrorMessage } from 'src/utility/errors'
import { useAddSiteUserMutation } from '../site/api'

type FormValues = {
  email: string
}

const FormSchema = Yup.object().shape({
  email: Yup.string()
    .test('valid-email', 'Invalid email', value => isValidEmail(value ?? ''))
    .required('Required'),
})

type Props = {
  site: Site
  onClose: () => void
}

export function AddSiteUserModal({ site, onClose }: Props): JSX.Element {
  const [selectedRole, setSelectedRole] = useState(SiteRole.READER)
  const [isRoleOptionsListOpen, setIsRoleOptionsListOpen] = useState(false)
  const [isThisUserInYourOrganization, setThisUserInYourOrganization] =
    useState(true)
  const [isTrustConfirmed, setIsTrustConfirmed] = useState(true)
  const orgUsersQuery = useOrganizationUsers(site.orgId)
  const roles: SiteRole[] = Object.values(SiteRole)
  const navigate = useNavigate()

  const addSiteUserMutation = useAddSiteUserMutation()

  async function addSiteUser({ email }: FormValues): Promise<void> {
    await addSiteUserMutation.mutateAsync(
      {
        factoryId: site.id,
        role: siteRoleToGql(selectedRole),
        email,
      },
      {
        onSuccess: () => {
          setTimeout(() => {
            toast.success(
              <Text>
                Invitation for <span className="font-500">{` ${email} `}</span>
                has been sent.
              </Text>,
              { position: 'top-right' },
            )
          }, 500)
          handleCloseModal()
          navigate(`/settings/orgs/${site.orgId}/sites/${site.id}`)
        },
      },
    )
  }

  function handleCloseModal(): void {
    addSiteUserMutation.reset()
    onClose()
  }

  function handleEmailChange(e: React.ChangeEvent<HTMLFormElement>): void {
    if (isValidEmail(e.target.value)) {
      if (!orgUsersQuery.data?.find(user => user.email === e.target.value)) {
        setThisUserInYourOrganization(false)
        setIsTrustConfirmed(false)
      } else {
        setThisUserInYourOrganization(true)
        setIsTrustConfirmed(true)
      }
    } else {
      setThisUserInYourOrganization(true)
      setIsTrustConfirmed(true)
    }
  }

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={FormSchema}
      onSubmit={values => addSiteUser(values)}
    >
      {({ isValid, errors, touched, submitForm }) => (
        <Modal
          title="Add User"
          isOpen
          close={onClose}
          footer={
            <Modal.Footer
              onCancel={onClose}
              onConfirm={submitForm}
              confirmLabel="Add User"
              type="success"
              buttonDisabled={
                addSiteUserMutation.isLoading ||
                !isValid ||
                (!isTrustConfirmed && !isThisUserInYourOrganization)
              }
            />
          }
        >
          <div className="max-w-[447px]">
            <Text variant="content-rubik" className="!mt-s">
              Add User to Organization{' '}
              <span className="font-medium">{site.orgName} | </span>
              Site <span className="font-medium">{site.name}</span>
            </Text>

            <Form onChange={handleEmailChange}>
              <div className="mt-m">
                <label className="mb-xs block" htmlFor="email">
                  <Text variant="small" bold className="my-xs">
                    Email Address
                  </Text>
                </label>
                <div className="relative">
                  <Field
                    name="email"
                    placeholder="Email Address"
                    className="mb-m flex w-full items-center rounded-2xs border-none pl-l text-description font-500 leading-[32px] outline-none ring-1 ring-border ring-offset-1 placeholder:font-thin"
                  ></Field>
                  {touched.email && (
                    <Text
                      variant="small"
                      className="absolute bottom-[-16px] left-0 text-text-danger"
                    >
                      {errors.email}
                    </Text>
                  )}
                  <div className="absolute left-[8px] top-[50%] translate-y-[-50%]">
                    <Icon icon={light('search')} />
                  </div>
                  {!isThisUserInYourOrganization && !isTrustConfirmed && (
                    <div className="absolute left-0 top-[calc(100%+8px)] z-10 w-full rounded-2xs border-[1px] border-solid border-border bg-background pl-xs drop-shadow-lg">
                      <Text variant="description" className="my-xs">
                        Users in organization
                      </Text>
                      <Text variant="description" bold className="my-xs">
                        This user is not in your organization
                      </Text>
                      <Button
                        variant="primary"
                        title="I trust this user"
                        className="my-xs"
                        onClick={() => setIsTrustConfirmed(true)}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="my-m">
                <label className="mb-xs block" htmlFor="site_role">
                  <Text variant="small" bold className="my-xs">
                    Site Role
                  </Text>
                </label>
                <div className="">
                  <Listbox value={selectedRole} onChange={setSelectedRole}>
                    <div className="relative mt-2xs">
                      <Listbox.Button
                        className={`${
                          isRoleOptionsListOpen && 'ring-black'
                        } relative w-full cursor-default rounded-2xs border-none bg-background p-xs text-left font-500 outline-none ring-1 ring-border ring-offset-1 focus:ring-black focus-visible:ring-black sm:text-xs`}
                      >
                        <span className="block truncate">{selectedRole}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <Icon
                            icon={light('chevron-down')}
                            className={classNames(
                              'transition-all',
                              isRoleOptionsListOpen && 'rotate-180',
                            )}
                          />
                        </span>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options
                          style={{ paddingInlineStart: 0 }}
                          className="absolute mt-2xs max-h-60 w-full overflow-auto rounded-md bg-background py-2xs text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-description"
                        >
                          {({ open }) => {
                            setIsRoleOptionsListOpen(open)
                            return (
                              <>
                                {roles.map((role, index) => (
                                  <Listbox.Option
                                    key={index}
                                    className={({ active }) =>
                                      `relative list-none cursor-default select-none py-xs px-s ${
                                        active
                                          ? 'bg-gray-100 text-gray-900'
                                          : 'text-gray-900'
                                      }`
                                    }
                                    value={role}
                                  >
                                    {({ selected }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            'block truncate',
                                            selected
                                              ? 'font-medium'
                                              : 'font-normal',
                                          )}
                                        >
                                          {role}
                                        </span>
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </>
                            )
                          }}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                </div>
              </div>
              <div className="mb-s">
                <Text variant="description" bold className="my-xs">
                  * User Roles:
                </Text>
                <Text variant="description" className="m-2xs mt-s">
                  <span className="font-500">Reader </span> | Read only access
                  to the Site.
                </Text>
                <Text variant="description" className="m-2xs">
                  <span className="font-500">Writer </span> | Read and write
                  access to the Site; no user management rights.
                </Text>
                <Text variant="description" className="m-2xs">
                  <span className="font-500">Admin </span> | Read, write and can
                  manage the Site.
                </Text>
              </div>
            </Form>
            {addSiteUserMutation.error && (
              <Text variant="description" className="my-xs text-text-danger">
                {parseApiErrorMessage(addSiteUserMutation.error)}
              </Text>
            )}
          </div>
        </Modal>
      )}
    </Formik>
  )
}
