import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { useSite } from 'src/contexts/site'
import * as api from 'src/services'
import { Asset } from 'src/types'
import { ASSETS_QUERY_KEY } from './assets.get'

export function useDeleteAssetMutation(): UseMutationResult<
  void,
  unknown,
  Asset
> {
  const queryClient = useQueryClient()
  const { id: currentFactory } = useSite()

  return useMutation({
    mutationFn: async (asset: Asset) => {
      // Need to delete hirearchy asset before general asset due to database constraints
      // TODO: What if the second delete fails? Do we reinstate the first one?
      await api.deleteHierarchyAsset(asset.hierarchyId)
      await api.deleteAsset(asset.assetId)
    },
    onSuccess: () => {
      queryClient.invalidateQueries([ASSETS_QUERY_KEY, currentFactory])
    },
  })
}
