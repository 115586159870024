// ! Red is reserved for showing bad data
// e.g Trend Graph showing bad OPC Quality Data with red dots

const contextGraphColors = [
  '#006911', // 0 Green
  '#E86D00', // 1 Orange
  '#FCC61E', // 2 Yellow
  '#9C45ED', // 3 Purple
  '#AD1457', // 4 Pink
  '#00C1FF', // 5 Teal
  '#8F7157', // 6 Brown
  '#2A80B9', // 7 Blue
  '#C1FF84', // 8 Lime Green -ish
  '#2D3E50', // 9 Dark Blue Grey -ish
]

/* eslint no-bitwise: ["warn", { "allow": ["^", ">>>"] }] */
const funhash = (s: string): number => {
  let h = 0xdeadbeef
  for (let i = 0; i < s.length; i++)
    h = Math.imul(h ^ s.charCodeAt(i), 2654435761)
  return (h ^ (h >>> 16)) >>> 0
}

export const getGraphColor = (id = ''): string =>
  contextGraphColors[funhash(id) % contextGraphColors.length]

export default contextGraphColors
