import React from 'react'
import { isEqual } from 'lodash'
import { Node } from 'src/conditions/ast'
import { isDefined, FetchStatus } from 'src/types'
import { Button } from 'src/components/ui'
import { useShallowEqualEffect } from './condition-form.utils'
import { ConditionInput } from './ConditionInput'
import {
  parseCondition,
  createNode,
  newFormCondition,
  joinNodes,
  FormCondition,
} from './formConditions'

interface ConditionFormProps {
  condition?: Node
  setCondition: (node?: Node) => void
  close: any
  tags: Record<string, string>
  filterUpdateStatus: any
  updateModelFilter: any
  setDisplayTags: (tags: string[]) => void
  instantUpdate: boolean
  onEditing: (editing: boolean) => void
}

// the condition may be un-parseable
const parse = (condition: Node | undefined): FormCondition[] => {
  try {
    return parseCondition(condition)
  } catch (error) {
    console.error(error)
    return []
  }
}

export function ConditionForm({
  condition,
  setCondition,
  close,
  tags,
  filterUpdateStatus,
  updateModelFilter,
  setDisplayTags,
  instantUpdate,
  onEditing,
}: ConditionFormProps): JSX.Element {
  // We update condition as the form updates to be able to
  // dispaly the filter. Therefore we keep a copy of the initial
  // condition value to be able to cancel changes
  const [initialCondition, setInitialCondition] = React.useState(condition)
  const [formConditions, setFormConditions] = React.useState(parse(condition))

  // work out which tags to display
  const displayTags = React.useMemo(() => {
    const allDisplayTags = formConditions.map(c => c.tag).filter(isDefined)
    // make a unique list
    return [...new Set(allDisplayTags)]
  }, [formConditions])

  const prevFilterUpdateStatus = React.useRef(filterUpdateStatus)
  React.useEffect(() => {
    if (
      prevFilterUpdateStatus.current !== FetchStatus.success &&
      filterUpdateStatus === FetchStatus.success
    ) {
      close()
    }
    prevFilterUpdateStatus.current = filterUpdateStatus
  }, [filterUpdateStatus, close])

  // update the display tags if they have changed
  useShallowEqualEffect(() => {
    setDisplayTags(displayTags)
  }, [displayTags, setDisplayTags])

  // see if we should update the upstream condition Node
  useShallowEqualEffect(() => {
    // Set the condition based on the valid formConditions
    const nodes = formConditions
      .map(c => createNode(c))
      .filter((n): n is Node => !!n)
    if (nodes.length > 0) {
      const newCondition = joinNodes(nodes)

      if (!isEqual(newCondition, condition)) {
        setCondition(newCondition)
      }
    } else {
      setCondition(undefined)
    }
  }, [condition, formConditions, setCondition])

  const updateCondition = (id: string, props: FormCondition): void => {
    const updatedConditions = formConditions.map(c => {
      if (c.id === id) {
        return props
      }
      return c
    })
    setFormConditions(updatedConditions)
  }

  const reset = (condition?: Node): void => {
    setFormConditions(parseCondition(condition))
  }

  const addCondition = (): void => {
    const newCondition = newFormCondition()
    setFormConditions([...formConditions, newCondition])
  }

  const removeCondition = (id: string): void => {
    const remainingConditions = formConditions.filter(c => c.id !== id)
    setFormConditions(remainingConditions)
  }

  const inputs = formConditions.map(condition => (
    <ConditionInput
      tags={tags}
      value={condition}
      updateCondition={updateCondition}
      key={condition.id}
      removeCondition={removeCondition}
    />
  ))

  const handleSaveOrDisplay = (): void => {
    setInitialCondition(condition)
    close()
    updateModelFilter(condition)
  }

  const handleClose = (): void => {
    setCondition(initialCondition)
    reset(initialCondition)
    close()
    onEditing(false)
  }

  return (
    <form className="flex flex-col items-start gap-[1em]">
      {inputs}
      <Button
        type="button"
        variant="tertiary"
        onClick={addCondition}
        title="Add condition"
      ></Button>
      <div className="flex items-center gap-[0.5em]">
        <Button
          title={instantUpdate ? 'Save' : 'Apply'}
          type="submit"
          variant="primary"
          color="primary"
          disabled={!condition || isEqual(condition, initialCondition)}
          isPending={filterUpdateStatus === FetchStatus.pending}
          onClick={handleSaveOrDisplay}
        />
        {filterUpdateStatus !== FetchStatus.pending && (
          <Button
            type="button"
            title="Cancel"
            variant="tertiary"
            onClick={handleClose}
          />
        )}
      </div>
    </form>
  )
}
