import React from 'react'
import toast from 'react-hot-toast'
import { WithAnomalyProps } from 'src/types'
import { timeAgo } from 'src/utility/time'
import { Text, Button, TextAreaInput, Card } from 'src/components/ui'
import { useAddAnomalyCommentMutation } from 'anomalies/api'

export function Comments({ anomaly }: WithAnomalyProps): JSX.Element {
  const [isHidden, setIsHidden] = React.useState(false)
  const [isActive, setIsActive] = React.useState(false)
  const [comment, setComment] = React.useState('')

  const mutation = useAddAnomalyCommentMutation()

  const form = React.useRef<HTMLFormElement>(null)
  const textarea = React.useRef<HTMLTextAreaElement>(null)

  async function addComment(): Promise<void> {
    await mutation.mutateAsync(
      { anomalyId: anomaly.id, comment },
      {
        onSuccess: () => {
          setIsActive(false)
          setComment('')
        },
        onError: () => {
          toast.error('Failed to add comment', { position: 'top-right' })
        },
      },
    )
  }

  return (
    <Card className="col-span-2">
      <div className="flex items-center gap-xs">
        <Text variant="title" bold>
          Comments ({anomaly.comments.length})
        </Text>
        <Button
          title={isHidden ? 'Show' : 'Hide'}
          onClick={() => setIsHidden(isHidden => !isHidden)}
          variant="tertiary"
        />
      </div>
      {!isHidden && (
        <>
          <form
            className="my-xs"
            onSubmit={e => {
              e.preventDefault()
              addComment()
              if (textarea.current) {
                textarea.current.blur()
              }
            }}
            ref={form}
          >
            <TextAreaInput
              value={comment}
              onChange={e => setComment(e.target.value)}
              placeholder="Add a comment..."
              onFocus={() => setIsActive(true)}
              onKeyPress={e => {
                if (e.key === 'Enter' && e.shiftKey) {
                  e.preventDefault()
                  if (form.current) {
                    form.current.dispatchEvent(new Event('submit'))
                  }
                }
              }}
            />
            {isActive && (
              <div className="mt-xs flex justify-end gap-s">
                {!mutation.isLoading && (
                  <Button
                    title="Cancel"
                    onClick={() => {
                      setIsActive(false)
                      setComment('')
                    }}
                    variant="tertiary"
                  />
                )}
                <Button
                  title="Comment"
                  type="submit"
                  variant="primary"
                  disabled={comment === ''}
                  isPending={mutation.isLoading}
                />
              </div>
            )}
          </form>
          <ul className="m-0 grid list-none gap-xs break-words p-0">
            {anomaly.comments
              .sort((a, b) => b.at.valueOf() - a.at.valueOf())
              .map(({ id, by, at, comment }) => (
                <li key={id}>
                  <div className="my-2xs">
                    <Text>{by}</Text>{' '}
                    <Text className="text-text-tertiary">
                      {timeAgo(at.valueOf())}
                    </Text>
                  </div>
                  <Text className="whitespace-pre-wrap">{comment}</Text>
                </li>
              ))}
          </ul>
        </>
      )}
    </Card>
  )
}
