import { DefaultTheme } from 'styled-components'

export const theme: DefaultTheme = {
  colors: {
    brand: '#00DC00',
    danger: '#E85746',
    primary: '#3366cc',
    prediction: '#F3C61D',
    warning: '#F3C61D',
    success: '#83C751',
    subtle: '#808080',
    black: '#333333',
    white: 'white',
    filter: 'rgba(0, 0, 0, 0.2)',
    chart: [
      '#007FAA',
      '#00552A',
      '#9932CC',
      '#000000',
      '#803224',
      '#1F3A93',
      '#40806A',
      '#696969',
      '#8D6708',
    ],
  },
  borderRadius: ['0px', '4px', '8px'],
  border: '1px solid #E0E0E0',
  fontSize: ['0.9rem', '1rem', '1.2rem'],
}
