import React from 'react'
import { Button, Banner, ConfirmModal } from 'src/components/ui'

interface AnomalyAnalysisInfoProps {
  withConfirmation: boolean
  pending: boolean
  onUpdate: () => void
  disabled?: boolean
}

export function AnomalyAnalysisInfo({
  withConfirmation,
  pending,
  onUpdate,
  disabled,
}: AnomalyAnalysisInfoProps): JSX.Element {
  const [showConfirm, setShowConfirm] = React.useState(false)
  const handleUpdate = (): void => {
    onUpdate()
    setShowConfirm(false)
  }

  return (
    <>
      <Banner
        variant="info"
        className="col-span-2"
        rightComponent={
          <Button
            variant="primary"
            disabled={disabled}
            title={`${
              withConfirmation
                ? 'Save changes and identify anomalies'
                : 'Identify anomalies'
            }`}
            onClick={() =>
              withConfirmation ? setShowConfirm(true) : handleUpdate()
            }
          />
        }
      >
        {withConfirmation
          ? `Your changes have not yet been saved. Click here to save your changes and re-run anomaly identification.  ${
              disabled
                ? 'At least one threshold must be configured to identify anomalies'
                : ''
            }`
          : `Your model has completed training! Before identifying anomalies, you can
      fine-tune the model by adjusting anomaly thresholds and optionally setting filters. ${
        disabled
          ? 'At least one threshold must be configured to identify anomalies'
          : ''
      }`}
      </Banner>
      {showConfirm && (
        <ConfirmModal
          text="Are you sure you want to re-run anomaly identification?"
          subText={
            <>
              <strong>Warning</strong>: Re-running anomaly identification will
              delete <em>all</em> previously identified anomalies for this
              model.
              <br />
              <br />
              Once the process completes, a new set of historical anomalies will
              be available for analysis.
            </>
          }
          pending={pending}
          renderConfirmButton={() => (
            <Button
              variant="primary"
              disabled={disabled}
              title="OK"
              onClick={handleUpdate}
              isPending={pending}
            />
          )}
          isOpen={showConfirm}
          setIsOpen={setShowConfirm}
        />
      )}
    </>
  )
}
