import { useParams } from 'react-router-dom'
import { Button, Spinner, Text } from 'src/components/ui'
import { ErrorDisplay } from 'pages/app'
import { useWorkspace } from '../api'
import { SegmentationCard } from './workspace-segmentations'

export function WorkspaceSegmentations(): JSX.Element {
  const { workspaceId } = useParams()
  if (!workspaceId) {
    throw new Error('Workspace id is required')
  }
  const workspaceQuery = useWorkspace(
    workspaceId,
    workspace => workspace.segmentations,
  )

  if (workspaceQuery.isLoading) {
    return <Spinner />
  }

  if (workspaceQuery.isError) {
    return (
      <ErrorDisplay
        error={workspaceQuery.error}
        message="Failed to fetch the Workspace"
        action={workspaceQuery.refetch}
      />
    )
  }

  return (
    <div className="flex flex-1 flex-col gap-s py-s">
      <div className="flex items-center justify-between">
        <Text variant="description">
          Lists all of the segmentations assigned to this workspace.
        </Text>
        <Button variant="primary" title="Add New Segmentation" />
      </div>
      <div className="flex flex-wrap gap-m">
        {Object.values(workspaceQuery.data).map(s => (
          <SegmentationCard key={s.id} segmentation={s} />
        ))}
      </div>
    </div>
  )
}
