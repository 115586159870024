import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import { Icon } from '../icons'
import { Text } from '../typography'

const variants = {
  info: {
    icon: light('circle-info'),
    boxStyles: 'bg-background-secondary border-border-success-secondary',
    iconColor: 'text-icon-success-secondary',
  },
  success: {
    icon: light('circle-check'),
    boxStyles: 'bg-background-success border-border-success',
    iconColor: 'text-icon-brand',
  },
  warning: {
    icon: light('triangle-exclamation'),
    boxStyles: 'bg-background-warning-secondary border-border-warning',
    iconColor: 'text-icon-warning',
  },
  error: {
    icon: light('circle-minus'),
    boxStyles: 'bg-background-danger-tertiary-hover border-border-danger',
    iconColor: 'text-text-danger',
  },
  custom: {
    icon: null,
    boxStyles: 'bg-background-hover border-border-secondary',
    iconColor: 'text-icon-brand',
  },
}

type Props = {
  children: string | React.ReactNode
  variant?: keyof typeof variants
  className?: string
  rightComponent?: React.ReactNode
}

export function Banner({
  variant = 'info',
  children,
  className,
  rightComponent,
}: Props): JSX.Element {
  return (
    <div
      role="banner"
      className={classNames(
        'flex items-center justify-between rounded-2xs border border-solid border-l-[4px] p-xs gap-xs',
        variants[variant].boxStyles,
        className,
      )}
    >
      <div className="flex items-center gap-xs">
        {variants[variant].icon && (
          <Icon
            data-testid="icon"
            icon={variants[variant].icon as IconProp}
            size="big"
            className={variants[variant].iconColor}
          />
        )}
        {typeof children === 'string' ? (
          <Text variant="content" bold>
            {children}
          </Text>
        ) : (
          children
        )}
      </div>
      {rightComponent && rightComponent}
    </div>
  )
}
