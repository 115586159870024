import { useState, useRef, useEffect, RefObject } from 'react'

type HeightListener = {
  wrapperRef: RefObject<HTMLDivElement>
  height: number | undefined
}

export function useHeightListener(): HeightListener {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState<number>()

  useEffect(() => {
    const handleResize = (): void => {
      if (wrapperRef.current) {
        setHeight(wrapperRef.current.clientHeight)
      }
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [wrapperRef, setHeight])

  return { wrapperRef, height }
}
