// times in ms since epoch
export type TimeRange = {
  from: number
  to: number
}

export type Period = {
  period: string
}

export type TimeRangeOrPeriod = TimeRange | Period

// determine if the TimeRangeOrPeriod is a TimeRange
export const isTimeRange = (value: TimeRangeOrPeriod): value is TimeRange => {
  const x = value as TimeRange
  return x.from !== undefined && x.to !== undefined
}

// if the ranges overlap, return the overlapping range
// otherwise, return undefined
export function timeRangeOverlap(
  a: TimeRange,
  b: TimeRange,
): TimeRange | undefined {
  if (a.to >= b.from && a.from <= b.to) {
    return {
      from: Math.max(a.from, b.from),
      to: Math.min(a.to, b.to),
    }
  }
  return undefined
}
