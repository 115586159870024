import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { OrganizationStatus, Site, SiteUser, siteRoleFromGql } from 'src/types'
import * as api from 'src/services'

const SITE_QUERY = 'site'
export const SITE_USERS = 'siteUsers'
const MY_SITES = 'mySites'
const SITE_CREDITS = 'siteCredits'

function toSiteUsers(
  users: api.SiteUser[],
  invitations: api.GqlFactoryInvitation[],
): SiteUser[] {
  return [
    ...users.map(user => ({
      id: user.id,
      email: user.email ?? '',
      name: user.name ?? '',
      role: siteRoleFromGql(user.role),
      status: OrganizationStatus.ACTIVE,
      userTag: user.userTag ?? 'Home',
      addedBy: user.addedBy ?? '',
      homeOrganization: {
        id: user.homeOrganization?.id ?? '',
        name: user.homeOrganization?.name ?? '',
      },
    })),
    ...invitations.map(invitation => ({
      id: invitation.invitee.id,
      email: invitation.invitee.email ?? '',
      name: invitation.invitee.name ?? '',
      role: siteRoleFromGql(invitation.role),
      status: OrganizationStatus.PENDING,
      userTag: 'Verified',
      addedBy: invitation.inviter.name ?? '',
      homeOrganization: {
        id: invitation.invitee.customer?.id ?? '',
        name: invitation.invitee.customer?.name ?? '',
      },
    })),
  ]
}

function toOrganizationSites(
  sites: api.GqlFetchMyFactoriesQuery['me']['factories'],
): Site[] {
  return sites.map(site => ({
    id: site.id,
    name: site.name,
    orgName: site.customer.name,
    orgId: site.customer.id,
    viewerRole: site.viewerRole ? siteRoleFromGql(site.viewerRole) : undefined,
    numOfGateways: site.agents?.items?.length ?? 0,
    numOfUsers: site.users?.items?.length ?? 0,
  }))
}

export function useSite(id: string): UseQueryResult<Site | null, Error> {
  return useQuery({
    queryKey: [SITE_QUERY, id],
    queryFn: async () => api.fetchFactory(id),
  })
}

// TODO this should be broken up into 2 queries
// there is no need to invalidate invitations on remove user
// and no need to invalidate users on cancel invitation
export function useSiteUsers({
  siteId,
}: {
  siteId: string
}): UseQueryResult<SiteUser[], Error> {
  return useQuery({
    queryKey: [SITE_USERS, siteId],
    queryFn: async () => {
      const users = await api.fetchFactoryUsers({ factory: siteId })
      const invitedUsers = await api.fetchFactoryInvitations({
        factory: siteId,
      })
      return toSiteUsers(
        users,
        invitedUsers.filter(invite => invite.state === 'ACTIVE'),
      )
    },
  })
}

export function useMySites(): UseQueryResult<Site[], Error> {
  return useQuery({
    queryKey: [MY_SITES],
    queryFn: async () => {
      const sites = await api.fetchMyFactories()
      return toOrganizationSites(sites.me.factories)
    },
  })
}

export function useSiteCredits({
  siteId,
}: {
  siteId: string
}): UseQueryResult<api.GqlFetchFactoryCreditsQuery['factory']> {
  return useQuery({
    queryKey: [SITE_CREDITS, siteId],
    queryFn: async () => {
      const data = await api.fetchFactoryCredits({ factory: siteId })
      return data.factory
    },
  })
}
