// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.1
//   protoc               v5.26.1
// source: segmentation.proto

/* eslint-disable */
import _m0 from 'protobufjs/minimal'
import { Meta } from './common'
import { Duration } from './google/protobuf/duration'
import { TimeSeries, TimeSeriesExpression } from './time_series'

export const protobufPackage = 'types.v1'

export interface Segmentation {
  kind?:
    | { $case: 'segRef'; value: string }
    | { $case: 'segDef'; value: SegmentationDef }
    | undefined
}

export interface SavedSegmentation {
  /** For storing TimeSeriesDefs in a workspace. Couples the def with an id and metadata. */
  id: string
  meta: Meta | undefined
  segDef: SegmentationDef | undefined
}

export interface SegmentationDef {
  label: string
  description: string
  kind?:
    | { $case: 'condition'; value: TimeSeriesExpression }
    | { $case: 'valueChange'; value: ValueChange }
    | { $case: 'slidingWindow'; value: SlidingWindow }
    | { $case: 'derived'; value: ModifiedSegmentation }
    | undefined
}

export interface TransitionWhitelistFloat {
  values: number[]
}

export interface TransitionWhitelist {
  kind?: { $case: 'twFloat'; value: TransitionWhitelistFloat } | undefined
}

export interface ValueChange {
  timeSeries: TimeSeries | undefined
  /**
   * bool            include_leading   = 3; // whether to include a transition_value period in the segment you transition into
   * bool            include_trailing  = 4; // whether to include a transition_value period in the segment you transition out of
   */
  transitionWhitelist: TransitionWhitelist | undefined
}

export interface SlidingWindow {
  length: Duration | undefined
  shift: Duration | undefined
}

export interface TimeReshape {
  shiftStart: Duration | undefined
  shiftEnd: Duration | undefined
}

export interface ConditionReshape {
  condition: TimeSeriesExpression | undefined
}

export interface OrdinalShift {
  shift: number
}

export interface SegmentationModification {
  kind?:
    | { $case: 'timeReshape'; value: TimeReshape }
    | { $case: 'conditionShift'; value: ConditionReshape }
    | {
        $case: 'ordinalShift'
        value: OrdinalShift
      }
    | undefined
}

export interface ModifiedSegmentation {
  source: Segmentation | undefined
  change: SegmentationModification | undefined
}

function createBaseSegmentation(): Segmentation {
  return { kind: undefined }
}

export const Segmentation = {
  encode(
    message: Segmentation,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    switch (message.kind?.$case) {
      case 'segRef':
        writer.uint32(10).string(message.kind.value)
        break
      case 'segDef':
        SegmentationDef.encode(
          message.kind.value,
          writer.uint32(18).fork(),
        ).ldelim()
        break
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Segmentation {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseSegmentation()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.kind = { $case: 'segRef', value: reader.string() }
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.kind = {
            $case: 'segDef',
            value: SegmentationDef.decode(reader, reader.uint32()),
          }
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): Segmentation {
    return {
      kind: isSet(object.seg_ref)
        ? { $case: 'segRef', value: globalThis.String(object.seg_ref) }
        : isSet(object.seg_def)
        ? { $case: 'segDef', value: SegmentationDef.fromJSON(object.seg_def) }
        : undefined,
    }
  },

  toJSON(message: Segmentation): unknown {
    const obj: any = {}
    if (message.kind?.$case === 'segRef') {
      obj.seg_ref = message.kind.value
    }
    if (message.kind?.$case === 'segDef') {
      obj.seg_def = SegmentationDef.toJSON(message.kind.value)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<Segmentation>, I>>(
    base?: I,
  ): Segmentation {
    return Segmentation.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<Segmentation>, I>>(
    object: I,
  ): Segmentation {
    const message = createBaseSegmentation()
    if (
      object.kind?.$case === 'segRef' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = { $case: 'segRef', value: object.kind.value }
    }
    if (
      object.kind?.$case === 'segDef' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'segDef',
        value: SegmentationDef.fromPartial(object.kind.value),
      }
    }
    return message
  },
}

function createBaseSavedSegmentation(): SavedSegmentation {
  return { id: '', meta: undefined, segDef: undefined }
}

export const SavedSegmentation = {
  encode(
    message: SavedSegmentation,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id)
    }
    if (message.meta !== undefined) {
      Meta.encode(message.meta, writer.uint32(18).fork()).ldelim()
    }
    if (message.segDef !== undefined) {
      SegmentationDef.encode(message.segDef, writer.uint32(26).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SavedSegmentation {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseSavedSegmentation()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.id = reader.string()
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.meta = Meta.decode(reader, reader.uint32())
          continue
        case 3:
          if (tag !== 26) {
            break
          }

          message.segDef = SegmentationDef.decode(reader, reader.uint32())
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): SavedSegmentation {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : '',
      meta: isSet(object.meta) ? Meta.fromJSON(object.meta) : undefined,
      segDef: isSet(object.seg_def)
        ? SegmentationDef.fromJSON(object.seg_def)
        : undefined,
    }
  },

  toJSON(message: SavedSegmentation): unknown {
    const obj: any = {}
    if (message.id !== '') {
      obj.id = message.id
    }
    if (message.meta !== undefined) {
      obj.meta = Meta.toJSON(message.meta)
    }
    if (message.segDef !== undefined) {
      obj.seg_def = SegmentationDef.toJSON(message.segDef)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<SavedSegmentation>, I>>(
    base?: I,
  ): SavedSegmentation {
    return SavedSegmentation.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<SavedSegmentation>, I>>(
    object: I,
  ): SavedSegmentation {
    const message = createBaseSavedSegmentation()
    message.id = object.id ?? ''
    message.meta =
      object.meta !== undefined && object.meta !== null
        ? Meta.fromPartial(object.meta)
        : undefined
    message.segDef =
      object.segDef !== undefined && object.segDef !== null
        ? SegmentationDef.fromPartial(object.segDef)
        : undefined
    return message
  },
}

function createBaseSegmentationDef(): SegmentationDef {
  return { label: '', description: '', kind: undefined }
}

export const SegmentationDef = {
  encode(
    message: SegmentationDef,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.label !== '') {
      writer.uint32(10).string(message.label)
    }
    if (message.description !== '') {
      writer.uint32(18).string(message.description)
    }
    switch (message.kind?.$case) {
      case 'condition':
        TimeSeriesExpression.encode(
          message.kind.value,
          writer.uint32(802).fork(),
        ).ldelim()
        break
      case 'valueChange':
        ValueChange.encode(
          message.kind.value,
          writer.uint32(810).fork(),
        ).ldelim()
        break
      case 'slidingWindow':
        SlidingWindow.encode(
          message.kind.value,
          writer.uint32(818).fork(),
        ).ldelim()
        break
      case 'derived':
        ModifiedSegmentation.encode(
          message.kind.value,
          writer.uint32(826).fork(),
        ).ldelim()
        break
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SegmentationDef {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseSegmentationDef()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.label = reader.string()
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.description = reader.string()
          continue
        case 100:
          if (tag !== 802) {
            break
          }

          message.kind = {
            $case: 'condition',
            value: TimeSeriesExpression.decode(reader, reader.uint32()),
          }
          continue
        case 101:
          if (tag !== 810) {
            break
          }

          message.kind = {
            $case: 'valueChange',
            value: ValueChange.decode(reader, reader.uint32()),
          }
          continue
        case 102:
          if (tag !== 818) {
            break
          }

          message.kind = {
            $case: 'slidingWindow',
            value: SlidingWindow.decode(reader, reader.uint32()),
          }
          continue
        case 103:
          if (tag !== 826) {
            break
          }

          message.kind = {
            $case: 'derived',
            value: ModifiedSegmentation.decode(reader, reader.uint32()),
          }
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): SegmentationDef {
    return {
      label: isSet(object.label) ? globalThis.String(object.label) : '',
      description: isSet(object.description)
        ? globalThis.String(object.description)
        : '',
      kind: isSet(object.condition)
        ? {
            $case: 'condition',
            value: TimeSeriesExpression.fromJSON(object.condition),
          }
        : isSet(object.value_change)
        ? {
            $case: 'valueChange',
            value: ValueChange.fromJSON(object.value_change),
          }
        : isSet(object.sliding_window)
        ? {
            $case: 'slidingWindow',
            value: SlidingWindow.fromJSON(object.sliding_window),
          }
        : isSet(object.derived)
        ? {
            $case: 'derived',
            value: ModifiedSegmentation.fromJSON(object.derived),
          }
        : undefined,
    }
  },

  toJSON(message: SegmentationDef): unknown {
    const obj: any = {}
    if (message.label !== '') {
      obj.label = message.label
    }
    if (message.description !== '') {
      obj.description = message.description
    }
    if (message.kind?.$case === 'condition') {
      obj.condition = TimeSeriesExpression.toJSON(message.kind.value)
    }
    if (message.kind?.$case === 'valueChange') {
      obj.value_change = ValueChange.toJSON(message.kind.value)
    }
    if (message.kind?.$case === 'slidingWindow') {
      obj.sliding_window = SlidingWindow.toJSON(message.kind.value)
    }
    if (message.kind?.$case === 'derived') {
      obj.derived = ModifiedSegmentation.toJSON(message.kind.value)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<SegmentationDef>, I>>(
    base?: I,
  ): SegmentationDef {
    return SegmentationDef.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<SegmentationDef>, I>>(
    object: I,
  ): SegmentationDef {
    const message = createBaseSegmentationDef()
    message.label = object.label ?? ''
    message.description = object.description ?? ''
    if (
      object.kind?.$case === 'condition' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'condition',
        value: TimeSeriesExpression.fromPartial(object.kind.value),
      }
    }
    if (
      object.kind?.$case === 'valueChange' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'valueChange',
        value: ValueChange.fromPartial(object.kind.value),
      }
    }
    if (
      object.kind?.$case === 'slidingWindow' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'slidingWindow',
        value: SlidingWindow.fromPartial(object.kind.value),
      }
    }
    if (
      object.kind?.$case === 'derived' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'derived',
        value: ModifiedSegmentation.fromPartial(object.kind.value),
      }
    }
    return message
  },
}

function createBaseTransitionWhitelistFloat(): TransitionWhitelistFloat {
  return { values: [] }
}

export const TransitionWhitelistFloat = {
  encode(
    message: TransitionWhitelistFloat,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    writer.uint32(10).fork()
    for (const v of message.values) {
      writer.float(v)
    }
    writer.ldelim()
    return writer
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): TransitionWhitelistFloat {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseTransitionWhitelistFloat()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag === 13) {
            message.values.push(reader.float())

            continue
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos
            while (reader.pos < end2) {
              message.values.push(reader.float())
            }

            continue
          }

          break
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): TransitionWhitelistFloat {
    return {
      values: globalThis.Array.isArray(object?.values)
        ? object.values.map((e: any) => globalThis.Number(e))
        : [],
    }
  },

  toJSON(message: TransitionWhitelistFloat): unknown {
    const obj: any = {}
    if (message.values?.length) {
      obj.values = message.values
    }
    return obj
  },

  create<I extends Exact<DeepPartial<TransitionWhitelistFloat>, I>>(
    base?: I,
  ): TransitionWhitelistFloat {
    return TransitionWhitelistFloat.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<TransitionWhitelistFloat>, I>>(
    object: I,
  ): TransitionWhitelistFloat {
    const message = createBaseTransitionWhitelistFloat()
    message.values = object.values?.map(e => e) || []
    return message
  },
}

function createBaseTransitionWhitelist(): TransitionWhitelist {
  return { kind: undefined }
}

export const TransitionWhitelist = {
  encode(
    message: TransitionWhitelist,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    switch (message.kind?.$case) {
      case 'twFloat':
        TransitionWhitelistFloat.encode(
          message.kind.value,
          writer.uint32(10).fork(),
        ).ldelim()
        break
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TransitionWhitelist {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseTransitionWhitelist()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.kind = {
            $case: 'twFloat',
            value: TransitionWhitelistFloat.decode(reader, reader.uint32()),
          }
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): TransitionWhitelist {
    return {
      kind: isSet(object.tw_float)
        ? {
            $case: 'twFloat',
            value: TransitionWhitelistFloat.fromJSON(object.tw_float),
          }
        : undefined,
    }
  },

  toJSON(message: TransitionWhitelist): unknown {
    const obj: any = {}
    if (message.kind?.$case === 'twFloat') {
      obj.tw_float = TransitionWhitelistFloat.toJSON(message.kind.value)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<TransitionWhitelist>, I>>(
    base?: I,
  ): TransitionWhitelist {
    return TransitionWhitelist.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<TransitionWhitelist>, I>>(
    object: I,
  ): TransitionWhitelist {
    const message = createBaseTransitionWhitelist()
    if (
      object.kind?.$case === 'twFloat' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'twFloat',
        value: TransitionWhitelistFloat.fromPartial(object.kind.value),
      }
    }
    return message
  },
}

function createBaseValueChange(): ValueChange {
  return { timeSeries: undefined, transitionWhitelist: undefined }
}

export const ValueChange = {
  encode(
    message: ValueChange,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.timeSeries !== undefined) {
      TimeSeries.encode(message.timeSeries, writer.uint32(10).fork()).ldelim()
    }
    if (message.transitionWhitelist !== undefined) {
      TransitionWhitelist.encode(
        message.transitionWhitelist,
        writer.uint32(18).fork(),
      ).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ValueChange {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseValueChange()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.timeSeries = TimeSeries.decode(reader, reader.uint32())
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.transitionWhitelist = TransitionWhitelist.decode(
            reader,
            reader.uint32(),
          )
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): ValueChange {
    return {
      timeSeries: isSet(object.time_series)
        ? TimeSeries.fromJSON(object.time_series)
        : undefined,
      transitionWhitelist: isSet(object.transition_whitelist)
        ? TransitionWhitelist.fromJSON(object.transition_whitelist)
        : undefined,
    }
  },

  toJSON(message: ValueChange): unknown {
    const obj: any = {}
    if (message.timeSeries !== undefined) {
      obj.time_series = TimeSeries.toJSON(message.timeSeries)
    }
    if (message.transitionWhitelist !== undefined) {
      obj.transition_whitelist = TransitionWhitelist.toJSON(
        message.transitionWhitelist,
      )
    }
    return obj
  },

  create<I extends Exact<DeepPartial<ValueChange>, I>>(base?: I): ValueChange {
    return ValueChange.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<ValueChange>, I>>(
    object: I,
  ): ValueChange {
    const message = createBaseValueChange()
    message.timeSeries =
      object.timeSeries !== undefined && object.timeSeries !== null
        ? TimeSeries.fromPartial(object.timeSeries)
        : undefined
    message.transitionWhitelist =
      object.transitionWhitelist !== undefined &&
      object.transitionWhitelist !== null
        ? TransitionWhitelist.fromPartial(object.transitionWhitelist)
        : undefined
    return message
  },
}

function createBaseSlidingWindow(): SlidingWindow {
  return { length: undefined, shift: undefined }
}

export const SlidingWindow = {
  encode(
    message: SlidingWindow,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.length !== undefined) {
      Duration.encode(message.length, writer.uint32(10).fork()).ldelim()
    }
    if (message.shift !== undefined) {
      Duration.encode(message.shift, writer.uint32(18).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SlidingWindow {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseSlidingWindow()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.length = Duration.decode(reader, reader.uint32())
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.shift = Duration.decode(reader, reader.uint32())
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): SlidingWindow {
    return {
      length: isSet(object.length)
        ? Duration.fromJSON(object.length)
        : undefined,
      shift: isSet(object.shift) ? Duration.fromJSON(object.shift) : undefined,
    }
  },

  toJSON(message: SlidingWindow): unknown {
    const obj: any = {}
    if (message.length !== undefined) {
      obj.length = Duration.toJSON(message.length)
    }
    if (message.shift !== undefined) {
      obj.shift = Duration.toJSON(message.shift)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<SlidingWindow>, I>>(
    base?: I,
  ): SlidingWindow {
    return SlidingWindow.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<SlidingWindow>, I>>(
    object: I,
  ): SlidingWindow {
    const message = createBaseSlidingWindow()
    message.length =
      object.length !== undefined && object.length !== null
        ? Duration.fromPartial(object.length)
        : undefined
    message.shift =
      object.shift !== undefined && object.shift !== null
        ? Duration.fromPartial(object.shift)
        : undefined
    return message
  },
}

function createBaseTimeReshape(): TimeReshape {
  return { shiftStart: undefined, shiftEnd: undefined }
}

export const TimeReshape = {
  encode(
    message: TimeReshape,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.shiftStart !== undefined) {
      Duration.encode(message.shiftStart, writer.uint32(10).fork()).ldelim()
    }
    if (message.shiftEnd !== undefined) {
      Duration.encode(message.shiftEnd, writer.uint32(18).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TimeReshape {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseTimeReshape()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.shiftStart = Duration.decode(reader, reader.uint32())
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.shiftEnd = Duration.decode(reader, reader.uint32())
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): TimeReshape {
    return {
      shiftStart: isSet(object.shift_start)
        ? Duration.fromJSON(object.shift_start)
        : undefined,
      shiftEnd: isSet(object.shift_end)
        ? Duration.fromJSON(object.shift_end)
        : undefined,
    }
  },

  toJSON(message: TimeReshape): unknown {
    const obj: any = {}
    if (message.shiftStart !== undefined) {
      obj.shift_start = Duration.toJSON(message.shiftStart)
    }
    if (message.shiftEnd !== undefined) {
      obj.shift_end = Duration.toJSON(message.shiftEnd)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<TimeReshape>, I>>(base?: I): TimeReshape {
    return TimeReshape.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<TimeReshape>, I>>(
    object: I,
  ): TimeReshape {
    const message = createBaseTimeReshape()
    message.shiftStart =
      object.shiftStart !== undefined && object.shiftStart !== null
        ? Duration.fromPartial(object.shiftStart)
        : undefined
    message.shiftEnd =
      object.shiftEnd !== undefined && object.shiftEnd !== null
        ? Duration.fromPartial(object.shiftEnd)
        : undefined
    return message
  },
}

function createBaseConditionReshape(): ConditionReshape {
  return { condition: undefined }
}

export const ConditionReshape = {
  encode(
    message: ConditionReshape,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.condition !== undefined) {
      TimeSeriesExpression.encode(
        message.condition,
        writer.uint32(10).fork(),
      ).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConditionReshape {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseConditionReshape()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.condition = TimeSeriesExpression.decode(
            reader,
            reader.uint32(),
          )
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): ConditionReshape {
    return {
      condition: isSet(object.condition)
        ? TimeSeriesExpression.fromJSON(object.condition)
        : undefined,
    }
  },

  toJSON(message: ConditionReshape): unknown {
    const obj: any = {}
    if (message.condition !== undefined) {
      obj.condition = TimeSeriesExpression.toJSON(message.condition)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<ConditionReshape>, I>>(
    base?: I,
  ): ConditionReshape {
    return ConditionReshape.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<ConditionReshape>, I>>(
    object: I,
  ): ConditionReshape {
    const message = createBaseConditionReshape()
    message.condition =
      object.condition !== undefined && object.condition !== null
        ? TimeSeriesExpression.fromPartial(object.condition)
        : undefined
    return message
  },
}

function createBaseOrdinalShift(): OrdinalShift {
  return { shift: 0 }
}

export const OrdinalShift = {
  encode(
    message: OrdinalShift,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.shift !== 0) {
      writer.uint32(8).int32(message.shift)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrdinalShift {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseOrdinalShift()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break
          }

          message.shift = reader.int32()
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): OrdinalShift {
    return { shift: isSet(object.shift) ? globalThis.Number(object.shift) : 0 }
  },

  toJSON(message: OrdinalShift): unknown {
    const obj: any = {}
    if (message.shift !== 0) {
      obj.shift = Math.round(message.shift)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<OrdinalShift>, I>>(
    base?: I,
  ): OrdinalShift {
    return OrdinalShift.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<OrdinalShift>, I>>(
    object: I,
  ): OrdinalShift {
    const message = createBaseOrdinalShift()
    message.shift = object.shift ?? 0
    return message
  },
}

function createBaseSegmentationModification(): SegmentationModification {
  return { kind: undefined }
}

export const SegmentationModification = {
  encode(
    message: SegmentationModification,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    switch (message.kind?.$case) {
      case 'timeReshape':
        TimeReshape.encode(
          message.kind.value,
          writer.uint32(10).fork(),
        ).ldelim()
        break
      case 'conditionShift':
        ConditionReshape.encode(
          message.kind.value,
          writer.uint32(18).fork(),
        ).ldelim()
        break
      case 'ordinalShift':
        OrdinalShift.encode(
          message.kind.value,
          writer.uint32(26).fork(),
        ).ldelim()
        break
    }
    return writer
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SegmentationModification {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseSegmentationModification()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.kind = {
            $case: 'timeReshape',
            value: TimeReshape.decode(reader, reader.uint32()),
          }
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.kind = {
            $case: 'conditionShift',
            value: ConditionReshape.decode(reader, reader.uint32()),
          }
          continue
        case 3:
          if (tag !== 26) {
            break
          }

          message.kind = {
            $case: 'ordinalShift',
            value: OrdinalShift.decode(reader, reader.uint32()),
          }
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): SegmentationModification {
    return {
      kind: isSet(object.time_reshape)
        ? {
            $case: 'timeReshape',
            value: TimeReshape.fromJSON(object.time_reshape),
          }
        : isSet(object.condition_shift)
        ? {
            $case: 'conditionShift',
            value: ConditionReshape.fromJSON(object.condition_shift),
          }
        : isSet(object.ordinal_shift)
        ? {
            $case: 'ordinalShift',
            value: OrdinalShift.fromJSON(object.ordinal_shift),
          }
        : undefined,
    }
  },

  toJSON(message: SegmentationModification): unknown {
    const obj: any = {}
    if (message.kind?.$case === 'timeReshape') {
      obj.time_reshape = TimeReshape.toJSON(message.kind.value)
    }
    if (message.kind?.$case === 'conditionShift') {
      obj.condition_shift = ConditionReshape.toJSON(message.kind.value)
    }
    if (message.kind?.$case === 'ordinalShift') {
      obj.ordinal_shift = OrdinalShift.toJSON(message.kind.value)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<SegmentationModification>, I>>(
    base?: I,
  ): SegmentationModification {
    return SegmentationModification.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<SegmentationModification>, I>>(
    object: I,
  ): SegmentationModification {
    const message = createBaseSegmentationModification()
    if (
      object.kind?.$case === 'timeReshape' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'timeReshape',
        value: TimeReshape.fromPartial(object.kind.value),
      }
    }
    if (
      object.kind?.$case === 'conditionShift' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'conditionShift',
        value: ConditionReshape.fromPartial(object.kind.value),
      }
    }
    if (
      object.kind?.$case === 'ordinalShift' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'ordinalShift',
        value: OrdinalShift.fromPartial(object.kind.value),
      }
    }
    return message
  },
}

function createBaseModifiedSegmentation(): ModifiedSegmentation {
  return { source: undefined, change: undefined }
}

export const ModifiedSegmentation = {
  encode(
    message: ModifiedSegmentation,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.source !== undefined) {
      Segmentation.encode(message.source, writer.uint32(10).fork()).ldelim()
    }
    if (message.change !== undefined) {
      SegmentationModification.encode(
        message.change,
        writer.uint32(18).fork(),
      ).ldelim()
    }
    return writer
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ModifiedSegmentation {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseModifiedSegmentation()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.source = Segmentation.decode(reader, reader.uint32())
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.change = SegmentationModification.decode(
            reader,
            reader.uint32(),
          )
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): ModifiedSegmentation {
    return {
      source: isSet(object.source)
        ? Segmentation.fromJSON(object.source)
        : undefined,
      change: isSet(object.change)
        ? SegmentationModification.fromJSON(object.change)
        : undefined,
    }
  },

  toJSON(message: ModifiedSegmentation): unknown {
    const obj: any = {}
    if (message.source !== undefined) {
      obj.source = Segmentation.toJSON(message.source)
    }
    if (message.change !== undefined) {
      obj.change = SegmentationModification.toJSON(message.change)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<ModifiedSegmentation>, I>>(
    base?: I,
  ): ModifiedSegmentation {
    return ModifiedSegmentation.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<ModifiedSegmentation>, I>>(
    object: I,
  ): ModifiedSegmentation {
    const message = createBaseModifiedSegmentation()
    message.source =
      object.source !== undefined && object.source !== null
        ? Segmentation.fromPartial(object.source)
        : undefined
    message.change =
      object.change !== undefined && object.change !== null
        ? SegmentationModification.fromPartial(object.change)
        : undefined
    return message
  },
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends globalThis.Array<infer U>
  ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string; value: unknown }
  ? { $case: T['$case']; value?: DeepPartial<T['value']> }
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>

type KeysOfUnion<T> = T extends T ? keyof T : never
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & {
      [K in Exclude<keyof I, KeysOfUnion<P>>]: never
    }

function isSet(value: any): boolean {
  return value !== null && value !== undefined
}
