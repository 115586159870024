import React from 'react'
import { CursorContext, CursorValue } from './CursorContext'

export const useCursor = (): CursorValue => {
  const context = React.useContext(CursorContext)
  // defaultContext must be defined here since it is not allowed
  // to call hooks conditionally
  const defaultContext = React.useState<number>()
  if (context === undefined) {
    // So, apparently it is allowed to call `useCursor` without a CursorProvider
    // in this case the cursor defaults to `undefined`
    // Sounds like a dodgy use of context, but for now we just restore
    // the code to a working state
    return defaultContext
  }
  return context
}
