export interface Asset {
  key: number
  parentKey: number
  name: string
  alias?: string | null
  active: boolean
  assetId: number
  hierarchyId: number
}

export const assetTitle = (asset: Asset): string => asset.alias || asset.name
