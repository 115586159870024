import { GqlTagClassification, ModelTagBase, TagDto } from 'src/services'
import { ModelTypes } from 'src/types'

export const getInputTagWarnings = (
  tag: TagDto,
  modelType?: ModelTypes | null,
  outputTagName?: string | null,
): string[] => {
  const warnings = []
  if (!tag.overriddenLabel && !tag.automaticLabel)
    warnings.push(
      'This tag needs to be analyzed and labeled before use. Labeling tags is currently a process that has to be started manually, so contact Intelecy if you want to use this tag.',
    )

  if (modelType === 'AnomalyModel' && outputTagName === tag.tagName) {
    warnings.push(
      'Anomaly models of type nowcast do not allow using the output as input. To fix this issue, either remove the output from the list of inputs or change the model type once the model has been created.',
    )
  }

  return warnings
}

export function getOutputTagWarnings(tag?: TagDto): string[] {
  if (!tag) return []
  return getOutputWarnings(tag.overriddenLabel ?? tag.automaticLabel)
}

export function getOutputTagWarningsFromModelTag(tag?: ModelTagBase): string[] {
  if (!tag) return []
  return getOutputWarnings(tag.overriddenLabel ?? tag.automaticLabel)
}

function getOutputWarnings(label?: GqlTagClassification | null): string[] {
  const warnings = []
  if (!label) {
    warnings.push(
      'This tag needs to be analyzed and labeled before use. Labeling tags is currently a process that has to be started manually, so contact Intelecy if you want to use this tag.',
    )
  }
  if (label === GqlTagClassification.Categorical) {
    warnings.push(
      'Currently there is no support for selecting categorical values like step and bool as output tags.',
    )
  }
  return warnings
}
