import { Text } from 'src/components/ui'
import { GqlGatewayFragment } from 'src/services'

type Props = {
  name: string
  serverEndpoint: string
  anonymous: boolean
  securityType: string
  securityPolicy: string
  gateway: GqlGatewayFragment
}

export function OpcUaConnectionOverview({
  gateway,
  securityType,
  securityPolicy,
  anonymous,
  serverEndpoint,
  name,
}: Props): JSX.Element {
  return (
    <div className="flex flex-col gap-s">
      <div className="flex flex-1 flex-col gap-s rounded-2xs p-s">
        <div>
          <Text variant="description">OPC-UA Connection Overview</Text>

          <Text className="mt-2xs" bold>
            OPC-UA Connection has been successfully configured
          </Text>
        </div>
        <div className="flex gap-xl">
          <div className="w-1/2 max-w-[350px]">
            <Text variant="description">Gateway ID</Text>
            <Text bold className="inline">
              {gateway.hostname}
            </Text>
            <Text variant="code" className="ml-2xs inline">
              ({gateway.id})
            </Text>
          </div>
          <div className="w-1/2 max-w-[350px]">
            <Text variant="description">Security Mode</Text>
            <Text bold className="mt-2xs">
              {`Type:  ${securityType ?? ''} | Policy: ${securityPolicy ?? ''}`}
            </Text>
          </div>
        </div>
        <div className="flex gap-xl">
          <div className="w-1/2 max-w-[350px]">
            <Text variant="description">Hostname</Text>
            <Text variant="code" className="mt-2xs w-fit leading-[18px]" bold>
              {serverEndpoint}
            </Text>
          </div>
          <div className="w-1/2 max-w-[350px]">
            <Text variant="description">User authentication</Text>
            <Text bold className="mt-2xs">
              Type: {anonymous ? 'Anonymous' : 'User Credentials'}
            </Text>
          </div>
        </div>

        <div>
          <Text variant="description">Connection name (optional)</Text>
          <div>
            <Text className="mt-2xs " bold>
              {name}
            </Text>
          </div>
        </div>
      </div>
    </div>
  )
}
