import { isDefined } from 'src/types'
import {
  ChartMarkerType,
  ChartOptions,
  SeriesOptions,
} from 'src/types/chartTypes'
import { ChartData } from '../../useChartData'

type Kind = 'min' | 'max'
export type MinMax = { min: number; max: number }

// gets the minimun visible data for a series
function series(kind: Kind, options: SeriesOptions, data: ChartData): number {
  if (options.disableRange) {
    // get the min/max of just the values
    return Math[kind](...data.data.map(([, v]) => v))
  }
  // this is the overall min/max
  return data[kind]
}

// gets the minimun visible data for a chart
function chart(kind: Kind, chart: ChartOptions, data: ChartData[]): number {
  const markers = chart.markers ?? []
  // get the min/max of all of the series min/max
  return Math[kind](
    ...chart.data
      .map((options, i) => ({
        options,
        data: data[i],
      }))
      // ignore inactive series
      .filter(({ options }) => !options.inactive)
      .map(({ options, data }) => series(kind, options, data))
      .filter(isDefined),
    // make sure we include chart markers in the chart bounds
    ...markers.map(m =>
      m.type === ChartMarkerType.LINE ? m.value : Math[kind](m.from, m.to),
    ),
  )
}
// make sure we have valid value
function finite(x: number): number {
  return isFinite(x) ? x : 0
}

const precision = (diff: number): number => {
  const digits = 2
  // can't do log of 0 or -ve
  // if the user specifies one limit and the other is calculated,
  // it is possible for min > max and diff to be -ve
  // avoid this
  const log = Math.log10(Math.abs(diff) || 1)
  const n = Math.floor(log) + 1 - digits
  return 10 ** n
}

function calculateMinMax(
  optMin: number | undefined,
  optMax: number | undefined,
  calcMin: () => number,
  calcMax: () => number,
): MinMax {
  let min = finite(optMin ?? calcMin())
  let max = finite(optMax ?? calcMax())

  // if the user has specified both the min and max
  // then we don't need any calculations, just return
  if (isDefined(optMin) && isDefined(optMax)) {
    return { min, max }
  }

  const p = precision(max - min)

  // round calculated min/max
  if (!isDefined(optMin)) {
    min = p * Math.floor(min / p)
  }
  if (!isDefined(optMax)) {
    max = p * Math.ceil(max / p)
  }

  return { min, max }
}

// gets the min/max values to use for every series in the chart
export function seriesMinMax(
  chartOptions: ChartOptions,
  data: ChartData[],
): MinMax[] {
  if (chartOptions.commonY) {
    const minMax = calculateMinMax(
      chartOptions.min,
      chartOptions.max,
      () => chart('min', chartOptions, data),
      () => chart('max', chartOptions, data),
    )

    // return the same min/max for all series
    return chartOptions.data.map(() => minMax)
  }

  return chartOptions.data.map((seriesOptions, i) => {
    return calculateMinMax(
      seriesOptions.min,
      seriesOptions.max,
      () => series('min', seriesOptions, data[i]),
      () => series('max', seriesOptions, data[i]),
    )
  })
}
