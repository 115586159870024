import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { datadogRum, RumResourceEvent } from '@datadog/browser-rum'

import { theme } from 'src/style'
import { AppRouter, Toaster } from 'pages/app'

import App from './App'
import { STACK, API_ROOT } from './config'
import GlobalStyle from './GlobalStyle'

// CSS imports
import './style/highchartsOverride.css'
import './style/style.css'

let version = STACK

if (STACK !== 'unknown') {
  version = import.meta.env.VITE_VERSION ?? ''

  datadogRum.init({
    applicationId: import.meta.env.VITE_DD_APP_ID ?? '',
    clientToken: import.meta.env.VITE_DD_CLIENT_TOKEN ?? '',
    site: 'datadoghq.com',
    service: import.meta.env.VITE_DD_SERVICE ?? '',
    env: STACK,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: true,
    enableExperimentalFeatures: ['clickmap'],
    startSessionReplayRecordingManually: true,
    proxy: API_ROOT + '/dd-telemetry',
    version,
    beforeSend: event => {
      if (event.type === 'resource') {
        const rsrcEvent = event as RumResourceEvent
        if (rsrcEvent.resource.status_code! >= 400) {
          datadogRum.addError(
            `${rsrcEvent.resource.method} ${rsrcEvent.resource.url} ${rsrcEvent.resource.status_code}`,
          )
        }
      }
      // return true to keep the event.
      return true
    },
  })
}

function debugNode(node: Node): string {
  if (node instanceof Text) {
    return `"${node.textContent}"`
  }
  if (node instanceof Element) {
    const clone = node.cloneNode(false) as Element
    return clone.outerHTML
  }
  return node.toString()
}

// See: https://github.com/facebook/react/issues/11538#issuecomment-417504600
if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild
  Node.prototype.removeChild = function <T extends Node>(child: T): T {
    if (child.parentNode !== this) {
      if (console) {
        console.error(
          'Cannot remove a child from a different parent',
          child,
          this,
        )
      }

      datadogRum.addError(
        new Error('Cannot remove a child from a different parent'),
        {
          child: debugNode(child),
          parent: debugNode(this),
        },
      )
      return child
    }
    return originalRemoveChild.call(this, child) as T
  }

  const originalInsertBefore = Node.prototype.insertBefore
  Node.prototype.insertBefore = function <T extends Node>(
    // Node to insert
    node: T,
    // child Node to insert `node` before
    child: Node | null,
  ): T {
    if (child && child.parentNode !== this) {
      if (console) {
        console.error(
          'Cannot insert before a child node from a different parent',
          child,
          this,
        )
      }
      datadogRum.addError(
        new Error('Cannot insert before a child node from a different parent'),
        {
          child: debugNode(child),
          parent: debugNode(this),
        },
      )
      return node
    }
    return originalInsertBefore.call(this, node, child) as T
  }
}

console.log(
  `Starting Intelecy Application version=${version} build_number=${
    import.meta.env.VITE_BUILD_NUMBER
  }`,
)

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />}>
      <Route path="*" element={<AppRouter />} />
    </Route>,
  ),
)

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Toaster />
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
