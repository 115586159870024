import { parseISO } from 'date-fns'
import {
  GqlAnomalyBaseFragment,
  GqlAnomalyCommentBaseFragment,
  GqlFetchAnomalyFragment,
  GqlLatestCommentAnomalyFragment,
  GqlLatestCommentFragment,
  GqlNavigationAnomalyFragment,
} from '../graphqlTypes'
import {
  Anomaly,
  AnomalyBaseComment,
  AnomalyBase,
  NavigationAnomaly,
  LatestCommentAnomaly,
  LatestComment,
} from './anomaly.types'

export function mapApiToBaseAnomaly(
  anomaly: GqlAnomalyBaseFragment,
): AnomalyBase {
  return {
    ...anomaly,
    start: parseISO(anomaly.start),
    end: anomaly.end ? parseISO(anomaly.end) : null,
    comments: anomaly.comments.map(mapApiToAnomalyBaseComment),
  }
}

export function mapApiToAnomaly(anomaly: GqlFetchAnomalyFragment): Anomaly {
  return {
    ...anomaly,
    start: parseISO(anomaly.start),
    end: anomaly.end ? parseISO(anomaly.end) : null,
    previous: anomaly.previous
      ? mapApiToNavigationAnomaly(anomaly.previous)
      : undefined,
    next: anomaly.next ? mapApiToNavigationAnomaly(anomaly.next) : undefined,
    comments: anomaly.comments.map(mapApiToAnomalyBaseComment),
  }
}

function mapApiToNavigationAnomaly(
  anomaly: GqlNavigationAnomalyFragment,
): NavigationAnomaly {
  return {
    ...anomaly,
    start: parseISO(anomaly.start),
    end: anomaly.end ? parseISO(anomaly.end) : null,
  }
}

function mapApiToAnomalyBaseComment(
  comment: GqlAnomalyCommentBaseFragment,
): AnomalyBaseComment {
  return {
    id: comment.id,
    by: comment.by,
    comment: comment.comment,
    at: parseISO(comment.at),
  }
}

export function mapApiToLatestComment(
  latestComment: GqlLatestCommentFragment,
): LatestComment {
  return {
    ...latestComment,
    at: parseISO(latestComment.at),
    anomaly: mapApiToLatestCommentAnomaly(latestComment.anomaly),
  }
}

function mapApiToLatestCommentAnomaly(
  anomaly: GqlLatestCommentAnomalyFragment,
): LatestCommentAnomaly {
  return {
    ...anomaly,
    start: parseISO(anomaly.start),
  }
}
