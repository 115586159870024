import classNames from 'classnames'
import { Text, TextAreaInput } from 'src/components/ui'
import { SiteRole } from 'src/types'
import { useSite } from 'src/contexts/site'
import { FetchModel } from 'src/services'
import { isModelEditing, modelValues } from '../modelOverview.state'

type ModelDescriptionProps = {
  model: FetchModel
}
export function ModelDescription({
  model,
}: ModelDescriptionProps): JSX.Element {
  const { viewerRole: role } = useSite()

  return (
    <div className="flex flex-col gap-2xs">
      <Text bold>Description</Text>
      {isModelEditing.value && role !== SiteRole.READER ? (
        <TextAreaInput
          inputClassName="max-h-[250px]"
          className="max-w-[500px]"
          value={modelValues.description.value}
          minRows={3}
          placeholder="Descriptive text"
          onChange={e => (modelValues.description.value = e.target.value)}
        />
      ) : (
        <Text
          className={classNames(
            'max-w-[500px] max-h-[250px] overflow-y-auto whitespace-pre-line',
            !model.description && 'text-text-tertiary',
          )}
        >
          {model.description || 'No description'}
        </Text>
      )}
    </div>
  )
}
