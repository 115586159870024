import React, { useEffect, useState } from 'react'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { useAuth } from 'src/contexts/auth'
import { LAUNCH_DARKLY_ID } from './config'

type WrapperProps = {
  children: React.ReactNode
}

type Wrapper = (props: WrapperProps) => JSX.Element

const PassThroughWrapper: Wrapper = ({ children }) => <>{children}</>

const FlagsWrapper: Wrapper = ({ children }) => {
  const { viewer } = useAuth()

  // wrapping initial value in function to get around useState and setState thinking
  // the functional component is a function to be evaluated.
  const [Container, setContainer] = useState<Wrapper>(() => PassThroughWrapper)

  useEffect(() => {
    ;(async () => {
      const provider = await asyncWithLDProvider({
        clientSideID: LAUNCH_DARKLY_ID,
        context: {
          kind: 'user',
          key: viewer.id,
          cid: viewer.customer?.id,
        },
        options: {
          diagnosticOptOut: true,
        },
      })

      setContainer(() => provider)
    })()
  }, [viewer])

  return <Container>{children}</Container>
}

export default FlagsWrapper
