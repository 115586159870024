import { GqlAnomalyConditionsFragment } from 'src/services'
import { ThresholdOption } from './components'

export const isThresholdSameAsOption = (
  threshold: GqlAnomalyConditionsFragment,
  thresholdOption: ThresholdOption,
): boolean => {
  return (
    threshold.errorScore.kind === thresholdOption.errorScoreKind &&
    threshold.errorScore.transform === thresholdOption.transform &&
    threshold.thresholdKind === thresholdOption.thresholdKind
  )
}

export const areSameThresholdTypes = (
  t1: GqlAnomalyConditionsFragment,
  t2: GqlAnomalyConditionsFragment,
): boolean => {
  return (
    t1.errorScore.kind === t2.errorScore.kind &&
    t1.errorScore.transform === t2.errorScore.transform &&
    t1.thresholdKind === t2.thresholdKind
  )
}
