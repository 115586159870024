import { useCallback } from 'react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate } from 'react-router-dom'
import { SiteRole } from 'src/types'
import { useSite } from 'src/contexts/site'
import { ErrorDisplay } from 'pages/app'
import {
  DynamicTable,
  Button,
  Text,
  Checkbox,
  TextInput,
  Spinner,
} from 'src/components/ui'
import { useAuth } from 'src/contexts/auth'
import { useModels } from 'models/api'
import { GqlModelBaseFragment } from 'src/services'
import { CreditStatus } from './components'
import { useModelFilters } from './useModelFilters'
import { getModelTableConfig } from './models.config'

export function ModelsPage(): JSX.Element {
  const modelsQuery = useModels()
  const {
    filter,
    showMine,
    showStarred,
    page,
    pageSize,
    setFilter,
    setShowMine,
    setShowStarred,
    setPage,
    setPageSize,
  } = useModelFilters()
  const { rootLink, isWorkshop, viewerRole, id: factory } = useSite()
  const navigate = useNavigate()
  const {
    viewer: { id: userId },
  } = useAuth()
  const isReader = viewerRole === SiteRole.READER

  const handleCreateModel = (): void => {
    navigate(`${rootLink}/models/new`)
  }

  const actions = {
    selectRow: ({ id }: any) => {
      navigate(`${rootLink}/models/${id}`)
    },
  }

  const decoratedData = (modelsQuery.data || []).map(model => {
    const {
      tag: { tagName, displayName },
    } = model
    return {
      ...model,
      tagDisplayName: displayName || tagName,
      link: `${rootLink}/models/${model.id}`,
    }
  })

  const doesExternalFilterPass = useCallback(
    ({ data: model }: { data: GqlModelBaseFragment }): boolean => {
      if (showMine && model.createdBy?.id !== userId) {
        return false
      }
      if (showStarred && !model.viewerHasStarred) {
        return false
      }
      if (filter) {
        return filter
          .toLowerCase()
          .split(' ')
          .every(word =>
            Object.entries(model).some(
              ([, value]) =>
                typeof value === 'string' && value.toLowerCase().includes(word),
            ),
          )
      }
      return true
    },
    [filter, showMine, showStarred, userId],
  )

  const isExternalFilterPresent = useCallback((): boolean => {
    return !!filter || showMine || showStarred
  }, [filter, showMine, showStarred])

  if (modelsQuery.isError) {
    return (
      <ErrorDisplay
        error={modelsQuery.error}
        action={modelsQuery.refetch}
        message="Failed to fetch models"
      />
    )
  }

  return (
    <div className="size-full p-[1em] pt-0">
      <div className="mb-s flex flex-col items-start justify-between gap-xs xl:flex-row xl:items-center">
        <div className="flex items-center gap-s">
          <TextInput
            value={filter}
            onChange={e => setFilter(e.target.value)}
            placeholder="Filter by name, type, output tag or state..."
            className="min-w-[320px]"
          />
          <div style={{ display: 'flex' }}>
            <div
              className="flex items-center"
              style={{ width: '140px' }}
              onClick={() => {
                if (!isWorkshop) {
                  setShowMine(!showMine)
                }
              }}
            >
              <Checkbox
                value={showMine}
                onChange={setShowMine}
                disabled={isWorkshop}
              />
              <label style={{ marginLeft: '5px' }}>
                <Text className="inline-block">Show only mine</Text>
              </label>
            </div>
            <div
              className="flex items-center"
              style={{ width: '160px' }}
              onClick={() => {
                if (!isWorkshop) {
                  setShowStarred(!showStarred)
                }
              }}
            >
              <Checkbox
                value={showStarred}
                onChange={setShowStarred}
                disabled={isWorkshop}
              />

              <label style={{ marginLeft: '5px' }}>
                <Text className="inline-block">Show favorites</Text>
              </label>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-xs">
          <div className="relative">
            <Button
              variant="primary"
              icon={regular('plus')}
              title="Create new model"
              onClick={handleCreateModel}
              disabled={isReader}
            ></Button>
          </div>
          <CreditStatus />
        </div>
      </div>
      {modelsQuery.isLoading ? (
        <Spinner />
      ) : (
        <div className="h-[calc(100%-50px)]">
          <DynamicTable
            id={`${factory}-Models`}
            data={decoratedData}
            config={getModelTableConfig(filter)}
            actions={actions}
            rowHeight={48}
            doesExternalFilterPass={doesExternalFilterPass}
            isExternalFilterPresent={isExternalFilterPresent}
            currentPage={page}
            currentPageSize={pageSize}
            onPageChange={setPage}
            onPageSizeChange={setPageSize}
          />
        </div>
      )}
    </div>
  )
}
