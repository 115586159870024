import React from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { OnlyModelProps, SiteRole } from 'src/types'
import { Button, ConfirmModal } from 'src/components/ui'
import { useSite } from 'src/contexts/site'
import { useCopyModelMutation } from 'models/api'
import { isModelEditing } from '../Overview/modelOverview.state'

export function CopyButton({ model }: OnlyModelProps): JSX.Element {
  const [showConfirm, setShowConfirm] = React.useState(false)
  const copyMutation = useCopyModelMutation()

  const navigate = useNavigate()
  const { rootLink, id, viewerRole } = useSite()

  const isReader = viewerRole === SiteRole.READER

  function handleConfirm(): void {
    copyMutation.mutateAsync(
      { model, factory: id },
      {
        onSuccess: data => {
          if (data?.id) {
            navigate(`${rootLink}/models`)
          }
        },
        onError: () => {
          toast.error('Failed to copy the model.', { position: 'top-right' })
        },
      },
    )
  }

  return (
    <>
      {showConfirm && (
        <ConfirmModal
          text={
            <>
              Are you sure you want to create a copy of <b>{model.name}</b>?
            </>
          }
          pending={copyMutation.isLoading}
          renderConfirmButton={() => (
            <Button
              title="Copy"
              variant="primary"
              onClick={handleConfirm}
              isPending={copyMutation.isLoading}
            />
          )}
          isOpen={showConfirm}
          setIsOpen={setShowConfirm}
        />
      )}
      <Button
        title="Copy model"
        variant="secondary"
        onClick={() => setShowConfirm(true)}
        disabled={isReader || isModelEditing.value}
      />
    </>
  )
}
