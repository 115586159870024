import { DynamicTable, Text, Spinner, Banner, Button } from 'src/components/ui'
import { hasRunInitialInference } from 'models/model/model.utils'
import { useSite } from 'src/contexts/site'
import { ChartAxis, SeriesType, ChartTableProps } from 'src/types/chartTypes'
import { useAnomalyModels } from 'models/api'
import { getAnomaliesConfig } from './table-configs'

const anomalySeriesTypes = new Set<SeriesType>([
  'prediction',
  'shortScore',
  'spikeScore',
])

export function AnomalyModelsTable(props: ChartTableProps): JSX.Element {
  return (
    <div className="h-full">
      <Text variant="title" bold>
        Select anomaly models to view
      </Text>
      <Table {...props} />
    </div>
  )
}

function Table({
  chart,
  addSeries,
  removeSeries,
}: ChartTableProps): JSX.Element {
  const anomalyModlesQuery = useAnomalyModels()
  const { id: factory } = useSite()

  if (anomalyModlesQuery.isError) {
    return (
      <Banner
        variant="error"
        rightComponent={
          <Button
            variant="primary"
            title="Try again"
            onClick={() => anomalyModlesQuery.refetch()}
          />
        }
      >
        Failed to load anomaly models
      </Banner>
    )
  }

  if (anomalyModlesQuery.isLoading) {
    return <Spinner />
  }

  const models = anomalyModlesQuery.data
    .filter(({ state }) => hasRunInitialInference(state))
    .flatMap(model => [
      {
        ...model,
        type: 'prediction',
        label: 'Prediction',
      },
      {
        ...model,
        type: 'spikeScore',
        label: 'Spike anomaly score',
      },
      {
        ...model,
        type: 'shortScore',
        label: 'Short anomaly score',
      },
    ])
    .map(model => {
      return {
        ...model,
        id: `${model.id}-${model.type}`,
        modelId: model.id,
        tag: {
          ...model.tag,
          displayTagName: model.tag.displayName
            ? model.tag.displayName
            : model.tag.tagName || '',
        },
      }
    })

  const selected = chart.data.flatMap(({ id, type }) =>
    anomalySeriesTypes.has(type) ? [`${id}-${type}`] : [],
  )

  const selectedScatter = chart.data.flatMap(({ id, type, axis }) =>
    (anomalySeriesTypes.has(type) && axis === ChartAxis.X) ||
    axis === ChartAxis.Y
      ? [{ id: `${id}-${type}`, axis }]
      : [],
  )

  const config = getAnomaliesConfig({
    addSeries,
    removeSeries,
    chartType: chart.type,
  })

  return (
    <div className="h-[calc(100%-24px)]">
      <DynamicTable
        id={`${factory}-TrendAnomalyModels`}
        maxHeightFull
        data={models}
        config={config}
        selectedRows={selectedScatter.length ? undefined : selected}
        selectedScatter={selectedScatter}
        fuzzySearch
        fuzzySearchField={['name', 'tag.displayTagName', 'label']}
        shouldAutofocusSearch
      />
    </div>
  )
}
