import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  AggregationMap,
  IntrinsicMap,
  ScatterAxes,
} from 'data-explorer/data-explorer.types'
import { Button, Icon, Text } from 'src/components/ui'
import { SegmentProperty } from 'src/dex/segment_series'
import { SavedTimeSeries, TimeSeries } from 'src/dex/time_series'

type AxesProps = {
  axes: ScatterAxes
  timeSeries: SavedTimeSeries[]
}

function getAxisFunctionAndTimeSeries(
  axis: TimeSeries | SegmentProperty,
  workspaceTimeSeries: SavedTimeSeries[],
): {
  function?: string
  timeSeries?: string
} {
  switch (axis.kind?.$case) {
    case 'tsAgg': {
      const agg = AggregationMap[axis.kind.value.agg]
      const timeSeries = axis.kind.value.ts
      if (timeSeries?.kind?.$case === 'tsRef') {
        const ts = workspaceTimeSeries.find(
          ts => ts.id === timeSeries.kind?.value,
        )
        return {
          function: agg,
          timeSeries: ts?.tsDef?.label || 'Unknown time series',
        }
      } else {
        return {
          function: agg,
          timeSeries: timeSeries?.kind?.value.label || 'Unknown time series',
        }
      }
    }
    case 'tsDef': {
      return {
        function: undefined,
        timeSeries: axis.kind.value.label,
      }
    }
    case 'tsRef': {
      const ts = workspaceTimeSeries.find(ts => ts.id === axis.kind?.value)
      return {
        function: undefined,
        timeSeries: ts?.tsDef?.label || 'Unknown time series',
      }
    }
    case 'intrinsicProperty': {
      return {
        function: IntrinsicMap[axis.kind.value],
        timeSeries: 'Segment',
      }
    }
  }
  return { function: undefined, timeSeries: undefined }
}

export function AxesDisplay({ axes, timeSeries }: AxesProps): JSX.Element {
  const xData = axes?.value.x
    ? getAxisFunctionAndTimeSeries(axes?.value.x, timeSeries)
    : { function: undefined, timeSeries: undefined }
  const yData = axes?.value.y
    ? getAxisFunctionAndTimeSeries(axes?.value.y, timeSeries)
    : { function: undefined, timeSeries: undefined }

  return (
    <div className="flex flex-col gap-s">
      <div className="flex items-center gap-xs">
        <Button
          variant="icon"
          className="!px-xs !py-2xs"
          icon={light('pencil')}
          iconSize="small"
        />
        <Button
          variant="icon"
          className="!px-xs !py-2xs"
          icon={light('trash-alt')}
          iconSize="small"
          disabled
        />
        <Icon size="regular" className="ml-xs" icon={regular('square-x')} />
        <Icon icon={regular('arrow-right')} />
        <Icon icon={regular('function')} />
        <Text bold>
          <span className="uppercase">{xData.function}</span>({xData.timeSeries}
          )
        </Text>
      </div>
      <div className="flex items-center gap-xs">
        <Button
          variant="icon"
          className="!px-xs !py-2xs"
          icon={light('pencil')}
          iconSize="small"
        />
        <Button
          variant="icon"
          className="!px-xs !py-2xs"
          icon={light('trash-alt')}
          iconSize="small"
          disabled
        />
        <Icon size="regular" className="ml-xs" icon={regular('square-y')} />
        <Icon icon={regular('arrow-right')} />
        <Icon icon={regular('function')} />
        <Text bold>
          <span className="uppercase">{yData.function}</span>({yData.timeSeries}
          )
        </Text>
      </div>
    </div>
  )
}
