import { useContext } from 'react'
import { ChartsContextType, ChartsContext } from './ChartsContext'

export const useCharts = (): ChartsContextType => {
  const context = useContext(ChartsContext)
  if (!context) {
    throw new Error('useChartsContext must be used within a ChartProvider')
  }
  return context
}
