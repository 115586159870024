import { parseISO } from 'date-fns'
import { GqlTimeSeries, GqlTimeSeriesData } from '../graphqlTypes'
import {
  Forecast,
  ForecastDto,
  ForecastPrediction,
  ForecastValueDto,
  HistoricalForecast,
  HistoricalForecastData,
} from './forecast.types'

export function mapApiToForecast(forecast: ForecastDto): Forecast {
  return {
    ...forecast,
    madeAt: parseISO(forecast.madeAt),
    prediction: forecast.prediction.map(mapApiToForecastPrediction),
  }
}

export function mapApiToForecastPrediction(
  data: ForecastValueDto,
): ForecastPrediction {
  return {
    ...data,
    timestamp: parseISO(data.timestamp),
  }
}

export function mapApiToHistoricalForecast(
  data: GqlTimeSeries,
): HistoricalForecast {
  return {
    ...data,
    from: parseISO(data.from),
    to: parseISO(data.to),
    data: data.data.map(mapApiToHistoricalForecastData),
  }
}

function mapApiToHistoricalForecastData(
  data: GqlTimeSeriesData,
): HistoricalForecastData {
  return {
    ...data,
    timestamp: parseISO(data.timestamp),
  }
}
