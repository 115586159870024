import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useEffect } from 'react'
import { Icon, Text, Modal } from 'src/components/ui'
import {
  ConnectionStatus,
  OpcUaConnectionConfiguration,
} from '../../opc-ua-connection.types'

type Props = {
  config: OpcUaConnectionConfiguration
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  handleSubmit: () => void
}
export function TestConnectionModal({
  config,
  isOpen,
  setIsOpen,
  handleSubmit,
}: Props): JSX.Element {
  useEffect(() => {
    if (
      !config.securityType ||
      !config.securityPolicy ||
      (!config.securityCertificate &&
        config.securityPolicy &&
        config.securityPolicy !== 'NONE') ||
      (!config.securityAnonymous && (!config.userName || !config.password))
    ) {
      setIsOpen(false)
    }
  }, [config, setIsOpen])

  return (
    <Modal
      allowOverflow
      isOpen={isOpen}
      title="Test OPC-UA Connection"
      close={() => setIsOpen(false)}
      footer={
        <Modal.Footer
          onCancel={() => setIsOpen(false)}
          onConfirm={handleSubmit}
          confirmLabel="Test Connection"
          type="success"
          buttonDisabled={
            config.securityConnectionState === ConnectionStatus.VERIFYING
          }
        />
      }
    >
      <div className="flex max-w-[498px] flex-col gap-s py-s">
        <Text variant="description">
          You are about to test the connection. Please be patient until the test
          finishes and do not refresh this page.
        </Text>
        <div className="flex flex-col gap-2xs rounded border border-solid border-border">
          <div className="flex h-11 items-center rounded-t border-0 border-b border-solid border-border bg-background-disabled-light">
            <Text variant="description" className="ml-s" bold>
              Output Message
            </Text>
          </div>
          <div className="ml-s flex h-11 items-center gap-xs">
            {config.securityConnectionState === ConnectionStatus.VERIFIED ? (
              <>
                <Icon
                  icon={light('circle-check')}
                  className="text-icon-brand"
                />
                <Text>Success</Text>
              </>
            ) : config.securityConnectionState === ConnectionStatus.FAILED ? (
              <>
                <Icon
                  icon={light('circle-exclamation')}
                  className="text-text-danger"
                />
                <Text>Error</Text>
              </>
            ) : (
              <>
                <Icon icon={light('circle-info')} />
                <Text className="text-text-tertiary">Success / Error</Text>
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-2xs rounded border border-solid border-border">
          <div className="flex h-11 items-center rounded-t border-0 border-b border-solid border-border bg-background-disabled-light">
            <Text variant="description" className="ml-s" bold>
              Optional System Message
            </Text>
          </div>
          <div className="ml-s flex min-h-[40px] items-center gap-xs text-text-tertiary">
            {config.securityConnectionState === ConnectionStatus.FAILED ? (
              <>
                <Icon
                  icon={light('circle-exclamation')}
                  className="text-text-danger"
                />
                <Text>{config.securityResponseText}</Text>
              </>
            ) : (
              <>
                {' '}
                <Icon icon={light('circle-info')} />
                <Text className="text-text-tertiary">
                  node_id / $system_clock
                </Text>
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}
