import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Accordion, Button } from 'src/components/ui'
import { Scatter } from 'src/dex/analysis'
import { SavedTimeRange } from 'src/dex/time_range'
import { SavedSegmentation } from 'src/dex/segmentation'
import { SavedTimeSeries } from 'src/dex/time_series'
import {
  extractSegmentationDef,
  extractTimeRangeDef,
} from 'data-explorer/data-explorer.utils'
import {
  AxesDisplay,
  SegmentationDisplay,
  TimeRangeDisplay,
} from '../../components'

type Props = {
  analysis: Scatter
  workspaceTimeRanges: SavedTimeRange[]
  workspaceSegmentations: SavedSegmentation[]
  workspaceTimeSeries: SavedTimeSeries[]
}

export function ScatterAnalysis({
  analysis,
  workspaceTimeRanges,
  workspaceSegmentations,
  workspaceTimeSeries,
}: Props): JSX.Element {
  const timeRange = analysis.timeRange
    ? extractTimeRangeDef(analysis.timeRange, workspaceTimeRanges)
    : undefined

  const segmentation =
    analysis.axes?.$case === 'segAxes'
      ? analysis.axes.value.segmentation
        ? extractSegmentationDef(
            analysis.axes.value.segmentation,
            workspaceSegmentations,
          )
        : undefined
      : undefined

  return (
    <div className="flex flex-col gap-s">
      {timeRange && (
        <Accordion label="Time range" labelVariant="content" initialIsOpen>
          <div className="flex flex-col gap-s">
            <TimeRangeDisplay timeRange={timeRange} />
            {segmentation && (
              <SegmentationDisplay
                segmentation={segmentation}
                timeSeries={workspaceTimeSeries}
              />
            )}
          </div>
        </Accordion>
      )}
      <Accordion label="Axes" labelVariant="content" initialIsOpen>
        <AxesDisplay axes={analysis.axes} timeSeries={workspaceTimeSeries} />
      </Accordion>
      <Accordion label="Result" labelVariant="content" initialIsOpen>
        <Button
          variant="icon-primary"
          icon={light('play')}
          title="Run Analysis"
        />
      </Accordion>
    </div>
  )
}
