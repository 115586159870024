import classNames from 'classnames'
import TextareaAutosize from 'react-textarea-autosize'
import { Text } from '../../typography'

type TextAreaInputProps = {
  label?: string
  labelSuffix?: string
  value: string
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  placeholder?: string
  disabled?: boolean
  className?: string
  onFocus?: () => void
  onKeyPress?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void
  maxLength?: number
  minRows?: number
  inputClassName?: string
}

export function TextAreaInput({
  label,
  labelSuffix,
  value,
  onChange,
  placeholder,
  disabled,
  className,
  onFocus,
  onKeyPress,
  maxLength,
  minRows,
  inputClassName,
}: TextAreaInputProps): JSX.Element {
  return (
    <div className={classNames('flex flex-col gap-2xs', className)}>
      {label && (
        <label>
          <Text variant="small" bold>
            {label}&nbsp;
            {labelSuffix && (
              <span className="!font-normal text-text-tertiary">
                {labelSuffix}
              </span>
            )}
          </Text>
        </label>
      )}
      <div
        className={`flex w-full items-center justify-between gap-2xs rounded-2xs border border-solid border-border bg-background p-xs transition-all duration-300 ${
          !disabled &&
          'focus-within:border-border-success-secondary focus-within:shadow-input hover:border-border-brand-tertiary hover:shadow-input focus-within:hover:border-border-success-secondary'
        }`}
      >
        <TextareaAutosize
          data-testId="textarea"
          className={classNames(
            'flex w-full min-w-0 resize-none appearance-none border-none bg-transparent text-description font-500 leading-[1.2] placeholder:font-normal disabled:text-text-disabled',
            inputClassName,
          )}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          onFocus={onFocus}
          onKeyDown={onKeyPress}
          maxLength={maxLength}
          minRows={minRows}
        />
      </div>
      {maxLength !== undefined && (
        <div className="flex justify-end whitespace-nowrap">
          <Text variant="small" className="text-text-tertiary">
            {value.length} / {maxLength}
          </Text>
        </div>
      )}
    </div>
  )
}
