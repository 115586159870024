import { useEffect, useMemo, useRef, useState } from 'react'
import { debounce } from 'lodash'
import { Banner, Button, Spinner, TimeSeriesChart } from 'src/components/ui'
import { tsdArrayMinMax } from 'src/utility/timeSeries'
import { GqlErrorScoreKind, GqlThresholdKind, GqlTransform } from 'src/services'
import { getThemeObject } from 'src/style/themes'
import { TimeSeriesData } from 'src/types'
import { tailwindTheme } from 'pages/app'
import { useModelAnomalyScore } from '../anomalyThresholds.api'
import { Data } from '../anomalyThresholds.types'
import { AnomalyStatusTimeline } from './AnomalyStatusTimeline'
import ThresholdSlider from './ThresholdSlider'

interface AnomalyThresholdInputProps {
  id: string // model id
  errorScoreKind: GqlErrorScoreKind
  transform: GqlTransform
  value: number
  onChange: (value: number) => void
  thresholdType?: GqlThresholdKind
  readOnly?: boolean
}

export function AnomalyThresholdInput(
  props: AnomalyThresholdInputProps,
): JSX.Element {
  const anomalyScore = useModelAnomalyScore({
    id: props.id,
    transform: props.transform,
    errorScoreKind: props.errorScoreKind,
  })

  if (anomalyScore.isLoading) {
    return <Spinner />
  }

  if (anomalyScore.isError) {
    return (
      <Banner
        rightComponent={
          <Button
            variant="primary"
            onClick={() => anomalyScore.refetch()}
            title="Try again"
          />
        }
        variant="error"
      >
        Failed to load anomaly score data
      </Banner>
    )
  }

  return (
    <AnomalyThresholdInputWithData
      {...props}
      anomalyScore={anomalyScore.data}
    />
  )
}

type AnomalyThresholdInputWithDataProps = AnomalyThresholdInputProps & {
  anomalyScore: TimeSeriesData[]
}

function AnomalyThresholdInputWithData({
  value,
  onChange,
  errorScoreKind,
  thresholdType,
  readOnly,
  anomalyScore,
}: AnomalyThresholdInputWithDataProps): JSX.Element {
  const [yAxisRange, setYAxisRange] = useState({ min: 0, max: 1 })
  const [extreme, setExtreme] = useState<number>()
  const theme = getThemeObject(tailwindTheme.value)

  const data: Data[] = anomalyScore.map(([ts, , , max]) => [ts, max])

  const setExtremesDebounced = useMemo(
    () => debounce(o => setExtreme(o), 1000),
    [setExtreme],
  )
  const valueRef = useRef(value)

  useEffect(() => {
    const [min, max] = tsdArrayMinMax(anomalyScore)

    const extremeValue = Math.max(
      Math.abs(min),
      Math.abs(max),
      Math.abs((value ?? 0) * 1.2),
    )

    if (value !== valueRef.current) {
      setExtremesDebounced(extremeValue)
    } else {
      setExtreme(extremeValue)
    }

    valueRef.current = value
  }, [anomalyScore, value, setExtremesDebounced])

  const yAxis =
    errorScoreKind === 'SIGNED' && extreme
      ? {
          min: -extreme,
          max: extreme,
        }
      : {
          min: 0,
          max: extreme,
        }

  return (
    <div className="flex size-full flex-col gap-s">
      <div className="w-full">
        <div className="relative">
          <TimeSeriesChart
            tooltip
            threshold={value}
            data={[
              {
                name: 'error',
                data,
                color: theme.background.success.secondary.DEFAULT,
              },
            ]}
            setYAxisRange={setYAxisRange}
            desaturate
            isPending={false}
            thresholdType={thresholdType}
            errorScoreKind={errorScoreKind}
            yAxis={yAxis}
          />
          {!readOnly && thresholdType && (
            <ThresholdSlider
              value={value}
              onChange={onChange}
              yAxisRange={yAxisRange}
              thresholdType={thresholdType}
            />
          )}
        </div>
      </div>
      <AnomalyStatusTimeline
        data={data}
        threshold={value}
        thresholdType={thresholdType}
      />
    </div>
  )
}
