import { Outlet } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ModalProvider } from 'react-modal-hook'

import { Subscriptions } from 'pages/app'
import ErrorBoundary from './ErrorBoundary'
import FlagsWrapper from './FlagsWrapper'
import { AuthProvider } from './contexts/auth'
import { NavigationProvider } from './contexts/navigation'

const queryClient = new QueryClient()

const App = (): JSX.Element => {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <FlagsWrapper>
            <NavigationProvider>
              <ModalProvider>
                <Outlet />
                <Subscriptions />
              </ModalProvider>
            </NavigationProvider>
          </FlagsWrapper>
        </AuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ErrorBoundary>
  )
}

export default App
