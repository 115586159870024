import { useParams } from 'react-router-dom'
import { StreamlitVerifier } from './components/StreamlitVerifier'
import { StreamlitConnector } from './components/StreamlitConnector'

export { StreamlitVerifier } from './components/StreamlitVerifier'
export { StreamlitConnector } from './components/StreamlitConnector'

export function StreamlitApp(): JSX.Element {
  const { siteId, slug, sessionId } = useParams()
  if (!siteId || !slug) throw new Error('missing required route params')

  if (sessionId) {
    return <StreamlitVerifier slug={slug} sessionId={sessionId} />
  }
  return <StreamlitConnector factory={siteId} slug={slug} />
}
