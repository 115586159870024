import { RefObject, useEffect, useMemo } from 'react'
import { throttle } from 'lodash'
import HighchartsReact from 'highcharts-react-official'

interface UseZoomProps {
  ref: RefObject<HighchartsReact.RefObject>
  from: number
  to: number
  isPending?: boolean
}

export function useZoom({ ref, from, to, isPending }: UseZoomProps): void {
  // See: https://kyleshevlin.com/debounce-and-throttle-callbacks-with-react-hooks
  const setExtremes = useMemo(
    () =>
      throttle(
        ({ from, to }) => {
          if (ref.current) {
            ref.current.chart.xAxis[0].setExtremes(from, to, true, false)
          }
        },
        100,
        { leading: true, trailing: true },
      ),
    [ref],
  )

  useEffect(() => {
    if (!isPending) {
      setExtremes({ from, to })
    }
  }, [setExtremes, from, to, isPending])
}
