import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import * as api from 'src/services'
import { SITE_USERS } from './site.get'

interface RemoveSiteUserInput {
  siteId: string
  userId: string
}

export function useRemoveSiteUserMutation(): UseMutationResult<
  api.GqlRemoveCustomerUserMutation,
  unknown,
  RemoveSiteUserInput
> {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ siteId, userId }: RemoveSiteUserInput) =>
      api.removeUser({
        factoryId: siteId,
        userId,
      }),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [SITE_USERS, variables.siteId],
      })
    },
  })
}

export function useUserSiteRoleMutation(): UseMutationResult<
  api.GqlSetFactoryUserRoleMutation,
  unknown,
  api.GqlSetFactoryUserRoleInput
> {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (input: api.GqlSetFactoryUserRoleInput) =>
      api.setUserRole(input),
    onSuccess: (_, input) => {
      queryClient.invalidateQueries([SITE_USERS, input.factoryId])
    },
  })
}
