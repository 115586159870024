import {
  GqlTestOpcUaConnectionQuery,
  GqlOpcConnectionFragment,
} from 'src/services'
import {
  Certificate,
  ConnectionStatus,
  OpcUaConnectionConfiguration,
  SecurityOptions,
  SecurityPolicy,
  SecurityType,
} from './opc-ua-connection.types'

export function parseEndpointsToSecurityOptions(
  serverResponse: GqlTestOpcUaConnectionQuery,
): SecurityOptions {
  const returnObj: SecurityOptions = {
    certificates: [] as Certificate[],
    securityTypes: [] as SecurityType[],
  }
  const endpoints = serverResponse.factory?.agent?.serverEndpoints.endpoints
  if (endpoints) {
    returnObj.securityTypes = endpoints.reduce((acc, endpoint) => {
      const securityType = endpoint.securityMode
      if (acc.find(acc => acc.value === securityType)) return acc
      return [
        ...acc,
        {
          label: formatLabel(securityType),
          value: securityType,
          policies: parseSecurityPolicies(securityType, serverResponse),
        },
      ]
    }, [] as SecurityType[])
  }
  const certificates =
    serverResponse.factory?.agent?.listCertificates.certificates
  if (certificates) {
    returnObj.certificates = certificates.map(cert => ({
      value: cert.thumbprint,
      label: cert.thumbprint,
      serialNumber: cert.serialNumber,
      thumbprint: cert.thumbprint,
    }))
  }
  return returnObj
}

function parseSecurityPolicies(
  securityType: string,
  res: GqlTestOpcUaConnectionQuery,
): SecurityPolicy[] {
  const endpoints = res.factory?.agent?.serverEndpoints.endpoints
  if (!endpoints) return []
  const securityPolicies = endpoints.reduce((acc, endpoint) => {
    if (endpoint.securityMode !== securityType) return acc
    const { securityPolicy } = endpoint
    if (securityPolicy === 'NONE') {
      // Use security policy from userIdentityTokens.securityPolicy
      const userNamePolicy = endpoint.userIdentityTokens.find(
        el => el.userTokenType === 'USER_NAME',
      )
      const anonymousPolicy = endpoint.userIdentityTokens.find(
        el => el.userTokenType === 'ANONYMOUS',
      )
      const obj = []
      if (userNamePolicy) {
        obj.push({
          label: userNamePolicy.securityPolicy
            ? formatLabel(userNamePolicy.securityPolicy)
            : 'None',
          value: userNamePolicy.securityPolicy ?? 'NONE',
          authenticationMethods: {
            anonymous: false,
            username: true,
          },
        })
      }
      if (anonymousPolicy) {
        obj.push({
          label: anonymousPolicy.securityPolicy
            ? formatLabel(anonymousPolicy.securityPolicy)
            : 'None',
          value: anonymousPolicy.securityPolicy ?? 'NONE',
          authenticationMethods: {
            anonymous: true,
            username: false,
          },
        })
      }
      return [...acc, ...obj]
    }
    if (acc.find(acc => acc.value === securityPolicy)) return acc
    return [
      ...acc,
      {
        label: formatLabel(securityPolicy),
        value: securityPolicy,
        authenticationMethods: {
          anonymous: endpoint.userIdentityTokens.some(
            el => el.userTokenType === 'ANONYMOUS',
          ),
          username: endpoint.userIdentityTokens.some(
            el => el.userTokenType === 'USER_NAME',
          ),
        },
      },
    ]
  }, [] as SecurityPolicy[])
  return securityPolicies
}

export function formatLabel(str: string): string {
  return str
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export function parseConnectionConfig(
  opcConnection: GqlOpcConnectionFragment,
): OpcUaConnectionConfiguration {
  return {
    connectionId: opcConnection.id,
    gatewayId: opcConnection.agentId,
    serverEndpoint: opcConnection.endpoint,
    connectionName: opcConnection.name,
    connectionState:
      opcConnection.endpoint && opcConnection.name
        ? ConnectionStatus.VERIFIED
        : ConnectionStatus.UNVERIFIED,
    detailsCompleted: opcConnection.endpoint ? true : false,
    securityType: opcConnection.securityMode ?? undefined,
    securityPolicy: opcConnection.securityPolicy ?? undefined,
    securityCertificate: opcConnection.x509Thumbprint ?? undefined,
    userName: opcConnection.username ?? '',
    password: '',
    securityAnonymous:
      opcConnection.securityMode &&
      opcConnection.securityPolicy &&
      !opcConnection.username
        ? true
        : false,
    securityConnectionState:
      opcConnection.securityMode && opcConnection.securityPolicy
        ? ConnectionStatus.VERIFIED
        : ConnectionStatus.UNVERIFIED,
    securityCompleted:
      opcConnection.securityMode && opcConnection.securityPolicy ? true : false,
    connectionConfigured:
      opcConnection.configurationState === 'COMPLETE' ? true : false,
  }
}

export function getDefaultConfig(): OpcUaConnectionConfiguration {
  return {
    gatewayId: '',
    serverEndpoint: '',
    connectionName: '',
    connectionState: ConnectionStatus.UNVERIFIED,
    detailsCompleted: false,
  }
}
