import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import * as api from 'src/services'
import * as path from 'src/utility/path'
import { ErrorDisplay } from 'pages/app'
import { Alert } from 'src/components/ui'
import { initializeState } from './RetryState'

const StreamlitWrapper = React.lazy(() => import('../StreamlitWrapper'))

interface StreamlitVerifierProps {
  slug: string
  sessionId: string
}

export function StreamlitVerifier({
  slug,
  sessionId,
}: StreamlitVerifierProps): JSX.Element {
  const proxyPath = `/proxy/${slug}/${sessionId}`
  const location = useLocation()
  const { pathname, search } = location
  const { verifyAttempts } = initializeState(location.state)

  // use a ref to preserve over re-renders
  const hasConnected = React.useRef(false)

  const verify = useQuery({
    queryKey: ['verifyStreamlitSession', proxyPath],
    queryFn: () => api.verifyStreamlitSession(proxyPath),
    // we always want the verify request to be sent
    cacheTime: 0,
    // don't retry, just re-connect
    retry: false,
  })

  const LoadingBlock = (
    <div className="mx-s flex h-[calc(100%-68px)] w-[calc(100%-32px)] flex-col gap-4 rounded-2xs bg-background p-8 px-l">
      <Alert
        variant={'info'}
        primaryMessage={`${
          slug === 'dex' ? 'Data Explorer' : 'Application'
        } is loading. Please wait...`}
      />
    </div>
  )

  if (verify.isSuccess) {
    hasConnected.current = true
    // FIXME we should add an Error Boundary around the Suspense
    // to catch failed loading due to new releases.
    // This should prompt the user to refresh
    // We should also improve the fallback
    return (
      <React.Suspense fallback={LoadingBlock}>
        <StreamlitWrapper slug={slug} sessionId={sessionId} path={proxyPath} />
      </React.Suspense>
    )
  }

  if (verify.isError) {
    // if we were connected or we have not reached the verifyAttempts limit
    // then try to reconnect again
    if (hasConnected.current || verifyAttempts < 2) {
      return (
        <Navigate
          to={{
            pathname: path.join(pathname, '..'),
            search,
          }}
          state={{
            // if we were connected, reset the verifyAttempts count
            verifyAttempts: hasConnected.current ? 0 : verifyAttempts + 1,
            // pass the search string to be restored after re-connect
          }}
        />
      )
    }

    // we've tried, but we kept failing
    return (
      <ErrorDisplay
        error={verify.error}
        message="Unable to connect to application"
      />
    )
  }

  // FIXME we can potentially give some useful feedback here
  // Verifying...
  return <></>
}
