import React from 'react'
import {
  AssetHierarchyContext,
  AssetHierarchyValue,
} from './AssetHierarchyContext'

export const useAssetHierarchy = (): AssetHierarchyValue => {
  const context = React.useContext(AssetHierarchyContext)
  if (context === undefined) {
    throw new Error('useAssetHierarchy used without a AssetHierarchyProvider')
  }
  return context
}
