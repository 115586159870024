import { getSegmentationIcon } from 'data-explorer/data-explorer.utils'
import { Button, Icon, Text } from 'src/components/ui'
import { SavedSegmentation } from 'src/dex/segmentation'

type Props = {
  segmentation: SavedSegmentation
}

export function SegmentationCard({ segmentation }: Props): JSX.Element {
  return (
    <>
      <div
        onClick={() => {
          // TODO: Navigate to analysis details page
          //   navigate(`segmentation/${segmentation.id}`)
        }}
        className="flex w-[400px] cursor-pointer flex-col gap-s rounded-2xs border border-solid border-border p-m pr-s transition duration-300 hover:shadow-button"
        key={segmentation.id}
      >
        <div className="flex items-center ">
          <Icon
            icon={getSegmentationIcon(
              segmentation.segDef?.kind?.$case ?? 'static',
            )}
            className="mr-s !h-[30px] !w-[36px] text-icon-secondary"
          />
          <div>
            <Text variant="description">Segmentation</Text>
            <Text variant="content" bold className="mb-xs mt-2xs">
              {segmentation.segDef?.label ?? segmentation.id}
            </Text>
          </div>
        </div>
        <div className="flex w-full items-start justify-between gap-s">
          <Button
            variant={'secondary'}
            title="Copy"
            className="w-full"
            onClick={e => {
              e.stopPropagation()
              console.log('Copy')
            }}
          />
          <Button
            variant={'secondary'}
            buttonColor="danger"
            title="Delete"
            className="w-full"
            onClick={e => {
              e.stopPropagation()
              console.log('Delete')
            }}
          />
        </div>
      </div>
    </>
  )
}
