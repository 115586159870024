import { isDefined } from 'src/types'
import { graphqlApi } from '../graphQL'
import { GatewayRelease } from './gateway.types'
import { mapApiToGatewayRelease } from './gateway.mappers'

export async function fetchGatewayReleases(): Promise<GatewayRelease[]> {
  const api = await graphqlApi()
  const response = await api.FetchAgentReleases()
  return (
    response.agentReleases?.items
      ?.filter(isDefined)
      .map(mapApiToGatewayRelease) ?? []
  )
}
