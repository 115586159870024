import classNames from 'classnames'
import { Progress } from '@components/progress'
import { ProgressBarProps, ProgressBarVariant } from './ProgressBar.types'

export function ProgressBar({
  value,
  variant = ProgressBarVariant.NORMAL,
}: ProgressBarProps): JSX.Element {
  return (
    <Progress
      value={value}
      className={classNames(
        variant === ProgressBarVariant.NORMAL && 'h-xs',
        variant === ProgressBarVariant.THIN && 'h-2xs',
      )}
    />
  )
}
