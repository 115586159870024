import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import qs from 'query-string'

// We often only expect a single string value, so the rules are:
// - if there is a single string, return it
// - if there is an array, return the first value
// - if null or empty array, return null
export const getSingle = (value: string | string[] | null): string | null => {
  if (value) {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        return value[0]
      }
      return null
    }
    return value
  }
  return null
}

// get the value as an array
export const getArray = (value: string | string[] | null): string[] => {
  if (value) {
    if (Array.isArray(value)) {
      return value
    }
    return [value]
  }
  return []
}

type UseUrlQuery = {
  query: qs.ParsedQuery<string>
  update: (props: Record<string, any>) => void
  generateQueryString: (props: Record<string, any>) => string
}

export function useUrlQuery(): UseUrlQuery {
  const location = useLocation()
  const navigate = useNavigate()

  const query = useMemo(
    () => qs.parse(location.search, { arrayFormat: 'comma' }),
    [location.search],
  )

  const generateQueryString = useCallback(
    props => {
      return qs.stringify({ ...query, ...props }, { arrayFormat: 'comma' })
    },
    [query],
  )

  const update = useCallback(
    props => {
      const search = generateQueryString(props)
      if (search !== location.search.substring(1)) {
        navigate({ search }, { replace: true })
      }
    },
    [generateQueryString, location.search, navigate],
  )

  return { query, update, generateQueryString }
}
