import { Spinner } from 'src/components/ui'

const height = 20
const width = 100
const padding = 2

interface SparklineProps {
  data?: number[]
  isPending: boolean
  color?: string
}

export function Sparkline({
  data = [],
  isPending,
  color = 'slategray',
}: SparklineProps): JSX.Element {
  const max = Math.max(...data)
  const min = Math.min(...data)
  const range = max - min
  const { length } = data

  return isPending ? (
    <div className="flex items-center justify-center">
      <Spinner small inline />
    </div>
  ) : (
    <div className="leading-[0]">
      <svg viewBox={`0 0 ${width + padding * 2} ${height + padding * 2}`}>
        <polyline
          points={data
            .map(val => (max - val) / range)
            .map((y, x) => ({
              x: padding + (x * width) / (length - 1),
              y:
                range === 0 ? (height + padding * 2) / 2 : padding + y * height,
            }))
            .map(({ x, y }) => `${x},${y}`)
            .join(' ')}
          style={{
            stroke: color,
            strokeWidth: '1',
            strokeLinejoin: 'round',
            strokeLinecap: 'round',
            fill: 'none',
          }}
        />
      </svg>
    </div>
  )
}
