import styled from 'styled-components'

export const AssetActionBar = styled.div`
  border-bottom: 1px solid #d9d9d9;
  background-color: #fefefe;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 2px 10px 2px 15px;
  justify-content: space-between;
  gap: 5px;
`

export const TagsActionBar = styled.div`
  border-bottom: 1px solid #d9d9d9;
  background-color: #fefefe;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 5px 2px 13px;
`

export const ButtonAdd = styled.div`
  padding-left: 10px;
  display: flex;
  align-items: center;
  padding: 0 30px;
`

export const TagTableContainer = styled.div`
  margin: 10px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  max-height: calc(100% - 80px);
`
