import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  extractSegmentationDef,
  extractTimeRangeDef,
  extractTimeSeriesDef,
} from 'data-explorer/data-explorer.utils'
import { Accordion, Button } from 'src/components/ui'
import { OverlaidSegments } from 'src/dex/analysis'
import { SavedTimeRange } from 'src/dex/time_range'
import { SavedSegmentation } from 'src/dex/segmentation'
import { SavedTimeSeries } from 'src/dex/time_series'
import { SegmentationDisplay, TimeRangeDisplay } from '../../components'
import { OverlaidSegmentsAxisDisplay, VisualOptionsDisplay } from './components'

type Props = {
  analysis: OverlaidSegments
  workspaceTimeRanges: SavedTimeRange[]
  workspaceSegmentations: SavedSegmentation[]
  workspaceTimeSeries: SavedTimeSeries[]
}

export function OverlaidSegmentsAnalysis({
  analysis,
  workspaceTimeRanges,
  workspaceSegmentations,
  workspaceTimeSeries,
}: Props): JSX.Element {
  const timeRange = analysis.timeRange
    ? extractTimeRangeDef(analysis.timeRange, workspaceTimeRanges)
    : undefined

  const segmentation = analysis.segmentation
    ? extractSegmentationDef(analysis.segmentation, workspaceSegmentations)
    : undefined

  const yAxis = analysis.y
    ? extractTimeSeriesDef(analysis.y, workspaceTimeSeries)
    : undefined

  return (
    <div className="flex flex-col gap-s">
      {timeRange && (
        <Accordion label="Time range" labelVariant="content" initialIsOpen>
          <div className="flex flex-col gap-s">
            <TimeRangeDisplay timeRange={timeRange} />
            {segmentation && (
              <SegmentationDisplay
                segmentation={segmentation}
                timeSeries={workspaceTimeSeries}
              />
            )}
          </div>
        </Accordion>
      )}
      <Accordion label="Axes" labelVariant="content" initialIsOpen>
        {yAxis && <OverlaidSegmentsAxisDisplay axis={yAxis} />}
      </Accordion>
      <Accordion label="Visual Options" labelVariant="content" initialIsOpen>
        <VisualOptionsDisplay
          color={analysis.color}
          workspaceTimeSeries={workspaceTimeSeries}
        />
      </Accordion>
      <Accordion label="Result" labelVariant="content" initialIsOpen>
        <Button
          variant="icon-primary"
          icon={light('play')}
          title="Run Analysis"
        />
      </Accordion>
    </div>
  )
}
