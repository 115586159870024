import Highcharts from 'highcharts/highstock'
import { prettyDate, prettyTime } from 'src/utility/time'
import { prettyTooltipValue as format } from 'src/utility/numbers'

Highcharts.wrap(
  Highcharts.Tooltip.prototype,
  'refresh',
  function (proceed, points, event) {
    if (event === undefined) {
      // drop refresh calls triggered by mouse
      proceed.call(this, points)
    } else if (
      window.location.pathname !== '/trend' ||
      !window.location.pathname.startsWith('/anomalies')
    ) {
      // unless we're on old pages
      proceed.call(this, points, event)
    }
  },
)

Highcharts.wrap(
  Highcharts.Tooltip.prototype,
  'hide',
  function (proceed, ...args) {
    // drop hide calls on new pages
    if (
      window.location.pathname !== '/trend' ||
      !window.location.pathname.startsWith('/anomalies')
    ) {
      proceed.call(this, args)
    }
  },
)

function positioner(length, height, point) {
  const { plotHeight, plotLeft, plotTop } = this.chart
  const padding = 8

  return {
    x:
      point.plotX < 0
        ? plotLeft
        : length + padding > point.plotX
        ? point.plotX + plotLeft + padding
        : point.plotX + plotLeft - (length + padding),
    y: (plotHeight - height) / 2 + plotTop,
  }
}

function formatter() {
  const subtle = value =>
    `<div style="color: gray; font-size: 0.75em">${value}</div>`
  const box = color =>
    `<div style="height: 1em; width: 1em; background-color: ${color}; margin-right: 0.2em;"></div>`
  const emphasized = (value, color) =>
    `<div style="font-weight: bold; display: flex; align-items: center;">${
      color ? box(color) : ''
    }${value}</div>`

  const values = point => {
    switch (point.series.type) {
      case 'arearange':
        return {
          name: point.series.userOptions.name,
          color: point.series.userOptions.color,
          low: point.point.low,
          high: point.point.high,
        }
      case 'line':
        return {
          name: point.series.userOptions.name,
          color: point.series.userOptions.color,
          timestamp: point.x,
          value: point.y,
        }
      default:
        return {}
    }
  }

  const points = Object.values(
    this.points.map(values).reduce(
      (obj, point) => ({
        ...obj,
        [point.name]: {
          ...(obj[point.name] || {}),
          ...point,
        },
      }),
      {},
    ),
  )

  const timestamp = Math.max(...points.map(({ timestamp }) => timestamp))

  if (points.length === 1) {
    const { value, high, low } = points[0]
    return [
      subtle(prettyDate(timestamp)),
      subtle(prettyTime(timestamp)),
      emphasized(format(value)),
      high !== undefined ? subtle(`Max: ${format(high)}`) : undefined,
      low !== undefined ? subtle(`Min: ${format(low)}`) : undefined,
    ].join('')
  }
  return [
    subtle(prettyDate(timestamp)),
    subtle(prettyTime(timestamp)),
    ...points.map(({ value, color }) => emphasized(format(value), color)),
  ].join('')
}

export const baseTooltipOptions = {
  animation: false,
  borderRadius: 8,
  borderWidth: 0,
  followPointer: false,
  formatter,
  padding: 6,
  positioner,
  shadow: false,
  shared: true,
  style: {
    color: 'black',
    fontSize: '1em',
  },
  useHTML: true,
}
