import React from 'react'
import { NavigationContext, NavigationValue } from './NavigationContext'

export const useNavigationContext = (): NavigationValue => {
  const context = React.useContext(NavigationContext)
  if (context === undefined) {
    throw new Error('useNavigationContext used without a NavigationProvider')
  }
  return context
}
