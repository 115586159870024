export interface RetryState {
  verifyAttempts: number
  search?: string
}

export function initializeState(state: unknown): RetryState {
  const s = state as RetryState
  if (
    s &&
    typeof s.verifyAttempts === 'number' &&
    ['string', 'undefined'].includes(typeof s.search)
  ) {
    return s
  }
  return { verifyAttempts: 0 }
}
