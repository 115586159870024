import { useEffect, useState, useRef, memo } from 'react'
import classNames from 'classnames'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Checkbox, Icon, Text, Tooltip } from 'src/components/ui'
import { ChartSize } from 'src/types/chartTypes'
import { useClickOutside } from 'src/utility'

type Option = {
  label: string
  value: ChartSize
}

const sizeOptions: Option[] = [
  { label: 'Small', value: 'small' },
  { label: 'Medium', value: 'medium' },
  { label: 'Large', value: 'large' },
]

type Props = {
  selectedSize: ChartSize
  setSelectedSize: (size: ChartSize) => void
  isFullWidth: boolean
  setIsFullWidth: (isFullWidth: boolean) => void
  disabled?: boolean
}

export const LayoutSizePicker = memo(function LayoutSizePicker({
  selectedSize,
  setSelectedSize,
  isFullWidth,
  setIsFullWidth,
  disabled,
}: Props): JSX.Element {
  const [isSizeOpen, setIsSizeOpen] = useState(false)

  useEffect(() => {
    if (disabled) setIsSizeOpen(false)
  }, [disabled])

  const dropdownRef = useRef<HTMLDivElement>(null)
  useClickOutside(dropdownRef, () => isSizeOpen && setIsSizeOpen(false))
  return (
    <Tooltip
      render={() => (
        <Text>
          Chart size and layout options are available when there is more than
          one chart
        </Text>
      )}
      direction="right"
      isOpen={disabled ? undefined : false}
    >
      <div
        ref={dropdownRef}
        className={classNames(
          'flex items-center justify-between bg-background py-xs px-s border border-solid rounded-2xs transition-all relative min-w-[180px]',
          isSizeOpen ? 'border-border-brand' : 'border-border',
          disabled ? 'opacity-70 cursor-default' : 'cursor-pointer',
        )}
        onClick={() => !disabled && setIsSizeOpen(!isSizeOpen)}
      >
        <div className="flex items-center">
          <Text>Chart Size:</Text>
          <Text bold className="ml-2xs">
            {sizeOptions.find(option => option.value === selectedSize)?.label}
          </Text>
        </div>
        <Icon
          icon={light('chevron-down')}
          className={classNames(
            'ml-xs transition-all',
            isSizeOpen ? 'transform rotate-180' : '',
          )}
        />
        <div
          className={classNames(
            'absolute left-0 top-[calc(100%+4px)] z-50 flex w-full min-w-[180px] flex-col rounded-2xs border border-solid border-border bg-background py-2xs shadow-sm transition-all',
            isSizeOpen
              ? 'opacity-100 scale-100'
              : 'opacity-0 scale-80 pointer-events-none',
          )}
        >
          {sizeOptions.map(option => (
            <div
              className="flex cursor-pointer items-center gap-xs px-xs py-2xs hover:bg-background-hover"
              key={option.value}
              onClick={() => {
                setIsSizeOpen(false)
                setSelectedSize(option.value)
              }}
            >
              <div className="flex h-[14px] w-[14px] items-center justify-center rounded-full border border-solid border-black">
                {option.value === selectedSize && (
                  <div className="h-[8px] w-[8px] rounded-full bg-background-brand" />
                )}
              </div>
              <Text bold className="mr-xs cursor-pointer">
                {option.label}
              </Text>
            </div>
          ))}
          <div className="mx-auto my-2xs h-px w-[calc(100%-16px)] bg-background-tertiary" />
          <div
            className="mx-xs flex items-center gap-xs py-2xs"
            onClick={() => {
              setIsSizeOpen(false)
              setIsFullWidth(!isFullWidth)
            }}
          >
            <Checkbox value={isFullWidth} onChange={() => null} />
            <Text bold>Fixed full width</Text>
          </div>
        </div>
      </div>
    </Tooltip>
  )
})
