import React, { useState } from 'react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useFlags } from 'launchdarkly-react-client-sdk'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'src/contexts/auth'
import { Icon, Text } from 'src/components/ui'
import { useClickOutside } from 'src/utility'
import { zIndex } from 'src/utility/constants/StyleConstants'

const DropDownMenuLink = ({
  icon,
  iconColor,
  title,
  onClick,
}: {
  icon: IconProp
  iconColor?: string
  title: string
  onClick?: () => void
}): JSX.Element => {
  return (
    <div
      className="flex cursor-pointer items-center gap-[10px] whitespace-nowrap px-[12px] py-0 hover:bg-background-hover"
      onClick={onClick}
    >
      <Icon icon={icon} className={classNames('w-[14px]', iconColor)} />
      <Text
        variant="description"
        bold
        className="whitespace-nowrap !leading-[32px]"
      >
        {title}
      </Text>
    </div>
  )
}

const MenuModal = ({
  onClose,
  children,
}: {
  onClose: () => void
  children: React.ReactNode
}): JSX.Element => {
  const menuRef = React.useRef(null)
  useClickOutside(menuRef, onClose)
  return (
    <div
      className="absolute right-0 top-[calc(100%+4px)] rounded-2xs border border-solid border-border bg-background shadow-user-menu"
      style={{ zIndex: zIndex.userMenu }}
      ref={menuRef}
    >
      {children}
    </div>
  )
}

export const UserMenu = (): JSX.Element => {
  const [open, setIsOpen] = useState(false)
  const navigate = useNavigate()
  const {
    logout,
    viewer: { email, name },
  } = useAuth()
  const { downloadsPage } = useFlags()

  return (
    <div
      className={classNames(
        'bg-background flex items-center min-h-[32px] pt-2xs px-[10px] pb-[6px] relative cursor-pointer leading-[1.3] rounded-2xs border border-solid',
        open ? 'border-border-brand' : 'border-border',
      )}
      onClick={() => {
        setIsOpen(!open)
      }}
    >
      <Icon className="mr-[15px] h-icon-small" icon={light('user')} />
      <Icon
        icon={light('chevron-down')}
        className={classNames(
          'mt-[2px] transition-all',
          open ? 'rotate-180' : 'rotate-0',
        )}
      />
      {open && (
        <MenuModal onClose={() => setIsOpen(false)}>
          <div style={{ padding: '8px 12px' }}>
            <span style={{ fontWeight: 500, fontSize: 12, lineHeight: '14px' }}>
              {name}
            </span>
            <span style={{ fontWeight: 300, fontSize: 10, display: 'block' }}>
              {email}
            </span>
          </div>
          <DropDownMenuLink
            title={'Account Settings'}
            icon={light('user-gear')}
            onClick={() => {
              setIsOpen(false)
              navigate('/settings')
            }}
          />
          <DropDownMenuLink
            title={'Organizations & Sites'}
            icon={light('building-user')}
            onClick={() => {
              setIsOpen(false)
              navigate('/settings/orgs')
            }}
          />
          {downloadsPage && (
            <DropDownMenuLink
              title={'Downloads'}
              icon={light('download')}
              onClick={() => {
                setIsOpen(false)
                navigate('/downloads')
              }}
            />
          )}
          <hr style={{ marginTop: 2, marginBottom: 0 }} />
          <div style={{ margin: '2px 0px' }}>
            <DropDownMenuLink
              title={'Sign Out'}
              icon={light('arrow-right-from-bracket')}
              iconColor={'red'}
              onClick={() => logout()}
            />
          </div>
        </MenuModal>
      )}
    </div>
  )
}
