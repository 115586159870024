import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Outlet, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import {
  BreadcrumbNavigation,
  Icon,
  RoutingTabs,
  Spinner,
  Text,
} from 'src/components/ui'
import { ErrorDisplay } from 'pages/app'
import { useNavigationContext } from 'src/contexts/navigation'
import { useSite } from 'src/contexts/site'
import { useWorkspace } from './api'

export function WorkspaceDetailsPage(): JSX.Element {
  const { workspaceId } = useParams()
  const { rootLink } = useSite()
  if (!workspaceId) {
    throw new Error('Workspace id is required')
  }
  const workspaceQuery = useWorkspace(workspaceId)
  const { setTitleComponent } = useNavigationContext()

  useEffect(() => {
    if (workspaceQuery.isSuccess) {
      setTitleComponent(
        <BreadcrumbNavigation
          navigationPath={{
            label: 'Data Explorer',
            url: `${rootLink}/data-explorer-new`,
            child: {
              label: workspaceQuery.data.label,
              url: `${rootLink}/data-explorer-new/workspace/${workspaceId}`,
            },
          }}
        />,
      )
    }

    return () => {
      setTitleComponent(null)
    }
  }, [
    rootLink,
    setTitleComponent,
    workspaceId,
    workspaceQuery.data,
    workspaceQuery.isSuccess,
  ])

  if (workspaceQuery.isLoading) {
    return <Spinner />
  }
  if (workspaceQuery.isError) {
    return (
      <ErrorDisplay
        error={workspaceQuery.error}
        message="Failed to fetch the Workspace"
        action={workspaceQuery.refetch}
      />
    )
  }

  const tabs = [
    {
      title: 'Analyses',
      path: ``,
    },
    { title: 'Tags and Time Series', path: 'tags' },
    { title: 'Time Ranges', path: 'time-ranges' },
    { title: 'Segmentations', path: 'segmentations' },
  ]

  return (
    <div className="m-s mt-0 flex h-[calc(100%-1em)] flex-col rounded-2xs bg-white p-m">
      <div className="mb-s flex w-full items-start justify-start gap-l">
        <Icon
          icon={light('folder-magnifying-glass')}
          className="!h-[30px] !w-[36px] text-icon-secondary"
        />
        <div className="flex gap-s">
          <div>
            <Text variant="description" className="mb-2xs">
              Workspace
            </Text>
            <Text variant="content" bold>
              {workspaceQuery.data.label}
            </Text>
          </div>
          <div className="max-w-[400px]">
            <Text variant="description" className="mb-2xs">
              Description
            </Text>
            <Text variant="content" bold>
              {workspaceQuery.data.description}
            </Text>
          </div>
        </div>
      </div>
      <RoutingTabs tabs={tabs} />
      <Outlet />
    </div>
  )
}
