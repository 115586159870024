import { isAfter } from 'date-fns'
import { useSearchParams } from 'react-router-dom'
import { Text, IntelecySpinner } from 'src/components/ui'
import { ErrorDisplay } from 'pages/app'
import { useDocsUrl } from './docs.api'

export function DocsPage(): JSX.Element {
  const { data, isError, error, refetch, isSuccess } = useDocsUrl()
  const [searchParams] = useSearchParams()
  const location = searchParams.get('location')

  if (isSuccess) {
    // if it's expired, refetch
    if (!isAfter(data.expiry, new Date())) {
      refetch()
    } else {
      window.location.replace(
        `${data.url}${location ?? ''}?jwt_token=${data.token}`,
      )
    }
  }

  if (isError) {
    return (
      <ErrorDisplay
        error={error}
        message="Something went wrong"
        action={refetch}
      />
    )
  }

  return (
    <div className="flex h-screen flex-1 flex-col justify-center gap-s rounded-xs bg-background">
      <IntelecySpinner size={2} />
      <Text variant="title" className="text-center !text-2xl">
        Loading...
      </Text>
    </div>
  )
}
