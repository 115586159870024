import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useAnomalyCountByState } from 'anomalies/api'
import { Icon, Text, Spinner } from 'src/components/ui'
import { CardHeader } from '../CardHeader'

export function AnomalyStatusCard(): JSX.Element {
  const anomalyCountByStateQuery = useAnomalyCountByState()

  const counts = {
    new: 0,
    inProgress: 0,
    closed: 0,
  }

  for (const stateCount of anomalyCountByStateQuery.data ?? []) {
    switch (stateCount.state) {
      case 'NEW':
        counts.new += stateCount.count
        break
      case 'IN_PROGRESS':
        counts.inProgress += stateCount.count
        break
      default:
        counts.closed += stateCount.count
    }
  }

  const sections = [
    {
      title: 'New',
      count: counts.new,
      color: 'text-text-danger',
    },
    {
      title: 'In progress',
      count: counts.inProgress,
      color: 'text-text-warning',
    },
    {
      title: 'Closed',
      count: counts.closed,
      color: 'text-text-success',
    },
  ]

  return (
    <>
      <CardHeader>
        <Icon icon={light('triangle-exclamation')} />
        <Text variant="title">Anomalies status</Text>
      </CardHeader>
      <div className="grid grid-cols-3 gap-0">
        {sections.map(({ title, count, color }) => (
          <div className="grid items-center justify-center" key={title}>
            <div>
              <p
                className={`my-0 text-center font-roboto text-[29px] leading-1.5 ${color}`}
              >
                {title}
              </p>
            </div>
            <div>
              {anomalyCountByStateQuery.isLoading ? (
                <Spinner small inline />
              ) : (
                <p className="my-0 text-center font-roboto text-[40px] leading-1.5 text-icon-success-secondary">
                  {count}
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
