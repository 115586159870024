import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import React, { memo, useState } from 'react'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { Icon, Text } from 'src/components/ui'
import { useClickOutside } from 'src/utility'
import { ChartType, getChartTypeLabel } from 'src/types/chartTypes'

type Option = {
  label: JSX.Element
  value: ChartType
}

function makeOption(type: ChartType, icon: IconDefinition): Option {
  return {
    label: (
      <div className="flex items-center gap-xs">
        <Icon icon={icon} />
        <Text bold className="whitespace-nowrap">
          {getChartTypeLabel(type)}
        </Text>
      </div>
    ),
    value: type,
  }
}

const ChartTypeOptions = [
  makeOption(ChartType.TimeSeries, light('chart-line')),
  makeOption(ChartType.Scatter, light('chart-scatter')),
]

type ChartTypePickerProps = {
  onClick: () => void
  chartType: ChartType
  setChartType: (chartType: ChartType) => void
}

export const ChartTypePicker = memo(function ChartTypePicker({
  onClick,
  chartType,
  setChartType,
}: ChartTypePickerProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = React.useRef<HTMLDivElement>(null)

  useClickOutside(dropdownRef, () => {
    if (isOpen) {
      setIsOpen(false)
    }
  })
  return (
    <div
      className={`relative flex w-[180px] cursor-pointer items-center justify-between gap-s rounded-2xs border border-solid bg-background transition duration-300 ${
        isOpen ? 'border-border-brand' : 'border-border'
      }`}
    >
      <div className="flex w-full items-center justify-between">
        <div onClick={onClick} className="flex-1 py-xs pl-s">
          {ChartTypeOptions.find(option => option.value === chartType)?.label}
        </div>
        <div
          className="flex items-center justify-end gap-xs py-xs pr-s"
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        >
          <div className="h-icon-big w-px bg-black" />
          <Icon
            icon={light('chevron-down')}
            className={classNames(
              'transition-all',
              isOpen ? 'rotate-180' : 'rotate-0',
            )}
          />
        </div>
      </div>
      {isOpen && (
        <div
          ref={dropdownRef}
          className="absolute right-0 top-[calc(100%+4px)] z-20 flex min-w-full flex-col rounded-2xs border border-solid border-border bg-background py-2xs drop-shadow-dropdown"
        >
          {ChartTypeOptions.map(option => (
            <div
              className="px-xs py-2xs transition-all hover:bg-background-hover"
              key={option.value}
              onClick={() => {
                setChartType(option.value)
                setIsOpen(false)
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  )
})
