import { Text } from 'src/components/ui'
import { tagLabelToText } from 'src/services'
import { getBaseFilterParams } from '../table.utils'
import { TableConfig } from '../table.types'

type Props = {
  canDrag: boolean
}
export const getAssetTagsTableConfig = ({ canDrag }: Props): TableConfig => {
  return {
    pageSize: 25,
    allowPaging: true,
    allowSorting: false,
    pageSizes: [5, 10, 25, 100],
    disallowSelection: false,
    columns: [
      {
        headerName: 'Tag',
        dndSource: canDrag,
        filterParams: getBaseFilterParams(true),
        valueGetter: ({ data: { displayName, tagName } }: any) =>
          displayName ?? tagName,
        initialFlex: 140,
        cellRenderer: ({ value }: any) => <Text>{value}</Text>,
      },
      {
        field: 'parentName',
        headerName: 'Parent Asset',
        initialFlex: 140,
        cellRenderer: ({ value }: any) => <Text>{value}</Text>,
      },
      {
        field: 'label',
        headerName: 'Label',
        initialFlex: 140,
        filterValueGetter: ({ data }: any) => {
          if (data.label) return tagLabelToText(data.label)
          return ''
        },
        cellRenderer: ({ value }: any) => (
          <Text>{value ? tagLabelToText(value) : ''}</Text>
        ),
      },
      {
        field: 'description',
        headerName: 'Description',
        filterParams: getBaseFilterParams(true),
        initialFlex: 140,
        cellRenderer: ({ value }: any) => <Text>{value}</Text>,
      },
      {
        field: 'engUnit',
        headerName: 'Unit',
        initialFlex: 120,
        cellRenderer: ({ value }: any) => <Text>{value}</Text>,
      },
    ],
  }
}
