import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Spinner } from 'src/components/ui'
import { ErrorDisplay } from 'pages/app'
import { useGateways } from 'gateway/api'
import {
  ConnectionStatus,
  OpcUaConnectionConfiguration,
} from './opc-ua-connection.types'
import {
  getDefaultConfig,
  parseConnectionConfig,
  parseEndpointsToSecurityOptions,
} from './opc-ua-connection.utils'
import {
  StepAccordion,
  OpcUaConnectionDetailsStep,
  OpcUaSecurityDetailsStep,
} from './opc-ua-configuration'
import {
  useCreateOpcUaConnection,
  useTestOpcConnectionMutation,
  useUpdateOpcUaConnection,
  useValidateOpcConnectionMutation,
} from './opc-ua-connection.api'

enum OpcUaConfigSteps {
  ConnectionDetails = 0,
  SecurityDetails = 1,
}
export function OpcUaConfigurationTab(): JSX.Element {
  const { orgId, siteId, gatewayId, connectionId } = useParams()
  if (!orgId || !siteId || !gatewayId)
    throw new Error('Missing orgId or siteId')
  const gatewaysQuery = useGateways(siteId)
  const testOpcConnectionMutation = useTestOpcConnectionMutation(
    siteId,
    gatewayId,
  )
  const validateOpcConnectionMutation = useValidateOpcConnectionMutation(
    siteId,
    gatewayId,
  )

  const [config, setConfig] = useState<OpcUaConnectionConfiguration>(
    getDefaultConfig(),
  )
  const [stepOpen, setStepOpen] = useState<number>(
    !connectionId
      ? config.detailsCompleted
        ? config.securityCompleted
          ? 2
          : 1
        : 0
      : -1,
  )
  const navigate = useNavigate()
  const createOpcUaConnectionMutation = useCreateOpcUaConnection(siteId)
  const updateOpcConnectionMutation = useUpdateOpcUaConnection(siteId)

  const selectedGateway = gatewaysQuery.data?.find(
    gateway => gateway.id === gatewayId,
  )

  const getConnectionSecurityOptions = useCallback(
    async (connection: OpcUaConnectionConfiguration) => {
      if (!siteId || !gatewayId) return
      await testOpcConnectionMutation.mutateAsync(connection.serverEndpoint, {
        onSuccess: data => {
          if (data.factory?.agent?.serverEndpoints.error) {
            setConfig({
              ...connection,
              connectionState: ConnectionStatus.FAILED,
              detailsCompleted: false,
            })
            setStepOpen(OpcUaConfigSteps.ConnectionDetails)
            return
          }
          const securityOptions = parseEndpointsToSecurityOptions(data)
          setConfig({
            ...connection,
            connectionState: ConnectionStatus.VERIFIED,
            securityOptions: securityOptions,
            detailsCompleted: true,
          })
          setStepOpen(OpcUaConfigSteps.SecurityDetails)
        },
        onError: () => {
          setConfig({
            ...connection,
            connectionState: ConnectionStatus.FAILED,
            detailsCompleted: false,
          })
          setStepOpen(OpcUaConfigSteps.ConnectionDetails)
        },
      })
    },
    // Prevent re-running this effect when properties of testOpcConnectionMutation change.
    // The effect should only re-run if the mutation function itself changes, avoiding infinite loops.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gatewayId, siteId, testOpcConnectionMutation.mutateAsync],
  )

  useEffect(() => {
    if (connectionId && selectedGateway) {
      const connection = selectedGateway.opcConnections?.items?.find(
        c => c?.id === connectionId,
      )
      if (connection) {
        const connectionConfig = parseConnectionConfig(connection)
        if (
          connectionConfig.detailsCompleted &&
          !connectionConfig.securityOptions
        ) {
          getConnectionSecurityOptions(connectionConfig)
        }
      }
    }
  }, [connectionId, selectedGateway, getConnectionSecurityOptions])

  function handleStepOpen(step: OpcUaConfigSteps, isOpen: boolean): void {
    if (isOpen) {
      setStepOpen(step)
    } else {
      setStepOpen(-1)
    }
  }

  async function handleVerifyConnection(): Promise<void> {
    if (!siteId || !gatewayId) return
    const newConfig = {
      ...config,
      connectionVerifyText: '',
      connectionState: ConnectionStatus.VERIFYING,
    }
    setConfig(newConfig)
    await testOpcConnectionMutation.mutateAsync(config.serverEndpoint, {
      onSuccess: ({ factory }) => {
        if (factory?.agent?.serverEndpoints.error) {
          setConfig({
            ...newConfig,
            connectionState: ConnectionStatus.FAILED,
            connectionVerifyText: factory?.agent?.serverEndpoints.error,
          })
          return
        }
        const securityOptions = parseEndpointsToSecurityOptions({ factory })
        setConfig({
          ...newConfig,
          connectionState: ConnectionStatus.VERIFIED,
          securityOptions: securityOptions,
        })
      },
      onError: () => {
        setConfig({
          ...newConfig,
          connectionState: ConnectionStatus.FAILED,
          connectionVerifyText: 'Failed to verify connection',
        })
        return
      },
    })
  }

  async function handleSaveDetails(): Promise<void> {
    if (!gatewayId) return
    if (connectionId) {
      await updateOpcConnectionMutation.mutateAsync(
        {
          opcConnectionId: connectionId,
          endpoint: config.serverEndpoint,
          ignoreServerEndpoint: true,
          name: config.connectionName,
        },
        {
          onSuccess: data => {
            setConfig({
              ...config,
              connectionId: data?.id,
              detailsCompleted: true,
            })
            handleStepOpen(OpcUaConfigSteps.SecurityDetails, true)
          },
          onError: () => {
            setConfig({
              ...config,
              connectionState: ConnectionStatus.FAILED,
              connectionVerifyText: 'Failed to save connection',
            })
          },
        },
      )
    } else {
      await createOpcUaConnectionMutation.mutateAsync(
        {
          agentId: gatewayId,
          endpoint: config.serverEndpoint!,
          ignoreServerEndpoint: true,
          name: config.connectionName ?? '',
        },
        {
          onSuccess: data => {
            if (data?.id) {
              navigate(
                `/settings/orgs/${orgId}/sites/${siteId}/gateways/${gatewayId}/opc-ua/${data.id}/configuration`,
              )
            }
            setConfig({
              ...config,
              connectionId: data?.id ?? '',
              detailsCompleted: true,
            })
            handleStepOpen(OpcUaConfigSteps.SecurityDetails, true)
          },
          onError: () => {
            setConfig({
              ...config,
              connectionState: ConnectionStatus.FAILED,
              connectionVerifyText: 'Failed to save connection',
            })
          },
        },
      )
    }
  }

  async function handleVerifySecurityDetails(): Promise<void> {
    if (!siteId || !connectionId || !gatewayId) return
    const updatedConfig = {
      ...config,
      securityConnectionState: ConnectionStatus.VERIFYING,
    }
    setConfig(updatedConfig)
    await validateOpcConnectionMutation.mutateAsync(
      {
        endpoint: config.serverEndpoint,
        ignoreServerEndpoint: true,
        securityMode: config.securityType,
        securityPolicy: config.securityPolicy,
        x509Thumbprint: config.securityCertificate,
      },
      {
        onSuccess: data => {
          if (!data.factory?.agent?.validateOpcConnection?.success) {
            setConfig({
              ...updatedConfig,
              securityConnectionState: ConnectionStatus.FAILED,
              securityResponseText:
                data.factory?.agent?.validateOpcConnection?.error ??
                'Failed to validate connection',
            })
            return
          } else {
            setConfig({
              ...updatedConfig,
              securityConnectionState: ConnectionStatus.VERIFIED,
            })
          }
        },
        onError: () => {
          setConfig({
            ...updatedConfig,
            securityConnectionState: ConnectionStatus.FAILED,
            securityResponseText: 'Failed to validate connection',
          })
        },
      },
    )

    // If verification is successful, update the connection
    if (!config.connectionId) return
    await updateOpcConnectionMutation.mutateAsync(
      {
        opcConnectionId: config.connectionId,
        name: config.connectionName,
        endpoint: config.serverEndpoint,
        ignoreServerEndpoint: true,
        securityMode: config.securityType,
        securityPolicy: config.securityPolicy,
        x509Thumbprint: config.securityCertificate,
      },
      {
        onSuccess: () => {
          setConfig({
            ...config,
            securityCompleted: true,
            securityConnectionState: ConnectionStatus.VERIFIED,
          })
        },
        onError: () => {
          setConfig({
            ...config,
            securityConnectionState: ConnectionStatus.FAILED,
            securityResponseText: 'Failed to save security settings',
          })
        },
      },
    )
  }

  async function handleFinish(): Promise<void> {
    if (!gatewayId && !connectionId) return
    navigate(
      `/settings/orgs/${orgId}/sites/${siteId}/gateways/${gatewayId}/opc-ua/${connectionId}`,
    )
  }

  if (gatewaysQuery.isLoading) return <Spinner />
  if (gatewaysQuery.error)
    return (
      <ErrorDisplay
        error={gatewaysQuery.error}
        action={gatewaysQuery.refetch}
        message="Failed to load gateways"
      />
    )

  return (
    <div className="flex flex-col gap-s">
      {config && selectedGateway && gatewaysQuery.data && (
        <div className="flex flex-col gap-xs">
          <StepAccordion
            isOpen={stepOpen === OpcUaConfigSteps.ConnectionDetails}
            setIsOpen={isOpen =>
              handleStepOpen(OpcUaConfigSteps.ConnectionDetails, isOpen)
            }
            index={1}
            state={config.detailsCompleted ? 'complete' : 'active'}
            title="OPC-UA Connection Details"
          >
            {selectedGateway && (
              <OpcUaConnectionDetailsStep
                disabled={
                  createOpcUaConnectionMutation.isLoading ||
                  config.connectionConfigured
                }
                gateway={selectedGateway}
                config={config}
                setConfig={setConfig}
                verifyConnection={handleVerifyConnection}
                handleSave={handleSaveDetails}
              />
            )}
          </StepAccordion>
          <StepAccordion
            isOpen={stepOpen === OpcUaConfigSteps.SecurityDetails}
            setIsOpen={isOpen =>
              handleStepOpen(OpcUaConfigSteps.SecurityDetails, isOpen)
            }
            index={2}
            state={
              config.detailsCompleted
                ? config.securityCompleted
                  ? 'complete'
                  : 'active'
                : 'disabled'
            }
            title="Security Settings"
          >
            <OpcUaSecurityDetailsStep
              disabled={config.connectionConfigured}
              config={config}
              setConfig={setConfig}
              verifyConnection={handleVerifySecurityDetails}
              handleFinish={handleFinish}
            />
          </StepAccordion>
        </div>
      )}
    </div>
  )
}
